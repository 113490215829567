<template>
    <div>
        <competition-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                                 :sortData="filterSet.sort"></competition-filter-link>
        <competition-list-link :listLayout="listLayout" :competitionData="competitionList"
                               :paginationData="filterSet.page"
                               v-if="competitionList.length > 0"></competition-list-link>
        <competition-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList"
                                v-else></competition-empty-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList" v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import CompetitionListLink from "@/components/competition/CompetitionListLink";
import CompetitionEmptyLink from "@/components/competition/CompetitionEmptyLink";
import CompetitionFilterLink from "@/components/competition/CompetitionFilterLink";
import PaginationLink from "@/components/competition/PaginationLink";
import competitionUtil from "@/components/competition/competitionUtil";
import alertMixins from "@/mixins/alertMixins";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import DeleteClass from "@/mixins/deleteClass";
import {mapState} from "vuex";

const {filterConfig} = competitionUtil;

export default {
    name: "CompetitionListComponent",
    mixins: [competitionUtil, alertMixins, filterMixins, listMixins],
    components: {
        CompetitionListLink,
        CompetitionEmptyLink,
        CompetitionFilterLink,
        PaginationLink
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setCompetitionListData: this.setData,
        }
    },
    props: {
        competitionType: '',
        competitionKey: '',
        selectArray: {
            type: Array,
            default: () => {
                return []
            }
        },
        memberKey: ''
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: '',
            getMemberKeyName: 'no',
            getKeyName: 'cid',
            selectFilterKeyName: 'i_idx',
            reList: [],
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            deleteClass: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('voteItem', this.voteItemConfirm)
        EventBus.$on('likeCompetition', this.likeCompetition)
        EventBus.$on('deleteCompetition', this.runDelete)
        EventBus.$on('deleteLikeCompetition', this.deleteLikeCompetition)
    },
    beforeDestroy() {
        EventBus.$off('voteItem')
        EventBus.$off('likeCompetition')
        EventBus.$off('deleteCompetition')
        EventBus.$off('deleteLikeCompetition')
    },
    destroyed() {
    },
    computed: {
        competitionList() {
            return this.reList
        },
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let competitionConfig = this.returnCompetitionConfig(this.competitionType);
            this.listLayout = competitionConfig.listLayout;
            this.paginationLayout = competitionConfig.paginationLayout;
            this.filterLayout = competitionConfig.filterLayout;
            this.emptyLayout = competitionConfig.emptyLayout;
            this.getFunc = competitionConfig.getFunc;
            this.listApiParamSet = competitionConfig.listApiParamSet;
            this.pageRow = competitionConfig.pageRow;
            this.getMemberKeyName = competitionConfig.getMemberKeyName;
            this.getKeyName = competitionConfig.getKeyName;
            this.selectFilterKeyName = competitionConfig.selectFilterKeyName;

            if (competitionConfig.pageRow && competitionConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = competitionConfig.pageRow;
                this.filterSet.page.size = competitionConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if (!util.isEmpty(this.competitionKey) && !util.isEmpty(this.getKeyName)) {
                params[this.getKeyName] = this.competitionKey
            } else if (!util.isEmpty(this.competitionKey)) {
                params.cid = this.competitionKey
            }
            if (!util.isEmpty(this.memberKey) && !util.isEmpty(this.getMemberKeyName)) {
                params[this.getMemberKeyName] = this.memberKey
            } else if (!util.isEmpty(this.memberKey)) {
                params.no = this.memberKey
            }

            let listOptions = {
                url: this.$api.$competition[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = competitionConfig.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);

            this.initDeleteClass();

        },
        initDeleteClass() {
            let options = {
                func: this.$api.$competition[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.deleteKayName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        setData(key, value) {
            this[key] = value
        },
        //공모전 투표
        voteItemConfirm(ci_idx) {
            this.createConfirm({
                content: this.$t('confirm_vote'),
                confirm: () => {
                    this.voteItem(ci_idx)
                },
                cancel: () => {

                }
            })
        },
        voteItem(ci_idx) {
            let params = {
                ci_idx: ci_idx
            }
            try {
                this.$api.$competition.voteItem(params).then(res => res.Data.Result).then(res => {
                    let result = this.returnVoteType(res.Info.type)
                    if (result === true) {
                        this.getList()
                        this.createAlert({
                            content: this.$t('vote_done'),
                            hide: () => {
                                // this.$router.back();
                                // this.getList()
                            }
                        })
                    } else {
                        this.createAlert({
                            content: result,
                            btnText: this.$t('yes'),
                            hide: () => {
                                // this.$router.back();
                            }
                        })
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        returnVoteType(type) {
            switch (type) {
                case 1 : {
                    return true
                }
                case -841 : {
                    return this.$t('vote_err_date')
                }
                case -842 : {
                    return this.$t('vote_err_over')
                }
                case -843 : {
                    return this.$t('vote_err_already')
                }
                case -844 : {
                    return this.$t('vote_err_fail')
                }
                default : {
                    return this.$t('vote_err_fail2')
                }
            }
        },
        //응모 아이템 삭제
        deleteAfterFunc() {
            this.createAlert({
                content: this.$t('cancel_success_msg'),
                hide: () => {
                    this.getList()
                }
            })
        },
        runDelete(key) {
            // this.deleteClass.runDelete(key);
            let params = {ci_idx: key}
            try {
                this.$api.$competition.deleteCompetitionEntryItem(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.deleteAfterFunc()
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        /* deleteEntry(key){
             let params = {ci_idx : key}
            try {
                 this.$api.$competition.deleteCompetitionEntryItem(params).then(res => res.Data.Result).then(res =>{
                 })
             }catch (e) {
                 console.log(e)
             }
 }*/
        likeCompetition(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.c_idx = key
                try {
                    this.$api.$competition.competitionLike(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type === 1) {
                            this.likeAfterFunc(key)
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        likeAfterFunc(key) {
            let likeIndex = this.List.findIndex(competition => competition.c_idx === key);
            if (likeIndex > -1) {
                if (!this.List[likeIndex].LikeStatus) {
                    this.List[likeIndex].likeCnt = this.List[likeIndex].likeCnt + 1;
                } else {
                    this.List[likeIndex].likeCnt = this.List[likeIndex].likeCnt - 1;
                }
                this.List[likeIndex].LikeStatus = !this.List[likeIndex].LikeStatus;
                this.$forceUpdate();
            }
        },
        deleteLikeCompetition(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.c_idx = key
                try {
                    this.$api.$competition.competitionLike(params).then(res => res.Data.Result).then(res => {
                        if (res.Info.type === 1) {
                            this.deleteLikeAfterFunc(key)
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        deleteLikeAfterFunc(key) {
            let likeIndex = this.List.findIndex(competition => competition.c_idx === key);
            if (likeIndex > -1) {
                this.List.splice(likeIndex, 1);
                this.filterSet.page.total = this.filterSet.page.total - 1;
                if (this.List.length < this.filterSet.page.total) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.page = this.filterSet.page.page * this.filterSet.page.size;
                    params.sp = 1;
                    this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if (res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            }
        }

    },
    watch: {
        'List': {
            deep: true,
            handler: function (val, oldVal) {
                this.reList = [...this.List]
                if (!util.isEmpty(this.selectArray)) {
                    this.reList = this.List.filter(data => {
                        let keyName = util.isEmpty(this.selectFilterKeyName) ? 'i_idx' : this.selectFilterKeyName;
                        return this.selectArray.indexOf(data[keyName]) < 0
                    });
                    /*for (let val of this.selectArray) {
                        console.log()
                        let idx = this.reList.findIndex(value => {
                            return value.i_idx === val
                        })
                        this.reList.splice(idx, 1)
                    }*/

                }

            }
        }
    },
}
</script>

<style scoped>

</style>
