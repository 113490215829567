import Api from '@/mixins/list/api';
import ElasticLazy from "@/mixins/list/elasticLazy";
import Elastic from "@/mixins/list/elastic";
import Detect from "@/mixins/list/detect";

import util from "@/mixins/util";
import EventBus from "../utils/event-bus";

export default {
    data() {
        return {
            List: [],
            listClass: null,

            paginationType: '',
            hasPre: false,
            finishGetList: true,
            isGetFirst: true,
            isGet: false,

        }
    },
    methods: {
        initList(type, options) {
            switch (type) {
                case 'api':
                    this.listClass = new Api(options);
                    break;
                case 'elasticLazy':
                    this.listClass = new ElasticLazy(options);
                    break;
                case 'elastic':
                    this.listClass = new Elastic(options);
                    break;
                case 'detect':
                    this.listClass = new Detect(options);
                    break;
            }
        },
        getList(isFirst = false) {
            this.finishGetList = false;
            if (isFirst) {
                EventBus.$emit('apiLoading', true);
                EventBus.$emit('apiLoadingShow', false)
            }

            try {

                let filterSet = JSON.parse(JSON.stringify(this.getFilterSet()));
                if (isFirst && this.paginationType === 'lazyload' && this.parserType === 'url') {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                    this.List = [];
                    if (!util.isEmpty(this.$route.query.page) && this.$route.query.page != 1) {
                        let url = this.$route.fullPath;
                        url = url.replace(`page=${this.$route.query.page}`, 'page=1');
                        this.$router.replace(url);
                    }
                    this.setPage('page', 1)
                } else if (isFirst && this.paginationType != 'lazyload') {
                    let parsingDataPage = this.getPasingDataPage();
                    filterSet.page.page = parsingDataPage;
                    this.List = [];
                } else if (this.isInitPage === true) {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                }

                if (this.listClass === null) {
                    return false;
                }
                if (this.isInitPage === true && !this.isGetFirst && util.isEmpty(filterSet.filter)) {
                    this.List = [];
                    EventBus.$emit('apiLoading', false);
                    EventBus.$emit('apiLoadingShow', true)
                    return false;
                }

                this.listClass.getList(filterSet, this.isInitPage).then(res => {
                    let list = res.list.slice();
                    if (this.paginationType === 'lazyload' && this.List.length > 0 && this.hasPre) {
                        this.List = list.concat(this.List);
                    } else if (this.paginationType === 'lazyload' && filterSet.page.page > 1) {
                        // this.List.splice(filterSet.page.page -1 * filterSet.page.size, filterSet.page.size);
                        this.List = this.List.concat(list);
                    } else {
                        this.List = list;
                    }
                    if (res.hasOwnProperty('total')) {
                        this.setPage('total', res.total);
                    }
                    if (isFirst && this.paginationType === 'lazyload') {

                    } else if (isFirst) {
                        this.setParsingDataPageFilterSet();
                    }
                    if (this.isInitPage) {
                        this.setPage('page', 1);
                        this.isInitPage = false;
                    }
                    this.finishGetList = true;
                    if (!this.isGet) {
                        this.isGet = true;
                    }
                    EventBus.$emit('apiLoading', false);
                    EventBus.$emit('apiLoadingShow', true)
                })
            } catch (e) {
                console.log(e);
            }
        },
        getterList() {
            return this.List;
        },
    },
    watch: {}
}
