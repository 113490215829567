import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes';
import {AuthCheck} from "@/mixins/Auth";
import EventBus from "@/utils/event-bus";

// 라우터 정의
Vue.use(VueRouter);
const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {

        if(to.path != from.path ) {
            // return
            if(to.path.indexOf('/myprofile') > -1 && from.path.indexOf('/myprofile') > -1) {
                return savedPosition;
            }else if(to.path.indexOf('/creator/') > -1 && from.path.indexOf('/creator/') > -1) {
                return savedPosition;
            }else if(to.path.indexOf('/mypage/') > -1 && from.path.indexOf('/mypage/') > -1) {
                return savedPosition;
            }
            return { x: 0, y: 0 };
        }else{

        }

    },
  mode  : "history",
  routes: routes
});


router.beforeEach(function (to, from, next) { // 토큰이 로컬스토리지에 담겼을 시 사용
  // to: 이동할 url에 해당하는 라우팅 객체
  if (to.matched.some(function (routeInfo) {
    return routeInfo.meta.authRequired;
  })) {
    let status = AuthCheck();
    if (status == 1) {
      next(); // 페이지 전환
    } else {
      //toke 삭제
      next({
        path : '/auth',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next(); // 페이지 전환
  };
});

export default router;
