<template>
    <div>
        <competition-write-link v-for="writeLayout in writeLayouts" :key="`competition${writeLayout}`"
                                :writeLayout="writeLayout" :competitionData="competitionData"></competition-write-link>
    </div>
</template>

<script>
import CompetitionWriteLink from "./CompetitionWriteLink";
import competitionUtil from "./competitionUtil";
import competitionValidator from "@/mixins/validators/competitionValidator";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import elFileMixins from "@/mixins/elFileMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";

export default {
    name: "CompetitionWriteComponent",
    mixins: [competitionUtil, competitionValidator, elFileMixins, awsS3FileUpload],
    components: {
        CompetitionWriteLink,
    },
    inject: ['competitionVueSetData'],
    provide() {
        return {
            competitionWriteSetData: this.setData,
        }
    },
    props: {
        competitionType: {
            type: String,
            default: () => {
                return ''
            }
        },
        competitionKey: {
            type: String,
            default: () => {
                return ''
            }
        },

    },
    data() {
        return {
            writeLayouts: [],
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            keyName: '',
            writeSuccessType: '',
            writeFailTypes: {},
            competitionData: {},
            isRunning: false,
            writeValidatorArr: '',
            getFunc: '',
            detailApiParamsSet: {},
            getKeyName: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        if (!util.isEmpty(this.competitionKey)) {
            this.getCompetition();
        } else {
            this.initTemplateConfig();
        }
        this.setMetaTitle();
    },
    mounted() {
        EventBus.$on('writeCompetition', this.checkValidator);
    },
    beforeDestroy() {
        EventBus.$off('writeCompetition');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setMetaTitle() {
            EventBus.$emit('setMetaTitle', this.$t('e_comp'));
        },
        initConfig() {
            let config = this.returnCompetitionConfig(this.competitionType)
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.writeSuccessType = config.writeSuccessType;
            this.keyName = config.keyName;
            this.writeFailTypes = config.writeFailTypes;
            this.writeValidatorArr = config.writeValidatorArr;
            this.getFunc = config.getFunc
            this.detailApiParamsSet = config.detailApiParamsSet;
            this.getKeyName = config.getKeyName;
        },
        initTemplateConfig() {
            let config = this.returnCompetitionConfig(this.competitionType);
            this.writeLayouts = config.writeLayouts;
        },
        getCompetition() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet))
            params[this.keyName] = this.competitionKey
            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.createAlert({
                            title: '',
                            content: this.$t('no_data_loading'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                this.$router.back();
                            }
                        });
                        return;
                    }
                    this.competitionData = res.List[0]
                    this.initTemplateConfig()
                })
            } catch (e) {
                console.log(e)
            }

        },
        setData(key, value) {
            this[key] = value;
        },
        checkValidator() {
            if (this.isRunning) {
                return false
            }
            this.isRunning = true;
            let validatorArr = JSON.parse(JSON.stringify(this.writeValidatorArr));
            if (this.is_judge === 1 && util.isEmpty(this.competitionKey)) {
                validatorArr.push('judge')
            }
            if (this.is_judge === 1 && !util.isEmpty(this.competitionKey)) {
                validatorArr.push('u_judge')
            }
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.pointConfirm()
                    this.competitionVueSetData('hasAllError', false)
                } else {
                    this.isRunning = false;
                    this.competitionVueSetData('hasAllError', true)
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                    });
                }
            })
        },
        pointConfirm() {
            let point = this.returnCommas(this.prize)
            if (util.isEmpty(this.competitionKey)) {
                this.createConfirm({
                    content: this.$t('competition_point_msg', {point}),
                    confirm: () => {
                        this.writeCompetition();
                    },
                    cancel: () => {
                        this.isRunning = false;
                    }
                });
            } else {
                this.writeCompetition()
            }

        },
        returnCommas(x = 0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        checkUpdateJudge(params) {
            if (params.judge.create.length === 0) {
                delete params.judge.create
            }
            if (params.judge.update.length === 0) {
                delete params.judge.update
            }
            if (params.judge.delete.length === 0) {
                delete params.judge.delete
            }
        },
        writeCompetition() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
            let dataArr = Object.keys(this.parsingData);

            dataArr.forEach(dataKey => {
                params[this.parsingData[dataKey]] = this[dataKey];
            })
            if (this.startStatus === 0) {
                params.c_start_time = this.startDate = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            }
            if (!util.isEmpty(this.competitionKey)) {
                params.c_idx = this.competitionKey;
                if (!util.isEmpty(this.c_idx)) {
                    params.c_idx = this.c_idx;
                }
                this.checkUpdateJudge(params)
            }
            EventBus.$emit('changeLoading', true)

            try {
                this.$api.$competition[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let result = this.returnWriteType(res.Info.type)
                    if (result === 'mg_point_error') {
                        EventBus.$emit('changeLoading', false)
                        this.isRunning = false;
                        this.createConfirm({
                            content: this.$t('buyItemErrorMsg4'),
                            confirm: () => {
                                window.open(`${window.location.origin}/mypage/point/charge`)
                                // this.movePage('/mypage/point')
                            },
                            cancel: () => {

                            }
                        });
                        return false;
                    } else if (result !== true) {
                        EventBus.$emit('changeLoading', false)
                        this.isRunning = false;
                        this.errorAlert(this.$t(result));
                        return false;
                    }
                    if (!util.isEmpty(this.deleteFiles)) {
                        this.runS3ApiDelete(this.deleteFiles)
                    }
                    if (!util.isEmpty(this.fileInfo)) {
                        let key = ''
                        key = !util.isEmpty(this.competitionKey) ? this.competitionKey : res.List.c_idx;

                        //s3파일 등록 및 api 등록
                        this.setMultiFileUpload(key, this.fileInfo)
                    }
                });

            } catch (e) {
                EventBus.$emit('changeLoading', false)
                this.isRunning = false;
                console.log(e);
            }
        },
        returnWriteType(type) {
            if (type == this.writeSuccessType) {
                return true;
            } else {
                this.isRunning = false;
                return util.isEmpty(this.writeFailTypes[type]) ? 'competition_fail' : this.writeFailTypes[type]
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                } else {
                    this.isRunning = false;
                    EventBus.$emit('changeLoading', false)
                }
            })
        },
        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            if (!util.isEmpty(this.competitionKey)) {
                this.createAlert({
                    content: this.$t('modify_done'),
                    hide: () => {
                        this.$router.back()
                    }
                })
            } else {
                let detailKey = key;
                let alertContent = this.$t('create_competition_msg')
                this.createConfirm(
                        {
                            content: alertContent,
                            confirmText: this.$t('check_now'),
                            cancelText: this.$t('check_later'),
                            confirm: () => {
                                this.$router.push(`/mypage/competition`);
                            },
                            cancel: () => {
                                // this.$router.push('/');
                                this.$router.back()
                            }
                        })
            }

        },

        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
