<template>
    <modal name="premium-market-add-modal" class="mypage-modal nick"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <item-write-component :item-type="'premiumMarketAdd'" :item-key="itemData.i_idx"></item-write-component>

            <curation-terms-modal></curation-terms-modal>
        </div>
    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";
import CurationTermsModal from "@/components/modal/CurationTermsModal";
import util from "@/mixins/util";
import ItemWriteComponent from "@/components/item/ItemWriteComponent";

export default {
    name: "PremiumMarketAddModal",
    mixins: [],
    components: {
        ItemWriteComponent,
        CurationTermsModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemData: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.itemData = event.params.itemData
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        openModal(name) {
            this.$modal.show(name);
        },
        modalClose() {
            this.$modal.hide('premium-market-add-modal');
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>