<template>
    <modal name="auction-history-modal" class="auction-history-modal"
           :width="`1200`" :height="`800`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="ah_modal_wrap">
            <div class="top_wrap">
                <div class="modal_title">{{ $t('bid_history') }}</div>
                <div class="close_btn_wrap">
                    <button class="close_modal" @click="modalClose"></button>
                </div>
            </div>
            <item-list-history-component :item-type="'auctionHistory'" :market-key="m_idx"></item-list-history-component>
        </div>
    </modal>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import ItemListHistoryComponent from "@/components/item/ItemListHistoryComponent";

export default {
    name: "AuctionHistoryModal",
    mixins: [],
    components: {
        ItemListHistoryComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            m_idx: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.m_idx = event.params.m_idx;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('auction-history-modal');
        },
        returnUrl() {
            return this.url
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>