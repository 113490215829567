<template>
  <div class="search_detail_wrap">
    <div class="search_item_wrap">
        <div class="search_left_wrap">
            <el-checkbox-group v-model="searchInputOption">
                <el-checkbox :label="'member'">{{ $t('creator') }}</el-checkbox>
                <el-checkbox :label="'item'">{{ $t('item') }}</el-checkbox>
                <el-checkbox :label="'competition'">{{ $t('competition') }}</el-checkbox>
            </el-checkbox-group>
        </div>
        <div class="search_input">
            <input :placeholder="$t('st')" v-model="searchInput" @keypress.enter="movePage()"></input>
            <button class="search_btn" @click="movePage()"><span>{{ $t('search') }}</span><img
                    src="@/assets/image/main/search_btn.png"></button>
        </div>
    </div>
  </div>
</template>

<script>

import alertMixins from "@/mixins/alertMixins";

export default {
    name: "SearchDetailComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            saveFilter: '',
            searchInputOption: [],
            searchInput: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.searchInputOption.push(this.$route.params.type);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage() {
            this.saveFilter = `?sd=${this.searchInput}&sty=${this.searchInputOption}`
            let url = `/search${this.saveFilter}`;
            this.$router.push(url);

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>