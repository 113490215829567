<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list border_none">
<!--                <div class="mypage_item_tab" :class="{ active : tab === 'send'}"
                     v-if="myType === 'normal'"
                     @click="changeTab('send')">
                    {{$t('s_request')}}
                </div>
                <div  class="mypage_item_tab" :class="{ active : tab  === 'request'}"
                      v-if="myType === 'normal'"
                      @click="changeTab('request')">
                    {{ $t('request_item') }}
                </div>
                <div  class="mypage_item_tab" :class="{ active : tab  === 'receive'}"
                      v-if="myType === 'curator'"
                      @click="changeTab('receive')">
                    {{ $t('receive_request') }}
                </div>
                <div  class="mypage_item_tab" :class="{ active : tab  === 'curation'}"
                      v-if="myType === 'curator'"
                      @click="changeTab('curation')">
                    {{ $t('c_item') }}
                </div>-->
<!--                <div class="mypage_item_4_tab" :class="{ active : tab === 'send'}"
                     v-if="myType === 'normal'"
                     @click="changeTab('send')">
                    {{$t('s_request')}}
                </div>
                <div  class="mypage_item_4_tab" :class="{ active : tab  === 'request'}"
                      v-if="myType === 'normal'"
                      @click="changeTab('request')">
                    {{ $t('request_item') }}
                </div>
                <div  class="mypage_item_4_tab" :class="{ active : tab  === 'receive'}"
                      v-if="myType === 'curator'"
                      @click="changeTab('receive')">
                    {{ $t('receive_request') }}
                </div>
                <div  class="mypage_item_4_tab" :class="{ active : tab  === 'curation'}"
                      v-if="myType === 'curator'"
                      @click="changeTab('curation')">
                    {{ $t('c_item') }}
                </div>-->
<!--                <curation-list-component :curation-type="'send'" :defaultFilter="{cuap: UserInfo.mb_no}" v-if="tab === 'send'"></curation-list-component>-->
                <curation-list-component :curation-type="'request'" :defaultFilter="{cuap: UserInfo.mb_no}" v-if="tab === 'request'"></curation-list-component>
<!--                <curation-list-component :curation-type="'receive'" :defaultFilter="{cu: UserInfo.mb_no}" v-else-if="tab === 'receive'"></curation-list-component>-->
                <curation-list-component :curation-type="'curation'" :defaultFilter="{cu: UserInfo.mb_no}" v-else-if="tab === 'curation'"></curation-list-component>
            </div>
        </div>
        <auction-history-modal></auction-history-modal>
    </div>
</template>

<script>
import CurationListComponent from "@/components/curation/CurationListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";

export default {
    name: "MyPageCuration",
    mixins: [AuthMixins],
    components: {
        MyPageHeaderComponent,
        CurationListComponent,
        AuctionHistoryModal
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            tab: 'request',
            myType: '',
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {},
    created() {
        this.myType = this.getMemberType();
        if(!util.isEmpty(this.$route.query.tab)) {
            // this.tab = this.$route.query.tab;
            this.$router.replace(this.$route.path)
        }
        if(this.myType === 'curator') {
            this.tab = 'curation';
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        movePage(url){
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
