<template>
    <utility-detail-link v-if="detailLayout != ''" :detailLayout="detailLayout" :utilityData="utilityData"></utility-detail-link>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import UtilityUtil from "@/components/utility/UtilityUtil";
import util from "@/mixins/util";
import AuthMixins from "@/mixins/AuthMixins";
import UtilityDetailLink from "@/components/utility/link/UtilityDetailLink";

export default {
    name: "UtilityDetailComponent",
    mixins: [alertMixins, UtilityUtil, AuthMixins],
    components: {UtilityDetailLink},
    provide() {
        return {}
    },
    inject: [],
    props: {
        utilityType: {
            default: () => ''
        },
        utilityKey: '',
        userUtilityKey: '',
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamsSet: {},
            utilityData: {},
            getSuccessType: '',
            getErrorTypes: '',

        }
    },
    created() {
        this.initConfig();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnUtilityConfig(this.utilityType);
            this.getFunc = config.getFunc;
            this.detailApiParamsSet = config.detailApiParamsSet;
            this.getSuccessType = config.getSuccessType;
            this.getErrorTypes = config.getErrorTypes;
            this.keyName = config.keyName;
            this.getUtilityDetail();
        },
        getUtilityDetail() {
            EventBus.$emit('apiLoading', true)
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet));

            if (!util.isEmpty(this.keyName)) {
                if (this.utilityType == 'utilityUserDetail') {
                    params[this.keyName] = this.userUtilityKey;
                } else {
                    params[this.keyName] = this.utilityKey;
                }
            }

            this.$api.$utility[this.getFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === this.getSuccessType && !util.isEmpty(res.List)) {
                    this.utilityData = res.List[0];
                    let config = this.returnUtilityConfig(this.utilityType);
                    this.detailLayout = config.detailLayout;
                    EventBus.$emit('apiLoading', false)
                } else {
                    EventBus.$emit('apiLoading', false)
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
