<template>
  <div>
    <div class="mypage_main">
      <my-page-header-component></my-page-header-component>
      <div class="mypage_list">
        <div class="mypage_item_tab" :class="{ active : tab === 'pointCharge'}"
             >
          {{$t('mingle_status')}}
        </div>
        <div  class="mypage_item_tab" :class="{ active : tab === 'pointHistory'}"
              @click="changeTab('history?backUrl=charge')">
          {{ $t('usage_history') }}
        </div>
        <point-charge-component :point-type="'pointCharge'"></point-charge-component>
<!--        <point-list-component :point-type="'pointHistory'" v-else-if="tab === 'pointHistory'"></point-list-component>-->
      </div>
    </div>
<!--    <terms-modal :termsType="`charge`"></terms-modal>-->
  </div>
</template>

<script>

import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import PointChargeComponent from "@/components/point/PointChargeComponent";

export default {
  name: "PointCharge",
  mixins: [],
  components: {PointChargeComponent, MyPageHeaderComponent},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      tab: 'pointCharge',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
    changeTab(tab) {
      this.tab = '';
      this.movePage(`/mypage/point/${tab}`);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
