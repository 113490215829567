import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            selectedItems: [],
            detectionImageKey: 0,
            url: "",

            createDate: '',
            place: '',
            email: '',
            phone: '',
            proofUrl1: '',
            proofUrl2: '',
            proofUrl3: '',
            requestForm: [],
        }
    },
    validators: {
        selectedItems: function (value) {
            return Validator.value(value)
        },
        detectionImageKey: function (value) {
            return Validator.value(value)
        },
        url: function (value) {
            return Validator.value(value)
                .required(this.$t('require_ignore_url'))
        },
        createDate: function (value) {
            return Validator.value(value)
                .required(this.$t('require_create_date'))
        },
        place: function (value) {
            return Validator.value(value)
                .required(this.$t('require_place'))
        },
        email: function (value) {
            return Validator.value(value)
                .required(this.$t('require_email'))
        },
        phone: function (value) {
            return Validator.value(value)
                .required(this.$t('require_phone'))
        },
        proofUrl1: function (value) {
            return Validator.value(value)
                .required(this.$t('require_proofUrl1'))
                .url(this.$t('require_proofUrl1'))
        },
        proofUrl2: function (value) {
            return Validator.value(value)
                .url(this.$t('require_proofUrl1'))
        },
        proofUrl3: function (value) {
            return Validator.value(value)
                .url(this.$t('require_proofUrl1'))
        },
        requestForm: function (value) {
            return Validator.value(value)
                .required(this.$t('require_request_form'))
                .custom(() => {
                    if(value.length === 0) {
                        return this.$t('require_request_form');
                    }
                })
        },
    },
}
