<template>
  <modal name="award-selection-rule-modal" class="award-selection-rule-modal"
         :width="`80%`" :height="`80%`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div>
      수상작 선정 규정 모달
      <terms-component></terms-component>
    </div>
  </modal>
</template>

<script>
import TermsComponent from "@/components/terms/TermsComponent";

export default {
  name: "AwardSelectionRuleModal",
  mixins: [],
  components: {
    TermsComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
    },
    modalClose() {
      this.$modal.hide('award-selection-rule-modal');
    },
    returnUrl(){
      return this.url
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>