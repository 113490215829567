<template>
    <div class="i_write_wrap" v-if="showLayout">
        <div class="i_write_main_wrap">
            <div class="write_header_wrap">
                <h1 class="title">{{ $t('e_item') }}</h1>
                <div class="caution_msg">{{ $t('cant_change_msg') }}</div>
            </div>
            <ul class="i_write_content_wrap">
                <item-basic-layout
                        ref="ItemBasicLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :setCategoryData="setCategoryData"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-basic-layout>
                <item-minglemint-layout
                        ref="ItemMinglemintLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :setDeleteFiles="setDeleteFiles"
                        :initStatusDate="initStatusDate"
                        :itemData="itemData"
                        :itemCategory="category"
                        :itemClass="itemClass"></item-minglemint-layout>
                <item-collection-layout
                        ref="ItemCollectionLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :itemData="itemData"
                        :collectionData="collectionData"
                        :itemClass="itemClass"></item-collection-layout>
                <item-hashtag-layout
                        ref="ItemHashtagLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :childCategory="childCategory"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-hashtag-layout>
                <item-extra-info-layout
                        ref="ItemExtraInfoLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :setDeleteFiles="setDeleteFiles"
                        :itemData="itemData"
                        :itemClass="itemClass"></item-extra-info-layout>
                <item-information-layout
                        ref="ItemInformationLayout"
                        :setValidatorArr="setValidatorArr"
                        :setComponentData="setComponentData"
                        :itemData="itemData"
                        :itemCategory="category"
                        :itemClass="itemClass"></item-information-layout>
            </ul>


        </div>
        <div class="status_content_wrap">
            <h2 class="sell_status">{{ $t('sell_status') }}</h2>
            <ul class="i_write_content_wrap">
                <div class="w_content_box mb0">
                    <el-radio-group class="el_radio_box" v-model="status"
                                    @change="changeStatus" :disabled="isAuctioning">
                        <el-radio class="el_radio_btn" :label="1" v-if="category != 178">
                            {{ $t('auction') }}
                        </el-radio>

                        <el-radio class="el_radio_btn" :label="3"
                                  v-if="type !== 'real'">
                            {{ $t('licenseSell') }}
                        </el-radio>
                        <el-radio class="el_radio_btn" :label="4" v-if="category != 178">
                            {{ $t('fix_selling') }}
                        </el-radio>

                        <el-radio class="el_radio_btn" :label="9">
                            {{ $t('no_sell') }}
                        </el-radio>
                    </el-radio-group>
                </div>

                <div class="mg_text ql-editor" v-html="$t('mg_info_text')"></div>

                <div v-if="showStatusLayout">
                    <item-auction-layout
                            ref="ItemAuctionLayout"
                            v-if="status === 1 "
                            :setValidatorArr="setValidatorArr"
                            :setComponentData="setComponentData"
                            :itemData="itemData"
                            :itemClass="itemClass"
                    ></item-auction-layout>
                    <item-license-layout
                            ref="ItemLicenseLayout"
                            v-if="status === 3"
                            :setValidatorArr="setValidatorArr"
                            :setComponentData="setComponentData"
                            :itemData="itemData"
                            :status-exif-data="statusExifData"
                            :itemClass="itemClass"
                    ></item-license-layout>
                    <item-limits-sale-layout
                            ref="ItemLimitsLayout"
                            v-if="status === 4 "
                            :setValidatorArr="setValidatorArr"
                            :setComponentData="setComponentData"
                            :itemData="itemData"
                            :itemClass="itemClass"
                    ></item-limits-sale-layout>
                </div>
            </ul>
        </div>
        <item-create-save-layout
                :setValidatorArr="setValidatorArr"
                :setComponentData="setComponentData"
                :checkValidator="checkValidator"
                :status="status"
                :itemData="itemData"
                :itemClass="itemClass"></item-create-save-layout>

        <div class="caption_check mt10" v-if="isValidationFail && status !== 9 && collectOwner !== 1"><p><img
                src="@/assets/image/main/caution_img.png"
                alt="">{{ $t('collect_select_error2') }}</p>
        </div>
        <div class="caption_check mt10" v-else-if="isValidationFail"><p><img
                src="@/assets/image/main/caution_img.png"
                alt="">{{ $t('item_create_required') }}</p>
        </div>
    </div>
</template>

<script>
import ItemBasicLayout from "@/components/item/layout/itemCreate/ItemBasicLayout";
import ItemMinglemintLayout from "@/components/item/layout/itemCreate/ItemMinglemintLayout";
import ItemExtraInfoLayout from "@/components/item/layout/itemCreate/ItemExtraInfoLayout";
import ItemHashtagLayout from "@/components/item/layout/itemCreate/ItemHashtagLayout";
import ItemInformationLayout from "@/components/item/layout/itemCreate/ItemInformationLayout";
import ItemCreateSaveLayout from "@/components/item/layout/itemCreate/ItemCreateSaveLayout";
import Item from "@/components/item/Item"
import AuthMixins from "@/mixins/AuthMixins";
import itemValidator from "@/components/item/itemValidator";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import dateMixins from "@/mixins/dateMixins";
import ItemAuctionLayout from "@/components/item/layout/itemCreate/ItemAuctionLayout";
import ItemLicenseLayout from "@/components/item/layout/itemCreate/ItemLicenseLayout";
import ItemLimitsSaleLayout from "@/components/item/layout/itemCreate/ItemLimitsSaleLayout";
import ItemCollectionLayout from "@/components/item/layout/itemCreate/ItemCollectionLayout";

export default {
    name: "ItemRegisterComponent",
    mixins: [AuthMixins, itemValidator, awsS3FileUpload, alertMixins, dateMixins],
    components: {
        ItemCollectionLayout,
        ItemLicenseLayout,
        ItemAuctionLayout,
        ItemBasicLayout,
        ItemMinglemintLayout,
        ItemExtraInfoLayout,
        ItemHashtagLayout,
        ItemLimitsSaleLayout,
        ItemInformationLayout,
        ItemCreateSaveLayout,
    },
    props: {
        itemKey: {default: '',}
    },
    data() {
        return {
            itemClass: null,
            itemType: 'create',
            validatorArr: [],
            basicValidatorArr: [],
            childCategory: [],
            collectionData: [],
            deleteFiles: [],
            isValidationFail: true,
            isRunning: false,
            hasAddHash: false,
            fileInfo: [
                {fileName: 'thumbnailFile', fileType: 'item',},
                {fileName: 'originalFile', fileType: 'original',},
                {fileName: 'videoStreamFile', fileType: 'item_video',},
                {fileName: 'detailFile', fileType: 'item_detail',},
                {fileName: 'guaranteeFile', fileType: 'guaranty',}
            ],
            getKeyName: 'sku',
            showLayout: false,
            showStatusLayout: false,
            itemData: {},
            i_idx: '',
            isAuctioning: false,
            statusExifData: {},
        }
    },
    created() {
        this.itemClass = new Item.ItemClass(this.UserInfo)
        if (!util.isEmpty(this.itemKey)) {
            this.getItem(this.itemKey);
        }
        this.getCollect();
        this.setMetaTitle();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setMetaTitle() {
            EventBus.$emit('setMetaTitle', this.$t('e_item'));
        },
        setValidatorArr(arr) {
            this.validatorArr = this.$_.concat(this.validatorArr, arr)
            this.validatorArr = this.$_.uniq(this.validatorArr)
            if (this.status === 9) {
                this.basicValidatorArr = this.validatorArr
            }
        },
        setComponentData(type, value) {
            this[type] = value;
            if (this.isValidationFail) {
                this.isValidationFail = false;
            }
        },
        setDeleteFiles(files) {
            //삭제하는 파일 a_idx 로 key 만 가지고 있기.
            util.Array.each(files, (file, index, arr) => {
                if (!util.isEmpty(file.a_idx)) {
                    this.deleteFiles.push(file.a_idx);
                }
            })
            this.deleteFiles = this.$_.uniq(this.deleteFiles)
        },
        setCategoryData(category) {
            //카테고리 Object 로 주면 child 를 this.childCategory 에 담아서 prop 으로 레이아웃에 넘겨주기
            if (!util.isEmpty(category.child)) {
                this.childCategory = category.child.slice();
            } else {
                this.childCategory = [];
            }
        },
        loadLayout() {
            this.showLayout = true;
            this.showStatusLayout = true;
            this.$nextTick(() => {
                $(document).scrollTop(0);
            })
        },
        getItem(key) {
            this.showLayout = false;
            let params = {}
            params[this.getKeyName] = key;
            try {
                this.$api.$item.getItemDetail(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('no_data_loading'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    this.i_idx = res.List[0].i_idx;
                    this.itemData = this.itemClass.getItemData(res.List[0])
                    if (this.itemData.hasOwnProperty('updateMember') && this.itemData.updateMember != this.UserInfo.mb_no) {
                        this.createAlert({
                            content: this.$t('no_data_loading'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    util.Object.each(this.itemData, (key, value, obj) => {
                        this[key] = this.$_.cloneDeep(value);
                    })
                    this.loadLayout();
                })
            } catch (e) {
                console.log(e);
            }
        },
        getCollect() {
            this.showLayout = false;
            let params = {
                ip: 0, no: this.UserInfo.mb_no,
            }
            try {
                this.$api.$collect.getCollection(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.collectionData = res.List;
                        this.loadLayout();
                    } else {
                        this.createAlert({
                            content: this.$t('listErrorMsg1'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        finishRun() {
            this.isRunning = false;
            EventBus.$emit('apiLoading', false)
        },
        checkValidator() {
            if (this.isRunning) {
                return false;
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            this.$validate(this.validatorArr).then(success => {
                if (!success) {
                    this.$refs.ItemBasicLayout.checkValidator();
                    this.$refs.ItemMinglemintLayout.checkValidator();
                    this.$refs.ItemExtraInfoLayout.checkValidator();
                    this.$refs.ItemHashtagLayout.checkValidator();
                    this.$refs.ItemCollectionLayout.checkValidator();
                    this.$refs.ItemInformationLayout.checkValidator();
                    if (this.$refs.hasOwnProperty('ItemAuctionLayout') && !util.isEmpty(this.$refs.ItemAuctionLayout)) {
                        this.$refs.ItemAuctionLayout.checkValidator();
                    }
                    if (this.$refs.hasOwnProperty('ItemLicenseLayout') && !util.isEmpty(this.$refs.ItemLicenseLayout)) {
                        this.$refs.ItemLicenseLayout.checkValidator();
                    }
                    if (this.$refs.hasOwnProperty('ItemLimitsLayout') && !util.isEmpty(this.$refs.ItemLimitsLayout)) {
                        this.$refs.ItemLimitsLayout.checkValidator();
                    }
                    this.isValidationFail = true;
                    this.finishRun();
                    this.$forceUpdate()
                } else {
                    this.run();
                }
            })
        },
        makeSendParams() {
            let params = {
                i_name: this.title,
                ic_idx: this.category,
                i_type: this.type,
                im_edition: this.isEdition,
                im_originator: this.originator,
                im_adult_chk: this.isCheckAdult,
                hashtag: this.hashtag,
                i_memo: this.information,
                im_type: this.exifType,
                im_status: this.status,
                c_idx: this.collect,
                im_is_ai:this.aiCreate
            }
            if (this.isEdition === 1) {
                params.im_total = this.editionTotal;
                if (this.type === 'real') {
                    params.im_count = this.editionCount;
                }
            }

            if (this.exif.hasOwnProperty('create') && !util.isEmpty(this.exif.create.value) && this.exif.create.value !== 0) {
                let itemDate = this.returnDateFormat(Date(this.exif.create.value), 'YYYY-MM-DD HH:mm:ss');
                params.imo_date = itemDate
            }
            if (this.exif.hasOwnProperty('country')) {
                params.imo_country = this.exif.country.value
            }
            if (this.exif.hasOwnProperty('si')) {
                params.imo_si = this.exif.si.value
            }
            if (this.exif.hasOwnProperty('city')) {
                params.imo_city = this.exif.city.value
            }
            if (this.exif.hasOwnProperty('size')) {
                params.imo_size = this.exif.size.value
            }
            if (this.exif.hasOwnProperty('copyright')) {
                params.imo_copyright = this.exif.copyright.value
            }
            if (!util.isEmpty(this.i_idx)) {
                params.i_idx = this.i_idx;
            }

            if (this.status === 1) {
                params.ia_is_minimum = this.minimumSetting;
                params.ia_minimum = this.minimum;
                params.ia_is_immediately = this.immediatelySetting;
                params.ia_immediately = this.immediately;
                params.ia_start_date = this.startDate;
                params.ia_end_date = this.endDate;
                params.i_status = "auction";
                if (this.startDateStatus === 0) {
                    params.ia_start_date = this.startDate = this.$moment().format('YYYY-MM-DD HH:mm');
                }
            } else if (this.status === 3) {
                params.i_status = "license";
                params.il_small_price = this.smallPrice
                params.il_medium_price = this.mediumPrice
                params.il_large_price = this.largePrice
                params.il_original_price = this.originalPrice
                params.il_caution = this.caution
                params.im_type = this.exifType;
            } else if (this.status === 4) {
                params.i_status = "pricetag";
                params.i_price = this.originalPrice;
            } else if (this.status === 9) {
                params.i_status = "nosale";
                params.i_price_org = this.itemData.priceOrg;
            }

            return params;
        },
        async run() {
            let data = this.makeSendParams();
            let result = await this.itemClass.runWrite(this.itemType, data);
            if (result.type === true) {
                let deleteResult = await this.runDeleteFile();
                this.setMultiFileUpload(result.key);
            } else {
                this.finishRun();
                this.errorAlert(`${this.$t('createItemMsg3')}`);
            }
        },
        runDeleteFile() {
            return new Promise(resolve => {
                this.runS3ApiDelete(this.deleteFiles, 0, resolve);
            })
        },
        setMultiFileUpload(key) {
            this.runUploadFileInfo(this.fileInfo, 0, key).then(res => {
                if (this.itemType === 'create') {
                    this.addHash(key);
                } else {
                    this.openAlert();
                }
            });
        },
        addHash(key) {
            let params = {
                i_idx: key
            }
            this.$api.$item.addHash(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.finishRun();
                    this.errorAlert(this.$t('nft_error'));
                    return
                }
                let itemKeyArr = res.List.filter(data => data != params.i_idx);
                if (util.isEmpty(itemKeyArr)) {
                    this.openAlert();
                } else {
                    this.allFileUpload = true;
                    this.uploadEditionFiles(itemKeyArr);
                }
            })
        },
        uploadEditionFiles(keyArr) {
            util.Array.each(keyArr, (data, index, arr) => {
                this.runUploadFileInfo(this.fileInfo, 0, data).then(res => {
                    if (keyArr.length === index + 1) {
                        this.openAlert();
                    }
                })
            });
        },
        openAlert() {
            this.finishRun();
            if (this.itemType === 'create') {
                this.openCreateItemTypeAlert();
            } else if (this.itemType === 'temporary') {
                this.openTemporaryItemAlert();
            }
        },
        openCreateItemTypeAlert() {
            this.createConfirm({
                content: this.$t('createItemMsg1'),
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.replace(`/mypage/item?tab=myItemHold`);
                },
                cancel: () => {
                    this.$router.push('/');
                },
                hide: () => {
                    this.$router.push('/');
                },
            })
        },
        openTemporaryItemAlert() {
            this.createConfirm({
                content: this.$t('createItemMsg2'),
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.push(`/mypage/item?tab=myItemTempSave`)
                },
                cancel: () => {
                    this.$router.push('/');
                },
                hide: () => {
                    this.$router.push('/');
                },
            })
        },

        changeStatus() {
            if (!util.isEmpty(this.basicValidatorArr)) {
                this.validatorArr = this.basicValidatorArr;
            }
            this.showStatusLayout = false;
            this.isValidationFail = false;
            this.initStatusDate();
            this.$nextTick(() => {
                this.showStatusLayout = true;
            })
        },
        initStatusDate() {
            //경매 값 초기화
            this.minimumSetting = 0;
            this.minimum = '';
            this.immediatelySetting = 0;
            this.immediate = '';
            this.startStatus = 0;
            this.startDate = '';
            this.endDate = '';
            // //라이센스 값 초기화
            this.smallType = false;
            this.smallPrice = '';
            this.mediumType = false;
            this.mediumPrice = '';
            this.largeType = false;
            this.largePrice = '';
            this.caution = '';
            if (!util.isEmpty(this.itemData)) {
                this.itemData.minimumSetting = 0;
                this.itemData.minimum = '';
                this.itemData.immediatelySetting = 0;
                this.itemData.immediate = '';
                this.itemData.startDateStatus = 0;
                this.itemData.startDate = '';
                this.itemData.endDate = '';
                this.itemData.smallType = false;
                this.itemData.smallPrice = '';
                this.itemData.mediumType = false;
                this.itemData.mediumPrice = '';
                this.itemData.largeType = false;
                this.itemData.largePrice = '';
                this.itemData.caution = '';
                this.itemData.originalPrice = '';
            }
            this.originalPrice = ''
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
