<template>
    <component :is="component" :paginationData="paginationData" :listLength="listLength" :finishGetList="finishGetList" v-if="component"/>
</template>

<script>
export default {
    name: "PaginationLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        paginationLayout: {},
        paginationData: {default: {page: 1, size: 10, total: 0}},
        listLength: {default: 0},
        finishGetList: {default: true}
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@/template/gallery/pagination/PaginationDefaultLayout')
            })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.paginationLayout) {
                return null
            }
            return () => import(`@/template/gallery/pagination/${this.paginationLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
