<template>
    <div>
        <div class="my_collect_list_box " v-for="(data, index) in collectData" :key="`collect${index}`">

            <div class="my_collect_img">
                <img :src="returnImage(data)" width="378" height="150" alt=""
                     @click="movePage(`/collect/${data.c_idx}`)">
                <button class="setting" @click="openEditModal(data.c_idx)"></button>
            </div>
            <div class="my_collect_main_img" @click="movePage(`/collect/${data.c_idx}`)">
                <img :src="returnMainImage(data)" width="80" height="80" alt="">
            </div>
            <div class="my_collect_text">
                <div class="my_collect_name" @click="movePage(`/collect/${data.c_idx}`)">{{
                        data.c_title
                    }}
                </div>
                <div style="min-width: 50px; display: inline-block">
                    <like-collection-component :like-data="data"></like-collection-component>
                </div>
                <div class="my_collect_item_cnt" @click="movePage(`/collect/${data.c_idx}`)">item
                    <span>{{ data.item_cnt }}</span></div>
                <div class="item_price" @click="movePage(`/collect/${data.c_idx}`)">
                    <span class="price">{{ returnCommas(data.c_total) }} Mg </span><br/>
                    <span class="item_small">({{ returnMingle(data.c_total) }})</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imageResizeMixins from "@/mixins/imageResizeMixins";
import priceMixins from "@/mixins/priceMixins";
import LikeCollectionComponent from "@/components/like/LikeCollectionComponent";
import util from "@/mixins/util";

export default {
    name: "CollectListUpdateLayout",
    mixins: [imageResizeMixins, priceMixins],
    components: {LikeCollectionComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {
        collectData: {default: () => []},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnImage(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.CollectionFiles) && !util.isEmpty(data.CollectionFiles.BgFile)) {
                return `${data.CollectionFiles.BgFile[0].org_url}?${this.setImageOptions(378, 150, 'webp')}`;
            } else {
                return '';
            }
        },
        returnMainImage(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.CollectionFiles) && !util.isEmpty(data.CollectionFiles.MainFile)) {
                return `${data.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(80, 80, 'webp')}`;
            }
        },
        openEditModal(key) {
            this.$modal.show('collection-setting-modal', {collectKey: key});
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
