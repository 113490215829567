const licenseConfig = {
    license: {
        layout: 'LicenseDefaultLayout',
    },
    licensePay:{
        writeLayouts:['LicenseWriteDefaultLayout']
    }
}
export default {
    methods: {
        returnLicenseConfig(type) {
            return licenseConfig[type];
        },
    }
}