import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
	data() {
		return {
			sharePrize: 1,
			prizeArray : [{
				idx :0,
				prize:'',
				result : '',
				rank : 1,
				name: ''
			}]
		}
	},
	computed: {},
	validators: {
		prizeArray: function (value) {
			return Validator.value(value)
				.required(this.$t('award_prize_validator_err1'))
				.custom(() => {
				    let beforePrice = 0;
					for (let v of value) {
						if(v.idx === 0){
							return this.$t('award_prize_validator_err2')
						}
						if (v.prize === 0 || v.prize === '') {
							return this.$t('award_prize_validator_err3')
						}
                        if (v.prize < 100) {
                            return this.$t('award_prize_validator_err4')
                        }
                        if(v.prize % 100 != 0) {
                            return this.$t('award_prize_validator_err4')
                        }
                        if(Number(v.prize) > 1000000000) {
                            return this.$t('maxPriceErrorMsg', {price:  util.Number.numFormat(1000000000)})
                        }
						if( Number(v.rank) !== 1 && Number(v.prize) > beforePrice) {
						    return this.$t('award_prize_validator_err5')
                        }
						if (v.result === '') {
							return this.$t('award_prize_validator_err6')
						}
                        beforePrice = Number(v.prize);
					}
				})
		},
		sharePrize: function (value) {
			return Validator.value(value)
		},
	},
	methods: {}
}
