<template>
    <component :is="component" :collectData="collectData" v-if="component" :launchUrl="launchUrl"/>
</template>

<script>
export default {
    name: "CollectDetailLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detailLayout: '',
        collectData: {
            default: () => {
            }
        },
      launchUrl:'',
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/collect/detail/CollectDetailDefaultLayout')
                })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.detailLayout) {
                return null
            }
            return () => import(`@/template/collect/detail/${this.detailLayout}`)
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
