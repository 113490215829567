<template>
    <div>
      <terms-detail-link :detailLayout="detailLayout" :termsData="termsData"></terms-detail-link>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import termsUtil from "@/components/terms/termsUtil";
import TermsDetailLink from "@/components/terms/TermsDetailLink";
import {mapState} from "vuex";
import util from "@/mixins/util";
import AuthMixins from "@/mixins/AuthMixins";

export default {
  name: "TermsComponent",
  mixins: [ alertMixins, termsUtil,AuthMixins],
  components: {TermsDetailLink},
  provide() {
    return {}
  },
  inject: [],
  props: {
    termsType: '',
    termsKey: '',
  },
  data() {
    return {
      detailLayout: '',
      getFunc: '',
      detailApiParamsSet: {},
      termsData: {},
      getSuccessType: '',
      getErrorTypes: '',

    }
  },
  created() {
    this.initConfig();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
    computed: {
        ...mapState({
            Country: state => state.country.Country,
        }),
    },
  methods: {
    initConfig() {
      let configTerms = this.returnTermsConfig(this.termsType);
      this.detailLayout = configTerms.detailLayout;
      this.getFunc = configTerms.getFunc;
      this.detailApiParamsSet = configTerms.detailApiParamsSet;
      this.getSuccessType = configTerms.getSuccessType;
      this.getErrorTypes = configTerms.getErrorTypes;
      let params = JSON.parse(JSON.stringify(this.detailApiParamsSet));
      params.la = this.langCheckLine(this.Country);
      params.ty = this.termsType;
      this.getDetail(params);
    },
    getDetail(params) {
      EventBus.$emit('apiLoading', true);
      this.$api.$terms[this.getFunc](params).then(res => res.Data.Result).then(res => {
          EventBus.$emit('apiLoading', false);
        if (res.Info.type != 1 || res.List.length === 0) {
          /*this.createAlert({
            title: '',
            content: '데이터를 불러올 수 없습니다.',
            btnText: '확인',
            hide: () => {
              this.$router.back();
              EventBus.$emit('apiLoading', false);
            }
          });*/
        }
        this.termsData = res.List[0];
          EventBus.$emit('apiLoading', false);
      })
    },
  },
    watch: {
        'Country': {
            deep: true,
            handler: function (val, oldVal) {
                this.la = this.langCheckLine(this.Country);
                this.initConfig();
            }
        },
    },
}
</script>

<style scoped>

</style>
