import { render, staticRenderFns } from "./JudgeWriteLink.vue?vue&type=template&id=35831669&scoped=true"
import script from "./JudgeWriteLink.vue?vue&type=script&lang=js"
export * from "./JudgeWriteLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35831669",
  null
  
)

export default component.exports