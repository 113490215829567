<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_tab" :class="{ active : tab === 'pointStatus'}"
                     @click="changeTab()">
                    {{$t('mingle_status')}}
                </div>
                <div  class="mypage_item_tab" :class="{ active : tab === 'pointHistory'}">
                    {{ $t('usage_history') }}
                </div>
                <point-list-component :point-type="'pointHistory'"></point-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import PointListComponent from "../../components/point/PointListComponent";
import PointChargeComponent from "../../components/point/PointChargeComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";

export default {
    name: "MyPagePointHistory",
    mixins: [],
    components: {MyPageHeaderComponent, PointChargeComponent, PointListComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointHistory',
            backUrl : 'pointStatus'
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.backUrl = this.$route.query.backUrl;
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
         if(tab !== 'pointHistory'){
             tab = this.backUrl;
         }

          this.tab = '';
         if(tab !== 'point'){
             this.movePage(`/mypage/point/${tab}`);
         }else{
             this.movePage(`/mypage/point`);
         }

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
