<template>
  <div>
    <board-detail-component :board-type="'noticeDetail'" :board-key="boardKey"></board-detail-component>
  </div>
</template>

<script>
import BoardDetailComponent from "@/components/board/BoardDetailComponent";

export default {
  name: "NoticeDetail",
  mixins: [],
  components: {
    BoardDetailComponent
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      boardKey: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.boardKey = this.$route.params.idx;
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>