import EventBus from "@/utils/event-bus";

const bidConfig = {
    bid: {
        layout: 'BidDefaultLayout',
    },
}
export default {
    methods: {
        returnBidConfig(type) {
            return bidConfig[type];
        },
    }
}