<template>
    <div>
        <history-detail-link :detailLayout="detailLayout" :historyData="historyData" :paginationData="paginationData"></history-detail-link>
    </div>
</template>

<script>
import historyUtil from "./historyUtil";
import HistoryDetailLink from "./HistoryDetailLink";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
export default {
    name: "HistoryDetailComponent",
    mixins: [historyUtil,alertMixins],
    components: {HistoryDetailLink},
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
        }
    },
    props: {
        historyType: '',
        historyKey: '',
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            historyData: {},
            detailApiParamSet: {},
            getSuccessType: '',
            getErrorTypes: {},
            getKeyName: 'm_idx',
            isRevers: false,
            paginationData: {page: 1, current_page: 1, last_page: 2, per_page: 10},
            page: 1,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnHistoryConfig(this.historyType);
            this.detailLayout = config.detailLayout;
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.getSuccessType = config.getSuccessType;
            this.getErrorTypes = config.getErrorTypes;
            this.getKeyName = config.getKeyName;
            this.isRevers = config.isRevers;
            this.getDetail();
        },
        getDetail() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamSet));
            if(util.isEmpty(this.getKeyName)) {
                params.m_idx = this.historyKey;
            }else{
                params[this.getKeyName] = this.historyKey;
            }

            if(this.page > 1) {
                params.pa = this.page;
            }

            this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === this.getSuccessType) {
                    if(this.isRevers) {
                        this.historyData = res.List.reverse();
                    }else{
                        this.historyData = res.List;
                    }
                    this.paginationData = res.Page;
                } else {
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                }
            })
        },
        setPage(type, value) {
            this[type] = value;
            this.getDetail();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
