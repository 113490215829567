<template>
    <div class="creator_detail_wrap">
        <utility-detail-component :utilityType="'utilityUserDetail'" :utilityKey="utilityKey" :userUtilityKey="userUtilityKey"></utility-detail-component>
    </div>
</template>


<script>
import UtilityDetailComponent from '@/components/utility/UtilityDetailComponent';
export default {
    name: "UtilityUserDetail",
    mixins: [],
    components: { UtilityDetailComponent },
    props: {

    },
    data() {
        return {
            utilityKey: Number(this.$route.params.idx),
            userUtilityKey: Number(this.$route.params.uu_idx),
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {
    },
}
</script>
