<template>
    <div class="dh_wrap">
      <div class="dh_text">{{ $t('ignore_url1') }}</div>

      <div class="dh_title_text">{{ $t('d_img_title') }}</div>
      <div class="dh_title">{{ itemData.i_name }}</div>
      <div class="dh_result_count_box" v-html="$t('d_result_text1', {count: numFormat(itemData.cnt)})">
      </div>
      <detection-list-component :detection-type="'urlList'" :detectionKey="detectionKey"></detection-list-component>
      <detection-url-modal :detectionKey="detectionKey"></detection-url-modal>
    </div>
</template>

<script>


import DetectionListComponent from "../../components/detection/DetectionListComponent";
import DetectionUrlModal from "../../components/modal/DetectionUrlModal";
import util from "@/mixins/util";

export default {
    name: "DetectionUrl",
    mixins: [],
    components: {DetectionUrlModal, DetectionListComponent},
    inject: [],
    provide() {
        return {
            setDetectItemData: this.setDetectItemData
        }
    },
    props: {},
    data() {
        return {
            detectionKey: Number(this.$route.params.d_idx),
            itemData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectItemData(item) {
            this.itemData = item;
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
