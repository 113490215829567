<template>
    <div class="create_item_wrap create_competition_wrap">
        <div class="write_header_wrap">
            <h1 class="title medium">{{ $t('e_comp') }}</h1>
        </div>
        <competition-write-component :competition-type="'competitionWrite'"></competition-write-component>
        <div>
            <div class="search_item_wrap terms_content_btn">
                <div class="search_item">
                    <el-checkbox v-model="termsCheck" @click="changeCheck">
                        <span class="item_text_span bold"
                              @click="openModal('terms-modal', 'contest')">{{ $t('terms_e_enroll') }}</span>
                      <span v-html="$t('agree_terms2')"></span>

                    </el-checkbox>
                </div>
            </div>
            <div class="submit_wrap item_create_btn">
                <div class="submit_btn">
                    <button class="enroll_submit_btn" @click="submit">{{ $t('enroll_comp') }}</button>
                </div>
                <div class="caption_check mt10" v-if="termsMsg"><p>{{ $t('caption_check') }}</p></div>
                <div class="caption_check mt10" v-if="hasAllError"><p>{{ $t('caption_check2') }}</p></div>
            </div>
        </div>

        <judge-search-modal></judge-search-modal>
    </div>
</template>

<script>
import CompetitionWriteComponent from "@/components/competition/CompetitionWriteComponent";
import TermsModal from "@/components/modal/TermsModal";
import JudgeSearchModal from "@/components/modal/JudgeSearchModal";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

export default {
    name: "CompetitionCreate",
    mixins: [],
    components: {
        TermsModal,
        CompetitionWriteComponent,
        JudgeSearchModal
    },
    inject: [],
    provide() {
        return {
            competitionVueSetData : this.setData
        }
    },
    props: {},
    data() {
        return {
            hasAllError: false,
            termsCheck: false,
            termsMsg: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
        },
        submit() {
            if (!this.termsCheck) {
                this.termsMsg = true
            } else {
                this.termsMsg = false
                EventBus.$emit('writeCompetition')
            }
        },
        setData(key,value){
            this[key] = value
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
