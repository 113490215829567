<template>
    <component :is="component" :galleryData="galleryData" :paginationData="paginationData" v-if="component"></component>
</template>

<script>
export default {
    name: "GalleryListLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        listLayout: '',
        galleryData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}}
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@/template/gallery/list/GalleryListDefaultLayout')
            })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.listLayout) {
                return null
            }
            return () => import(`@/template/gallery/list/${this.listLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
