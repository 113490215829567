<template>
  <div class="notice_main_wrap">
    <div class="notice_text">
      <span>{{ $t('service_center') }}</span>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div class="mypage_item_3_tab border_left" v-bind:class="{active: tab === 'notice'}">
        {{$t('notice')}}
      </div>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div  class="mypage_item_3_tab" :class="{ active: tab === 'faq'}"
            @click="changeTab('faq')">
        FAQ
      </div>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div  class="mypage_item_3_tab border_right" :class="{ active: tab === 'qna'}"
            @click="changeTab('qna')">
        {{ $t('qna') }}
      </div>
    </div>

    <board-List-component :boardType="boardType"></board-List-component>
    <transition :name="`detail-${$root.$children[0].transitionName}`">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";
import {mapState} from "vuex";

export default {
  name: "Notice",
  mixins: [],
  components: {
    BoardListComponent,
  },
  inject: [],
  provide() {
    return{
    }
  },
  props: {
  },
  data() {
    return{
      boardType: 'notice',
      tab: 'notice',
    }
  },
  beforeRouterEnter() {},
  created() {
      document.title = `밍글민트(Minglemint NFT Marketplace)`
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    ...mapState({
      UserInfo: state => state.userInfo.UserInfo,
    })
  },
  methods:{
    movePage(url){
      this.$router.push(url);
    },
    changeTab(tab) {
      this.tab = '';
      this.$nextTick(() => {
        this.tab = tab
      })
      this.backUrl = this.boardType;
      // this.$router.replace(`${this.$route.path}?tab=${tab}`);
      this.movePage(`/${tab}`);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>