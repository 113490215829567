<template>
    <component :is="component" v-if="component" :competitionData="competitionData"></component>
</template>

<script>
export default {
    name: "CompetitionWriteLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        writeLayout: '',
        competitionData: {default: () => {}}
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/competition/write/CompetitionWriteDefaultLayout')
                })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.writeLayout) {
                return null
            }
            return () => import(`@/template/competition/write/${this.writeLayout}`)
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
