<template>
  <div class="unsubscribe_result_wrap">
    <div class="result_logo">
      <div><img src="@/assets/image/main/result_logo.png" alt=""></div>
      <span class="result_msg">{{$t('unsub_done')}}<br>
        {{$t('thanks_ming')}}</span>
    </div>
    <div class="unsubscribe_btn" @click="movePage('/')">
      <button>{{$t('to_home')}}</button>
    </div>
  </div>

</template>

<script>
import alertMixins from "@/mixins/alertMixins";
export default {
  name: "UnsubscribeResultComponent",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {

  },
  data() {
    return{
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url) {
      this.$router.push(url);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>