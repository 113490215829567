<template>
    <div>
        <competition-list-link :listLayout="listLayout" :competitionData="competitionData"
                               :paginationData="paginationData"
                               v-if="competitionData.length > 0"></competition-list-link>
        <competition-empty-link :emptyLayout="emptyLayout" v-else></competition-empty-link>
    </div>
</template>

<script>
import CompetitionListLink from "@/components/competition/CompetitionListLink";
import CompetitionEmptyLink from "@/components/competition/CompetitionEmptyLink";
import competitionUtil from "@/components/competition/competitionUtil";
import alertMixins from "@/mixins/alertMixins";

const {filterConfig} = competitionUtil;

export default {
    name: "CompetitionHomeListComponent",
    mixins: [competitionUtil, alertMixins],
    components: {
        CompetitionListLink,
        CompetitionEmptyLink,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionType: '',
    },
    data() {
        return {
            listLayout: '',
            emptyLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: '',
            competitionData: [],
            paginationData: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let competitionConfig = this.returnCompetitionConfig(this.competitionType);
            this.listLayout = competitionConfig.listLayout;
            this.emptyLayout = competitionConfig.emptyLayout;
            this.getFunc = competitionConfig.getFunc;
            this.listApiParamSet = competitionConfig.listApiParamSet;

            this.pageRow = competitionConfig.pageRow;

            this.getCompetition();
        },

        getCompetition() {
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.competitionData = res.List
                    }
                })
            } catch (e) {
                console.log(e)
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
