<template>

</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "PaymentResult",
    mixins: [alertMixins, AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
        this.writeAfter();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        writeAfter() {
            let token = this.$localStorage.get('token');
            if (!util.isEmpty(token)) {
                this.$store.dispatch('userInfo/setUserInfo', {token: token});
            }
            this.createAlert({
                content: this.$t('pay_point_success'),
                btnText: this.$t('yes'),
                hide: () => {
                    EventBus.$emit('apiLoading', false);
                    this.$router.push('/mypage/point');
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
