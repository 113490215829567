<template>
  <component :is="component" :finishGetList="finishGetList" v-if="component"/>
</template>

<script>
export default {
  name: "CompetitionEmptyLink",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    emptyLayout: '',
      finishGetList: {default: true},
  },
  data() {
    return {
      component: null,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/competition/empty/CompetitionEmptyDefaultLayout')
        })
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
    loader() {
      if (!this.emptyLayout) {
        return null
      }
      return () => import(`@/template/competition/empty/${this.emptyLayout}`)
    },
  },
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
