<template>
  <div>
    <div v-if="!true">
      <member-detail-component :member-type="'userProfile'"></member-detail-component>
    </div>
    <div v-else>
      <member-detail-component :member-type="'myProfile'"></member-detail-component>
    </div>
    <filter-modal></filter-modal>
  </div>
</template>
<script>
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import FilterModal from "@/components/modal/FilterModal";

export default {
  name: "MemberDetail",
  mixins: [],
  components: {
    MemberDetailComponent,
    FilterModal,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>