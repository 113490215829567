import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            title: '',
            thumbnailFile: [],
            backgroundFile: [],
            itemKey: 0,
        }
    },
    validators: {
        title: function (value) {
            return Validator.value(value)
                .required(this.$t('gallery_input'))
        },
        thumbnailFile: function (value) {
            return Validator.value(value)
                .required(this.$t('require_collection_thumbnail'))
                .custom(() => {
                    if(value.length === 0) {
                        return this.$t('require_collection_thumbnail');
                    }
                })
        },
        backgroundFile: function (value) {
            return Validator.value(value)
                .required(this.$t('require_collection_background'))
                .custom(() => {
                    if(value.length === 0) {
                        return this.$t('require_collection_background');
                    }
                })
        },
        itemKey: function (value) {
            return Validator.value(value)
        }
    },
}
