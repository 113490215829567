<template>
    <div class="creator_detail_wrap">
        <utility-detail-component :utilityType="'utilityDetail'" :utilityKey="utilityKey"></utility-detail-component>
    </div>
</template>


<script>
import UtilityDetailComponent from '@/components/utility/UtilityDetailComponent';
export default {
    name: "UtilityDetail",
    mixins: [],
    components: { UtilityDetailComponent },
    props: {

    },
    data() {
        return {
            utilityKey: Number(this.$route.params.idx),
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {
    },
}
</script>
