<template>
    <li class="i_w_content inform">
        <h3 class="sub_title" :class="{'no_caution_img': itemCategory != 178}">{{ $t('i_inform') }}</h3>
        <div class="clear"></div>
        <div class="portfolio_edit_textarea">
            <quill-editor
                    ref="myQuillEditor"
                    v-model="information"
                    :options="editorOption"
            />
        </div>
        <div class="mingle_cation_text mb10" v-if="itemCategory == 178">
            {{ $t('promptInfoMsg') }}
        </div>
        <div class="val_cap" v-if="validation.hasError('information') && itemCategory == 178">
            {{ validation.firstError('information') }}
        </div>
    </li>
</template>

<script>
import util from "@/mixins/util";
import quillEditorMixins from "@/mixins/quillEditorMixins";
import SimpleVueValidation from "simple-vue-validator";
import Vue from "vue";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemInformationLayout",
    mixins: [quillEditorMixins],
    components: {},
    props: {
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        setComponentData: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,},
        itemCategory: '',
    },
    data() {
        return {
            information: '',
            validatorArr: [],
            self: null,
        }
    },
    created() {
        this.setValidatorArr(['information']);
        if (this.isItemData()) {
            this.settingItemData()
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {

        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.information = this.itemData.information
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {
            this.$validate(['information'])
        },
    },
    watch: {
        'information': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('information', this.information)
            },
        },
    },
    validators: {
        information: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        console.log(value)
                        console.log(value)
                        if (this.itemCategory == 178 && util.isEmpty(value)) {
                            return this.$t('itemInfoEmptyMsg')
                        }
                    })
        },
    },
}
</script>

<style lang="scss">


</style>
