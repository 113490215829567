<template>
    <div class="entry_list">
        <div class="entry_title_wrap">
            <div class="entry_title">{{ $t('s_apply') }}</div>
            <div class="award_relative_wrap">
                <div class="award_select_wrap" v-if="isOrganizer">
                    <button @click="movePage(`/award/${competitionKey}/write`)">
                        {{ $t('s_award') }}
                    </button>
                </div>
            </div>
        </div>
        <competition-list-component :competition-type="'entryList'"
                                    :competition-key="competitionKey"></competition-list-component>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "EntryList",
    mixins: [],
    components: {
        CompetitionListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.cid,
            organizer: this.$route.params.mb_no
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('competitionVueSetData', this.setData)
    },
    beforeDestroy() {
        EventBus.$off('competitionVueSetData')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
        isOrganizer() {
            //return this.UserInfo.mb_no === Number(this.organizer)
            return this.UserInfo.mb_uuid === this.organizer
        },

    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        setData(key, value) {
            this[key] = value
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
