<template>
    <div class="award_wrap">

        <div class="al_default_wrap">
            <div class="award_title">{{$t('award')}}</div>
        </div>
        <award-list-component :award-type="'award'" :award-key="awardKey"></award-list-component>
    </div>

</template>

<script>
import AwardListComponent from "@/components/award/AwardListComponent";

export default {
    name: "AwardList",
    mixins: [],
    components: {
        AwardListComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            awardKey : this.$route.params.c_idx
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
