<template>
  <div class="qna_create_wrap">
    <div class="qna_create_title">
      {{ $t('write_qna') }}
    </div>
    <board-write-component :boardType="boardType" :boardKey="boardKey"></board-write-component>
    <div class="qna_create_btn_wrap">
      <button class="qna_create_btn list" @click="backList()">{{ $t('back_msg') }}</button>
      <button class="qna_create_btn confirm" @click="update()">{{$t('update_btn')}}</button>
    </div>
  </div>
</template>

<script>
import BoardWriteComponent from "@/components/board/BoardWriteComponent";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "QnaUpdate",
  mixins: [alertMixins],
  components: {
    BoardWriteComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      boardType: 'qnaUpdate',
      boardKey: this.$route.params.idx,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    backList() {
      this.createConfirm({
        content: this.$t('update_qna_back'),
        confirm: () => {
          this.$router.back();
        }
      });
    },
    update() {
      this.createConfirm({
        content: this.$t('update_qna_confirm'),
        confirm: () => {
          EventBus.$emit('writeBoard');
        }
      });
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>