<template>
  <component :is="component" :alarmData = "alarmData" :paginationData="paginationData" v-if="component" />
</template>

<script>
export default {
  name: "AlarmListLink",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    listLayout: '',
    alarmData: {
      type: Array,
      default: () => []
    },
    paginationData: {default: {page: 1, size: 10, total: 0}},
  },
  data() {
    return{
      component: null,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/alarm/list/AlarmListDefaultLayout')
        })

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    loader() {
      if (!this.listLayout) {
        return null
      }
      return () => import(`@/template/alarm/list/${this.listLayout}`)
    },
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>