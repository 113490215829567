<template>
    <div>
        <!--        타입선택-->
        <li class="i_w_content">
            <h3 class="sub_title">{{ $t('s_type') }}</h3>

            <div class="w_content_box">
                <!-- 임시저장 수정인 경우 disabled 처리 -->
                <el-radio-group class="el_radio_box" v-model="type" @change="changeType(type)"
                                :disabled="isItemData() || itemCategory == 178">
                    <el-radio class="el_radio_btn" :label="'digital'">{{ $t('digi') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="`real`">{{ $t('real') }}</el-radio>
                </el-radio-group>
            </div>
            <div class="mingle_cation_text" v-if="itemCategory == 178">
                {{ $t('promptDigitalMsg') }}
            </div>

        </li>
        <li class="i_w_content" v-if="itemCategory == 2">
            <h3 class="sub_title">{{ $t('ai_create_check') }}</h3>

            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="aiCreate" @change="parentChange('aiCreate',aiCreate)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                </el-radio-group>
            </div>
        </li>
        <!--              아이템 원본-->
        <li class="i_w_content upload_wrap" v-if=" type === 'digital' ">
            <h3 class="sub_title ">{{ $t('real_item') }}</h3>
            <div class="w_content_box ba0">
                <div class="item_file" v-show="!hasFile('originalFile')">
                    <el-upload
                            v-if="itemCategory == 178"
                            class=""
                            action=""
                            accept=".csv,.txt,.xls,.xlsx"
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="originalFile"
                            :auto-upload="false"
                            :on-change="promptOriginalFileChange"
                    >
                        <button class="upload-name"><span>{{ $t('file_upload') }}</span></button>
                    </el-upload>

                    <el-upload
                            v-else
                            class=""
                            action=""
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="originalFile"
                            :auto-upload="false"
                            :on-change="originalFileChange"
                    >
                        <button class="upload-name"><span>{{ $t('file_upload') }}</span></button>
                    </el-upload>
                </div>
                <div class="filebox" v-if="hasFile('originalFile')">
                    <div class="file_name">
                        <div>{{ originalFile[0].org_name }}</div>
                        <button v-if="hasFile('originalFile')" @click="removeList('originalFile',0)"></button>
                    </div>

                </div>
                <div class="mingle_cation_text in_block vertical_bottom ml20" v-if="itemCategory == 178">
                    {{ $t('promptMsg') }}
                </div>
                <div class="val_cap" v-if="validation.hasError('originalFile') && originalFile.length == 0">
                    {{ validation.firstError('originalFile') }}
                </div>
            </div>
        </li>

        <!--              썸네일-->
        <li class="i_w_content detail_thumb_wrap">
            <h3 class="sub_title ">{{ $t('thumbnail') }}</h3>
            <div class="w_content_box" style="position: relative">
                <el-upload
                        class="n_el_up"
                        action=""
                        list-type="picture-card"
                        :show-file-list="false"
                        :file-list="thumbnailFile"
                        :auto-upload="false"
                        :on-change="thumbnailFileChange"
                        :disabled="exifType === 'photo'"
                >
                    <img v-if="hasFile('thumbnailFile')" :src="thumbnailFile[0].org_url" class="">
                    <button v-if="hasFile('thumbnailFile')" class=""
                            @click="removeList('thumbnailFile',0)"></button>
                    <img v-else src="@/assets/image/main/change_item.png" alt="" style="width: 93px; height: 73px;">
                </el-upload>
            </div>
            <div v-if="itemCategory==178">
                <div class="mingle_cation_text mb10">{{ $t('thumAiMsg') }}</div>
                <button class="add_default_btn mr10" @click="openUrl('https://chat-gpt.org/image/')">
                    {{ $t('chatgpt') }}
                </button>
                <button class="add_default_btn mr10" @click="openUrl('https://openai.com/product/dall-e-2/')">
                    {{ $t('dalle') }}
                </button>
                <button class="add_default_btn mr10" @click="openUrl('https://docs.midjourney.com/')">
                    {{ $t('midjourney') }}
                </button>
                <button class="add_default_btn mr10" @click="openUrl('https://stablediffusionweb.com/')">
                    {{ $t('stablediffusion') }}
                </button>
            </div>
            <div class="val_cap" v-if="validation.hasError('thumbnailFile')">
                {{ validation.firstError('thumbnailFile') }}
            </div>
        </li>


        <!--              에디션-->
        <li class="i_w_content">
            <h3 class="sub_title">{{ $t('edition') }}</h3>
            <div class="w_content_box edition_wrap">
                <!-- 임시저장 수정인 경우 disabled 처리 -->
                <el-radio-group class="el_radio_box" v-model="isEdition"
                                @change="changeEdition(isEdition)"
                                :disabled="isItemData()"
                >
                    <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                </el-radio-group>
                <ul class="w_content sub_content" v-if="isEdition === 1">
                    <li class="real_edition">
                        <div v-if="type == 'digital'">
                            <div class="content_name">{{ $t('b_edi') }}</div>
                            <el-input class="input_txt" type="number" v-model="editionTotal" :disabled="isItemData()"
                                      @change="parentChange('editionTotal', editionTotal)"
                            />
                        </div>
                        <div v-else-if="type=='real'">
                            <div class="content_name">{{ $t('edition_number') }}</div>
                            <el-input class="input_txt" type="number" v-model="editionCount" :disabled="isItemData()"
                                      @change="parentChange('editionCount', editionCount)"
                            />
                            <span class="mr7">/</span>
                            <el-input class="input_txt" type="number" v-model="editionTotal" :disabled="isItemData()"
                                      @change="parentChange('editionTotal', editionTotal)"
                            />
                        </div>
                        <div class="val_cap" v-if="validation.hasError('editionCount')">
                            {{ validation.firstError('editionCount') }}
                        </div>
                        <div class="val_cap" v-else-if="validation.hasError('editionTotal')">
                            {{ validation.firstError('editionTotal') }}
                        </div>
                    </li>
                </ul>
            </div>
        </li>

        <!--              보증서/감정서-->
        <li class="i_w_content">
            <h3 class="sub_title">{{ $t('certi') }}</h3>
            <div class="w_content_box f_upload_wrap">
                <el-radio-group class="el_radio_box" v-model="isGuarantee"
                                @change="changeIsGuarantee(isGuarantee)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('own') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('n_own') }}</el-radio>
                </el-radio-group>
                <div class="w_content sub_content" v-if="isGuarantee === 1">
                    <div>
                        <el-upload
                                class=""
                                :auto-upload="false"
                                action=""
                                :show-file-list="false"
                                :on-change="guaranteeFileChange"
                        >
                            <button class="upload-name"><span>{{ $t('file_upload') }}</span>
                            </button>
                        </el-upload>
                    </div>
                    <div class="filebox">
                        <div class="file_name" v-for="(data,index) in guaranteeFile"
                             :key="`guarantee${index}`">
                            <div>{{ data.org_name }}</div>
                            <button class="drop_file"
                                    @click="removeList('guaranteeFile', index)"></button>
                        </div>
                    </div>
                    <div class="val_cap" v-if="validation.hasError('guaranteeFile')">
                        {{ validation.firstError('guaranteeFile') }}
                    </div>
                </div>
            </div>
        </li>

        <!--              본인창작여부-->
        <li class="i_w_content self_made">
            <h3 class="sub_title ">{{ $t('self_made') }}</h3>
            <div class="w_content_box self_made">
                <el-radio-group class="el_radio_box" v-model="originator"
                                @change="parentChange('originator', originator)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                </el-radio-group>
            </div>
        </li>

        <!-- 세부정보 -->
        <li class="i_w_content detail_info_wrap" v-if="exifType != ''">
            <h3 class="sub_title ">{{ $t('detail_info') }}</h3>
            <div class="w_content_box">
                <div class="inform_txt">
                    <span class="">
                        {{ $t('d_info_msg1') }}<br/>
                        {{ $t('d_info_msg2') }}
                    </span>
                </div>
                <!-- 세부정보 - 이미지 -->
                <ul class="sub_content detail_wrap" v-if="exifType === 'photo'">
                    <li v-if="exif.hasOwnProperty('create')">
                        <div class="content_name">{{ $t('t_video') }}</div>
                        <el-date-picker
                                placeholder="04/02/2021 14:52:37 +0000"
                                id="itemDate"
                                type="datetime"
                                class="date-picker"
                                value-format="dd/MM/yyyy HH:mm:ss"
                                format="dd/MM/yyyy HH:mm:ss"
                                v-model="exif.create.value"
                                :readonly="exif.create.options.readonly"
                                @change="exifDataChange('create', exif.create.value)">
                        </el-date-picker>
                    </li>
                    <li v-if="exif.hasOwnProperty('country')">
                        <div class="content_name">{{ $t('v_country') }}</div>
                        <el-input type="text" placeholder="Republic of Korea" v-model="exif.country.value"
                                  :readonly="exif.country.options.readonly"
                                  @input="exifDataChange('country', exif.country.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('si')">
                        <div class="content_name">{{ $t('v_si') }}</div>
                        <el-input type="text" placeholder="Gyeongsangbuk-do" v-model="exif.si.value"
                                  :readonly="exif.si.options.readonly"
                                  @input="exifDataChange('si', exif.si.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('city')">
                        <div class="content_name">{{ $t('v_city') }}</div>
                        <el-input type="text" placeholder="Gyeongju-si" v-model="exif.city.value"
                                  :readonly="exif.city.options.readonly"
                                  @input="exifDataChange('city', exif.city.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('size')">
                        <div class="content_name">{{ $t('size') }}</div>
                        <el-input type="text" placeholder="4131*6196" v-model="exif.size.value"
                                  :readonly="exif.size.options.readonly"
                                  @input="exifDataChange('size', exif.size.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('copyright')">
                        <div class="content_name ">
                            {{ $t('copyright_info') }}
                        </div>
                        <el-input type="text" placeholder="Copyright ..." v-model="exif.copyright.value"
                                  :readonly="exif.copyright.options.readonly"
                                  @input="exifDataChange('copyright',exif.copyright.value)"/>
                    </li>
                </ul>
                <!-- 세부정보 - 비디오 -->
                <ul class="sub_content detail_wrap" v-else-if="exifType === 'video'">
                    <li v-if="exif.hasOwnProperty('create')">
                        <div class="content_name">{{ $t('t_video') }}</div>
                        <el-date-picker
                                placeholder="04/02/2021 14:52:37 +0000"
                                type="datetime"
                                class="date-picker"
                                value-format="dd/MM/yyyy HH:mm:ss"
                                format="dd/MM/yyyy HH:mm:ss"
                                v-model="exif.create.value"
                                :readonly="exif.create.options.readonly"
                                @change="exifDataChange('create', exif.create.value)">
                        </el-date-picker>
                    </li>
                    <li v-if="exif.hasOwnProperty('country')">
                        <div class="content_name">{{ $t('v_country') }}</div>
                        <el-input type="text" placeholder="Republic of Korea" v-model="exif.country.value"
                                  :readonly="exif.country.options.readonly"
                                  @input="exifDataChange('country', exif.country.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('si')">
                        <div class="content_name">{{ $t('v_si') }}</div>
                        <el-input type="text" placeholder="Gyeongsangbuk-do" v-model="exif.si.value"
                                  :readonly="exif.si.options.readonly"
                                  @input="exifDataChange('si', exif.si.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('city')">
                        <div class="content_name">{{ $t('v_city') }}</div>
                        <el-input type="text" placeholder="Gyeongju-si" v-model="exif.city.value"
                                  :readonly="exif.city.options.readonly"
                                  @input="exifDataChange('city', exif.city.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('size')">
                        <div class="content_name">{{ $t('v_long') }}</div>
                        <el-input type="text" placeholder="39:12:03" v-model="exif.size.value"
                                  :readonly="exif.size.options.readonly"
                                  @input="exifDataChange('size', exif.size.value)"/>

                    </li>
                    <li v-if="exif.hasOwnProperty('copyright')">
                        <div class="content_name ">
                            {{ $t('copyright_info') }}
                        </div>
                        <el-input type="text" placeholder="Copyright ..." v-model="exif.copyright.value"
                                  :readonly="exif.copyright.options.readonly"
                                  @input="exifDataChange('copyright',exif.copyright.value)"/>
                    </li>
                </ul>

                <!-- 세부정보 - 오디오 -->
                <ul class="sub_content detail_wrap" v-else-if="exifType === 'audio'">
                    <li v-if="exif.hasOwnProperty('create')">
                        <div class="content_name">{{ $t('t_record') }}</div>
                        <el-date-picker
                                placeholder="04/02/2021 14:52:37 +0000"
                                type="datetime"
                                class="date-picker"
                                value-format="dd/MM/yyyy HH:mm:ss"
                                format="dd/MM/yyyy HH:mm:ss"
                                v-model="exif.create.value"
                                :readonly="exif.create.options.readonly"
                                @change="exifDataChange('create', exif.create.value)">
                        </el-date-picker>
                    </li>
                    <li v-if="exif.hasOwnProperty('country')">
                        <div class="content_name">{{ $t('c_country') }}</div>
                        <el-input type="text" placeholder="Republic of Korea" v-model="exif.country.value"
                                  :readonly="exif.country.options.readonly"
                                  @input="exifDataChange('country', exif.country.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('si')">
                        <div class="content_name">{{ $t('c_si') }}</div>
                        <el-input type="text" placeholder="Gyeongsangbuk-do" v-model="exif.si.value"
                                  :readonly="exif.si.options.readonly"
                                  @input="exifDataChange('si', exif.si.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('city')">
                        <div class="content_name">{{ $t('c_city') }}</div>
                        <el-input type="text" placeholder="Gyeongju-si" v-model="exif.city.value"
                                  :readonly="exif.city.options.readonly"
                                  @input="exifDataChange('city', exif.city.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('size')">
                        <div class="content_name">{{ $t('v_long') }}</div>
                        <el-input type="text" placeholder="39:12:03" v-model="exif.size.value"
                                  :readonly="exif.size.options.readonly"
                                  @input="exifDataChange('size', exif.size.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('copyright')">
                        <div class="content_name ">
                            {{ $t('copyright_info') }}
                        </div>
                        <el-input type="text" placeholder="Copyright ..." v-model="exif.copyright.value"
                                  :readonly="exif.copyright.options.readonly"
                                  @input="exifDataChange('copyright',exif.copyright.value)"/>
                    </li>
                </ul>
                <!-- 세부정보 - 기타 -->
                <ul class="sub_content detail_wrap" v-else-if="exifType === 'etc'">
                    <li v-if="exif.hasOwnProperty('create')">
                        <div class="content_name">{{ $t('t_create') }}</div>
                        <el-date-picker
                                placeholder="04/02/2021 14:52:37 +0000"
                                type="datetime"
                                class="date-picker"
                                value-format="dd/MM/yyyy HH:mm:ss"
                                format="dd/MM/yyyy HH:mm:ss"
                                v-model="exif.create.value"
                                :readonly="exif.create.options.readonly"
                                @change="exifDataChange('create', exif.create.value)">
                        </el-date-picker>
                    </li>
                    <li v-if="exif.hasOwnProperty('country')">
                        <div class="content_name">{{ $t('c_country') }}</div>
                        <el-input type="text" placeholder="Republic of Korea" v-model="exif.country.value"
                                  :readonly="exif.country.options.readonly"
                                  @input="exifDataChange('country', exif.country.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('si')">
                        <div class="content_name">{{ $t('c_si') }}</div>
                        <el-input type="text" placeholder="Gyeongsangbuk-do" v-model="exif.si.value"
                                  :readonly="exif.si.options.readonly"
                                  @input="exifDataChange('si', exif.si.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('city')">
                        <div class="content_name">{{ $t('c_city') }}</div>
                        <el-input type="text" placeholder="Gyeongju-si" v-model="exif.city.value"
                                  :readonly="exif.city.options.readonly"
                                  @input="exifDataChange('city', exif.city.value)"/>
                    </li>
                    <li v-if="exif.hasOwnProperty('copyright')">
                        <div class="content_name ">
                            {{ $t('copyright_info') }}
                        </div>
                        <el-input type="text" placeholder="Copyright ..." v-model="exif.copyright.value"
                                  :readonly="exif.copyright.options.readonly"
                                  @input="exifDataChange('copyright',exif.copyright.value)"/>
                    </li>
                </ul>
            </div>
        </li>

    </div>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import alertMixins from "@/mixins/alertMixins";
import elFileMixins from "@/mixins/elFileMixins";
import EXIF from "exif-js"
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import exifMixins from "@/components/item/lib/exifMixins";
import EventBus from "@/utils/event-bus";
import exifr from 'exifr'
import VideoPlayer from "@/components/videoPlayer";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemMinglemintLayout",
    mixins: [alertMixins, elFileMixins, dateMixins, exifMixins],
    components: {VideoPlayer},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        setDeleteFiles: {
            default: () => {
            }, type: Function
        },
        initStatusDate: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,},
        itemCategory: '',
    },
    provide() {
        return {
            videoSetData: this.setData,
        }
    },
    data() {
        return {
            type: 'digital',
            isEdition: 0,
            editionCount: 0,
            editionTotal: 0,
            isGuarantee: 0,
            guaranteeFile: [],
            thumbnailFile: [],
            originalFile: [],
            videoFile: [],
            videoStreamFile: [],
            originator: 1,
            videoType: {},
            exifType: '',
            exif: {},
            validatorArr: ['type', 'thumbnailFile', 'originalFile', 'exifType', 'editionCount', 'editionTotal', 'guaranteeFile'],
            player: {},
            guaranteeMaxSize: 200 * 1024 * 1024,
            thumbnailMaxSize: 20 * 1024 * 1024,
            originalMaxSize: 200 * 1024 * 1024,
            statusExifData: {},
            aiCreate:0
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
        this.setValidatorArr(this.validatorArr);
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        setData(key, value) {
            this[key] = value
        },
        checkValidator() {
            this.$validate(['type', 'thumbnailFile', 'originalFile', 'editionCount', 'editionTotal', 'guaranteeFile','aiCreate'])
        },
        async settingItemData() {
            this.type = this.itemData.type;
            this.isEdition = this.itemData.isEdition;
            this.editionTotal = this.itemData.editionTotal;
            this.editionCount = this.itemData.editionCount;
            this.isGuarantee = this.itemData.isGuarantee;
            this.guaranteeFile = this.$_.cloneDeep(this.itemData.guaranteeFile);
            this.thumbnailFile = this.$_.cloneDeep(this.itemData.thumbnailFile)
            this.originalFile = this.$_.cloneDeep(this.itemData.originalFile)
            if (!util.isEmpty(this.itemData.videoFile)) {
                this.videoFile = this.$_.cloneDeep(this.itemData.videoFile)
            }
            if (!util.isEmpty(this.itemData.videoStreamFile)) {
                this.videoStreamFile = this.$_.cloneDeep(this.itemData.videoStreamFile)
            }

            this.originator = this.itemData.originator;
            this.exifType = this.itemData.exifType;
            this.exif = this.$_.cloneDeep(this.itemData.exif);
            if (this.exifType === 'photo' && util.isEmpty(this.originalFile[0].org_url) && (util.isEmpty(this.exif.size.value) || this.exif.size.value === '0*0')) {
                let size = await this.getImageExifDataSize({}, this.originalFile[0].org_url);
                if (!util.isEmpty(size)) {
                    this.exif.size.value = size;
                    this.exif.size.options.readonly = true;
                }
                this.parentChange('exif', this.exif);
            }
        },
        changeType(value) {
            this.parentChange('type', value);
            this.changeEdition(this.isEdition)
            this.parentChange('status', 9);
            this.initStatusDate();
            if (!util.isEmpty(this.originalFile)) {
                this.originalFile = [];
                this.parentChange('originalFile', this.originalFile)
            }
            if (value === 'real') {
                this.exifType = 'etc';
                this.exif = this.itemClass.getExifDataObj();
                this.parentChange('exifType', this.exifType)
                this.parentChange('exif', this.exif)
                this.setThumbnailToOriginal();
            }
        },
        changeEdition(value) {
            this.parentChange('isEdition', value);
            this.count = 0;
            this.total = 0;
        },
        changeIsGuarantee(value) {
            this.parentChange('isGuarantee', value);
            if (value === 0) {
                this.guaranteeFile = [];
                this.parentChange('guaranteeFile', this.guaranteeFile);
            }
        },
        checkFileIdx(file) {
            if (util.isEmpty(file) || util.isEmpty(file.a_idx)) {
                return false;
            }
            return true;
        },
        removeList(type, index) {
            if (type === 'originalFile') {
                this.setExifType();
            }
            let files = this[type].splice(index, 1);
            this.setDeleteFiles(files)
            this.parentChange(`${type}`, this[type])
            if (type === 'originalFile' && !util.isEmpty(this.videoStreamFile)) {
                this.setDeleteFiles(this.videoStreamFile[0])
                this.videoStreamFile = [];
                this.parentChange(`videoStreamFile`, this.videoStreamFile)
            }
            if (type === 'originalFile' && !util.isEmpty(this.videoFile)) {
                this.setDeleteFiles(this.videoFile[0])
                this.videoFile = [];
                this.parentChange(`videoFile`, this.videoFile)
            }
            this.$forceUpdate();
        },
        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        checkFileSize(size, max) {
            if (size > max) {
                let sizeMB = max / 1024 / 1024
                this.errorAlert(this.$t('uploadFile_error1', {size: sizeMB + "MB"}));
                return false;
            }
            return true;
        },
        guaranteeFileChange(file, fileList) {
            file = this.makeFileObj(file);
            if (this.guaranteeFile.length > 5) {
                this.errorAlert(this.$t('l_img2'));
                fileList.pop();
                return false;
            }
            if (!this.checkFileSize(file.size, this.guaranteeMaxSize)) {
                fileList.pop();
                return false;
            }
            this.guaranteeFile = fileList;
            this.$validate(['guaranteeFile'])
            this.parentChange('guaranteeFile', this.guaranteeFile);
            return true;
        },
        thumbnailFileChange(file, fileList) {
            let type = file.raw.type.split('/');
            file = this.makeFileObj(file);
            if (!this.checkFileSize(file.size, this.thumbnailMaxSize)) {
                fileList.pop();
                return false;
            }
            if (!(type[1] === 'jpeg' || type[1] === 'png')) {
                this.errorAlert(this.$t('uploadFile_error2'))
                fileList.pop();
                return false;
            }
            if (this.checkFileIdx(this.thumbnailFile[0])) {
                file.a_idx = this.thumbnailFile[0].a_idx;
            }
            this.thumbnailFile[0] = file;
            this.parentChange('thumbnailFile', this.thumbnailFile);
            this.$validate(['thumbnailFile'])
            this.setThumbnailToOriginal();
            this.$forceUpdate();
            return true;
        },
        isSetThumbnailToOriginal() {
            return this.type === 'real' && !util.isEmpty(this.thumbnailFile);
        },
        setThumbnailToOriginal() {
            if (this.isSetThumbnailToOriginal()) {
                let orgFile = Object.assign({}, this.thumbnailFile[0]);
                this.originalFile[0] = orgFile;
                this.parentChange('originalFile', this.originalFile);
            }
        },
        setExifType(file) {

            if (util.isEmpty(file)) {
                this.exifType = '';
                this.parentChange('exifType', this.exifType);
                this.exif = {};
                this.parentChange('exif', this.exif);
                return false;
            }


            let type = file.raw.type.split('/');
            let exifType = type[0];
            if (exifType === 'image') {
                exifType = 'photo'
            }
            if (['photo', 'video', 'audio', 'etc'].indexOf(exifType) < 0) {
                exifType = 'etc';
            }
            this.exifType = exifType;
            this.statusExifData.exifType = exifType;
            this.parentChange('exifType', this.exifType);
        },
        originalFileChange(file, fileList) {
            file = this.makeFileObj(file);
            if (!this.checkFileSize(file.size, this.originalMaxSize)) {
                fileList.pop();
                return false;
            }
            this.setExifType(file)
            this.originalFile[0] = file;
            this.parentChange('originalFile', this.originalFile);
            if (this.exifType === 'photo') {
                this.setOriginalToThumbnail();
            } else if (this.exifType === 'video') {
                this.videoStreamFile[0] = file;
                this.parentChange('videoStreamFile', this.videoStreamFile);
            }
            if (this.isMakeExifData(file)) {
                this.makeExifData(file);
            }
            this.parentChange('statusExifData', this.statusExifData);
            this.$validate(['originalFile'])
            return true;
        },
        promptOriginalFileChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            file.name = this.regExp(file.name)
            file.org_name = file.name;
            file.org_url = file.url;
            if (file.size > maxSize) {
                this.errorAlert($t('uploadFile_error1', {size: this.imageSizeError}));
                fileList.pop();
            } else {
                this.originalFile[0] = file;
                this.exifType = 'etc';
                this.parentChange('originalFile', this.originalFile);
                this.parentChange('exifType', this.exifType);

                this.setExifType(file)
                if (this.isMakeExifData(file)) {
                    this.makeExifData(file);
                }
                this.parentChange('statusExifData', this.statusExifData);
                this.$forceUpdate();

            }
        },
        setOriginalToThumbnail() {
            this.thumbnailFile = this.$_.cloneDeep(this.originalFile);
            this.parentChange('thumbnailFile', this.thumbnailFile);
        },
        isMakeExifData(file) {
            return !util.isEmpty(file) && !util.isEmpty(file.raw);
        },
        makeExifData(file) {
            let _this = this;
            let fileData = file.raw;
            let url = file.org_url;
            let exifData = {};
            //추후 이미지가아닐때 exif 정보를 가져오는 플러그인 추가가 필요합니다.
            if (this.exifType === 'photo') {
                exifr.parse(fileData, true).then(res => {
                    exifData = res;
                    exifData.fileUrl = url;
                    _this.exif = _this.itemClass.getExifDataObj(_this.exifType);
                    _this._settingExifValue(exifData)
                    _this.$forceUpdate();
                });
            } else if (this.exifType === 'video') {
                _this.exif = _this.itemClass.getExifDataObj(_this.exifType);
                this.settingVideoExifValueSize();
            } else if (this.exifType === 'audio') {
                _this.exif = _this.itemClass.getExifDataObj(_this.exifType);
                this.settingAudioExifValueSize();
            } else {
                _this.exif = _this.itemClass.getExifDataObj(_this.exifType);
                _this.parentChange('exif', _this.exif);
            }

        },

        async settingVideoExifValueSize() {
            let $this = this
            let size = '';
            let idx = this.originalFile[0].name.lastIndexOf('.')
            let extension = this.originalFile[0].name.substring(idx + 1);
            let url = this.originalFile[0].org_url;
            this.videoType = `video/${extension}`;
            if (extension === 'mp4') {
                size = await this.getBlobVideoFileSize(url);
                if (!util.isEmpty(size)) {
                    this.exif.size.value = size;
                    this.exif.size.options.readonly = true;
                }
                this.parentChange('exif', this.exif);
            } else {
                var reader = new FileReader();

                function dataURLtoBlob(dataurl) {
                    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    return new Blob([u8arr], {type: mime});
                }

                reader.readAsDataURL(this.originalFile[0].raw);

                reader.onload = async function () {
                    let test = reader.result.split('base64,');
                    let dataUrl = 'data:video/mp4;base64,' + test[1]
                    let blob = dataURLtoBlob(dataUrl);
                    var DOMURL = window.URL || window.webkitURL || window;
                    var bloburl = DOMURL.createObjectURL(blob);

                    size = await $this.getBlobVideoFileSize(bloburl);
                    console.log(size);
                    if (!util.isEmpty(size)) {
                        $this.exif.size.value = size;
                        $this.exif.size.options.readonly = true;
                    }
                    $this.parentChange('exif', $this.exif);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            }

        },

        async settingAudioExifValueSize() {
            let $this = this
            let idx = this.originalFile[0].name.lastIndexOf('.')
            let extension = this.originalFile[0].name.substring(idx + 1);
            let url = this.originalFile[0].org_url;

            let size = await this.getBlobVideoFileSize(url);
            if (!util.isEmpty(size)) {
                this.exif.size.value = size;
                this.exif.size.options.readonly = true;
            }
            this.parentChange('exif', this.exif);
        },
        _settingExifValue(exifData) {
            this.parentChange('exif', this.exif);
            if (this.exifType === 'photo') {
                this._settingImageExifValueCreate(exifData);
                this._settingImageExifValueLocation(exifData);
                this._settingImageExifValueSize(exifData);
                this._settingImageExifValueCopyright(exifData);
                this._settingTitleExifValueTitle(exifData);
                this._settingTitleExifValueTag(exifData);
            }
            this.statusExifData.exif = this.exif;
            this.parentChange('exif', this.exif);
        },
        async _settingImageExifValueCreate(exifData) {
            let create = await this.getExifDataCreate(exifData)
            if (!util.isEmpty(create)) {
                this.exif.create.value = create;
                this.exif.create.options.readonly = true;
            }
            this.parentChange('exif', this.exif);
        },
        async _settingImageExifValueLocation(exifData) {
            let location = await this.getExifDataImageLocation(exifData)
            if (!util.isEmpty(location.country)) {
                this.exif.country.value = location.country;
                this.exif.country.options.readonly = true;
            }
            if (!util.isEmpty(location.si)) {
                this.exif.si.value = location.si;
                this.exif.si.options.readonly = true;
            }
            if (!util.isEmpty(location.city)) {
                this.exif.city.value = location.city;
                this.exif.city.options.readonly = true;
            }
            this.parentChange('exif', this.exif);
        },
        async _settingImageExifValueSize(exifData) {
            let size = await this.getImageExifDataSize(exifData, exifData.fileUrl);
            if (!util.isEmpty(size)) {
                this.exif.size.value = size;
                this.exif.size.options.readonly = true;
            }
            this.parentChange('exif', this.exif);
        },
        async _settingImageExifValueCopyright(exifData) {
            let copyright = await this.getExifDataCopyright(exifData);
            if (!util.isEmpty(copyright)) {
                this.exif.copyright.value = copyright;
            }
            this.parentChange('exif', this.exif);
        },
        async _settingTitleExifValueTitle(exifData) {
            let title = await this.getExifDataTitle(exifData)
            if (!util.isEmpty(title)) {
                EventBus.$emit('basicParentChange', title);
            }
        },
        async _settingTitleExifValueTag(exifData) {
            let tag = await this.getExifDataTag(exifData)
            if (!util.isEmpty(tag)) {
                util.Array.each(tag, (data, index, arr) => {
                    EventBus.$emit(`hashTagAdd`, data);
                });
            }
        },
        exifDataChange(type, value) {
            this.parentChange('exif', this.exif);
        },
        openUrl(url) {
            window.open(url);
        },

    },
    watch: {
        'itemCategory': {
            deep: true,
            handler: function (val, oldVal) {
                if (this.itemCategory == 178) {
                    this.type = 'digital'
                    this.changeType(this.type);
                }
            }
        }
    },
    validators: {
        type: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg1'))
        },
        editionCount: function (value) {
            return Validator.value(value)
                    // .required(this.$t('itemErrorMsg4'))
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg4'))
                    .custom(() => {
                        if (this.isEdition === 1 && this.type === 'real' && (value * 1 > this.editionTotal * 1 && this.editionTotal)) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        editionTotal: function (value) {
            return Validator.value(value)
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg5'))
                    .custom(() => {
                        if (this.isEdition === 1 && (value < 1 || util.isEmpty(value))) {
                            return this.$t('itemErrorMsg5')
                        }
                        if (this.isEdition === 1 && (value * 1 < this.editionCount * 1 && this.editionCount)) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        guaranteeFile: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.isGuarantee === 1 && value.length === 0) {
                            return this.$t('itemErrorMsg17')
                        }
                    })
        },
        thumbnailFile: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg15'))
        },
        originalFile: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.type === 'digital' && value.length === 0) {
                            return this.$t('itemErrorMsg16')
                        }
                    })
        },
        aiCreate: function (value) {
            return Validator.value(value)
        },
    },
}
</script>

<style scoped>

</style>
