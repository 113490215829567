const curationConfig = {
    curationWrite: {
        writeLayouts: ['CurationWriteItemsLayout'],
        getFunc: '',
        detailApiParamSet: {},
        getKeyName: '',
        writeFunc: 'createCuration',
        writeApiParamSet: {
        },
        parsingData: {
            i_idx: 'i_idx',
            curatorKey: "ibc_curator"
        },
        directUrl: '/mypage/curation',
        directType: 'getItemList',
        keyName: 'i_idx',
        writeSuccessType: 1,
        writeFailTypes: {
            //'-103': {openAlert: false, text: 'd_change_status_err2'},
        },
        writeFailDefault: 'curation_create_err1',
        writeSuccessAlert: 'curation_c_msg3',
        openConfirm: true,
    },
    curation: {
        filterLayout: 'CurationFilterDefaultLayout',
        listLayout: 'CurationListDefaultLayout',
        emptyLayout: 'CurationEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCurationAuctionItem',
        listApiParamSet: {
            ip:1,
            obt: 'ibc.created_at',
            obs: 'desc',
        },
        pageRow: 10,
        sortOptions: {},

        deleteFunc: 'abandonCuration',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-100': 'curation_abandon_err1',
            '-106': 'curation_abandon_err2',
            '-101': 'curator_cancel_err2',
            '-1': 'curation_abandon_err3',
        },
        deleteAfterAlert: 'curation_abandon_success',
        keyName: 'ibc_idx',

    },
    request: {
        filterLayout: 'CurationFilterRequestLayout',
        listLayout: 'CurationListRequestLayout',
        emptyLayout: 'CurationEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCurationRequestItem',
        listApiParamSet: {
            ip:1,
            obt: 'ibc.created_at',
            obs: 'desc',
        },
        pageRow: 10,
        sortOptions: {},

        deleteFunc: 'abandonCuration',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-100': 'curation_abandon_err1',
            '-106': 'curation_abandon_err2',
            '-101': 'curator_cancel_err2',
            '-1': 'curation_abandon_err3',
        },
        deleteAfterAlert: 'curation_abandon_success',
        keyName: 'ibc_idx',
    },
    receive: {
        filterLayout: 'CurationFilterReceiveLayout',
        listLayout: 'CurationListReceiveLayout',
        emptyLayout: 'CurationEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',

        getFunc: 'getCuration',
        listApiParamSet: {
            ip:1,
            ibst:[0, 1, 2, 4, 5],
            obt: 'ibc.created_at',
            obs: 'desc',
        },
        pageRow: 10,
        sortOptions: {},

        deleteFunc: 'cancelCuration',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        keyName: 'ibc_idx',
    },
    send: {
        filterLayout: 'CurationFilterSendLayout',
        listLayout: 'CurationListSendLayout',
        emptyLayout: 'CurationEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCuration',
        listApiParamSet: {
            ip:1,
            // ibst:[0, 1, 2, 3, 4],
            obt: 'ibc.created_at',
            obs: 'desc',
        },
        pageRow: 10,
        sortOptions: {},

        deleteFunc: 'cancelCuration',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        keyName: 'ibc_idx',
    },
    curationHistory: {
        filterLayout: 'CurationFilterNoneLayout',
        listLayout: 'CurationListHistoryLayout',
        emptyLayout: 'CurationEmptyDefaultLayout',
        paginationLayout: 'PaginationModalLayout',
        getFunc: 'getCurationHistory',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 10,
        sortOptions: {},

        deleteFunc: 'cancelCuration',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        keyName: 'ibc_idx',

    },

}
export default {
    filterConfig: {
        tab: {
            type: 'match',
            classname: 'tab',
        },
        ina: { //제목(아이템이름)
            type: 'query',
            classname: 'searchInput',
        },
        hash: { //해시태그
            type: 'query',
            classname: 'searchInput',
        },
        orni: { //창작자
            type: 'query',
            classname: 'searchInput',
        },
        cuni: { //의뢰큐레이터
            type: 'query',
            classname: 'searchInput',
        },
        ic: { //아이템 카테고리
            type: 'terms',
            classname: 'filterCategory',
        },
        ed: { //아이템 에디션
            type: 'terms',
            classname: 'filterEdition',
        },
        ty: { //아이템 타입
            type: 'terms',
            classname: 'filterType',
        },
        ibst: {//0:대기,1:수락,2:거절,3:의뢰취소,4:판매완료,5:의뢰포기
            type: 'terms',
            classname: 'filterStatus',
            option: {
                accept: [1, 4]
            }
        },
        cu: {
            type: 'between',
            classname: 'filterRequestDate'
        },
        cust: { //큐레이션 상태
            type: 'terms',
            classname: 'filterCurationStatus',
            option: {
                'cancel': [3, 5, 6, 7]
            }
        },
        st: { //아이템 상태
            type: 'terms',
            classname: 'filterItemStatus',
        },
        pr: { //아이템 가격
            type: 'between',
            classname: 'priceInput',
            option: {
                start: 'spr',
                end: 'epr'
            }
        },
        cr:{
            type: 'between',
            classname: 'filterDate',
        },
    },
    methods: {
        returnCurationConfig(type) {
            return curationConfig[type];
        }
    },
}
