const judgeConfig = {
    judgeBefore: {
        listLayout: 'JudgeListBeforeLayout',
    },
    judgeAfter: {
        listLayout: 'JudgeListAfterLayout',
    },
    organizer: {
        listLayout: 'JudgeListOrganizerLayout',
    },
    judgeWrite: {
        writeLayout: 'JudgeWriteDefaultLayout',
        writeFunc : 'createJudge'
    }

}
export default {
    methods: {
        returnJudgeConfig(type) {
            return judgeConfig[type];
        }
    },
}
