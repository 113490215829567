const galleryConfig = {
    profileGallery: {
        listLayout: 'GalleryListProfileLayout',
        emptyLayout: 'GalleryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 15,
        sortOptions: {},
    },
    creatorGallery: {
        listLayout: 'GalleryListCreatorLayout',
        emptyLayout: 'GalleryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 15,
        sortOptions: {},
    },
    gallery: {
        listLayout: 'GalleryListDefaultLayout',
        emptyLayout: 'GalleryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 15,
        sortOptions: {},
    },
    galleryBanner: {
        detailLayout: 'GalleryDetailBannerLayout',
        getFunc: 'getCollection',
        detailApiParamSet: {
        },
        getKeyName: 'id',
        isGetWriter: true,
    },
    galleryEdit: {
        listLayout: 'GalleryListEditLayout',
        emptyLayout: 'GalleryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',

        getFunc: 'getCollectionItem',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 20,
        sortOptions: {},

        deleteFunc: 'deleteCollectionItem',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-103': "collection_item_delete_err1",
            '-202': "curator_cancel_err2"
        },
        keyName: 'ci_idx',
    },
    galleryItemView: {
        listLayout: 'GalleryListItemLayout',
        emptyLayout: 'GalleryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',

        getFunc: 'getCollectionItem',
        listApiParamSet: {
            ip:1,
        },
        pageRow: 20,
        sortOptions: {},

        deleteFunc: 'deleteCollectionItem',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        keyName: 'ci_idx',
    },
    galleryAdd: {
        writeLayouts: ['GalleryWriteModalLayout'],
        writeFunc: 'createCollection',
        writeApiParamSet: {},
        parsingData: {
            title: 'c_title',
            // thumbnailFile: '',
            backgroundFile: '',
        },
        fileInfo: [
            /*{
                fileName: 'thumbnailFile',
                fileType: 'collectionThumb',
            },*/{
                fileName: 'backgroundFile',
                fileType: 'collectionBack',
            },
        ],
        directUrl: 'gallery-setting-modal',
        directType: 'modal',
        writeSuccessType: 1,
        writeFailTypes: {},
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        getFunc: 'getCollection',
        detailApiParamsSet: {},
        deleteFunc: '',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-103': "collection_delete_err1",
            '-202': "curator_cancel_err2"
        },

        keyName: 'c_idx',
    },

    galleryUpdate: {
        writeLayouts: ['GalleryWriteModalLayout'],

        writeFunc: 'updateCollection',
        writeApiParamSet: {},
        parsingData: {
            title: 'c_title',
            // thumbnailFile: '',
            backgroundFile: '',
        },
        fileInfo: [
            /*{
                fileName: 'thumbnailFile',
                fileType: 'collectionThumb',
            },*/{
                fileName: 'backgroundFile',
                fileType: 'collectionBack',
            },
        ],
        directUrl: 'gallery-setting-modal',
        directType: 'modal',
        writeSuccessType: 1,
        writeFailTypes: {
            '-202' : {openAlert: true, text: 'curator_cancel_err2'},
        },
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        getFunc: 'getCollection',
        detailApiParamsSet: {},
        deleteFunc: 'deleteCollection',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-103': "collection_delete_err1",
            '-202': "curator_cancel_err2"
        },

        keyName: 'c_idx',
    },
    galleryDetail: {
        detailLayout: 'GalleryDetailDefaultLayout'
    },
    galleryItemAdd: {
        writeLayouts: ['GalleryWriteItemAddLayout'],
        writeFunc: 'createCollectionItem',
        writeApiParamSet: {},
        parsingData: {
            itemKey: 'i_idx',
        },
        fileInfo: [],
        directUrl: '',
        directType: '',
        writeSuccessType: 1,
        writeFailTypes: {
            '-103' : {openAlert: true, text: 'collection_item_add_err1'},
            '-104' : {openAlert: true, text: 'collection_item_add_err2'},
            '-202' : {openAlert: true, text: 'curator_cancel_err2'},
            '-601' : {openAlert: true, text: 'collection_item_add_err3'},
        },
        writeFailDefault: 'collection_item_add_err4',
        writeSuccessAlert: 'd_ignore_url_success',
        getFunc: 'getCollection',
        detailApiParamsSet: {},
        deleteFunc: '',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        keyName: 'c_idx',
    },
}
export default {
    filterConfig: {

    },
    methods: {
        returnGalleryConfig(type) {
            return galleryConfig[type];
        }
    },
}
