import { render, staticRenderFns } from "./EntryListComponent.vue?vue&type=template&id=79653715&scoped=true"
import script from "./EntryListComponent.vue?vue&type=script&lang=js"
export * from "./EntryListComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79653715",
  null
  
)

export default component.exports