<template>
    <li class="i_w_content hash_wrap">
        <h3 class="sub_title no_caution_img">{{ $t('hashtag') }}</h3>
        <div class="w_content_box hash_tag">
            <div class="content_name ">
                {{ $t('d_category') }}
            </div>
            <div class="w_content" v-if="childCategory.length > 0">
                <el-select id="hash" v-model="selectedCategory" class="w_content my_filter select"
                           :placeholder="$t('s_category')"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                           @change="addHash('category', selectedCategory)">
                    <el-option :value="data.node_id" v-for="data in childCategory"
                               :key="`item${data.node_id}`" :label="returnCategoryName(data)">
                    </el-option>
                </el-select>
                <ul class="hash_tag_wrap">
                    <li v-for="(data,index) in hashtag" :key="`hash${index}`" v-if="data.type === 1">
                        <div class="hash_wrap">
                            <span class="hash_name">{{ returnHashTag(data.tag) }}</span>
                            <button class="delete_btn" @click="removeHash(index)"></button>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="w_content w_self">
                <el-input type="text" :placeholder="$t('w_hash')" v-model="hashInputValue"
                          @keyup.enter.native="addHash('input', hashInputValue)"/>
                <div class="plus_btn" for="addition" @click="addHash('input', hashInputValue)"></div>
                <div class="d_hash_tag_wrap" v-for="(data,index) in hashtag" :key="`hash${index}`"
                     v-if="data.type === 2">
                    <div class="hash_wrap">
                        <span class="hash_name ">{{ data.tag }}</span>
                        <button class="delete_btn" @click="removeHashInput(index,hashInputValue)"></button>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemHashtagLayout",
    mixins: [alertMixins],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        childCategory: {default: () => []},
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            selectedCategory: '',
            hashInputValue: '',

            hashtag: [],
            hashtagInput: [],

            validatorArr: [],

            hashTagDataDefine: {type: 2, tag: ''},
            isChangeHashtag: 0,
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData()
        }
    },
    mounted() {
        EventBus.$on('hashTagAdd', this.exifAddHash)
    },
    beforeDestroy() {
        EventBus.$off('hashTagAdd')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.hashtag = this.itemData.hashtag;
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {

        },
        removeCategoryType() {
            this.hashtag = [];
            this.parentChange('hashtag', this.hashtag);
        },
        _getHashCategoryType(value) {
            let hash = this.$_.cloneDeep(this.hashTagDataDefine)
            hash.type = 1;
            hash.tag = value;
            return hash
        },
        _getHashInputType(value) {
            let hash = this.$_.cloneDeep(this.hashTagDataDefine)
            hash.type = 2;
            hash.tag = value;
            return hash
        },
        _checkAddHashValidator(hash) {
            if (util.isEmpty(hash) || util.isEmpty(hash.tag)) {
                return false;
            }
            if (this.hashtag.length > 50) {
                this.errorAlert(this.$t('l_hash'));
                return false;
            }
            if (this.hashtag.findIndex(data => data.type == hash.type && data.tag == hash.tag) > -1) {
                return false;
            }
            return true;
        },
        exifAddHash(value) {
            this.addHash('input', value);
        },
        addHash(type, value) {
            let hash = null;
            if (type === 'category') {
                hash = this._getHashCategoryType(value)
            } else if (type === 'input') {
                hash = this._getHashInputType(value)
                this.hashtagInput.push(hash);
            }
            if (!this._checkAddHashValidator(hash)) {
                return false;
            }
            this.hashtag.push(hash);
            this.parentChange('hashtag', this.hashtag);
            this.parentChange('hashtagInput', this.hashtagInput);
            this.hashInputValue = "";
            this.$forceUpdate();
        },
        returnHashTag(data) {
            let lang = this.$localStorage.get('lang');
            let hashIndex = this.childCategory.findIndex(category => category.node_id === data);
            console.log(hashIndex)
            if (hashIndex > -1) {
                return this.returnCategoryName(this.childCategory[hashIndex])
                /*if (lang === 'ko') {
                    return this.childCategory[hashIndex].NAME;
                } else {
                    return this.childCategory[hashIndex].NAME;
                }*/
            }
            return '';
        },
        removeHash(index) {
            this.hashtag.splice(index, 1);
            this.parentChange('hashtag', this.hashtag);
        },
        removeHashInput(index, data) {
            let hashIndex = this.hashtagInput.findIndex(hash => hash.tag === data);
            this.hashtagInput.splice(hashIndex, 1);
            this.hashtag.splice(index, 1);
            this.parentChange('hashtag', this.hashtag);
        },
        returnCategoryName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.NAME
            }
            return data.NAME_ENG
        },
    },
    watch: {
        'childCategory': {
            deep: true,
            handler: function (val, oldVal) {
                if (this.isChangeHashtag > 0) {
                    this.selectedCategory = '';
                    this.removeCategoryType();
                    this.hashtag = this.hashtagInput;
                }
                this.isChangeHashtag = 1;
            }
        }
    },
}
</script>

<style scoped>

</style>
