<template>
    <div>
        <!--              최소입찰가-->
        <li class="i_w_content mini_bid_wrap">
            <h3 class="sub_title">{{ $t('mini_bid') }}</h3>
            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="minimumSetting"
                                :disabled="itemData.isAuctioning"
                                @change="parentChange('minimumSetting', minimumSetting)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no_setting') }}
                    </el-radio>
                </el-radio-group>
                <div class="mg_input">
                    <div class="content_name">{{ $t('mini_bid') }}</div>
                    <el-input type="tel" v-model="minimum" placeholder="0"
                              class="el_input_num"
                              @input="returnNumber('minimum',minimum)"
                              :disabled="minimumSetting === 0 || itemData.isAuctioning"/>
                </div>
                <div class="val_cap" v-if="validation.hasError('minimum')&& minimumSetting === 1">
                    {{ validation.firstError('minimum') }}
                </div>
                <div class="val_cap" v-if="!validation.hasError('minimum') && maxPriceErrorMsg">
                    {{ maxPriceErrorMsg }}
                </div>
            </div>
        </li>

        <!--              즉시구매가-->
        <li class="i_w_content buy_now_wrap">
            <h3 class="sub_title">{{ $t('i_buy') }}</h3>
            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="immediatelySetting"
                                :disabled="itemData.isAuctioning"
                                @change="parentChange('immediatelySetting', immediatelySetting)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no_setting') }}
                    </el-radio>
                </el-radio-group>
                <div class="mg_input">
                    <div class="content_name">{{ $t('i_buy') }}</div>
                    <el-input type="tel" v-model="immediately" placeholder="0"
                              class="el_input_num"
                              @input="returnNumber('immediately',immediately)"
                              :disabled="immediatelySetting === 0 || itemData.isAuctioning"/>
                </div>
                <div class="val_cap"
                     v-if="validation.hasError('immediately') && immediatelySetting === 1">
                    {{ validation.firstError('immediately') }}
                </div>
                <div class="val_cap" v-if="!validation.hasError('immediately') && maxPriceErrorMsg">
                    {{ maxPriceErrorMsg }}
                </div>
            </div>
        </li>

        <!--              경매시작일-->
        <li class="i_w_content detail_info_wrap time_wrap">
            <h3 class="sub_title ">{{ $t('start_bid') }}</h3>
            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="startDateStatus"
                                :disabled="itemData.isAuctioning"
                                @change="parentChange('startDateStatus', startDateStatus)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('start') }}
                    </el-radio>
                </el-radio-group>
                <ul class="sub_content detail_wrap">
                    <li>
                        <div class="content_name ">
                            {{ $t('start_bid') }}
                        </div>
                        <el-date-picker
                                :disabled="itemData.isAuctioning || startDateStatus===0"
                                class="range_time"
                                v-model="startDate"
                                :picker-options="startPickerOptions"
                                @change="dateChange('startDate', startDate)"
                                format="yyyy-MM-dd HH:mm"
                                type="datetime"
                                popper-class="not_now"
                                placeholder="YYYY-MM-DD HH:MM">
                        </el-date-picker>
                        <div class="val_cap" v-if="validation.hasError('startDate')">
                            {{ validation.firstError('startDate') }}
                        </div>
                    </li>
                </ul>
            </div>
        </li>

        <!--              경매종료일-->
        <li class="i_w_content detail_info_wrap time_wrap">
            <h3 class="sub_title ">{{ $t('end_bid') }}</h3>
            <div class="w_content_box">
                <ul class="sub_content detail_wrap mt0 pt0">
                    <li>
                        <div class="content_name ">
                            {{ $t('end_bid') }}
                        </div>
                        <el-date-picker
                                :disabled="itemData.isAuctioning"
                                class="range_time"
                                v-model="endDate"
                                @change="dateChange('endDate', endDate)"
                                :picker-options="endPickerOptions"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                popper-class="not_now"
                                placeholder="YYYY-MM-DD HH:MM">
                        </el-date-picker>
                        <div class="val_cap" v-if="validation.hasError('endDate')">
                            {{ validation.firstError('endDate') }}
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    </div>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";
import priceMixins from "@/mixins/priceMixins";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemAuctionLayout",
    mixins: [priceMixins],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            validatorArr: ['minimum', 'immediately', 'startDate', 'endDate'],

            minimumSetting: 0,
            minimum: '',
            immediatelySetting: 0,
            immediately: '',
            startDate: '',
            startDateStatus: 0,
            endDate: '',
            endDateStatus: 0,

            startPickerOptions: {
                disabledDate: this.returnStartDate,
            },
            endPickerOptions: {
                disabledDate: this.returnEndDate,
            },
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
        this.setValidatorArr(this.validatorArr)
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.minimumSetting = this.itemData.minimumSetting;
            this.minimum = this.itemData.minimum;
            this.returnNumber('minimum', this.minimum)
            this.immediatelySetting = this.itemData.immediatelySetting;
            this.immediately = this.itemData.immediately;
            this.returnNumber('immediately', this.immediately)
            this.startDateStatus = this.itemData.startDateStatus;
            this.startDate = this.itemData.startDate;
            this.endDate = this.itemData.endDate;
            this.$forceUpdate();
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {
            try {
                this.$validate(['minimum', 'immediately', 'startDate', 'endDate'])
            } catch (e) {
                console.log(e)
            }
        },
        returnStartDate(time) {
            if (!util.isEmpty(this.endDate)) {
                return time.getTime() > this.$moment(this.endDate) || time.getTime() < this.$moment();
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }
        },
        returnEndDate(time) {
            if (!util.isEmpty(this.startDate)) {
                return time.getTime() <= this.$moment(this.startDate).add(-1, 'day');
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }
        },
        dateChange(key, value) {
            let date = this.$moment(value).format('YYYY-MM-DD HH:mm')
            if (util.isEmpty(value)) {
                date = '';
            }
            this.parentChange(key, date)
        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);

            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
    },
    watch: {},
    validators: {
        minimum: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.minimumSetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }

                            }
                    )

        },
        immediately: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.immediatelySetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }

                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                    let minStr = String(this.minimum)
                                    if (this.minimumSetting === 1 && Number(str.replace(/,/gi, '')) < Number(minStr.replace(/,/gi, ''))) {
                                        return this.$t('auctionErrorMsg');
                                    }

                                }
                            }
                    )
        },
        startDate: function (value) {
            return Validator.value(value)
                    // .required(this.$t('itemErrorMsg11'))
                    .custom(
                            () => {
                                if (this.startDateStatus === 1 && util.isEmpty(value)) {
                                    return this.$t('itemErrorMsg11')
                                }
                                // if (this.$moment() > this.startDate) {
                                //     return this.$t('itemErrorMsg12')
                                // }
                            }
                    )
        },
        endDate: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg13'))
                    .custom(
                            () => {
                                if (value < this.startDate) {
                                    return this.$t('itemErrorMsg14')
                                }
                            }
                    )
        },
    },
}
</script>

<style scoped>

</style>
