<template>
    <div class="">
        <item-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                          :sortData="filterSet.sort"></item-filter-link>
        <item-empty-link :emptyLayout="emptyLayout" v-if="List.length === 0"></item-empty-link>
        <item-list-history-link :listLayout="listLayout" :paginationData="filterSet.page" :historyData="List"
                                v-else></item-list-history-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"></pagination-link>
    </div>
</template>

<script>
import ItemEmptyLink from "@/components/item/ItemEmptyLink";
import ItemFilterLink from "@/components/item/ItemFilterLink";
import PaginationLink from "@/components/item/PaginationLink";
import itemUtil from "@/components/item/itemUtil";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import ItemListHistoryLink from "@/components/item/ItemListHistoryLink";

const {filterConfig} = itemUtil;

let defaultFilterSet = {
    filter: {},
    page: {page: 1, size: 10, total: 0},
    sort: {},
}
export default {
    name: "ItemListHistoryComponent",
    mixins: [itemUtil, alertMixins, filterMixins, listMixins],
    components: {
        ItemListHistoryLink,
        ItemEmptyLink,
        ItemFilterLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setItemListData: this.setData,
        }
    },
    props: {
        itemType: '',
        categoryKey: {default: 0},
        marketKey: '',
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
            itemHistory: [],
            marketData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('vuex', defaultFilterSet);
    },
    mounted() {
        if (util.isEmpty(this.marketData)) {
            this.getMarketData();
        } else {
            this.getList(true);
        }

        EventBus.$on('getItemList', this.getList);
    },
    beforeDestroy() {
        EventBus.$off('getItemList');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let itemConfig = this.returnItemTemplateConfig(this.itemType);
            this.listLayout = itemConfig.listLayout;
            this.filterLayout = itemConfig.filterLayout;
            this.emptyLayout = itemConfig.emptyLayout;
            this.paginationLayout = itemConfig.paginationLayout;
            this.getFunc = itemConfig.getFunc;
            this.listApiParamSet = itemConfig.listApiParamSet;
            this.keyName = itemConfig.keyName;

            if (itemConfig.pageRow && itemConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = itemConfig.pageRow;
                this.filterSet.page.size = itemConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));


            if (this.marketKey !== '' || !util.isEmpty(this.marketKey)) {
                params[this.keyName] = this.marketKey
            }

            let listOptions = {
                url: this.$api.$item[this.getFunc],
                defaultParams: params,
            }

            this.initList('api', listOptions);
        },
        getList(isFirst = false) {
            this.finishGetList = false;
            EventBus.$emit('apiLoading', true);
            try {
                let filterSet = JSON.parse(JSON.stringify(this.getFilterSet()));
                if (isFirst && this.paginationType === 'lazyload' && this.parserType === 'url') {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                    this.List = [];
                    if (!util.isEmpty(this.$route.query.page) && this.$route.query.page != 1) {
                        let url = this.$route.fullPath;
                        url = url.replace(`page=${this.$route.query.page}`, 'page=1');
                        this.$router.replace(url);
                    }
                    this.setPage('page', 1)
                } else if (isFirst && this.paginationType != 'lazyload') {
                    let parsingDataPage = this.getPasingDataPage();
                    filterSet.page.page = parsingDataPage;
                    this.List = [];
                } else if (this.isInitPage === true) {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                }
                if (this.listClass === null) {
                    return false;
                }
                if (this.isInitPage === true && !this.isGetFirst && util.isEmpty(filterSet.filter)) {
                    this.List = [];
                    return false;
                }
                this.listClass.getList(filterSet, this.isInitPage).then(res => {
                    let list = res.list.slice();
                    if (this.paginationType === 'lazyload' && this.List.length > 0 && this.hasPre) {
                        this.List = list.concat(this.List);
                    } else if (this.paginationType === 'lazyload' && filterSet.page.page > 1) {
                        this.List = this.List.concat(list);
                    } else {
                        this.List = list;
                    }

                    this.List = list;

                    if (res.hasOwnProperty('total')) {
                        this.setPage('total', res.total + 1);
                    }
                    if (isFirst && this.paginationType === 'lazyload') {

                    } else if (isFirst) {
                        this.setParsingDataPageFilterSet();
                    }
                    if (this.isInitPage) {
                        this.setPage('page', 1);
                        this.isInitPage = false;
                    }
                    let maxPage = Math.ceil(this.filterSet.page.total / this.filterSet.page.size);
                    if (this.filterSet.page.page === maxPage) {
                        this.List.push(this.marketData);
                    }

                    this.finishGetList = true;
                    if (!this.isGet) {
                        this.isGet = true;
                    }
                    EventBus.$emit('apiLoading', false);
                })
            } catch (e) {
                console.log(e);
            }
        },
        getMarketData() {
            let params = {
                m_idx: this.marketKey
            }
            this.$api.$item.getMarket(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.errorAlert('');
                    return
                }
                this.marketData = res.List
                this.getList(true)
            })
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
