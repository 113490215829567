<template>
    <div class="portfolio_list_wrap">
        <member-detail-component :memberType="'memberPortfolio'" :memberKey="memberKey"></member-detail-component>
    </div>
<!--    <portfolio-list-component :portfolio-type="'portfolioUser'"></portfolio-list-component>-->
</template>
<script>
// import PortfolioListComponent from "@/components/portfolio/PortfolioListComponent";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";

export default {
    name: "CreatorDetailPortfolio",
    mixins: [],
    components: {
        MemberDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
    },
    watch: {},
}
</script>

<style scoped>

</style>
