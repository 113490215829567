<template>
  <modal name="judge-search-modal" class="judge-search-modal"
         :width="`997px`" :height="`884px`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div class="js_modal_wrap">
      <div class="js_modal_title">
        <span>{{ $t('search_judge') }}</span>
        <button class="cancel_btn" @click="modalClose"></button>
      </div>
      <member-list-component :member-type="'judge'" :category-key="'1'"></member-list-component>
    </div>
  </modal>
</template>

<script>
import MemberListComponent from "@/components/member/MemberListComponent";

export default {
  name: "JudgeSearchModal",
  mixins: [],
  components: {
    MemberListComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      url: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
        let path = this.$route.path;
        if(path != this.$route.fullPath) {
            this.$router.replace(path);
        }
    },
    modalClose() {
      this.$modal.hide('judge-search-modal');

    },
    returnUrl(){
      return this.url
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
