<template>
    <div>

        <div class="collection_wrap">
            <div class="collection_edit_wrap">
                <!--                <div class="collection_banner ">
                                    <img src="@/assets/image/main/gallery_banner.png" class="opacity">
                                    <div class="banner_info">
                                        <div class="banner_user">
                                            Nickname collection
                                        </div>
                                        <div class="banner_subject">
                                            고양이 사진관
                                        </div>
                                    </div>
                                </div>-->
                <gallery-detail-component gallery-type="galleryBanner"
                                          :galleryKey="galleryKey"></gallery-detail-component>
                <div class="gallery_item_edit_wrap">
                    <gallery-list-component :gallery-type="'galleryItemView'"
                                            :defaultFilter="defaultFilter"></gallery-list-component>
                </div>
            </div>
        </div>
        <!--    <button @click="openModal('gallery-setting-modal')">갤러리 추가</button>-->
        <gallery-setting-modal></gallery-setting-modal>
    </div>
</template>

<script>
import GalleryDetailComponent from "@/components/gallery/GalleryDetailComponent";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import GallerySettingModal from "@/components/modal/GallerySettingModal";

export default {
    name: "GalleryDetail",
    mixins: [],
    components: {
        GalleryDetailComponent,
        GalleryListComponent,
        GallerySettingModal
    },
    inject: ['setViewData'],
    provide() {
        return {
            setViewData:this.setData
        }
    },
    props: {},
    data() {
        return {
            galleryKey: Number(this.$route.params.idx),
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {cid: this.galleryKey};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name, {galleryType: 'galleryAdd'});
        },
        movePage(url) {
            this.$router.push(url);
        },
        setData(key, value) {
            this[key] = value;
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>

