<template>
    <div>
        <history-detail-component :history-type="'itemHistory'" :historyKey="historyKey"></history-detail-component>
    </div>
</template>

<script>
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";

export default {
    name: "ItemHistory",
    mixins: [],
    components: {
        HistoryDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            historyKey:''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.historyKey = this.$route.params.idx
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>