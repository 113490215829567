<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>

            <div class="mypage_list">
                <div class="mypage_item_tab border_bottom" :class="{ active : tab === 'pointWithdraw'}"
                >
                    {{ $t('mingle_status') }}
                </div>
                <div class="mypage_item_tab border_bottom" :class="{ active : tab === 'pointHistory'}"
                     @click="changeTab('history?backUrl=withdraw')">
                    {{ $t('usage_history') }}
                </div>
                <point-withdraw-component :point-type="'pointWithdraw'"></point-withdraw-component>
            </div>
        </div>
    </div>
</template>

<script>

import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import PointWithdrawComponent from "@/components/point/PointWithdrawComponent";

export default {
    name: "PointWithdraw",
    mixins: [],
    components: {PointWithdrawComponent, MyPageHeaderComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointWithdraw',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            // this.$router.replace(`${this.$route.path}?tab=${tab}`);
            this.movePage(`/mypage/point/${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>