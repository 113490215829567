<template>
    <div class="share_btn" @click="runShare()">
        <button class="kakao"></button>
        <div>{{ $t('kakako') }}</div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import shareUtil from "@/components/share/shareUtil";
import {kakaoService} from "@/plugins/kakaoService";
import EventBus from "@/utils/event-bus";
import imageOption from "@/mixins/imageOption";

const {getShareLink, makeFirebaseShortLink} = shareUtil;
export default {
    name: "ShareKaKaoComponent",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {    
        shareType: {default: '',},
        shareData: {
            default: {
                path: '/',
                img: 'https://testimage.minglemint.com/profileBack/no_image.jpg',
                title: `밍글민트(Minglemint NFT Marketplace)`,
                description: `밍글민트(Minglemint NFT Marketplace)`
            }
        },
        runAfter: {
            type: Function, default: () => {
            }
        },
    },
    data() {
        return {
            dynamicLink: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        runShare() {
            EventBus.$emit('apiLoading', true);
            let $this = this;
            let format = this.returnExtension(this.shareData.img);
            let imgUrl = this.shareData.img + '?' + this.setImageOptions(300, 200, format);
            let img = new Image()
            img.src = imgUrl;
            img.onload = function () {
                setTimeout(() => {
                    $this.openKakao(imgUrl);
                    document.body.removeChild(img);
                }, 500)
            }
            document.body.appendChild(img);

        },
        async openKakao(imgUrl) {
            let shareData = {
                img: imgUrl,
                path: this.shareData.path,
                title: this.shareData.title,
                description: this.shareData.description,
            }
            console.log(shareData);
            let dynamicLink = await makeFirebaseShortLink(shareData);
            this.kakaoShare(dynamicLink, this.shareData.img);
        },
        kakaoShare(link, img) {
            kakaoService().initService();
            if (util.isEmpty(window.Kakao) || util.isEmpty(window.Kakao.Link)) {
                EventBus.$emit('apiLoading', true);
                setTimeout(() => {
                    EventBus.$emit('apiLoading', false);
                    this.kakaoShare(link, img);
                }, 500)
                EventBus.$emit('apiLoading', true);
                return false;
            }
            let msg = this.$t('share_msg')
            let readMore = this.$t('read_more')
            Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: msg,
                    imageUrl: img,
                    link: {
                        mobileWebUrl: link,
                        webUrl: link,
                    },
                },
                buttons: [
                    {
                        title: readMore,
                        link: {
                            mobileWebUrl: link,
                            webUrl: link,
                        },
                    },
                ],
                installTalk: true,
            })
            kakaoService().disconnect();
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
