import {request} from "@/api/request";

const state = {
  utilityCategoryList: [],
}

const getters = {
  getUtilityCategoryList: state => state.utilityCategoryList,
}

const mutations = {
  updateUtilityCategoryList(state, obj) {
    state.utilityCategoryList = obj.data;
  },
}
const actions = {
  setUtilityCategory({commit, rootState}, {}) {
    try {
      let params = {
        parent: [0],
      };
      request.get('utility/category', params).then(res => {
        if (res.data.Data.Result.Info.type != 1) {
          return false;
        }
        let categoryList = res.data.Data.Result.List
        commit('updateUtilityCategoryList', {data: categoryList})
      })
    } catch (e) {
      console.log(e);
    }
  },
}
export default {namespaced: true, state, getters, mutations, actions}
