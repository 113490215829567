<template>
    <modal name="terms-modal" class="terms-modal"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="terms_modal_wrap" id="modal" data-backdrop="static" data-keyboard="false">
          <div class="top_wrap">
            <div class="modal_title">
              <h1 v-if="termsType === 'service'">{{$t('service_terms')}}</h1>
              <h1 v-else-if="termsType === 'privacy'">{{$t('personal_terms')}}</h1>
              <h1 v-else-if="termsType === 'leave'">{{$t('unsubscribe')}}</h1>
              <h1 v-else-if="termsType === 'product'">{{$t('t_e_item')}}</h1>
              <h1 v-else-if="termsType === 'auctionSell'">{{$t('bid_terms')}}</h1>
              <h1 v-else-if="termsType === 'auctionBuy'">{{$t('terms_bid')}}</h1>
              <h1 v-else-if="termsType === 'productSell'">{{$t('product_sell_terms')}}</h1>
              <h1 v-else-if="termsType === 'licenseSell'">{{$t('license_terms')}}</h1>
              <h1 v-else-if="termsType === 'licenseBuy'">{{$t('terms_license')}}</h1>
              <h1 v-else-if="termsType === 'contest'">{{$t('terms_e_enroll')}}</h1>
              <h1 v-else-if="termsType === 'contestItem'">{{$t('terms_apply')}}</h1>
              <h1 v-else-if="termsType === 'contestWin'">{{$t('s_award_terms')}}</h1>
              <h1 v-else-if="termsType === 'curator'">{{$t('curator')}}</h1>
              <h1 v-else-if="termsType === 'curatorForm'">{{$t('curator_req_terms')}}</h1>
              <h1 v-else-if="termsType === 'curation'">{{$t('terms_curation')}}</h1>
              <h1 v-else-if="termsType === 'lawsuit'">{{$t('lawsuit')}}</h1>
              <h1 v-else-if="termsType === 'lawsuitRequest'">{{$t('lawsuitRequest')}}</h1>
              <h1 v-else-if="termsType === 'mingle'">{{$t('mingle')}}</h1>
              <h1 v-else-if="termsType === 'charge'">{{$t('charge_terms')}}</h1>
              <h1 v-else-if="termsType === 'withdraw'">{{$t('withdraw_terms')}}</h1>
              <h1 v-else-if="termsType === 'fees'">{{$t('fees_info')}}</h1>
              <h1 v-else-if="termsType === 'itembuy'">{{$t('buy_item_terms')}}</h1>


            </div>
            <div class="close_btn_wrap request_modal">
              <button class="close_modal" @click="modalClose()"></button>
            </div>
          </div>
          <div class="middle_wrap" style="margin-top: 30px">
            <terms-component :termsType="termsType" :termsKey="termsKey"></terms-component>
          </div>
        </div>
    </modal>
</template>

<script>
import TermsComponent from "@/components/terms/TermsComponent";

export default {
    name: "TermsModal",
    mixins: [],
    components: {
        TermsComponent
    },
    inject: [],
    provide() {
        return {
          'setTermsData': this.setTermsData,
        }
    },
    props: {
    },
    data() {
        return {
            termsType: '',
            url: '',
            termsKey: '',
            termsData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
      setTermsData(value) {
        this.termsData = value;
      },
      handlerBeforeOpen(event) {
        this.termsType = event.params.termsType;
      },
      handlerOpened() {
      },
      handlerBeforeClose() {

      },
      modalClose() {
        // $('body').removeClass('modal-open');
        this.$modal.hide('terms-modal');
      },
      returnUrl() {
          return this.url
      },
    },
    watch: {},
}
</script>

<style scoped>

</style>