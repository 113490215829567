<template>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import {loginTokenSet, snsRegisterSet, getTokenObj} from "@/mixins/Auth";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";

// const NaverKey = "mtNp5mLPqG7slXUOAFnV";
// let thisUrl = "https://localhost:8080/auth";
export default {
    name: "AuthenticationResult",
    components: {},
    mixins: [alertMixins, AuthMixins],
    data() {
        return {

        }
    },
    created() {
        let query = this.$route.query;
		this.getDecodeData(query);
    },
    mounted() {
        EventBus.$emit('apiLoading', true);
    },
    beforeDestroy() {
        EventBus.$emit('apiLoading', false);
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getDecodeData(data) {
            let params = {
                data: data.enc_data,
            }
            /*if(util.isEmpty(data) || util.isEmpty(data.enc_data) || util.isEmpty(encodeObj) || util.isEmpty(objData.key) || util.isEmpty(objData.iv)) {
                this.failNiceAuth();
            }*/
            // let objData = JSON.parse(atob(encodeObj));
            /*if(util.isEmpty(encodeObj) || util.isEmpty(objData.key) || util.isEmpty(objData.iv)) {
                this.failNiceAuth();
            }*/
            this.$localStorage.set('enc_data', data.enc_data);
            EventBus.$emit('apiLoading', true);
            //window.opener.location.href = window.opener.location.origin + '/mypage/update?enc_data=' + data.enc_data;
            window.opener.location.reload();
            window.close();

            return false;
            this.$api.$member.getDecryptData(params).then(res => res.Data.Result).then(res => {

                // this.memberUpdate({})

            })
        },
        memberUpdate(niceData) {
            let params = {

            }
            this.$api.$member.updateMember(params).then(res => res.Data.Result).then(res => {
                if(res.Info.type != 1) {

                }
            })
        },
        failNiceAuth() {
            this.errorAlert(this.$t('authentication_fail'));
            this.$router.replace('/mypage/update');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
