<template>
  <div class="qna_detail_main">
    <board-detail-component :board-type="'qnaDetail'" :board-key="boardKey"></board-detail-component>
    <div class="qna_bottom">
      <button class="black-btn" @click="moveModify(boardKey)"  v-if="qnaData < 1" >{{ $t('modify') }}</button>
      <button class="black-btn m_left14"  @click="deleteQna" v-if="qnaData < 1">{{ $t('confirm_del') }}</button>
      <button class="black-btn m_left14" @click="backList()">{{$t('list_view')}}</button>
    </div>
  </div>
</template>

<script>
import BoardDetailComponent from "@/components/board/BoardDetailComponent";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
  name: "QnaDetail",
  mixins: [],
  components: {
    BoardDetailComponent
  },
  inject: [],
  provide() {
    return{
      'setQnaData': this.setQnaData,
    }
  },
  props: {
  },
  data() {
    return{
      boardKey: '',
      qnaData: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.boardKey = this.$route.params.idx;
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url) {
      this.$router.push(url);
    },
    backList(){
      this.$router.back();
    },
    moveModify(path) {
      this.$router.push(`${path}/update`);
    },
    deleteQna() {
      EventBus.$emit('openDeleteConfirm')
    },
    setQnaData(value) {
      this.qnaData = value;
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>