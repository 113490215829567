<template>
    <div>
        <curation-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter" :sortData="filterSet.sort"></curation-filter-link>
        <curation-list-link :listLayout="listLayout" :curationData="List" :paginationData="filterSet.page" v-if="List.length !== 0"></curation-list-link>
        <curation-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" v-else></curation-empty-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import CurationFilterLink from "@/components/curation/CurationFilterLink";
import CurationListLink from "@/components/curation/CurationListLink";
import CurationEmptyLink from "@/components/curation/CurationEmptyLink";
import PaginationLink from "@/components/curation/PaginationLink";
import curationUtil from "@/components/curation/curationUtil";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import DeleteClass from "@/mixins/deleteClass";

const {filterConfig} = curationUtil;
export default {
    name: "CurationListComponent",
    mixins: [curationUtil, filterMixins, listMixins, alertMixins],
    components: {
        CurationFilterLink,
        CurationListLink,
        CurationEmptyLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return{
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setCurationListData: this.setData,
        }
    },
    props: {
        curationType: '',
        defaultFilter: {default: () => {}},
    },
    data() {
        return{
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: 10,
            sortOptions: {},
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            deleteAfterAlert: '',
            keyName: '',
            deleteClass: null,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('curationListDelete', this.runDelete)
        EventBus.$on('curationListCancel', this.runCurationCancel)
        EventBus.$on('curationListAllow', this.runAllowCuration)
        EventBus.$on('curationListReject', this.runRejectCuration)
    },
    beforeDestroy() {
        EventBus.$off('curationListDelete')
        EventBus.$off('curationListCancel')
        EventBus.$off('curationListAllow')
        EventBus.$off('curationListReject')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initConfig(){
            let config = this.returnCurationConfig(this.curationType);
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.deleteAfterAlert = config.deleteAfterAlert;
            this.keyName = config.keyName;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = util.isEmpty(this.listApiParamSet) ? {} : JSON.parse(JSON.stringify(this.listApiParamSet));

            if(!util.isEmpty(this.defaultFilter)) {
                params = Object.assign({}, params, this.defaultFilter);
            }

            let listOptions = {
                url: this.$api.$curation[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
            this.initDeleteClass();
        },
        initDeleteClass() {
            if(util.isEmpty(this.deleteFunc)) {
                return false;
            }
            let options = {
                func: this.$api.$curation[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.keyName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        deleteAfterFunc() {
            this.getList();
            if(!util.isEmpty(this.deleteAfterAlert)) {
                this.createAlert({
                    content: this.$t(this.deleteAfterAlert),
                })
            }
        },
        runDelete(key) {
            this.deleteClass.runDelete(key);
        },
        runCurationCancel(curation) {
            let params = {
                ibc_idx: curation.ibc_idx,
            }
            try {
                this.$api.$curation.cancelCuration(params).then(res => res.Data.Result).then(res => {
                    if(res.List.Data.Status) {
                        this.getList();
                        this.createAlert({
                            content: this.$t('cancel_success_msg')
                        })
                    }else{
                        this.errorAlert(this.$t('curation_cancel_err1'))
                    }
                })
            }catch (e) {
                console.log(e)
            }
        },
        runAllowCuration(curation) {
            let params = {
                ibc_idx: curation.ibc_idx,
            }
            try {
                this.$api.$curation.allowCuration(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.getList();
                        this.createAlert({
                            content: this.$t('curation_allow_success'),
                        })
                    }else{
                        this.errorAlert(this.$t('curation_allow_err1'))
                    }
                })
            }catch (e) {
                console.log(e)
            }
        },
        runRejectCuration(curation) {
            let params = {
                ibc_idx: curation.ibc_idx,
            }
            try {
                this.$api.$curation.rejectCuration(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.getList();
                        this.createAlert({
                            content: this.$t('curation_reject_success'),
                        })
                    }else{
                        this.errorAlert(this.$t('curation_reject_err1'))
                    }
                })
            }catch (e) {
                console.log(e)
            }
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
