import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            title: '',
            content: '',
            type: 'digital',
            category: 0,
            edition: 0,
            count: 0,
            total: 0,
            status: 9,
            originator: 1,
            im_type: '',
            i_status: 'nosale',
            enroll: 1,
            adult: 0,
            itemDate: '',
            country: '',
            si: '',
            city: '',
            size: '',
            copyright: '',
            hashtag: [],
            minimumSetting: 0,
            minimum: '',
            immediatelySetting: 0,
            immediately: '',
            startDate: '',
            endData: '',
            smallPrice: '',
            mediumPrice: '',
            largePrice: '',
            extraLargePrice: '',
            originalPrice: '',
            i_price: '',
            caution: '',
            startDateStatus: 0,
            detailThumnail: [],
            thumnail: [],
            original: [],
            guarantee: [],
            guaranteeType: 0,
            temp: 0,
            smallType: false,
            mediumType: false,
            largeType: false,
            bid_price: '',

            i_idx: '',
            collect: '',
            collectOwner: ''
        }
    },
    computed: {},
    validators: {
        title: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg1'))
        },
        content: function (value) {
            return Validator.value(value)
            // .required(this.$t('itemErrorMsg2'))
        },
        type: function (value) {
            return Validator.value(value)
        },
        category: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg3'))
        },
        edition: function (value) {
            return Validator.value(value)
        },
        count: function (value) {
            this.editionStatus = false;
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg4'))
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg4'))
                    .custom(() => {
                        if (value * 1 > this.total * 1 && this.total) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        total: function (value) {
            this.editionStatus = false;
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg5'))
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg5'))
                    .custom(() => {
                        if (value < 1) {
                            return this.$t('itemErrorMsg5')
                        }
                        if (value * 1 < this.count * 1 && this.count) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        status: function (value) {
            return Validator.value(value)
        },
        originator: function (value) {
            return Validator.value(value)
        },
        im_type: function (value) {
            return Validator.value(value)
        },
        enroll: function (value) {
            return Validator.value(value)
        },
        temp: function (value) {
            return Validator.value(value)
        },
        adult: function (value) {
            return Validator.value(value)
        },
        itemDate: function (value) {
            return Validator.value(value)
            // .custom(
            //     () => {
            //         if (value === '') {
            //             if (this.im_type === 'photo' || this.im_type === 'video') {
            //                 return this.$t('exifItem_error1')
            //             } else if (this.im_type === 'audio') {
            //                 return this.$t('exifItem_error2')
            //             } else if (this.im_type === 'etc') {
            //                 return this.$t('exifItem_error8')
            //             }
            //         }
            //     })
        },
        country: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (value === '') {
                                    if (this.im_type === 'photo' || this.im_type === 'video') {
                                        // return this.$t('exifItem_error3')
                                    } else if (this.im_type === 'audio' || this.im_type === 'etc') {
                                        // return this.$t('exifItem_error9')
                                    }
                                }
                            })
        },
        si: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (value === '') {
                                    if (this.im_type === 'photo' || this.im_type === 'video') {
                                        // return this.$t('exifItem_error4')
                                    } else if (this.im_type === 'audio' || this.im_type === 'etc') {
                                        // return this.$t('exifItem_error10')
                                    }
                                }
                            })
        },
        city: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (value === '') {
                                    if (this.im_type === 'photo' || this.im_type === 'video') {
                                        // return this.$t('exifItem_error5')
                                    } else if (this.im_type === 'audio' || this.im_type === 'etc') {
                                        // return this.$t('exifItem_error11')
                                    }
                                }
                            })
        },
        size: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (value === '') {
                                    if (this.im_type === 'photo') {
                                        return this.$t('exifItem_error6')
                                    } else if (this.im_type === 'audio' || this.im_type === 'video') {
                                        // return this.$t('exifItem_error12')
                                    }
                                }
                                // if (this.im_type === 'photo') {
                                //     let regexp = /^([1-9]\d*)\*([1-9]\d*)$/;
                                //     if (!regexp.test(value)) {
                                //         return this.$t('exifItem_error6');
                                //     }
                                // }
                            })
        },
        copyright: function (value) {
            return Validator.value(value)
            // .required(this.$t('itemErrorMsg7'))
        },
        hashtag: function (value) {
            return Validator.value(value)
            // .required(this.$t('itemErrorMsg8'))
        },
        minimumSetting: function (value) {
            return Validator.value(value)
        },
        minimum: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.minimumSetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }

                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }

                            }
                    )

        },
        immediatelySetting: function (value) {
            return Validator.value(value)
        },
        immediately: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.immediatelySetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }

                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }

                                    if (this.minimumSetting === 1 && value <= this.minimum) {
                                        return this.$t('auctionErrorMsg');
                                    }

                                }
                            }
                    )
        },
        startDate: function (value) {
            return Validator.value(value)
                    // .required(this.$t('itemErrorMsg11'))
                    .custom(
                            () => {
                                // if (this.$moment() > this.startDate) {
                                //     return this.$t('itemErrorMsg12')
                                // }

                            }
                    )
        },
        endData: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg13'))
                    .custom(
                            () => {
                                if (this.endData < this.startDate) {
                                    return this.$t('itemErrorMsg14')
                                }
                            }
                    )
        },
        smallPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.smallType && this.im_type === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        mediumPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.mediumType && this.im_type === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        largePrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.largeType && this.im_type === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        extraLargePrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {

                                if (value === '' && this.im_type === 'photo' || Number(value) === 0) {
                                    return this.$t('itemErrorMsg19')
                                }
                                let str = String(value);
                                let last = String(str.substring(str.length - 2, str.length))
                                if (last !== '00') {
                                    return this.$t('Mg_msg')
                                }
                            }
                    )
        },
        originalPrice: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg19'))
                    .custom(() => {
                        if (value === '' || Number(value) === 0) {
                            return this.$t('itemErrorMsg19')
                        }

                        let str = String(value);
                        let last = String(str.substring(str.length - 2, str.length))
                        if (last !== '00') {
                            return this.$t('Mg_msg')
                        }
                    })
        },
        caution: function (value) {
            return Validator.value(value)
        },
        detailThumnail: function (value) {
            return Validator.value(value)
        },
        thumnail: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg15'))
        },
        original: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.type === 'digital' && value.length === 0) {
                            return this.$t('itemErrorMsg16')
                        }
                    })
        },
        guarantee: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg17'))
        },
        i_price: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.i_status === 'pricetag' && value === '' || Number(value) === 0) {
                            return this.$t('itemErrorMsg18')
                        }

                        let str = String(value);
                        let last = String(str.substring(str.length - 2, str.length))
                        if (last !== '00') {
                            return this.$t('Mg_msg')
                        }
                    })
        },
        bid_price: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (value === '' || Number(value) === 0) {
                            return this.$t('buyItemErrorMsg7')
                        }
                        let str = String(value);
                        let last = String(str.substring(str.length - 2, str.length))
                        if (last !== '00') {
                            return this.$t('Mg_msg')
                        }
                        let price = util.Number.returnNumber(value)
                        if (price < Number(this.immediately)) {
                            return this.$t('buyItemErrorMsg5')
                        }
                        if (price < Number(this.i_price)) {
                            return this.$t('buyItemErrorMsg6')
                        }
                    })
        },
        collect: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
        },
        collectOwner: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
                    .custom(() => {
                        if (value === 0 && this.status !== 9) {
                            return this.$t('collect_select_error2')
                        }
                    })
        },
    },
    methods: {
        restData() {
            this.title = '';
            this.content = '';
            this.type = 'digital';
            this.category = 0;
            this.edition = 0;
            this.count = '';
            this.total = '';
            this.status = 9;
            this.originator = 1;
            this.im_type = '';
            this.i_status = 'nosale';
            this.enroll = 1;
            this.adult = 0;
            this.itemDate = '';
            this.country = '';
            this.si = '';
            this.city = '';
            this.size = '';
            this.copyright = '';
            this.hashtag = [];
            this.minimumSetting = 0;
            this.minimum = '';
            this.immediatelySetting = 0;
            this.immediately = '';
            this.startDate = '';
            this.endData = '';
            this.smallPrice = '';
            this.mediumPrice = '';
            this.largePrice = '';
            this.extraLargePrice = '';
            this.originalPrice = '';
            this.i_price = '';
            this.caution = '';
            this.detailThumnail = [];
            this.thumnail = [];
            this.original = [];
            this.guarantee = [];
            this.guaranteeType = 0;
        },
    }
}
