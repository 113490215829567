<template>
    <modal name="collection-setting-modal" class="gallery-setting-modal"
           :width="`1200px`" :height="`80%`" :pivotY="0.5" :scrollable="true"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="gs_modal_wrap">
            <div class="gs_modal_title">
                <span>{{ $t('setting_collect') }}</span>
                <button class="cancel_btn" @click="modalClose"></button>
            </div>
            <collect-write-component :collect-type="collectType" :collectKey="collectKey"></collect-write-component>
        </div>
    </modal>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";
import util from "@/mixins/util";
import CollectWriteComponent from "@/components/collect/CollectWriteComponent";

export default {
    name: "CollectionSettingModal",
    mixins: [alertMixins],
    components: {
        CollectWriteComponent,
        GalleryWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            showComponent: false,
            collectKey: 0,
            collectType: 'collectAdd',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.collectKey)) {
                this.collectType = 'collectAdd';
            } else {
                this.collectKey = event.params.collectKey;
                this.collectType = 'collectUpdate';
            }
            this.showComponent = true;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.collectKey = 0;
        },
        modalClose() {
            this.$modal.hide('collection-setting-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
