import util from "./util";

import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    created() {
    },
    mounted() {

    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            collectionList: state => state.collection.collectionList,
        })
    },
    methods: {
        updateCollectionList(data) {
            let list = JSON.parse(JSON.stringify(this.collectionList))
            let index = list.findIndex(collect => collect.c_idx === data.c_idx)
            if (index > -1) {
                list[index] = data
                this.$store.dispatch('collection/changeCollectionList', {data: list});
            }
        }
    }
}
