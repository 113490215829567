<template>
    <div class="create_item_wrap">
        <item-register-component :item-key="itemKey"></item-register-component>
    </div>
<!--    <div class="create_item_wrap">
        <item-write-component :item-type="'itemUpdate'" :item-key="itemKey"></item-write-component>
        <div>
            <div class="search_item_wrap terms_content_btn">
                <div class="search_item">
                    <el-checkbox v-model="termsCheck" @click="changeCheck">
                        <span class="item_text_span bold" @click="openModal('terms-modal', 'product')">{{$t('t_e_item')}}</span>
                        <span class="item_text_span ">{{$t('agree_terms2')}}</span>
                    </el-checkbox>
                </div>
            </div>
            <div class="submit_wrap item_create_btn">
                <div class="submit_btn">

                    <button class="enroll_submit_btn" @click="deleteItem">{{$t('confirm_del')}}</button>
                    <button class="enroll_save_btn" @click="save('writeItemTemporary',2)">{{$t('temporary_save')}}</button>
                    <button class="enroll_submit_btn" @click="save('writeItemTemporary',1)">{{$t('do_enroll')}}</button>
                </div>
                <div class="caption_check mt10" v-if="termsMsg"><p>{{$t('caption_check')}}</p></div>
            </div>
        </div>
&lt;!&ndash;        <terms-modal :termsType="'product'"></terms-modal>&ndash;&gt;
    </div>-->
</template>

<script>
import ItemWriteComponent from "@/components/item/ItemWriteComponent";
import ItemRegisterComponent from "@/components/item/ItemRegisterComponent";
import TermsModal from "@/components/modal/TermsModal";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemUpdate",
    mixins: [],
    components: {
        TermsModal,
        ItemWriteComponent,
        ItemRegisterComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            termsCheck: false,
            termsMsg: false,
            itemKey:''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey=this.$route.params.idx
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        changeCheck() {
            this.termsCheck = !this.termsCheck;
        },
        save(type,data) {
            if (!this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit(type,data);
        },
        deleteItem(){
            EventBus.$emit('deleteItem');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
