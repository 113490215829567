<template>
    <div>
        <div id="naverIdLogin" style="display: none;"></div>
<!--        <button @click="signDisconnect()">disconnect</button>-->
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import {naverService, naverDisconnect} from "@/plugins/naverService";
import alertMixins from "@/mixins/alertMixins";
import {loginTokenSet, snsRegisterSet, getTokenObj} from "@/mixins/Auth";
import AuthMixins from "@/mixins/AuthMixins";

// const NaverKey = "mtNp5mLPqG7slXUOAFnV";
// let thisUrl = "https://localhost:8080/auth";
export default {
    name: "NaverCallback",
    components: {},
    mixins: [alertMixins, AuthMixins],
    data() {
        return {
            accessToken: 'https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js',
        }
    },
    created() {
        let params = this.$route.params;
        // if(params.hasOwnProperty('type')) {
        //     thisUrl += `/${params.type}`
        // }

        // let naver_id_login = new window.LoginWithNaverId(NaverKey, thisUrl);
        // this.snsLogin(naver_id_login.oauthParams.access_token);
    },
    mounted() {
        EventBus.$emit('apiLoading', true);
        naverService().getUserInfo()
        this.accessToken = naverService().getOathParams()
        this.snsLogin(this.accessToken);
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        signDisconnect() {
            naverDisconnect(this.accessToken).then(res => {
                this.$router.replace('/')
            })
        },
        snsLogin: function (token) {
            console.log(token);
            // 로그인 성공시, API를 호출합니다.
            let params = {
                code: token
            };
            EventBus.$emit('apiLoading', true);
            this.$api.$auth.naverLogin(params).then(res => res.Data.Result).then((res) => {
                if (res.Info.type === 1) {
                    console.log(res.List);
                    this.loginSns(res.List, 'naver');
                } else {
                    this.createAlert({
                        title: "",
                        content: "로그인에 실패하였습니다.",
                        btnText: "확인",
                        confirmBtn: true,
                        closeBtn: true,
                        hide: () => {
                            window.close();
                        }
                    })
                }
            })
        },
        loginNaver: async function (result) {

            if (result.token) {
                loginTokenSet(result.token);
            }
            let params = this.$route.params;
            this.$localStorage.set('snsResult', '');
            if (result.mb_is_verify != 1) {
                let tokenObj = getTokenObj();
                this.$store.dispatch('userInfo/setSnsUserInfo', {token: result.token, mb_no: tokenObj.mb_no});

                window.close();
                window.opener.location.href = `/auth/register/sns`;
            } else {
                window.close();
                window.opener.location.href = "/";
            }

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
