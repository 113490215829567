<template>
  <component :is="component" v-if="component" :boardData="boardData"/>
</template>

<script>
export default {
  name: 'BoardWriteLink',
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    boardLayout: '',
    boardData: {},
  },
  data() {
    return {
      component: null,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/board/write/BoardWriteDefaultLayout')
        })
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
    loader() {
      if (!this.boardLayout) {
        return null
      }
      return () => import(`@/template/board/write/${this.boardLayout}`)
    },
  },
  methods: {}
  ,
  watch: {}
  ,
}
</script>

<style scoped>

</style>