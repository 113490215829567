<template>
    <div>
        <!--Hot Creator-->
        <div class="contest_wrap curator creator_top ">
            <div class="contest_title">Hot Creator</div>
            <swiper class="tc_swiper" ref="swiper" :options="swiperOption" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="tc_swiper_slide" v-for="(data,index) in creator" :key="`creator${index}`">
                    <span>
                        <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator</div>
                        <img class="tc_swiper_slide_pic" :id="`img_${data.mb_uuid}`" :src="returnBackground(data)"
                             alt="">
                        <div class="tc_profile">
                            <div class="tc_name">
                                <img class="tc_profile_pic2" :id="`profile_${data.mb_uuid}`" :src="returnProfile(data)"
                                     alt="">
                                <div class="tc_nickname" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>
                                <img class="tc_special" :id="`special_${data.mb_uuid}`" v-if="data.mb_premium===1"
                                     src="@/assets/image/main/special.png" alt="">
                            </div>
                        </div>
                        <div class="tc_curator_detail">
                            <div class="sub_wrap">
                                <div class="like_img" :class="{'active': isMyLike(data)}"
                                     :id="`like_creator_${data.mb_no}`"></div>
                                <div class="like_cnt" :id="`like_creator_${data.mb_no}`">{{ data.likeCnt }}</div>
                                <span class="sub_point" :id="`point_${data.mb_uuid}`">·</span>
                                <div class="item_subject" :id="`item_${data.mb_uuid}`">item <span
                                        :id="`itemCnt_${data.mb_uuid}`">
                                    {{ data.itemCnt }}</span></div>
                            </div>
                        </div>
                    </span>
                </swiper-slide>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_creator_prev" slot="button-prev"
                 v-show="creator.length > 5"></div>
            <div class="swiper_btn swiper-button-next hot_creator_next" slot="button-next"
                 v-show="creator.length > 5"></div>
        </div>
        <!--Top Curator-->
        <div class="contest_wrap curator">
            <div class="contest_title">Top Collector</div>
            <swiper class="tc_swiper" ref="swiper" :options="swiperOption2" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="tc_swiper_slide" v-for="(data,index) in collector" :key="`collector${index}`">
                    <span>
                        <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator</div>
                        <img class="tc_swiper_slide_pic" :id="`img_${data.mb_uuid}`" :src="returnBackground(data)"
                             alt="">
                        <div class="tc_profile">
                            <div class="tc_name">
                                <img class="tc_profile_pic2" :id="`profile_${data.mb_uuid}`" :src="returnProfile(data)"
                                     alt="">
                                <div class="tc_nickname" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>
                                <img class="tc_special" :id="`special_${data.mb_uuid}`" v-if="data.mb_premium===1"
                                     src="@/assets/image/main/special.png"
                                     alt="">
                            </div>
                        </div>
                        <div class="tc_curator_detail">
                            <div class="sub_wrap">
                               <div class="like_img" :class="{'active': isMyLike(data)}"
                                    :id="`like_collector_${data.mb_no}`"></div>
                                <div class="like_cnt" :id="`like_collector_${data.mb_no}`">{{ data.likeCnt }}</div>
                                <span class="sub_point" :id="`point_${data.mb_uuid}`">·</span>
                                <div class="item_subject" :id="`item_${data.mb_uuid}`">item <span
                                        :id="`itemCnt_${data.mb_uuid}`">{{ data.itemCnt }}</span></div>
                            </div>
                        </div>
                    </span>
                </swiper-slide>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_collector_prev" slot="button-prev"
                 v-show="collector.length > 5"></div>
            <div class="swiper_btn swiper-button-next hot_collector_next" slot="button-next"
                 v-show="collector.length > 5"></div>
        </div>
        <!--        Top Curator-->
        <div class="contest_wrap curator">
            <div class="contest_title">Top Curator</div>
            <swiper class="tc_swiper" ref="swiper" :options="swiperOption3" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="tc_swiper_slide" v-for="(data,index) in curator" :key="`curator${index}`">
                    <span>
                        <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator</div>
                        <img class="tc_swiper_slide_pic" :id="`img_${data.mb_uuid}`" :src="returnBackground(data)"
                             alt="">
                        <div class="tc_profile">
                            <div class="tc_name">
                                <img class="tc_profile_pic2" :id="`profile_${data.mb_uuid}`" :src="returnProfile(data)"
                                     alt="">
                                <div class="tc_nickname" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>
                                <img class="tc_special" :id="`special_${data.mb_uuid}`" v-if="data.mb_premium===1"
                                     src="@/assets/image/main/special.png"
                                     alt="">
                            </div>
                        </div>
                        <div class="tc_curator_detail">
                            <div class="sub_wrap">
                             <div class="like_img" :class="{'active': isMyLike(data)}"
                                  :id="`like_curator_${data.mb_no}`"></div>
                                <div class="like_cnt" :id="`like_curator_${data.mb_no}`">{{ data.likeCnt }}</div>
                                <span class="sub_point" :id="`point_${data.mb_uuid}`">·</span>
                                <div class="item_subject" :id="`item_${data.mb_uuid}`">item <span
                                        :id="`itemCnt_${data.mb_uuid}`">{{ data.itemCnt }}</span></div>
                            </div>
                        </div>
                    </span>
                </swiper-slide>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_curator_prev" slot="button-prev"
                 v-show="curator.length > 5"></div>
            <div class="swiper_btn swiper-button-next hot_curator_next" slot="button-next"
                 v-show="curator.length > 5"></div>
        </div>


    </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {mapState} from "vuex";

export default {
    name: "MemberHomeListComponent",
    mixins: [memberUtil, imageOption, imageResizeMixins],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberType: '',
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.hot_creator_next',
                    prevEl: '.hot_creator_prev',
                },
            },
            swiperOption2: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.hot_collector_next',
                    prevEl: '.hot_collector_prev',
                },
            },
            swiperOption3: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.hot_curator_next',
                    prevEl: '.hot_curator_prev',
                },
            },
            collector: [],
            creator: [],
            curator: []
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getMember();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {

        getMember() {
            this.$api.$member.getMainList().then(res => res.Data.Result).then(res => {
                if (res.List.length !== 0) {
                    this.creator = res.List.Creator
                    if (this.creator.length < 5) {
                        this.swiperOption.loop = false;
                    }
                    this.collector = res.List.Collector
                    if (this.collector.length < 5) {
                        this.swiperOption2.loop = false;
                    }
                    this.curator = res.List.Curator
                    if (this.curator.length < 5) {
                        this.swiperOption3.loop = false;
                    }
                }
            })
        },
        movePage(url) {
            this.$router.push(`/creator/${url}`);
        },
        returnBackground(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.ProfileBack)) {
                return "";
            }
            return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, this.returnExtension(data.Files.ProfileBack[0].org_url))}`;
        },
        returnProfile(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.Profile)) {
                return "";
            }
            return `${data.Files.Profile[0].org_url}?${this.setImageOptions(68, 68, this.returnExtension(data.Files.Profile[0].org_url))}`;
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId)) {
                return false;
            }

            let elementIdx = elementId.split('_');
            if (elementIdx[0] === 'like') {
                this.likeMember(elementIdx[1], elementIdx[2])
            } else {
                this.movePage(elementIdx[1]);
            }

        },
        isMyLike(data) {
            if (util.isEmpty(data)) {
                return
            }
            return data.likeStatus
        },
        likeMember(type, key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.follow_id = key
                this.$api.$member.toggleLike(params).then(res => res.Data.Result).then(res => {
                    if (res.List.type === 'create') {
                        this.likeAfterFunc('add', key, type);
                    } else if (res.List.type === 'delete') {
                        this.likeAfterFunc('sub', key, type);
                    }
                })
            }
        },
        likeAfterFunc(type, key, member) {
            let likeIndex = this[member].findIndex(data => data.mb_no === Number(key));
            if (likeIndex > -1) {
                if (type === 'add') {
                    this[member][likeIndex].likeCnt = this[member][likeIndex].likeCnt + 1;
                } else if (type === 'sub') {
                    this[member][likeIndex].likeCnt = this[member][likeIndex].likeCnt - 1;
                }
                this[member][likeIndex].likeStatus = !this[member][likeIndex].likeStatus;
                this.$forceUpdate();
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
