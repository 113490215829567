<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_tab" :class="{ active : tab === 'buy'}"
                     @click="changeTab('buy')">
                    {{ $t('real') }} {{ $t('buy') }}
                </div>
                <div class="mypage_item_tab" :class="{ active : tab === 'sale'}"
                     @click="changeTab('sale')">
                    {{ $t('real') }} {{ $t('sell') }}
                </div>
                <item-list-component :item-type="'realBuy'" v-if="tab === 'buy'"></item-list-component>
                <item-list-component :item-type="'realSale'" v-else-if="tab === 'sale'"></item-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "MyPageReal",
    mixins: [],
    components: {
        ItemListComponent,
        MyPageHeaderComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'buy',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>