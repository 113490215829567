<template>
    <div>
        <competition-detail-component :competition-type="'competitionDetail'"
                                      :competition-key="competitionKey"></competition-detail-component>
        <share-modal></share-modal>
    </div>
</template>

<script>
import CompetitionDetailComponent from "@/components/competition/CompetitionDetailComponent";
import ShareModal from "@/components/modal/ShareModal";

export default {
    name: "CompetitionDetail",
    mixins: [],
    components: {
        ShareModal,
        CompetitionDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.idx
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
