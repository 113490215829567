<template>
    <div>
        <award-write-link :writeLayout="writeLayout" :competitionKey="competitionKey"
                          :competitionPrize="competitionPrize"></award-write-link>
    </div>
</template>

<script>
import AwardWriteLink from "@/components/award/AwardWriteLink";
import awardUtil from "@/components/award/awardUtil";
import awardValidator from "@/mixins/validators/awardValidator";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
export default {
    name: "AwardWriteComponent",
    mixins: [awardUtil, awardValidator,alertMixins],
    components: {
        AwardWriteLink,
    },
    inject: [],
    provide() {
        return {
            awardWriteSetData: this.setData,
        }
    },
    props: {
        competitionKey: '',
        awardType: '',
    },
    data() {
        return {
            writeLayout: '',
            getFunc: '',
            detailApiParamsSet: {},
            keyName: '',
            competitionPrize: 0,

            getKeyName: 'id',
            competitionReturnKey:'',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig()
    },
    mounted() {
        this.getCompetition()
        EventBus.$on('writeAward', this.checkValidator)
    },
    beforeDestroy() {
        EventBus.$off('writeAward')
    },
    methods: {
        initConfig() {
            let awardConfig = this.returnAwardConfig(this.awardType);
            this.writeLayout = awardConfig.writeLayout;
            this.getFunc = awardConfig.getFunc
            this.detailApiParamsSet = awardConfig.detailApiParamsSet
            this.keyName = awardConfig.keyName;
            this.getKeyName = awardConfig.getKeyName;

        },
        getCompetition() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet))
            if(!util.isEmpty(this.getKeyName)) {
                params[this.getKeyName] = this.competitionKey;
            }else{
                params.id = this.competitionKey;
            }

            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.createAlert({
                            title: '',
                            content: this.$t('no_data_loading'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                this.$router.back();
                            }
                        });
                        return;
                    }
                    this.competitionReturnKey = res.List[0].c_idx
                    this.competitionPrize = res.List[0].c_prize
                })
            } catch (e) {
                console.log(e)
            }
        },
        setData(key, value) {

            this[key] = value
        },

        checkValidator(){
            let prizeArray = this.prizeArray
            prizeArray.forEach(v => {
                v.prize = util.Number.returnNumber(v.prize)
            })
            let validatorArr = ['prizeArray'];
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeAward();
                } else {
                    EventBus.$emit('AwardWriteDefaultLayoutValidator',validatorArr);
                }
            })
        },
        writeAward() {
            let prizeList = []
            util.Array.each(this.prizeArray, (prize, index, arr) => {
                prizeList.push({
                    ci_idx: prize.idx,
                    ci_prize: prize.prize,
                    ci_result: prize.result,
                    ci_rank: prize.rank,
                })
            })
            let params = {
                prize: prizeList,
                c_idx: this.competitionReturnKey,
                c_winner_cnt: this.sharePrize
            }

            try {
                this.$api.$competition.writeAward(params).then(res => res.Data.Result).then(res => {
                    let result = this.returnAward(res.Info.type)
                    if(result !== true){
                        this.errorAlert(result)
                    }else {
                        this.createAlert({
                            content : '수상작이 등록되었습니다.',
                            hide:()=>{
                                this.$router.back()
                            }
                        })
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        returnAward(type){
            switch (type) {
                case 1 :{
                    return true
                }
                case -202 :{
                    return this.$t('curator_cancel_err2')
                }
                case -861 :{
                    return "올바른 수상범위가 아닙니다."
                }
                case -862 :{
                    return "등록할 수 있는 공모전 상태가 아닙니다."
                }
                case -863 :{
                    return "중복 수상작이 존재합니다."
                }
                case -864 :{
                    return "상금 분배에 실패했습니다."
                }
                case -865 :{
                    return "수상불가한 응모작이 존재합니다."
                }
                case -866 :{
                    return "중복 순위가 존재합니다."
                }
                default :{
                    return "수상작 등록에 실패했습니다."
                }
            }
        },
    },
    destroyed() {
    },
    computed: {},
    watch: {},
}
</script>

<style scoped>

</style>
