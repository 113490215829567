import {request} from "@/api/request";
import {getTokenObj} from "@/mixins/Auth";

import Vue from "vue"

const state = {
    collectionList: [],
}

const getters = {
    getCollectionList: state => state.collectionList,
}

const mutations = {
    updateCollectionList(state, obj) {
        state.collectionList = obj.data;
    },
}
const actions = {
    setTokenCollectionList({commit, rootState}, {data}) {
        let info = getTokenObj(data);
        try {
            request.get('collect', {ip: 0, no: info.mb_no,opf:1}).then(res => {
                if (res.data.Data.Result.Info.type != 1) {
                    return false;
                }
                let categoryList = res.data.Data.Result.List
                commit('updateCollectionList', {data: categoryList})
            })
        } catch (e) {
            console.log(e);
        }
    },
    setCollectionList({commit, rootState}, {data}) {
        let info = getTokenObj(data);
        try {
            request.get('collect', {ip: 0, no: info.mb_no,opf:1}).then(res => {
                if (res.data.Data.Result.Info.type != 1) {
                    return false;
                }
                let categoryList = res.data.Data.Result.List
                commit('updateCollectionList', {data: categoryList})
            })
        } catch (e) {
            console.log(e);
        }
    },
    changeCollectionList({commit, rootState}, {data}) {
        commit('updateCollectionList', {'data': data});
    },
}
export default {namespaced: true, state, getters, mutations, actions}
