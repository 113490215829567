<template>
    <div>
        <div class="mypage_main unsubscribe_main_wrap">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="terms_content hAuto">
                    <div class="terms_msg">
                        <div class="fw700 mb80 pb1">
                            {{ $t('account_number') }} : 기업은행 586 039914 04 015 <br>
                            {{ $t('amount_owner') }} : 주식회사 빅스터 <br>
                        </div>

                        <div class="mb50 pb1">
                            ※ {{ $t('deposit_info1') }} <br>
                            ※ {{ $t('deposit_info2') }} <br>
                            ※ {{ $t('deposit_info3') }}
                        </div>
                        <div class="fw700 pb1">
                            {{$t('staff_email')}} : chung.sy@bigster.co.kr <br>
                            {{$t('admin_email')}} : lim.jw@bigster.co.kr <br>
                        </div>
                    </div>
                </div>

                <div class="unsubscribe_btn" @click="openModal()">
                    <button>{{ $t('deposit_btn') }}</button>
                </div>
            </div>
        </div>
        <mypage-deposit-modal></mypage-deposit-modal>
    </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import MypageDepositModal from "@/components/modal/MypageDepositModal";


export default {
    name: "MyPageDeposit",
    mixins: [],
    components: {MyPageHeaderComponent, MypageDepositModal},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal() {
            this.$modal.show('mypage-deposit-modal')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
