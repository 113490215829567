<template>
  <div>
    <div class="mypage_main">
      <my-page-header-component></my-page-header-component>
      <div class="mypage_list">
        <div class="mypage_item_4_tab" :class="{ active : tab === 'myCompetition'}"
             @click="changeTab('myCompetition')" v-if="getMemberType() === 'normal'">
          {{$t('my_competition')}}
        </div>
        <div  class="mypage_item_4_tab" :class="{ active : tab  === 'competitionMy'}"
              @click="changeTab('competitionMy')" v-if="getMemberType() === 'normal'">
          {{$t('my_competition')}} {{ $t('award') }}
        </div>
        <div  class="mypage_item_4_tab" :class="{ active : tab  === 'entryItem'}"
              @click="changeTab('entryItem')" v-if="getMemberType() === 'normal'">
          {{ $t('my_entry_item') }}
        </div>
        <div  class="mypage_item_4_tab" :class="{ active : tab  === 'judge' || getMemberType() !== 'normal'}"
              @click="changeTab('judge')">
          {{ $t('judges') }}
        </div>
        <competition-list-component v-if="tab" :competition-type="tab"
                                    :memberKey="returnMemberKey()"></competition-list-component>
<!--        <competition-list-component v-else-if="tab" :competition-type="tab"></competition-list-component>-->
<!--        <item-list-component v-if="tab  === 'entryItem'" :item-type="tab"></item-list-component>-->
<!--        <competition-list-component v-else-if="tab" :competition-type="tab"></competition-list-component>-->
      </div>
    </div>
  </div>
</template>

<script>
  import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
  import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
  import ItemListComponent from "@/components/item/ItemListComponent";
  import {mapState} from "vuex";
  import util from "@/mixins/util";
  import AuthMixins from "@/mixins/AuthMixins";
  export default {
    name: "MyPageCompetition",
    mixins: [AuthMixins],
    components: {
      ItemListComponent,
      CompetitionListComponent,
      MyPageHeaderComponent,
    },
    inject: [],
    provide() {
      return{

      }
    },
    props: {
    },
    data() {
      return{
        tab: '',
      }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
      if(!util.isEmpty(this.$route.query.tab)) {
        this.tab = this.$route.query.tab;
      }else{
        this.tab = this.getMemberType() !== 'normal' ? 'judge' : 'myCompetition';
      }
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
      ...mapState({
        UserInfo: state => state.userInfo.UserInfo,
      })
    },
    methods:{
      movePage(url){
        this.$router.push(url);
      },
      changeTab(tab) {
        this.tab = '';
        this.$nextTick(() => {
          this.tab = tab
        })
            this.$forceUpdate()
        this.$router.replace(`${this.$route.path}?tab=${tab}`);
        // let url = `${this.$route.path}?tab=${tab}`
        // if(this.$route.fullPath != url) {
        //   this.$router.replace(url);
        // }
      },
        returnMemberKey(){
          return this.tab === "myCompetition" ? this.UserInfo.mb_no : ''
        }
    },
    watch: {

    },
  }
</script>

<style scoped>

</style>
