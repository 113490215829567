import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementKoLocale from 'element-ui/lib/locale/lang/ko'// element-ui lang

Vue.use(VueI18n)
const elemMessages = {
    en: elementEnLocale,
    ko: elementKoLocale
}
function loadLocaleMessages () {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    let messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
            messages[locale].el = elemMessages[locale].el
        }
    })
    return messages
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    //fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
})
