const detectionConfig = {
    detectionWrite: {
        writeLayouts: ['DetectionWriteDefaultLayout'],
        writeFunc: 'itemDetect',
        writeApiParamSet: {},
        parsingData: {
            'selectedItems': 'i_idx'
        },
        directUrl: '',
        directType: 'getItemList',
        keyName: 'i_idx',
        writeSuccessType: 99,
        writeFailTypes: {
            '-101': {openAlert: true, text: 'detect_err2'},
            '-601': {openAlert: true, text: 'detect_err3'},
        },
        writeFailDefault: 'detect_err4',
        writeSuccessAlert: 'detect_success1',
        updatePoint: true,
    },
    detectionDetail: {
        detailLayout: 'DetectionDetailDefaultLayout',
        getFunc: 'getItemDetectDetail',
        detailApiParamSet: {},
        getKeyName: 'i_idx',
    },
    detectionHistory: {
        listLayout: 'DetectionListDefaultLayout',
        filterLayout: 'DetectionFilterHistoryLayout',
        emptyLayout: 'DetectionEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getDetectResult',
        listApiParamSet: {
            ip: 1,
            sp: 10,
            d_idx: 0
        },
        pageRow: 10,
        sortOptions: {},
        initListType: 'detect',


        deleteFunc: 'deleteIgnoreUrl',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-103': "d_ignore_url_delete_err1"
        },
        keyName: 'di_idx',
    },
    request: {
        listLayout: 'DetectionListRequestHistoryLayout',
        filterLayout: 'DetectionFilterRequestLayout',
        emptyLayout: 'DetectionEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc: 'getDetection',
        listApiParamSet: {
            ip: 1,
            sp: 10,
        },
        pageRow: 10,
        sortOptions: {},
    },
    requestWrite: {
        writeLayouts: ['DetectionWriteRequestLayout'],

        writeFunc: 'createDetectLaw',
        writeApiParamSet: {},
        parsingData: {
            detectionImageKey: 'dr_idx',
            createDate: 'l_date',
            place: 'l_place',
            email: 'l_email',
            phone: 'l_hp',
            proofUrl1: 'l_url_1',
            proofUrl2: 'l_url_2',
            proofUrl3: 'l_url_3',
            requestForm: '',
        },
        fileInfo: [
            {
                fileName: 'requestForm',
                fileType: 'lawsuit',
            },
        ],
        directUrl: '/mypage/detection?tab=request',
        directType: 'list',
        keyName: 'l_idx',
        writeSuccessType: 1,
        writeFailTypes: {
            '-103': {openAlert: true, text: 'd_change_status_err2'},
            '-601': {openAlert: true, text: 'd_change_status_err3'},
            '-104': {openAlert: true, text: 'd_request_err1'},
        },
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        updatePoint: false,
    },
    requestDetail: {
        detailLayout: 'DetectionDetailRequestLayout',
        getFunc: 'getRequestDetail',
        detailApiParamSet: {},
        getKeyName: 'l_idx',
    },
    myRequest: {
        listLayout: 'DetectionListMyRequestLayout',
        filterLayout: 'DetectionFilterNoneLayout',
        emptyLayout: 'DetectionEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    urlList: {
        listLayout: 'DetectionListUrlLayout',
        filterLayout: 'DetectionFilterUrlLayout',
        emptyLayout: 'DetectionEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getIgnoreUrl',
        listApiParamSet: {
            ip: 1,
            sp: 10,
            d_idx: 0
        },
        pageRow: 10,
        sortOptions: {},
        initListType: 'detect',

        deleteFunc: 'deleteIgnoreUrl',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-103': "d_ignore_url_delete_err1"
        },
        keyName: 'di_idx',
        updatePoint: false,
    },
    urlWrite: {
        writeLayouts: ['DetectionWriteUrlLayout'],
        writeFunc: 'createIgnoreUrl',
        writeApiParamSet: {},
        parsingData: {
            'detectionImageKey': 'd_idx',
            "url": 'di_text'
        },
        directUrl: 'detection-url-modal',
        directType: 'modal',
        keyName: 'di_idx',
        writeSuccessType: 1,
        writeFailTypes: {
            '-103': {openAlert: false, text: 'd_change_status_err2'},
            '-601': {openAlert: false, text: 'd_change_status_err3'},
            '-104': {openAlert: false, text: 'd_ignore_domain_err2'},
        },
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        updatePoint: false,
    }
}
export default {
    filterConfig: {
        tab: {
            type: 'match',
            classname: 'tab',
        },
        na: {
            type: 'query',
            classname: 'filterInput',
        },
        do: {
            type: 'query',
            classname: 'filterInput',
        },
        uid: {
            type: 'query',
            classname: 'filterInput',
        },
        st: {
            type: 'terms',
            classname: 'filterStatus'
        },
        cr: {
            type: 'between',
            classname: 'filterDate',
        },
        pa: { //url 검색
            type: 'query',
            classname: 'filterInput',
        },
        te: { //di_text : 무시 URL
            type: 'query',
            classname: 'filterInput'
        }
    },
    methods: {
        returnDetectionConfig(type) {
            return detectionConfig[type];
        }
    },
}
