import EventBus from "@/utils/event-bus";

const entryConfig = {
    entryList: {
        filterLayout: 'EntryFilterDefaultLayout',
        listLayout: 'EntryListDefaultLayout',
        emptyLayout: 'EntryEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    entrySearch: {
        filterLayout: 'EntryFilterDefaultLayout',
        listLayout: 'EntryListSearchLayout',
        emptyLayout: 'EntryEmptyDefaultLayout',
        paginationLayout: 'PaginationModalLayout',
    },
    entry: {
        writeLayout: 'EntryWriteDefaultLayout',
        writeFunc:'writeEntry',
        writeSuccessType : 1,
        writeFailTypes:{
            '-821' : 'entry_err_over',
            '-820' : 'entry_err_over2',
            '-823' : 'entry_err_date',
            '-1' : 'entry_err_date3',
        }
    },
}
export default {
    methods: {
        returnEntryConfig(type) {
            return entryConfig[type];
        },
    }
}
