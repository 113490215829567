<template>
    <div class="creator_main">
      <div class="creator_title">{{$t('creator')}}</div>
        <member-list-component :member-type="'creator'"></member-list-component>
        <br>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import MemberListComponent from "@/components/member/MemberListComponent";

export default {
    name: "Creator",
    metaInfo: {
        title: `크리에이터(Creater)`,
        titleTemplate: '%s | 밍글민트(Minglemint NFT Marketplace)',
    },
    mixins: [],
    components: {
        MemberListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>