<template>
    <div>
      <board-detail-link :detailLayout="detailLayout" :boardData="boardData"  v-if="boardData.length !== 0"></board-detail-link>
    </div>
</template>

<script>
import BoardDetailLink from "@/components/board/BoardDetailLink";
import boardUtil from "@/components/board/boardUtil";
import alertMixins from "@/mixins/alertMixins";
import DeleteClass from "@/mixins/deleteClass";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "BoardDetailComponent",
    mixins: [boardUtil, alertMixins],
    components: {
        BoardDetailLink,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        boardType: '',
        boardKey: '',
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamsSet: {},
            getKeyName: '',
            boardData: {},
            DeleteClass: null,

            getCategory: '',
            categoryApiParamsSet: {},
            deleteFunc: '',
            deleteApiParamsSet: {},
            deleteSuccessType: 1,
            deleteFailTypes: {},
            keyName: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
      this.initConfig();
      this.getBoard();
      this.newDeleteClass();
    },
    mounted() {
      EventBus.$on('openDeleteConfirm', this.openDeleteConfirm);
    },
    beforeDestroy() {
      EventBus.$off('openDeleteConfirm');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let boardConfig = this.returnBoardConfig(this.boardType);
            this.detailLayout = boardConfig.detailLayout;
            this.getFunc = boardConfig.getFunc;
            this.detailApiParamsSet = boardConfig.detailApiParamsSet;
            this.getKeyName = boardConfig.getKeyName;
            this.getCategoryFunc = boardConfig.getCategoryFunc;
            this.categoryApiParamsSet = boardConfig.categoryApiParamsSet;

            this.deleteFunc = boardConfig.deleteFunc;
            this.deleteApiParamsSet = boardConfig.deleteApiParamsSet;
            this.deleteFailTypes = boardConfig.deleteFailTypes;
            this.deleteSuccessType = boardConfig.deleteSuccessType;
            this.keyName = boardConfig.keyName;
        },
    /*  getCategory() {
        EventBus.$emit('apiLoading', true);
      },*/
      getBoard() {
        EventBus.$emit('apiLoading', true);
        let params = JSON.parse(JSON.stringify(this.detailApiParamsSet));

        params[this.getKeyName] = this.boardKey;

        this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
          if (res.Info.type != 1 || res.List.length === 0) {
            EventBus.$emit('apiLoading', false);
            this.createAlert({
              title: '',
              content: this.$t('no_data_loading'),
              btnText: this.$t('yes'),
              hide: () => {
                this.$router.back();
              }
            });
          }
          EventBus.$emit('apiLoading', false);
          this.boardData = res.List[0];
        })
      },
      openDeleteConfirm() {
        this.createConfirm({
          content: this.$t('caution_del'),
          confirm: () => {
            this.runDelete();
          }
        })
      },
      runDelete() {
        EventBus.$emit('apiLoading', true);
        this.DeleteClass.runDelete(this.boardKey);
      },
      deleteAfterFunc() {
        EventBus.$emit('apiLoading', false);
        EventBus.$emit('boardListDeleteAfterFunc', this.keyName, Number(this.boardKey));
        this.createAlert({
          content: this.$t('deleteSuccessMsg'),
          hide: () => {
            this.$router.back();
          }
        })
      },
      newDeleteClass() {
        if (!util.isEmpty(this.deleteFunc)) {
          let deleteOptions = {
            func: this.$api.$board[this.deleteFunc],
            apiParamSet: this.deleteApiParamsSet,
            keyName: this.keyName,
            successType: this.deleteSuccessType,
            errorTypes: this.deleteFailTypes,
            afterFunc: this.deleteAfterFunc,
          }
          this.DeleteClass = new DeleteClass(deleteOptions);
        }
      },
    },
    watch: {},
}
</script>

<style scoped>

</style>