import util from "@/mixins/util";
import getBlobDuration from "get-blob-duration";

export default {
    mixins: [],
    methods: {
        getExifDataCopyright(exifData) {
            let resultCopyright = '';
            return new Promise(resolve => {
                if (exifData.hasOwnProperty('Copyright')) {
                    resultCopyright = exifData.Copyright;
                }
                resolve(resultCopyright);
            })
        },

        getImageExifDataSize(exifData, url) {
            let resultSize = '';
            return new Promise(resolve => {
                if (exifData.hasOwnProperty('ExifImageWidth') && exifData.hasOwnProperty('ExifImageHeight')) {
                    resultSize = `${exifData.ExifImageWidth}*${exifData.ExifImageHeight}`
                    resolve(resultSize);
                } else if (exifData.hasOwnProperty('PixelXDimension') && exifData.hasOwnProperty('PixelYDimension')) {
                    resultSize = `${exifData.PixelXDimension}*${exifData.PixelYDimension}`
                    resolve(resultSize);
                } else if (exifData.hasOwnProperty('ImageWidth') && exifData.hasOwnProperty('ImageHeight')) {
                    resultSize = `${exifData.ImageWidth}*${exifData.ImageHeight}`
                    resolve(resultSize);
                } else {
                    let img = new Image()
                    img.src = url;
                    img.onload = function () {
                        resultSize = `${this.width}*${this.height}`
                        document.body.removeChild(img);
                        resolve(resultSize)
                    }
                    document.body.appendChild(img);
                }
            })
        },
        getExifDataCreate(exifData) {
            let result = '';
            let dateFormat = 'MM/DD/YYYY HH:mm:ss'
            return new Promise(resolve => {
                // if (exifData.hasOwnProperty('DateTime')) {
                //     result = this.$moment(exifData.DateTime, 'YYYY:MM:DD HH:mm:ss').format(dateFormat)
                // }
                if (exifData.hasOwnProperty('DateTimeOriginal')) {
                    result = this.$moment(exifData.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss').format(dateFormat)
                }
                resolve(result)
            })
        },

        _setGps(degrees, minutes, seconds, direction) {
            let gps = degrees + (minutes / 60) + (seconds / 360000);
            if (direction == "S" || direction == "W") {
                gps = gps * -1;
            }
            return gps;
        },

        _getImageExifLatLng(data) {
            // let latDegree = data.GPSLatitude[0].numerator / data.GPSLatitude[0].denominator;
            // let latMinute = data.GPSLatitude[1].numerator / data.GPSLatitude[1].denominator;
            // let latSecond = data.GPSLatitude[2].numerator / data.GPSLatitude[2].denominator;
            let latDegree = data.GPSLatitude[0];
            let latMinute = data.GPSLatitude[1];
            let latSecond = data.GPSLatitude[2];
            let latDirection = data.GPSLatitudeRef;
            let lat = this._setGps(latDegree, latMinute, latSecond, latDirection)
            let lonDegree = data.GPSLongitude[0];
            let lonMinute = data.GPSLongitude[1];
            let lonSecond = data.GPSLongitude[2];
            // let lonDegree = data.GPSLongitude[0].numerator / data.GPSLongitude[0].denominator;
            // let lonMinute = data.GPSLongitude[1].numerator / data.GPSLongitude[1].denominator;
            // let lonSecond = data.GPSLongitude[2].numerator / data.GPSLongitude[2].denominator;
            let lonDirection = data.GPSLongitudeRef;
            let long = this._setGps(lonDegree, lonMinute, lonSecond, lonDirection);
            return {lat: lat, long: long};
        },

        _getGeocode({lat, long}) {
            let resultCounty = {}
            return new Promise(resolve => {

                if (util.isEmpty(lat) || util.isEmpty(long) || util.isEmpty(google)) {
                    resolve(resultCounty)
                    return resultCounty;
                }

                const geocoder = new google.maps.Geocoder();
                let latlng = {
                    lat: parseFloat(lat),
                    lng: parseFloat(long),
                }

                geocoder.geocode({'location': latlng}, function (results, status) {
                    let address = results[0].address_components;
                    let countryIndex = address.findIndex(add => add.types[0] === 'country');
                    if (countryIndex > -1) {
                        resultCounty.country = address[countryIndex].long_name
                    }
                    let doIndex = address.findIndex(add => add.types[0] === 'administrative_area_level_1');
                    if (doIndex > -1) {
                        resultCounty.si = address[doIndex].long_name
                    }
                    let siIndex = address.findIndex(add => add.types[0] === 'locality');
                    if (siIndex > -1) {
                        resultCounty.city = address[siIndex].long_name
                    }
                    console.log(resultCounty);
                    resolve(resultCounty)
                })
            })
        },
        _naverGeocode({lat, long}) {
            let resultCounty = {}
            return new Promise(resolve => {

                if (util.isEmpty(lat) || util.isEmpty(long) || util.isEmpty(naver)) {
                    resolve(resultCounty)
                    return resultCounty;
                }
                let latlng = {
                    lat: parseFloat(lat),
                    lng: parseFloat(long),
                }
                naver.maps.Service.reverseGeocode({
                    coords: new naver.maps.LatLng(lat, long),
                }, function (status, response) {
                    if (status !== naver.maps.Service.Status.OK) {
                        console.log('네이버 지도 API 한도에 초과하였습니다.')
                        resolve(true);
                        return
                    }

                    if (response.v2.status.code === 0) {
                        let address = response.v2.address.jibunAddress.split(" ");
                        resultCounty.country = '대한민국'
                        resultCounty.si = address[0];
                        resultCounty.city = address[1]
                        resolve(resultCounty)
                    } else {
                        resolve(false);
                    }


                });
            });
        },
        getExifDataImageLocation(exifData) {
            let geo = null;
            let result = {
                country: '',
                city: '',
                si: '',
            }
            return new Promise(async resolve => {
                if (exifData.hasOwnProperty('GPSLatitude') && exifData.hasOwnProperty('GPSLongitude') &&
                    exifData.hasOwnProperty('GPSLatitudeRef') && exifData.hasOwnProperty('GPSLongitudeRef')) {
                    geo = this._getImageExifLatLng(exifData);
                    if (geo === null) {
                        resolve(result);
                        return
                    }
                    let location = await this._naverGeocode(geo);
                    if (location === false) {
                        location = await this._getGeocode(geo);
                    }

                    if (location)
                        if (location.hasOwnProperty('country')) {
                            result.country = location.country;
                        }
                    if (location.hasOwnProperty('si')) {
                        result.si = location.si;
                    }
                    if (location.hasOwnProperty('city')) {
                        result.city = location.city;
                    }
                    resolve(result);
                } else {
                    resolve(result);
                }

            })
        },
        getExifDataTitle(exifData) {
            let resultTitle = '';
            return new Promise(resolve => {
                if (exifData.hasOwnProperty('title')) {
                    resultTitle = exifData.title.value;
                }
                resolve(resultTitle);
            })
        },
        getExifDataTag(exifData) {
            let resultTag = '';
            return new Promise(resolve => {
                if (exifData.hasOwnProperty('subject')) {
                    resultTag = exifData.subject;
                }
                resolve(resultTag);
            })
        },
        getBlobVideoFileSize(blobUrl) {
            return getBlobDuration(blobUrl).then(function(duration) {
                let playtime = duration;
                let minutes = parseInt(playtime / 60, 10);
                let seconds = Math.round(playtime % 60);
                let hour = 0;
                if(minutes >= 60) {
                    hour = parseInt(minutes / 60);
                    minutes = Math.round(minutes % 60);
                }
                let size = `${minutes}:${seconds}`;
                if(hour > 0) {
                    size = `${hour}:${minutes}:${seconds}`;
                }
                return size;
            }).catch(e => {
            });
        },
    },
}
