<template>
    <div>
        <collect-write-link v-for="writeLayout in writeLayouts" :key="`collect${writeLayout}`"
                            :collectData="collectData"
                            :write-layout="writeLayout"></collect-write-link>
    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import CollectWriteLink from "@/components/collect/CollectWriteLink";
import collectUtil from "@/components/collect/collectUtil";
import collectValidator from "@/mixins/validators/collectValidator";
import {mapState} from "vuex";


export default {
    name: "CollectWriteComponent",
    mixins: [elFileMixins, awsS3FileUpload, collectUtil, collectValidator],
    components: {
        CollectWriteLink
    },
    inject: [],
    provide() {
        return {
            collectWriteSetData: this.setData,
        }
    },
    props: {
        collectType: '',
        collectKey: {default: 0},
    },
    data() {
        return {
            writeLayouts: '',
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            writeSuccessType: 1,
            writeFailTypes: {},
            writeFailDefault: '',
            writeSuccessAlert: '',
            getFunc: '',
            detailApiParamsSet: {},
            keyName: '',

            collectData: {},
            isRunning: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        if (this.collectKey === 0) {
            this.initTemplate();
        } else {
            this.getCollect();
        }
    },
    mounted() {
        EventBus.$on('writeCollect', this.checkValidator);
    },
    beforeDestroy() {
        EventBus.$off('writeCollect')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        setData(type, value) {
            this[type] = value;
        },
        initTemplate() {
            let config = this.returnCollectConfig(this.collectType);
            this.writeLayouts = config.writeLayouts;

        },
        initConfig() {
            let config = this.returnCollectConfig(this.collectType);
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.writeSuccessType = config.writeSuccessType;
            this.writeFailTypes = config.writeFailTypes;
            this.writeFailDefault = config.writeFailDefault;
            this.writeSuccessAlert = config.writeSuccessAlert;
            this.getFunc = config.getFunc;
            this.detailApiParamsSet = config.detailApiParamsSet;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.keyName = config.keyName;
        },
        getCollect() {
            let params = Object.assign({}, this.detailApiParamsSet);
            params.id = this.collectKey;
            try {
                this.$api.$collect[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                if (this.directType === 'modal') {
                                    this.$modal.hide(this.directUrl)
                                } else {
                                    this.$router.back();
                                }
                            }
                        })
                        return false;
                    }
                    this.collectData = res.List[0];
                    this.initTemplate();
                })
            } catch (e) {
                console.log(e);
            }
        },

        checkValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = Object.keys(this.parsingData);

            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeCollect();
                } else {
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`, validatorArr);
                    });
                }
            })
        },
        writeCollect() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                if (this.parsingData[dataKey] != '') {
                    params[this.parsingData[dataKey]] = this[dataKey];
                }
            })
            if (this.collectKey != 0) {
                params[this.keyName] = this.collectKey;
            }
            try {
                EventBus.$emit('changeLoading', true)
                this.$api.$collect[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnWriteType(res.Info.type);
                    if (type === true) {
                        let key = ''
                        key = res.List.c_idx;
                        if (this.collectKey != 0) {
                            key = this.collectKey;
                        }
                        if (!util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDeleteFiles(this.deleteFiles)
                        }
                        if (!util.isEmpty(this.fileInfo)) {
                            this.setMultiFileUpload(key, this.fileInfo)
                        } else {
                            this.writeAfterFunc()
                        }
                    } else if (type.openAlert) {
                        EventBus.$emit('changeLoading', false)
                        this.errorAlert(this.$t(type.text));
                    } else {
                        EventBus.$emit('changeLoading', false)
                        this.setValidatorErr(this.$t(type.text))
                    }
                    this.isRunning = false;
                })
            } catch (e) {
                console.log(e);
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                }
            })
        },
        returnWriteType(type) {
            if (this.writeSuccessType == type) {
                return true;
            }
            return this.writeFailTypes.hasOwnProperty(type) ? this.writeFailTypes[type] : {
                openAlert: true,
                text: this.writeFailDefault
            };
        },
        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            let detailKey = key;
            this.$store.dispatch('collection/setCollectionList', {data: this.UserInfo.mb_no})
            this.createAlert({
                content: this.$t(this.writeSuccessAlert),
                hide: () => {
                    if (this.directType === 'back') {
                        this.$router.back();
                        return false;
                    }
                    if (this.directUrl === '') {
                        this.writeLayouts = [];
                        this.getCollect();
                        return false;
                    }
                    if (this.directType === 'modal') {
                        this.$modal.hide(this.directUrl)
                        EventBus.$emit('getCollectList');
                        return false;
                    }
                    let url = this.directUrl;
                    if (this.directType === 'detail') {
                        url = url + detailKey;
                    }
                    this.$router.push(url);
                },
            })
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
