<template>
    <li class="i_w_content l_selling_wrap">
        <h3 class="sub_title">{{ $t('selling_price') }}</h3>
        <div class="w_content_box">
            <div class="mg_input">
                <div class="content_name">{{ $t('fix_amount') }}</div>
                <el-input type="tel" v-model="originalPrice" placeholder="0"
                          @input="returnNumber('originalPrice',originalPrice)"
                          class="el_input_num"/>
            </div>
            <div class="val_cap" v-if="validation.hasError('originalPrice')">
                {{ validation.firstError('originalPrice') }}
            </div>
            <div class="val_cap" v-if="!validation.hasError('originalPrice') && maxPriceErrorMsg">
                {{ maxPriceErrorMsg }}
            </div>
        </div>
    </li>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";
import priceMixins from "@/mixins/priceMixins";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemLimitsSaleLayout",
    mixins: [priceMixins],
    components: {
    },
    props: {
        setComponentData: {default: () => {}, type: Function},
        setValidatorArr: {default: () => {}, type: Function},
        itemData: {default: () => {}, type: Object},
        itemClass: {default: null, }
    },
    data() {
        return {
            validatorArr: ['originalPrice'],
            originalPrice: '',
        }
    },
    created() {
        if(this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
        this.setValidatorArr(this.validatorArr)
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.originalPrice = this.itemData.originalPrice;
            this.returnNumber('originalPrice', this.originalPrice);
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {
            this.$validate(['originalPrice'])
        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);

            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
    },
    watch: {
    },
    validators: {
        originalPrice: function (value) {
            return Validator.value(value)
                .required(this.$t('itemErrorMsg19'))
                .custom(() => {
                    if (value === '' || Number(value) === 0) {
                        return this.$t('itemErrorMsg18')
                    }

                    let str = String(value);
                    let last = String(str.substring(str.length - 2, str.length))
                    if (last !== '00') {
                        return this.$t('Mg_msg')
                    }
                })
        },
    },
}
</script>

<style scoped>

</style>
