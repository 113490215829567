<template>
  <div>
    <gallery-write-component :gallery-type="'galleryAdd'"></gallery-write-component>
    <filter-modal></filter-modal>
  </div>
</template>

<script>
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";
import FilterModal from "@/components/modal/FilterModal";

export default {
  name: "GalleryAdd",
  mixins: [],
  components: {
    GalleryWriteComponent,
    FilterModal,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>