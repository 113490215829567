<template>
    <div>
<!--        <item-write-component :item-type="'itemStatusUpdate'" :item-key="itemKey"></item-write-component>-->
        <item-status-component :itemKey="itemKey"></item-status-component>
    </div>
</template>

<script>
import ItemWriteComponent from "@/components/item/ItemWriteComponent";
import ItemStatusComponent from "@/components/item/ItemStatusComponent";

export default {
    name: "ItemStatusUpdate",
    mixins: [],
    components: { ItemWriteComponent, ItemStatusComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
