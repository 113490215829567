<template>
    <modal name="mypage-referrer-modal" class="mypage-modal referrer"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >

        <member-write-component :member-type="'updateReferrer'"></member-write-component>
    </modal>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "MypageReferrerModal",
    mixins: [memberValidator],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            nick: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('mypage-referrer-modal');
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>