<template>
    <span>
<!--        <button class="kakao_login_wrap" @click="movePage('/auth/sns')">-->
        <button class="kakao_login_wrap" @click="triggerKakao()">
            <a style="display:none;" id="kakao-login-btn"></a>
            <img src="@/assets/image/main/kakao.png">
        </button>
        <!--        <button @click="KakaoDisconnect">Disconnect</button>-->
    </span>
</template>

<script>
import {kakaoService} from "@/plugins/kakaoService";
import EventBus from "@/utils/event-bus";
import {getTokenObj, loginTokenSet} from "@/mixins/Auth";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";


export default {
    name: "KakaoLoginComponent",
    mixins: [AuthMixins, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
        kakaoService().setKaKaoBtn(this.afterKakaoLogin)
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },

        triggerKakao() {
            document.querySelector("#kakao-login-btn").click();
        },
        afterKakaoLogin(result) {
            console.log(result)
            let params = {
                code: result.access_token
            };
            EventBus.$emit('apiLoading', true);
            this.$api.$auth.kakaoLogin(params).then(res => res.Data.Result).then((res) => {
                EventBus.$emit('apiLoading', false);
                if (res.Info.type === 1) {
                    this.loginSns(res.List, 'kakao');
                } else {
                    this.createAlert({
                        title: "",
                        content: this.$t('error_msg2'),
                        btnText: this.$t('yes'),
                        confirmBtn: true,
                        closeBtn: true,
                        hide: () => {
                            window.close();

                        }
                    })
                }
            })
        },
        KakaoDisconnect() {
            kakaoService().disconnect(this.afterKakaoDisconnect)
        },
        afterKakaoDisconnect(result) {
            console.log(result)

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
