<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_tab" :class="{ active : tab === 'pointStatus'}">
                    {{$t('mingle_status')}}
                </div>
                <div  class="mypage_item_tab" :class="{ active : tab === 'pointHistory'}"
                      @click="changeTab('history?backUrl=point')">
                    {{ $t('usage_history') }}
                </div>

                <point-status-component :point-type="'pointStatus'"></point-status-component>
            </div>
        </div>
    </div>
</template>

<script>

import PointStatusComponent from "@/components/point/PointStatusComponent";
import PointChargeComponent from "../../components/point/PointChargeComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";

export default {
    name: "MyPagePoint",
    mixins: [],
    components: {MyPageHeaderComponent, PointChargeComponent, PointStatusComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointStatus',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
           /* this.$nextTick(() => {
                this.tab = tab
            })*/
            this.movePage(`/mypage/point/${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
