<template>
    <span class="facebook_login_component_warp">
         <facebook-login app-id="2648890535256317" ref="facebook" @login="getUserData" v-show="false">
             <button slot-scope="scope">
                 <button type="button" class="icon-fb">페이스북 로그인</button>
             </button>
         </facebook-login>
        <button class="facebook_login_wrap" @click="triggerFacebook">
<!--        <button class="facebook_login_wrap" @click="movePage('/auth/sns')">-->
            <img src="@/assets/image/main/facebook.png">
        </button>
    </span>
</template>

<script>
import axios from "axios";
import facebookLogin from 'facebook-login-vuejs';
import {getTokenObj, loginTokenSet} from "@/mixins/Auth";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";


export default {
    name: "FacebookLoginComponent",
    mixins: [alertMixins, AuthMixins],
    components: {
        facebookLogin
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            //2648890535256317
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        getUserData() {
            if (/MSIE 10/i.test(navigator.userAgent) || (/MSIE 9/i.test(navigator.userAgent) || /rv:11.0/i.test(navigator.userAgent))) {
                this.createAlert({
                    title: '',
                    content: "페이스북 로그인은 인터넷 익스플로러 외 브라우저에서 가능합니다.",
                    btnText: "확인"
                });
                return false;
            }

            FB.getLoginStatus((response) => {
                console.log(response, "res");
                if (response.status === 'connected') {
                    console.log(response.authResponse)
                    let accessToken = response.authResponse.accessToken;
                    let params = {
                        code: accessToken
                    };
                    console.log(response)
                    this.$api.$auth.facebookLogin(params).then(res => res.Data.Result).then((res) => {
                        console.log(res);
                        if (res.Info.type !== 1) {
                            this.createAlert({
                                title: "",
                                content: this.$t('error_msg2'),
                                btnText: this.$t('yes'),
                                confirmBtn: true,
                                closeBtn: true,
                                hide: () => {
                                    window.close();
                                }
                            })
                        } else {
                            this.loginSns(res.List, 'facebook')
                        }
                    });
                }

            });
        },
        triggerFacebook() {
            document.querySelectorAll(".facebook_login_component_warp .container button")[0].click()
        },
        disconnectFacebook(accessToken) {
            let userId = this.UserInfo.mb_id;
            userId = userId.replace('facebook_', "");
            axios.post(`https://graph.facebook.com/${userId}/permissions?method=delete&access_token=${accessToken}`, {}, {headers: {}}).then(res => {
                if (res.data.success) {
                    // this.quitMember();
                    this.runQuit = false;
                } else {
                    // this.reFacebookLogin();
                }
            }).catch(e => {
                console.log(e);
                // this.reFacebookLogin();
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
