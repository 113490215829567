import { render, staticRenderFns } from "./PointChargeComponent.vue?vue&type=template&id=704ef1ae&scoped=true"
import script from "./PointChargeComponent.vue?vue&type=script&lang=js"
export * from "./PointChargeComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704ef1ae",
  null
  
)

export default component.exports