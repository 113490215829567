<template>
  <div class="login_main_div">
    <div class="login_top" >
      <span v-html="$t('share_msg')"></span>
<!--      {{$t('loginText1')}}
      <br>{{$t('loginText2')}}-->
      <div class="login_top_img"><img src="@/assets/image/main/main_logo.png" alt=""></div>
    </div>
    <login-component></login-component>

    <div class="login_sns_start">
      <div class="login_sns_wrap">
        {{$t('start_sns')}}
      </div>
    </div>
    <div class="login_sns">
      <div class="login_sns_wrap">
        <google-login-component></google-login-component>
        <naver-login-component></naver-login-component>
        <kakao-login-component></kakao-login-component>
        <facebook-login-component></facebook-login-component>
          <apple-login-component></apple-login-component>
      </div>
    </div>
  </div>
</template>

<script>
import LoginComponent from "@/components/login/LoginComponent";
import GoogleLoginComponent from "@/components/login/GoogleLoginComponent";
import NaverLoginComponent from "@/components/login/NaverLoginComponent";
import KakaoLoginComponent from "@/components/login/KakaoLoginComponent";
import FacebookLoginComponent from "@/components/login/FacebookLoginComponent";
import AppleLoginComponent from "@/components/login/AppleLoginComponent";

export default {
  name: "Login",
  metaInfo: {
    title: `밍글민트(Minglemint NFT Marketplace)`,
  },
  mixins: [],
  components: {
      AppleLoginComponent,
    LoginComponent,
    GoogleLoginComponent,
    NaverLoginComponent,
    KakaoLoginComponent,
    FacebookLoginComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>