<template>  
    <collect-list-component :collect-type="'userCollect'" :default-filter="{uuid:memberKey}"></collect-list-component>
</template>
<script>

import CollectListComponent from "@/components/collect/CollectListComponent";

export default {
    name: "CreatorDetailCollect",
    mixins: [],
    components: {
        CollectListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
