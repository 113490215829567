<template>
  <div>
    <button style="margin-right: 15px" @click="movePage('/notice')" value="notice">{{$t('notice')}}</button>
    <button style="margin-right: 15px" @click="movePage('/faq')" value="faq">FAQ</button>
    <button style="margin-right: 15px" @click="movePage('/qna')" value="qna">{{$t('qna')}}</button>
    <board-List-component :boardType="boardType"></board-List-component>
    <transition :name="`detail-${$root.$children[0].transitionName}`">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";

export default {
  name: "ServiceCenter",
  mixins: [],
  components: {
    BoardListComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      boardType: 'notice',
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url){
      this.$router.push(url);
    },
    changeTab(boardType) {
      this.boardType = '';
      this.$nextTick(() => {
        this.boardType = boardType
      })
      // let url = `${this.$route.path}?tab=${tab}`
      // if(this.$route.fullPath != url) {
      //   this.$router.replace(url);
      // }
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>