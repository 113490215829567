<template>
    <modal name="mypage-authentication-modal" class="mypage-modal authentication"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="mypage-modal-wrap">
            <div class="top_wrap">
                <div class="service_msg">{{$t('popup_authentication')}}</div>
                <div class="close_btn_wrap">
                    <button class="close_modal" @click="modalClose()"></button>
                </div>
            </div>
            <!--      <div class="middle_wrap img_wrap" v-if="stepCheck === 1">-->
            <div class="middle_wrap img_wrap">
                <img class="verify_img" src="@/assets/image/main/popup_verify.png" alt=""/>
                <div class="confirm_btn_wrap">
                    <button class="btn save" @click="runAuthentication()">{{$t('do_enroll')}}</button>
                    <button class="btn cancel" @click="modalClose()">{{$t('do_later')}}</button>
                </div>
            </div>
            <!--      <div class="middle_wrap" v-if="stepCheck === 2">
                    <el-input class="el_info_input" type="tel" maxlength="20"
                              :placeholder="$t('phonePlaceholder')" v-model="phone"
                              @input="setData('phone',phone)"
                              @keypress.enter="nextStep()"/>
            &lt;!&ndash;       전화번호 중복 체크 &ndash;&gt;
                    <div class="validation" >
                      {{ $t('val_phone') }}
                    </div>
                    <div class="confirm_btn_wrap">
                      <button class="btn full" @click="nextStep()">{{$t('send_num_btn')}}</button>
                    </div>
                  </div>
                  <div class="middle_wrap" v-if="stepCheck === 3">
                    <div class="info_text">010-7444-6330</div>
                    <el-input class="el_info_input el_input_num" type="number" maxlength="6"
                              :placeholder="$t('input_num')" v-model="verifyNum"
                              @input="setData('verifyNum',verifyNum)"
                              @keypress.enter="nextStep()"/>
                    &lt;!&ndash;       전화번호 중복 체크 &ndash;&gt;
                    <div class="validation" v-if="!errorMsg" >{{$t('c_number_no')}}</div>
                    <div class="possible" v-else-if="successMsg" >{{$t('c_number_confirm')}}</div>
                    <div class="confirm_btn_wrap">
                      <button class="btn full" @click="nextStep()">{{$t('save')}}</button>
                    </div>
                  </div>-->
        </div>
    </modal>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import EventBus from "@/utils/event-bus";

export default {
    name: "MypageAuthenticationModal",
    mixins: [memberValidator],
    components: {
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            phone: '',
            verifyNum: '',
            stepCheck: 1,
            errorMsg: false,
            successMsg: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('mypage-authentication-modal');
            $("body").removeClass("modal-open")
        },
        returnUrl(){
            return this.url
        },
        setData(key, value) {
            this[key] = value
        },
        runAuthentication() {
            EventBus.$emit('runAuthenticatio')
            this.modalClose();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
