import alertMixin from "@/mixins/alertMixins";
import util from "@/mixins/util";

class DeleteClass {
    func = () => {
        return new Promise(function(resolve, reject) {
            let resultData = {
                Data: {Result: {Info: {Type : 1}}}
            }
            resolve(resultData)
        });
    };

    apiParamSet = {};
    keyName = 'wr_id';
    successType = 1;
    errorTypes = {};
    constructor(options) {
        if(!util.isEmpty(options.func)) {
            this.func = options.func;
        }
        if(!util.isEmpty(options.apiParamSet)) {
            this.apiParamSet = options.apiParamSet;
        }
        if(!util.isEmpty(options.keyName)) {
            this.keyName = options.keyName;
        }
        if(!util.isEmpty(options.successType)) {
            this.successType = options.successType;
        }
        if(!util.isEmpty(options.errorTypes)) {
            this.errorTypes = options.errorTypes;
        }
        if(!util.isEmpty(options.afterFunc)) {
            this.afterFunc = options.afterFunc;
        }
    }

    afterFunc = () => {};
    openErrorAlert(content) {
       /* Swal.fire({
            title: '',
            html: content,
            confirmButtonText: '확인',
            customClass:'alert_default',
            onClose: () => {

            }
        })*/
        alertMixin.methods.errorAlert(content);

    }
    checkDeleteType(type) {
        if(type === this.successType) {
            return true;
        }
        return util.isEmpty(this.errorTypes[type]) ? this.$t('deleteErrorMsg') : this.errorTypes[type];
    }

    runDelete(key) {
        let params = JSON.parse(JSON.stringify(this.apiParamSet));
        params[this.keyName] = key;
        try {
            this.func(params).then(res => res.Data.Result).then(res => {
                let type = this.checkDeleteType(res.Info.type);
                if(type === true) {
                    this.afterFunc(key);
                }else{
                    this.openErrorAlert(type);
                }
            })
        }catch (e) {
            console.log(e);
        }

    }
}

export default DeleteClass;
