<template>
  <div>
      <item-detail-component :item-type="'entryDetail'" :item-key="entryKey"></item-detail-component>
      <judge-write-modal></judge-write-modal>
  </div>
</template>

<script>
import ItemDetailComponent from "@/components/item/ItemDetailComponent";
import JudgeWriteModal from "@/components/modal/JudgeWriteModal";
import EventBus from "@/utils/event-bus";

export default {
  name: "EntryDetail",
  mixins: [],
  components: {
      JudgeWriteModal,
      ItemDetailComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
        entryKey:this.$route.params.idx
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
      EventBus.$on('getDetailData',this.getDetailData);
  },
  beforeDestroy() {
      EventBus.$off('getDetailData');
  },
  destroyed() {},
  computed: {
  },
  methods:{
      getDetailData(){
          EventBus.$emit('getDetail');
      }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
