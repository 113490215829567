<template>
    <div class="">
        <history-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" :isGetFirst="isGetFirst" v-if="List.length ===0"></history-empty-link>
        <history-list-link :listLayout="listLayout" :total="filterSet.page.total" :historyData="List" :paginationData="filterSet.page" v-else></history-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  ></pagination-link>
    </div>
</template>

<script>
import HistoryListLink from "@/components/history/HistoryListLink";
import HistoryEmptyLink from "@/components/history/HistoryEmptyLink";
import historyUtil from "@/components/history/historyUtil";
import PaginationLink from "@/components/history/PaginationLink";
//import PaginationLink from "../competition/PaginationLink";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import alertMixins from "@/mixins/alertMixins";

const {filterConfig} = historyUtil;
export default {
    name: "HistoryListComponent",
    mixins: [historyUtil, filterMixins, listMixins],
    components: {
        PaginationLink,
        HistoryListLink,
        HistoryEmptyLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
        }
    },
    props: {
        historyType: {default: ''},
        defaultFilter: {default: () => {}},
    },
    data() {
        return {
            listLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig);
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnHistoryConfig(this.historyType);
            this.listLayout = config.listLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if(!util.isEmpty(this.defaultFilter)) {
                params = Object.assign({}, params, this.defaultFilter);
            }
            let listOptions = {
                url: this.$api.$item[this.getFunc],
                defaultParams: params,
            }
            this.initList('api', listOptions);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
