<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_4_tab" v-if="userType === 'member'" :class="{ active : tab === 'item'}"
                     @click="changeTab('item')">
                    {{ $t('item') }}
                </div>
                <div class="mypage_item_4_tab" v-if="userType === 'member'" :class="{ active : tab === 'creator'}"
                     @click="changeTab('creator')">
                    {{ $t('creator') }}
                </div>
                <div class="mypage_item_4_tab" v-if="userType === 'member'" :class="{ active : tab === 'competition'}"
                     @click="changeTab('competition')">
                    {{ $t('competition') }}
                </div>
                <div class="mypage_item_4_tab" v-if="userType === 'member'" :class="{ active : tab === 'collect'}"
                     @click="changeTab('collect')">
                    {{ $t('collection') }}
                </div>
                <div class="mypage_item_tab" v-if="userType !== 'member'" :class="{ active : tab === 'current'}"
                     @click="changeTab('current')">
                    {{ $t('mingle_status') }}
                </div>
                <div class="mypage_item_tab" v-if="userType !== 'member'" :class="{ active : tab === 'log'}"
                     @click="changeTab('log')">
                    {{ $t('usage_history') }}
                </div>
                <member-list-component :member-type="'interestCreator'"
                                       v-if="tab === 'creator'"></member-list-component>
                <item-list-component :item-type="'interestItem'" v-if="tab === 'item'"></item-list-component>
                <competition-list-component :competition-type="'interestCompetition'"
                                            v-if="tab === 'competition'"></competition-list-component>
                <collect-list-component :collect-type="'mypageCollect'" :defaultFilter="{no:UserInfo.mb_no}"
                                        v-if="tab === 'collect'"></collect-list-component>
                <point-status-component :point-type="'pointStatus'" v-if="tab === 'current'"></point-status-component>
                <point-list-component :point-type="'pointHistory'" v-if="tab === 'log'"></point-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import ItemListComponent from "@/components/item/ItemListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import MemberListComponent from "@/components/member/MemberListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import CollectListComponent from "@/components/collect/CollectListComponent";
import PointStatusComponent from "@/components/point/PointStatusComponent";
import PointListComponent from "../../components/point/PointListComponent";

export default {
    name: "MyPageInterest",
    mixins: [AuthMixins, alertMixins],
    components: {
        CollectListComponent,
        CompetitionListComponent,
        MemberListComponent,
        MyPageHeaderComponent,
        ItemListComponent,
        PointStatusComponent,
        PointListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: '',
            userType : "d"
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
        this.checkTab()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        checkTab() {
            if(this.userType === 'member') {
                this.tab = 'item';
            } else {
                this.tab = 'current';
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
