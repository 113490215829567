import {getTokenObj, loginTokenSet} from "@/mixins/Auth";
import util from "@/mixins/util";
import {LevelClass} from "@/mixins/level";

export default {
    data() {
        return {
            redirect: '/',
            LevelClass: new LevelClass(),
        }
    },
    methods: {
      checkLevel() {
          let tokenObj = getTokenObj();
          this.$api.$config.getGroup().then(res => res.Data.Result).then(res => {
              if(res.Info.type != 1 || util.isEmpty(tokenObj.g_idx)) {
                  this.checkLevelFail();
              } else {
                  let g_idx = tokenObj.g_idx;
                  let groupList = res.List;
                  this.makeGroupObj(g_idx, groupList);
              }
          })
      },
        makeGroupObj(g_idx, groupList) {
          let groupIndex = groupList.findIndex(group => group.g_idx === g_idx);
          if ( groupIndex < 0 ) {
              this.checkLevelFail();
          } else {
              let levelObj = {
                  level: groupList[groupIndex].g_level,
                  type: groupList[groupIndex].g_type,
                  isSuper: groupList[groupIndex].g_is_super === 1 ? true : false,
              }
              this.checkLoginLevel(levelObj);
          }
        },
        checkLevelFail(errorMsg = '') {
            if(!errorMsg) {
                errorMsg = window.$t('error_msg2');
            }
            this.errorMsg = errorMsg;
            this.errorAlert(errorMsg);
            loginTokenSet('');
            this.$localStorage.remove('token');
        },
        checkLevelSuccess(levelObj) {
          this.LevelClass.setterLevelObj(levelObj);
          this.$router.replace(this.redirect);
        },
        checkLoginLevel(levelObj) {
            if(levelObj.type === 'user' || levelObj.type == 'curator' || levelObj.type == 'director' || levelObj.type == 'manager') {
                this.checkLevelSuccess(levelObj);
            } else {
                this.checkLevelFail(window.$t('error_msg2'));
            }
        },

    },
}
