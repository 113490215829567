const portfolioConfig = {
    portfolio: {
        filterLayout: 'PortfolioFilterDefaultLayout',
        listLayout: 'PortfolioListDefaultLayout',
        emptyLayout: 'PortfolioEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    portfolioUser: {
        listLayout: 'PortfolioListUserLayout',
        emptyLayout: 'PortfolioEmptyDefaultLayout',
    },
    portfolioMy: {
        listLayout: 'PortfolioListMyLayout',
        emptyLayout: 'PortfolioEmptyDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    portfolioWrite:{
        writeLayouts:['PortfolioWriteDefaultLayout']
    },
    portfolioUpdate:{
        writeLayouts:['PortfolioWriteUpdateLayout']
    }
}
export default {
    methods: {
        returnPortfolioConfig(type) {
            return portfolioConfig[type];
        }
    },
}
