<template>
    <modal name="judge-result-modal" class="judge-result-modal"
           :width="`650px`" :height="`461px`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="judge_result_wrap">
            <button @click="modalClose()" class="back_btn"></button>
            <h1>{{ $t('awardResult') }}</h1>
            <div class="modal_content">
                <div class="modal_wrap">
                    <div class="content2">
                        <div class="reason_write_wrap">
                            <textarea readonly v-model="reason"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <button class="save_btn" @click="modalClose()">{{ $t('yes') }}</button>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "JudgeResultModal",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            reason: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.reason)) {
                event.stop()
            } else {
                this.reason = event.params.reason
            }
        },
        handlerOpened() {
            this.initResult()
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('judge-result-modal');
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
