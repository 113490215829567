<template>
    <div>
      <div class="mypage_main">
        <my-page-header-component></my-page-header-component>
        <div class="mypage_list">
          <div class="mypage_item_tab detection border_bottom" :class="{ active_b : tab === 'image'}"
               @click="changeTab('image')">
            {{$t('myImg')}}
          </div>
          <div  class="mypage_item_tab detection border_bottom" :class="{ active_b : tab === 'request'}"
                @click="changeTab('request')">
            {{ $t('request_history') }}
          </div>
        <detection-write-component :detection-type="'detectionWrite'" v-if="tab === 'image'"></detection-write-component>
        <detection-list-component :detection-type="'request'" v-else-if="tab === 'request'"></detection-list-component>

        </div>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>
      </div>
    </div>
</template>

<script>
import DetectionListComponent from "@/components/detection/DetectionListComponent";
import ItemListComponent from "../../components/item/ItemListComponent";
import DetectionWriteComponent from "../../components/detection/DetectionWriteComponent";
import FilterModal from "../../components/modal/FilterModal";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import util from "@/mixins/util";

export default {
    name: "MyPageDetection",
    mixins: [],
    components: {
      MyPageHeaderComponent,
        FilterModal,
        DetectionWriteComponent,
        ItemListComponent,
        DetectionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'image',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        if(!util.isEmpty(this.$route.query.tab)) {
            this.tab = this.$route.query.tab;
        }
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },

        changeTab(tab) {
            if(this.tab === tab) {
                return false;
            }
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
                let route = `${this.$route.path}?tab=${tab}`
                if(tab != this.$route.fullPath) {
                    this.$router.replace(route);
                }
            })
          this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
