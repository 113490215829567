<template>
  <modal name="competition-filter-modal" class="competition-filter-modal"
         :width="`50%`" :height="`50%`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div>
      공모전 필터 모달
    </div>
  </modal>
</template>

<script>
export default {
  name: "CompetitionFilterModal",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      url: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
      // this.scrollAble();
    },
    modalClose() {
      // this.scrollAble();
      this.$modal.hide('competition-filter-modal');
    },
    returnUrl() {
      return this.url
    },
    // scrollAble(){
    //   $('html, body').removeClass('hidden');
    // }
  },
  watch: {},
}
</script>

<style scoped>

</style>