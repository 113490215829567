import Vue from 'vue'
import store from '@/store/'
import util from "@/mixins/util";
import API from '@/api/index'
import Order from "@/components/point/Order";


var PaymentClass = (function () {
    function PaymentClass(PayUser) {
        var User = PayUser || null,
            UserKey = PayUser[this.getPaymentClassConstants().UserKey] || null

        this.User = User;
        this.UserKey = UserKey;
        if (this.UserKey == null ) {
            throw new Error(this.getPaymentClassConstants().PARAMETERERROR);
        }
    };

    PaymentClass.prototype.getPaymentClassConstants = function getPaymentClassConstants() {

        let PaymentClassConstants = {
            cart:{
                i_idx:null,
                c_qty:null,
                c_type:null
            },
            address:{

            },
            UserKey: 'mb_no',
            UserName: 'mb_nick',
            memberId: 'mb_id',
            DataTime: 'created_at',
            PARAMETERERROR: 'The parameters: UserKey, userAuth data is not available plz check your data store',
        };
        return PaymentClassConstants;
    };
    PaymentClass.prototype.paymentStart = function paymentStart( roomId, roomType ) {

        return true;
    };

        PaymentClass.prototype._setCart = function _setCart( itemId, qty,c_type ) {

        let cart = this.getPaymentClassConstants().cart;
        cart.i_idx = itemId;
        cart.c_qty = qty;
        cart.c_type = c_type;

        return cart;
    };



    PaymentClass.prototype.directPay =  async function  directPay(itemId, qty,c_type) {

        //test
        let OrderClass = new Order.OrderClass(this.User);
        let cart = this._setCart(itemId, qty, c_type)
        return API.$pay.createCart(cart).then(res => res.Data.Result).then(res => {
            let status = this._chkStatus(res.List)
            if(!status) {
                return false;
            }

            console.log('directPay',orderId)
            let orderId = this._getOrderIdFromList(res.List)
            console.log('directPay orderId',orderId)
            return orderId;
        })

    };
    PaymentClass.prototype.getPaymentUser = function getPaymentUser(){
        return this.User;
    }
    PaymentClass.prototype.getBrowserType = function getBrowserType(){
            var agt = navigator.userAgent.toLowerCase();
            if (agt.indexOf('chrome') != -1) return 'notSafari';
            if (agt.indexOf("safari") != -1) return 'safari';
            return 'notSafari'
    }

    PaymentClass.prototype._chkStatus =  function  _chkStatus(List) {

        if(List.hasOwnProperty('Data') && !util.isEmpty(List.Data)  &&
            List.Data.hasOwnProperty('Status') && !util.isEmpty(List.Data.Status) ){
            return List.Data.Status
        }
        return false;

    };

    PaymentClass.prototype._getOrderIdFromList =  function  _getOrderIdFromList(List) {
        let o_id = List.Data.Data.Basic.Data.Cart.Data.o_id;
        return o_id;

    };

    PaymentClass.prototype.checkOrderId =  function checkOrderId(o_id) {
        let resultData = {type: 0, o_id: o_id, reason: '결재 실패'};
        let params = {
            o_id: o_id
        }
        return API.$pay.checkOrderId(params).then(res => res.Data.Result).then(res => {
            resultData.type = res.Info.type;
            resultData.reason = res.Info.reason;
            return resultData;
        })

    }
    return PaymentClass;
})();

export default {
    PaymentClass: PaymentClass,
    data() {
        return {
        }
    },
    methods: {

    }
}

