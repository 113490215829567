<template>
    <div>
        <judge-write-link :writeLayout="writeLayout"></judge-write-link>
    </div>
</template>

<script>
import JudgeWriteLink from "@/components/judge/JudgeWriteLink";
import judgeUtil from "@/components/judge/judgeUtil";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
export default {
    name: "JudgeWriteComponent",
    mixins: [judgeUtil,alertMixins],
    components: {
        JudgeWriteLink,
    },
    inject: [],
    provide() {
        return {
            JudgeSetData : this.setData
        }
    },
    props: {
        judgeType: '',
        judgeKey: '',
    },
    data() {
        return {
            writeLayout: '',
            score:0,
            reason:'',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('writeJudge',this.writeJudge)
    },
    beforeDestroy() {
        EventBus.$off('writeJudge')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let judgeConfig = this.returnJudgeConfig(this.judgeType);
            this.writeLayout = judgeConfig.writeLayout;
            this.writeFunc = judgeConfig.writeFunc
        },
        setData(key, value) {
            this[key] = value
        },
        writeJudge(){
            EventBus.$emit('apiLoading', true);
            let params = {
                ci_idx : this.judgeKey,
                cij_score : this.score,
                cij_reason : this.reason
            }

            try {
                this.$api.$competition.createJudge(params).then(res => res.Data.Result).then(res => {
                    let result = this.returnJudgeType(res.Info.type)
                    if (result === true) {
                        EventBus.$emit('apiLoading', false);
                        this.createAlert({
                            content: this.$t('judge_msg'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                EventBus.$emit('getDetailData');
                                this.$modal.hide('judge-write-modal')
                            }
                        })
                    } else {
                        EventBus.$emit('apiLoading', false);
                        this.createAlert({
                            content: result,
                            btnText: this.$t('yes'),
                            hide: () => {
                            }
                        })
                    }
                })
            }catch (e) {
                EventBus.$emit('apiLoading', false);
            }
        },
        returnJudgeType(type){
            switch (type){
                case 1 :{
                    return true
                }
                case -851 : {
                    return this.$t('judge_err_st')
                }
                case -852 : {
                    return this.$t('judge_err_not')
                }
                case -853 : {
                    return this.$t('judge_err_already')
                }
                default :{
                    return this.$t('judge_err_fail2')
                }
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
