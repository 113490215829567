<template>
    <modal name="detection-url-modal" class="detection-url-modal"
           :width="`534`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="du_modal_wrap">
          <div class="du_modal_title_wrap">
            <div class="du_modal_title">{{ $t('ignore_url_enroll') }}</div>
            <button class="close_btn" @click="modalClose()"></button>
          </div>
          <div class="du_modal_text">
            {{ $t('ignore_url_text') }}
          </div>
          <div>
            <detection-write-component :detection-type="'urlWrite'"></detection-write-component>
          </div>
          <div class="du_btn_wrap">
            <el-button class="du_btn save" @click="runWrite">{{$t('enroll')}}</el-button>
            <el-button class="du_btn cancel" @click="modalClose()">{{$t('cancel')}}</el-button>
            <div class="val_cap" v-if="validatorErr != ''">{{validatorErr}}</div>
          </div>
        </div>
    </modal>
</template>

<script>
import DetectionWriteComponent from "../detection/DetectionWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionUrlModal",
    mixins: [],
    components: {DetectionWriteComponent},
    inject: [],
    provide() {
        return {
            setValidatorErr: this.setValidatorErr,
        }
    },
    props: {},
    data() {
        return {
            validatorErr: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.validatorErr = "";
        },
        modalClose() {
            this.$modal.hide('detection-url-modal');
        },
        returnUrl() {
            return this.url
        },
        setValidatorErr(value) {
            this.validatorErr = value;
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
