import {request} from "@/api/request";
import Vue from "vue"

const state = {
    itemCategoryList: [],
}

const getters = {
    getItemCategoryList: state => state.itemCategoryList,
}

const mutations = {
    updateItemCategoryList(state, obj) {
        state.itemCategoryList = obj.data;
    },
}
const actions = {
    setItemCategory({commit, rootState}, {}) {
        try {
            request.get('itemcategory' ,{ip:0}).then(res => {
                if (res.data.Data.Result.Info.type != 1) {
                    return false;
                }
                let categoryList = res.data.Data.Result.List
                commit('updateItemCategoryList', {data: categoryList})
            })
        } catch (e) {
            console.log(e);
        }
    },
}
export default {namespaced: true, state, getters, mutations, actions}
