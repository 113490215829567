<template>
    <div>
<!--        <creator-detail-tabs :nowTab="'premium'" :memberKey="memberKey"></creator-detail-tabs>-->
        <item-list-component :item-type="'premiumUser'" :member-key="memberKey"></item-list-component>
    </div>
</template>
<script>
import CreatorDetailTabs from "@/components/tabs/CreatorDetailTabs";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import FilterModal from "@/components/modal/FilterModal";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import ItemListComponent from "@/components/item/ItemListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import PortfolioListComponent from "@/components/portfolio/PortfolioListComponent";

export default {
    name: "CreatorDetailPremium",
    mixins: [],
    components: {
        CreatorDetailTabs,
        MemberDetailComponent,
        FilterModal,
        CurationHistoryModal,
        ItemListComponent,
        CompetitionListComponent,
        PortfolioListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
            tab: 'premiumUser',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        // this.memberKey = this.$route.params.idx;
        //this.memberKey = this.$route.params.idx;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
