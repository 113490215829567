<template>
    <div>
        <curation-write-link
            v-for="writeLayout in writeLayouts" :key="`curation${writeLayout}`"
            :writeLayout="writeLayout" :curationData="curationData" :itemData="itemData"></curation-write-link>
    </div>

</template>

<script>
import CurationWriteLink from "@/components/curation/CurationWriteLink";
import curationUtil from "@/components/curation/curationUtil";
import util from "@/mixins/util";
import curationValidator from "@/mixins/validators/curationValidator";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationWriteComponent",
    mixins: [curationUtil, curationValidator, alertMixins],
    components: {
        CurationWriteLink
    },
    inject: [],
    provide() {
        return{
            curationWriteSetData: this.setData,
        }
    },
    props: {
        curationType: '',
        curationKey: {default: 0},
        itemKey: {default: 0}
    },
    data() {
        return{
            writeLayouts: [],
            getFunc: '',
            detailApiParamSet: {},
            getKeyName: '',
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            writeSuccessType: 1,
            writeFailTypes: {},
            writeFailDefault: '',
            writeSuccessAlert: '',
            keyName: '',
            openConfirm: false,

            curationData: {},
            itemData: {},
            isRunning: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        if(this.curationKey === 0 || util.isEmpty(this.getFunc)) {
            this.initTemplate();
        }else{
            this.getCuration()
        }
        if(this.itemKey != 0 ) {
            this.getItem()
        }
    },
    mounted() {
        EventBus.$on('curationWrite', this.checkValidator);
    },
    beforeDestroy() {
        EventBus.$off('curationWrite')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setData(type, value) {
            this[type] = value;
        },
        initTemplate() {
            let config = this.returnCurationConfig(this.curationType);
            this.writeLayouts = config.writeLayouts;
        },
        initConfig(){
            let config = this.returnCurationConfig(this.curationType);
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.getKeyName = config.getKeyName;
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.writeSuccessType = config.writeSuccessType;
            this.writeFailTypes = config.writeFailTypes;
            this.writeFailDefault = config.writeFailDefault;
            this.writeSuccessAlert = config.writeSuccessAlert;
            this.keyName = config.keyName;
            this.openConfirm = config.openConfirm;
        },
        getCuration() {
            this.initTemplate();
        },
        getItem() {
            let params = {id: this.itemKey};
            try {
                this.$api.$item.getItemDetail(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.itemData = res.List[0];
                        this.i_idx = this.itemKey;
                    } else {
                        this.createAlert({
                            content: this.$t('listErrorMsg1'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                this.$router.back();
                            }

                        })
                    }
                });
            }catch (e) {
                console.log(e)
            }
        },
        checkValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = Object.keys(this.parsingData);
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeCuration();
                } else {
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                    });
                }
            })
        },
        writeCuration() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                if(this.parsingData[dataKey] != '') {
                    params[this.parsingData[dataKey]] = this[dataKey];
                }
            });
            if(this.curationKey != 0) {
                params[this.keyName] = this.curationKey;
            }

            try {
                EventBus.$emit('changeLoading', true)
                this.$api.$curation[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    if(this.curationType === 'curationWrite') {
                        this.checkCurationWrite(res);
                        return false;
                    }
                    let type = this.returnWriteType(res.Info.type);
                    if(type === true) {
                        let key = ''
                        if(res.hasOwnProperty('List') && res.List.hasOwnProperty(this.keyName)) {
                            key = res.List[this.keyName];
                        }else{
                            key = this.curationKey;
                        }
                        this.writeOpenAlert(key)
                    }else if(type.openAlert) {
                        this.errorAlert(this.$t(type.text));
                    }else{
                        this.setValidatorErr(this.$t(type.text))
                    }
                    this.isRunning = false;
                    EventBus.$emit('changeLoading', false)
                })
            }catch (e) {
                console.log(e);
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
            }
        },
        checkCurationWrite(res) {
            if(res.List.Data.Status) {
                this.writeOpenAlert(0)
            }else{
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
                this.errorAlert(this.$t(this.writeFailDefault));
            }
        },
        returnWriteType(type) {
            if(this.writeSuccessType == type) {
                return true;
            }
            return this.writeFailTypes.hasOwnProperty(type) ? this.writeFailTypes[type] : {openAlert: true, text: this.writeFailDefault};
        },
        writeOpenAlert(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            if(this.openConfirm) {
                this.createConfirm({
                    content: `${this.$t(this.writeSuccessAlert)}<br>${this.$t('curator_application_success2')}`,
                    confirmText: this.$t('check_now'),
                    cancelText: this.$t('check_later'),
                    confirm: () => {
                        this.$router.push('/mypage/curation')
                    },
                    cancel: () => {
                        this.writeAfterFunc(key)
                    },
                })
            }else{
                this.createAlert({
                    content: this.$t(this.writeSuccessAlert),
                    hide: () => {
                        this.writeAfterFunc(key);
                    }
                })
            }
        },
        writeAfterFunc(detailKey) {
            if (this.directType === 'getItemList') {
                EventBus.$emit('getItemList');
                return false;
            }
            if (this.directType === 'back') {
                this.$router.back();
                return false;
            }
            if (this.directUrl === '') {
                this.writeLayouts = [];
                this.getGallery();
                return false;
            }
            if(this.directType === 'modal') {
                this.$modal.hide(this.directUrl)
                EventBus.$emit('getGalleryList');
                return false;
            }
            let url = this.directUrl;
            if (this.directType === 'detail') {
                url = url + detailKey;
            }
            this.$router.push(url);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
