<template>
  <modal name="mypage-pw-modal" class="mypage-modal pw"
         :width="`100%`" :height="`100%`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
      <member-write-component :member-type="'updatePwd'"></member-write-component>
  </modal>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
  name: "MypagePwModal",
  mixins: [memberValidator],
  components: {
      MemberWriteComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      pwd: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
        $("body").removeClass("modal-open");
    },
    modalClose() {
        $("body").removeClass("modal-open");
      this.$modal.hide('mypage-pw-modal');
    },
    returnUrl(){
      return this.url
    },
    setData(key, value) {
      this[key] = value
    },
    checkSave() {

    },
  },
  watch: {},
}
</script>

<style scoped>

</style>