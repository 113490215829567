<template>
    <div class="curation_request">
        <div class="ifmf_title">{{$t('c_request')}}</div>
        <curation-write-component :curationType="'curationWrite'"></curation-write-component>
<!--        <member-detail-component :member-type="'curationRequest'" :memberKey="memberKey"></member-detail-component>
        <div class="ifmf_sub_msg">{{$t('c_s_msg')}}</div>
        <item-list-component :item-type="'curationRequest'" :memberKey="UserInfo.mb_no"></item-list-component>-->
    </div>
</template>

<script>
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import AuthMixins from "@/mixins/AuthMixins";

import CurationWriteComponent from "@/components/curation/CurationWriteComponent";

export default {
    name: "CurationRequest",
    mixins: [AuthMixins],
    components: {
        CurationWriteComponent,
        MemberDetailComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            memberKey: this.$route.params.mb_no,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
