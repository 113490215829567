<template>
    <curation-write-component :curation-type="'curationWrite'" :itemKey="itemKey"></curation-write-component>
</template>

<script>
import CurationWriteComponent from "@/components/curation/CurationWriteComponent";

export default {
    name: "CurationRequestDetail",
    mixins: [],
    components: {
        CurationWriteComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            memberKey: Number(this.$route.params.mb_no),
            itemKey: Number(this.$route.params.i_idx),
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
