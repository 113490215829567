<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_3_tab" :class="{ active : tab === 'myItemHistory'}"
                     @click="changeTab('myItemHistory')">
                    {{ $t('history') }}
                </div>
                <div class="mypage_item_3_tab" :class="{ active : tab === 'myItemHold'}"
                     @click="changeTab('myItemHold')">
                    {{ $t('holding') }}
                </div>
                <div class="mypage_item_3_tab" :class="{ active : tab === 'myItemTempSave'}"
                     @click="changeTab('myItemTempSave')">
                    {{ $t('tempSave') }}
                </div>
                <item-list-component :item-type="tab" :member-key="UserInfo.mb_no" v-if="tab"></item-list-component>
            </div>

        </div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import ItemListComponent from "@/components/item/ItemListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import util from "@/mixins/util";

export default {
    name: "MyPageItem",
    mixins: [AuthMixins, alertMixins],
    components: {
        MyPageHeaderComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'myItemHistory',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        console.log(this.getMemberType())
        if(this.getMemberType() === 'curator') {
            this.$router.replace('/mypage/curation');
            return false;
        } else if (this.getMemberType() === 'manager' || this.getMemberType() === 'director') {
            this.$router.replace('/mypage/interest')
            return false;
        }
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('myItemHistory');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
