<template>
    <div class="item_wrap ">
        <!--        <div class="item_category_title">{{ categoryName }}</div>-->
        <div class="item_category_title"></div>
        <div class="item_category_wrap">
            <button class="item_category" :class="{'active':categoryType === ''}" @click="changeCategory('')">
                All
            </button>
            <button class="item_category" :class="{'active':categoryType===data.node_id}"
                    @click="changeCategory(data)" v-for="data in itemCategoryList"
                    :key="`categort${data.node_id}`">
                {{ data.NAME_ENG }}
            </button>
        </div>
        <ul class="item_card_wrap">
            <li class="list_item" v-for="(data,index) in itemList" :key="`item${index}`">
                <div class="thumbnail" :style="` background-image: url(${returnThumbnail(data)});`"
                     @click="movePage(data)">
                    <div class="card_category cc_category" @click="movePage(data)">
                        {{ data.ic_name_eng }}
                    </div>
                    <div class="card_category cc_real" v-if="data.i_type ==='real'" @click="movePage(data)">
                        {{ $t('real') }}
                    </div>
                    <div class="card_category cc_real play_icon"
                         v-if="data.im_type === 'video'|| data.im_type==='audio'">
                        <img class="icon play" src="@/assets/image/main/icon_play.png" alt="">
                    </div>
                </div>
                <div class="card_detail">
                    <div class="profile_group">
<!--                        컬렉션-->
                        <el-popover
                                trigger="click"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover"
                        >
                            <img slot="reference" class="card_profile" :src="returnCollectImage(data)">
                            <div class="popover_wrap" v-if="checkCollect(data)"
                                 @click="moveCollect(data)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('collection') }}
                                    </div>
                                    <div class="popover_nickname no_profile_nickname" >
                                        <img class="nickname_img collection_img" :src="returnCollectImage(data)">
                                        <div class="popover_text_list collect_text_info">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ data.Collection.c_title }}</div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ returnCollectLike(data.Collection) }}</div>
                                                <div>ITEM</div>
                                                <div>{{ data.Collection.item_cnt }}</div>
                                            </div>
                                            <div class="popover_price" >
                                                <span class="price">{{ returnPriceString(data.Collection.c_total) }} Mg </span>
                                                <span class="item_small">({{ returnPriceStringMingle(data.Collection.c_total) }})</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ data.Collection.c_intro }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover
                                trigger="click"
                                @show="getMemberData(data,'im_originator')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover"
                        >
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'originator')">
                            <div class="popover_wrap" v-if="checkMemberData()"
                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('k_creator') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                            <div class="popover_wrap" v-else>
                                <div class="popover_top no_profile">
                                    <div class="popover_creator no_profile_creator">
                                        {{ $t('k_creator') }}
                                    </div>
                                    <div class="popover_nickname no_profile_nickname">
                                        <img class="nickname_img no_profile_img"
                                             src="@/assets/image/main/no_profile.png">
                                        <div class="popover_text_list no_profile_text">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text no_profile_text">{{ $t('notInfo') }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom no_profile_bottom">
                                    <div class="pop_pover_div no_profile_div">
                                        {{ $t('notProvider') }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>

                        <el-popover
                                placement="bottom"
                                trigger="click"
                                @show="getMemberData(data,'provider')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover">
                            <img slot="reference" class="card_profile" :src="returnItemProfile(data,'provider')">
                            <div class="popover_wrap" v-if="checkMemberData()"
                                 @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('owner') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>


                        <el-popover
                                placement="bottom"
                                trigger="click"
                                @show="getMemberData(data,'ibc_curator')"
                                :open-delay="500"
                                :offset="0"
                                :append-to-body="false"
                                :popper-options="{
                                boundariesElement: 'body',
                                gpuAcceleration: true,
                                positionFixed: true,
                                preventOverflow: true,}"
                                popper-class="main_popover no_profile_popover">
                            <img slot="reference" class="card_profile" v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')">
                            <div class="popover_wrap" @click="moveMember(`creator/${memberData.mb_uuid}`)">
                                <div class="popover_top">
                                    <div class="popover_creator">
                                        {{ $t('curator') }}
                                    </div>
                                    <div class="popover_nickname">
                                        <img class="nickname_img" :src="returnMemberImg()">
                                        <div class="popover_text_list">
                                            <div class="popover_nickname_list">
                                                <div class="nick_text">{{ memberData.mb_nick }}</div>
                                                <div class="pop_curator" v-if="memberData.g_idx=== 2">
                                                    <span>Curator</span></div>
                                                <div class="pop_curator second_color" v-if="memberData.mb_premium===1">
                                                    <span>Special</span></div>
                                            </div>
                                            <div class="popover_nickname2">
                                                <img class="pn_img" src="@/assets/image/main/heart1.png">
                                                <div>{{ memberData.likeCnt }}</div>
                                                <div>ITEM</div>
                                                <div>{{ memberData.itemCnt }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popover_bottom">
                                    <div>
                                        {{ memberData.mb_introduce }}
                                    </div>
                                </div>
                            </div>
                        </el-popover>


                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <div class="like_img" :class="{'active': isMyLike(data)}"
                                 @click="likeItem(data.i_idx)"></div>
                            <div class="icon_count" @click="likeItem(data.i_idx)">{{ data.im_like }}</div>
                        </div>
                        <div class="icon_wrap" @click="movePage(data)">
                            <img class="icon eye" src="@/assets/image/main/eye_empty.png" alt="">
                            <div class="icon_count">{{ data.im_real_hit }}</div>
                        </div>
                    </div>
                    <div class="card_title card_tit_home" @click="movePage(data)">{{ data.i_name }}</div>
                    <div class="premium" v-if="checkPremium(data)" @click="movePage(data)">Premium</div>
                    <div class="item_status time" @click="movePage(data)">{{ returnItemType(data) }}</div>
                    <div class="item_price" @click="movePage(data)">
                        <!--                        <img class="item_small_logo" src="@/assets/image/main/small_logo.png" alt="">-->
                        <span class="price">{{ returnCommas(data.i_price) }} Mg </span><br/>
                        <span class="item_small">({{ returnMingle(data.i_price) }})</span>
                    </div>
                </div>
            </li>
            <li v-if="itemList.length === 0">
                <div class="empty_wrap">
                    <div class="img_box"></div>
                    <div class="text_box">{{ $t('no_default_list') }}</div>
                </div>
                <!--                <div>리스트가 존재하지 않습니다.</div>-->
            </li>
        </ul>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import LikeComponent from "@/components/like/LikeComponent";
import priceMixins from "@/mixins/priceMixins";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemHomeListComponent",
    mixins: [itemUtil, alertMixins, imageOption, dateMixins, imageResizeMixins, priceMixins],
    components: {LikeComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemType: '',
    },
    data() {
        return {
            listLayout: '',
            listApiParamSet: '',
            getFunc: '',
            itemList: {},
            categoryName: 'All',
            categoryType: '',
            memberData: {},
            memberCheck: false,
            currentTime: '',

            isGetData: false,

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
        this.initConfig();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            this.getHomeData();
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let config = this.returnItemTemplateConfig(this.itemType);
            this.listLayout = config.listLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;

            this.getHomeData();
        },
        setData(key, value) {
            this[key] = value;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        getHomeData() {
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if (this.categoryType !== '') {
                params.ic = [this.categoryType];
            }
            try {
                this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.itemList = res.List
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        changeCategory(data) {
            if (data === '') {
                this.categoryType = '';
                this.categoryName = 'All'
            } else {
                this.categoryType = data.node_id;
                this.categoryName = this.returnCategoryName(data);
            }
            this.getHomeData();
        },
        returnCategory(data) {
            let itemIndex = this.itemCategoryList.findIndex(category => category.node_id === data);
            if (itemIndex > -1) {
                return this.returnCategoryName(this.itemCategoryList[itemIndex]);
            }
        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, )}`;
                // return data.ItemFile.Item[0].org_url;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }

            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
            return 'http://testimage.minglemint.com/profile/no_image.png?w=40&h=40&f=png'
        },

        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        returnCategoryName(data) {
            if (data.node_id === 2) {
                return 'Art'
            } else if (data.node_id === 3) {
                return 'Photo'
            } else if (data.node_id === 4) {
                return 'Video'
            } else if (data.node_id === 5) {
                return 'Music'
            } else if (data.node_id === 9) {
                return 'Collectibles'
            } else if(data.node_id === 178){
                return 'Prompt'
            }
        },
        returnCommas(x = 0) {
            if (util.isEmpty(x)) {
                return 0;
            }
            return util.Number.numFormat(x);
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/openmarket/${data.i_sku}`);
            }

        },
        isMyLike(data) {
            return data?.Like?.myLike
        },
        likeItem(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.i_idx = key
                this.$api.$item.itemLike(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.likeAfterFunc('add', key);
                    } else if (res.Info.type === 2) {
                        this.likeAfterFunc('sub', key);
                    }
                })
            }
        },
        likeAfterFunc(type, key) {
            let likeIndex = this.itemList.findIndex(item => item.i_idx === key);

            if (likeIndex > -1) {
                console.log(this.itemList[likeIndex])
                console.log(this.itemList[likeIndex].im_like)
                let likeCnt = Number(this.itemList[likeIndex].im_like)
                if (type === 'add') {
                    this.itemList[likeIndex].im_like = likeCnt + 1;
                } else if (type === 'sub') {
                    this.itemList[likeIndex].im_like = likeCnt - 1;
                }
                this.itemList[likeIndex].Like.myLike = !this.itemList[likeIndex].Like.myLike;
                this.$forceUpdate();
            }
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },
        checkCollect(data) {
            return !util.isEmpty(data.Collection)
        },
        moveCollect(data) {
            if (!util.isEmpty(data.Collection)) {
                this.$router.push(`/collect/${data.Collection.c_idx}`)
            }
        },
        returnCollectLike(data) {
            if (!util.isEmpty(data.Likes)) {
                return data.Likes.cnt
            }
            return 0
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
