<template>
    <div class="middle_wrap">
        <el-input class="el_info_input pw" type="tel"
                  :placeholder="$t('input_price')" v-model="price" @input="inputNumber('price',price)">
            <template slot="append">{{$t('won')}}</template>
        </el-input>
        <!--       비밀번호 체크 -->
        <div class="validation pw" v-if="validation.hasError('price')">
            {{ validation.firstError('price') }}
        </div>
        <div class="confirm_btn_wrap">
            <button class="btn save" @click="save()">{{ $t('save') }}</button>
            <button class="btn cancel" @click="afterMethod()">{{ $t('cancel') }}</button>
        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import priceMixins from "@/mixins/priceMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    name: "PointDepositComponent",

    mixins: [alertMixins, priceMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        afterMethod: {default: () => {}, type:Function,},
    },
    data() {
        return {
            price: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {

    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        checkValidator() {
            return this.$validate(['price']);
        },
        inputNumber(type, price) {
            let number = this.returnMaxPrice(price);
            this[type] = util.Number.numFormat(number);
        },
        async save() {
            let success = await this.checkValidator();
            if(!success) {
                return false;
            }
            this.writeDeposit();
        },
        writeDeposit() {
            let params = {
                d_deposit: this.returnNumber(this.price),
            }
            try {
                this.$api.$point.createDeposit(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.createAlert({
                            content: this.$t('deposit_success_text'),
                            hide: () => {
                                this.afterMethod();
                            }
                        })
                    }else{

                    }
                })
            }catch (e) {
                console.log(e);
            }
        }
    },
    watch: {},
    validators: {
        price: function (value) {
            return Validator.value(value)
                .required(this.$t('required_deposit1'))
                .custom(() => {
                    let num = util.Number.returnNumber(value);
                    if(num == 0) {
                        return this.$t('required_deposit1');
                    }
                    if(num < 1000 || num % 1000 != 0) {
                        return this.$t('deposit_price_err1');
                    }
                })
        },
    },
}
</script>

<style scoped>

</style>
