<template>
    <div>
        <member-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter" :sortData="filterSet.sort" :filterSet="filterSet"></member-filter-link>
        <member-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" v-if="List.length === 0"></member-empty-link>
        <member-list-link :listLayout="listLayout" :member-data="List" :paginationData="filterSet.page"
                          v-else></member-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import MemberListLink from "@/components/member/MemberListLink";
import MemberFilterLink from "@/components/member/MemberFilterLink";
import MemberEmptyLink from "@/components/member/MemberEmptyLink";
import PaginationLink from "@/components/member/PaginationLink";
import alertMixins from "@/mixins/alertMixins";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import {mapState} from "vuex";

const {filterConfig} = memberUtil;

export default {
    name: "MemberListComponent",
    mixins: [memberUtil, alertMixins, filterMixins, listMixins],
    components: {
        MemberFilterLink,
        MemberEmptyLink,
        MemberListLink,
        PaginationLink
    },
    inject: [],
    provide() {
        return{
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setMemberListData: this.setData,
        }
    },
    props: {
        memberType: '',
        categoryKey: {default: 0},
        memberKey: '',
    },
    data() {
        return{
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig);
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('getMemberList',this.getList);
        EventBus.$on('likeMember',this.likeMember);
        EventBus.$on('memberListChangeSaveFilter', () => {this.changeSaveFilter(this.parserType)});
    },
    beforeDestroy() {
        EventBus.$off('getMemberList')
        EventBus.$off('likeMember')
        EventBus.$off('memberListChangeSaveFilter')
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        setData(type, value) {
            this[type] = value;
        },
        initConfig(){
            let memberConfig = this.returnMemberConfig(this.memberType);
            this.listLayout = memberConfig.listLayout;
            this.filterLayout = memberConfig.filterLayout;
            this.emptyLayout = memberConfig.emptyLayout;
            this.paginationLayout = memberConfig.paginationLayout;
            this.getFunc = memberConfig.getFunc;
            this.listApiParamSet = memberConfig.listApiParamSet;
            this.keyName = memberConfig.keyName;
            if (memberConfig.pageRow && memberConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = memberConfig.pageRow;
                this.filterSet.page.size = memberConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if (Number(this.categoryKey) !== 0) {
                params.ic = [this.categoryKey];
            }
            if (this.memberKey !== '' || !util.isEmpty(this.memberKey)) {
                params[this.keyName] = this.memberKey
            }
            let listOptions = {
                url: this.$api.$member[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = memberConfig.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },
        movePage(url){
            this.$router.push(url);
        },
        likeMember(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.follow_id = key
                this.$api.$member.toggleLike(params).then(res => res.Data.Result).then(res => {
                    if (res.List.type === 'create') {
                        this.likeAfterFunc('add', key);
                    } else if (res.List.type === 'delete') {
                        if(this.memberType === 'interestCreator'){
                            this.likeListAfterFunc(key);
                        }else{
                            this.likeAfterFunc('sub', key);
                        }
                    }
                })
            }
        },
        likeAfterFunc(type, key) {
            let likeIndex = this.List.findIndex(data => data.mb_no === Number(key));
            if (likeIndex > -1) {
                if (type === 'add') {
                    this.List[likeIndex].likeCnt = this.List[likeIndex].likeCnt + 1;
                } else if (type === 'sub') {
                    this.List[likeIndex].likeCnt = this.List[likeIndex].likeCnt - 1;
                }
                this.List[likeIndex].likeStatus = !this.List[likeIndex].likeStatus;
                this.$forceUpdate();
            }
        },
        likeListAfterFunc(key) {
            let likeIndex = this.List.findIndex(data => data.mb_no === Number(key));
            if (likeIndex > -1) {
                this.List.splice(likeIndex, 1);
                this.filterSet.page.total = this.filterSet.page.total - 1;
                if (this.List.length < this.filterSet.page.total) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.page = this.filterSet.page.page * this.filterSet.page.size;
                    params.sp = 1;
                    this.$api.$member[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if (res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            }
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
