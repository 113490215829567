<template>
    <div class="iac_wrap">
        <div class="item_add_title">{{$t('add_item')}}</div>
        <div class="item_add_sub">{{$t('add_item_collection')}}</div>
        <gallery-write-component :galleryType="'galleryItemAdd'" :galleryKey="galleryKey"></gallery-write-component>
<!--        <item-list-component :item-type="'itemAdd'"></item-list-component>-->
    </div>
</template>

<script>
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";
// import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "ItemAdd",
    mixins: [],
    components: {
        GalleryWriteComponent,
        // ItemListComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            galleryKey: Number(this.$route.params.idx),
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
