<template>
    <div class="pm_list_wrap">
<!--        <div class="pm_text">-->
<!--            <span class="pm_premium">Premium</span>-->
<!--            &lt;!&ndash;            <span class="pm_market">{{ $t('market') }}</span>&ndash;&gt;-->
<!--        </div>-->
        <primium-market-banner-component></primium-market-banner-component>
        <div class="pm_tap_wrap">

            <button class="pm_tap" :class="{ active : tab === '0'}"
                    @click="changeTab('0')">{{ $t('all') }}
            </button>
            <button class="pm_tap" :class="{ active : tab === `${data.node_id}`}" v-for="data in itemCategoryList"
                    :key="`category${data.node_id}`"
                    @click="changeTab(`${data.node_id}`)">{{ returnCategoryName(data) }}
            </button>
        </div>
        <div class="pm_best_item_text">
          BEST NFT ITEMS
        </div>
        <div v-if="tab === `0`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `2`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `3`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `4`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `5`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `9`">
            <div class="pm_thumbnail_box">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <filter-modal></filter-modal>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import FilterModal from "@/components/modal/FilterModal";
import ItemListMarketTopComponent from "@/components/item/itemListMarketTopComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import PrimiumMarketBannerComponent from "@/components/banner/PrimiumMarketBannerComponent";

export default {
    name: "PremiumMarket",
    metaInfo: {
        title: `프리미엄(Premium)`,
        titleTemplate: '%s | 밍글민트(Minglemint NFT Marketplace)',
    },
    mixins: [],
    components: {
        PrimiumMarketBannerComponent,
        ItemListMarketTopComponent,
        ItemListComponent,
        FilterModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('0');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnCategoryName(data) {

            if (data.node_id === 2) {
                return this.$t('art');
            } else if (data.node_id === 3) {
                return this.$t('picture');
            } else if (data.node_id === 4) {
                return this.$t('video');
            } else if (data.node_id === 5) {
                return this.$t('music');
            } else if (data.node_id === 9) {
                return this.$t('collections');
            }else if(data.node_id === 178){
                return this.$t('prompt')
            }
        },
    },
    watch: {
        // "$route.fullPath": {
        //     deep: true,
        //     handler: function (val, oldVal) {
        //         if (this.tab != this.$route.query.tab) {
        //             this.changeTab(this.$route.query.tab);
        //         }
        //     }
        // },
        "$route.path": {
            deep: true,
            immediate: true,
            handler: function (val, oldVal) {
                if (this.$route.path.indexOf('/premiummarket') > -1) {
                } else {
                }
            },
        }
    },
}
</script>

<style scoped>

</style>