<template>
  <modal name="filter-modal" class="filter-modal"
         :width="`350px`" :height="`500px`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div>
      <component :is="component" v-if="component"/>
    </div>
  </modal>
</template>

<script>
export default {
  name: "FilterModal",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    modalLayout: '',
  },
  data() {
    return {
      component: null,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
    loader() {
      if (!this.modalLayout) {
        return null
      }
      return () => import(`@/template/modalFilter/${this.modalLayout}`)
    },
  },
  methods: {
    handlerBeforeOpen(event) {
      this.modalLayout = event.params.modalLayout;
      this.loader()
          .then(() => {
            this.component = () => this.loader()
          })
          .catch(() => {
            this.component = () => import('@/template/modalFilter/ModalFilterDefaultLayout')
          })
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
    },
    modalClose() {
      this.$modal.hide('filter-modal');
    },

  },
  watch: {},
}
</script>

<style scoped>

</style>