<template>
    <div>
        <point-withdraw-link v-for="writeLayout in writeLayouts" :key="`point${writeLayout}`"
                             :writeLayout="writeLayout"></point-withdraw-link>
    </div>
</template>

<script>
import PointWithdrawLink from "./PointWithdrawLink";
import pointUtil from "./pointUtil";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import pointValidator from "@/mixins/validators/pointValidator";
import {getStorageToken} from "@/mixins/Auth";

export default {
    name: "PointWithdrawComponent",
    mixins: [pointUtil, alertMixins, pointValidator],
    components: {PointWithdrawLink},
    inject: [],
    provide() {
        return {
            pointSetData: this.setData
        }
    },
    props: {
        pointType: '',
    },
    data() {
        return {
            writeLayouts: [],
            parsingData: {},
            writeFunc: '',
            writeApiParamSet: {},
            definePoint: {},
            listApiParamSet: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.getDefinePoint();
    },
    mounted() {
        EventBus.$on('withdraw', this.checkValidator)
    },
    beforeDestroy() {
        EventBus.$off('withdraw')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnPointConfig(this.pointType);
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.writeLayouts = config.writeLayouts;
            this.parsingData = config.parsingData;
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
        },
        getDefinePoint() {
            EventBus.$emit('changeLoading', true);
            let params = Object.assign({}, this.listApiParamSet);
            try {
                this.$api.$point[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if (res.Info.type == 1) {
                        this.definePoint = res.List[0];
                    }
                    EventBus.$emit('changeLoading', false);
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
        setData(key, value) {
            this[key] = value
        },
        checkValidator() {
            EventBus.$emit('apiLoading', true);
            let validatorArr = Object.keys(this.parsingData);
            this.$validate(validatorArr).then(success => {
                if (success) {
                    if (Number(this.definePoint.pd_condition) > this.point) {
                        this.withdrawReturnType('lack');
                    } else if (this.point % 100 != 0) {
                        this.withdrawReturnType('min_mingle');
                    } else {
                        this.withdraw();
                    }
                } else {
                    EventBus.$emit('apiLoading', false);
                }
            })
        },
        withdraw() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                params[this.parsingData[dataKey]] = this[dataKey];
            })
            try {
                this.$api.$point[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if (res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.withdrawReturnType(res.Info.type);
                        return false;
                    } else {
                        this.createAlert({
                            content: this.$t('withdraw_success1', {point: util.Number.numFormat(this.point)}),
                            hide: () => {
                                let token = getStorageToken();
                                this.$store.dispatch('userInfo/setUserInfo', {token});
                                this.$router.push('/mypage/point/history?backUrl=withdraw');
                            }
                        })
                    }
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
        withdrawReturnType(type) {
            if (type === 'empty') {
                this.errorAlert(`${this.$t('withdraw_err1')}`);
            } else if (type === 'lack') {
                this.errorAlert(`${this.$t('withdraw_err2', {point: util.Number.numFormat(this.definePoint.pd_condition)})}`);
            } else if (type === 'min_mingle') {
                this.errorAlert(`${this.$t('Mg_500_msg')}`);
            } else if (type === -804) {
                this.errorAlert(`${this.$t('withdraw_err2', {point: util.Number.numFormat(this.definePoint.pd_condition)})}`);
            } else if (type === -103) {
                this.errorAlert(`${this.$t('withdraw_err3')}`);
            } else {
                this.errorAlert(`${this.$t('withdraw_err4')}`);
            }
            EventBus.$emit('apiLoading', false);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
