<template>
    <div class="">
        <utility-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                          :sortData="filterSet.sort"></utility-filter-link>
        <utility-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList"
                         v-if="List.length === 0"></utility-empty-link>
        <utility-list-link :listLayout="listLayout" :utilityData="List" :paginationData="filterSet.page"
                        v-else></utility-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"></pagination-link>
    </div>
</template>

<script>
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";
import UtilityUtil from "@/components/utility/UtilityUtil";
import UtilityListLink from "@/components/utility/link/UtilityListLink";
import UtilityEmptyLink from "@/components/utility/link/UtilityEmptyLink";
import UtilityFilterLink from "@/components/utility/link/UtilityFilterLink";
import PaginationLink from "@/components/utility/link/PaginationLink";

const {filterConfig} = UtilityUtil;

export default {
    name: "UtilityListComponent",
    mixins: [UtilityUtil, alertMixins, filterMixins, listMixins],
    components: {
        UtilityListLink,
        UtilityEmptyLink,
        UtilityFilterLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setItemListData: this.setData,
        }
    },
    props: {
        utilityType: '',
        collectKey: '',
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
            pageRow: '',
            paginationType: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
    },
    beforeDestroy() {

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let config = this.returnUtilityConfig(this.utilityType);
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.keyName = config.keyName;
            this.pageRow = config.pageRow
            this.paginationType = config.paginationType;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if ((this.collectKey !== '' || !util.isEmpty(this.collectKey)) && !util.isEmpty(this.keyName)) {
                params[this.keyName] = this.collectKey
            }

            let listOptions = {
                url: this.$api.$utility[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
