<template>
  <div class="bid_main_wrap">
    <div class="bid_content_wrap">
      <div class="bid_title_wrap">
        <h1 class="title">{{ $t('bid') }}</h1>
      </div>

      <div class="content_wrap">
        <div class="img_wrap">
          <div class="b_img">
            <img src="@/assets/image/main/bid_img1.jpg"/>
          </div>
          <swiper class="small_img_wrap" ref="swiper" :options="swiperOption2" :slidesPerView="'auto'">
            <swiper-slide class="s_img">
              <img src="@/assets/image/main/bid_img2.jpg"/>
            </swiper-slide>
            <swiper-slide class="s_img">
              <img src="@/assets/image/main/bid_img3.jpg"/>
            </swiper-slide>
            <swiper-slide class="s_img">
              <img src="@/assets/image/main/bid_img4.jpg"/>
            </swiper-slide>
            <swiper-slide class="s_img">
              <img src="@/assets/image/main/bid_img5.jpg"/>
            </swiper-slide>
          </swiper>
        </div>
        <div class="inform_wrap">
          <div class="inform_header">
            <div class="inform_h_header">
              <ul class="thumb_img">
                <li>
                  <el-popover
                      :open-delay="500"
                      :offset="0"
                      :append-to-body="true"
                      :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                      trigger="click"
                      popper-class="bid_popover">
                    <img slot="reference" src="@/assets/image/main/bid_thumb1.png">
                    <div class="popover_wrap">
                      <div class="popover_top">
                        <!--창작자-->
                        <div class="popover_creator">
                          {{ $t('k_creator') }}
                        </div>
                        <!--소유자-->
                        <!--큐레이터-->
                        <div class="popover_nickname">
                          <img class="nickname_img" src="@/assets/image/main/select_profile.png">
                          <div class="popover_text_list">
                            <div class="pop_curator"><span>{{ $t('en_curator') }}</span></div>
                            <div class="pop_curator second_color"><span>{{ $t('en_special') }}</span></div>
                            <div class="popover_nickname_list">
                              <div class="nick_text">{{ $t('en_NickName') }}</div>

                            </div>
                            <div class="popover_nickname2">
                              <img class="pn_img" src="@/assets/image/main/heart1.png">
                              <div>251</div>
                              <div>ITEM</div>
                              <div>123</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="popover_bottom">
                        <div>
                          {{$t('info_memo')}}<br>
                          영역 이상일 경우 말줄임표 처리로 작업합니다.
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </li>

                <li>
                  <el-popover
                      :open-delay="500"
                      :offset="0"
                      :append-to-body="true"
                      :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                      trigger="click"
                      popper-class="bid_popover">
                    <img slot="reference" src="@/assets/image/main/bid_thumb2.png">
                    <div class="popover_wrap">
                      <div class="popover_top">

                        <!--소유자-->
                        <div class="popover_creator">
                          {{ $t('owner') }}
                        </div>
                        <div class="popover_nickname">
                          <img class="nickname_img" src="@/assets/image/main/select_profile.png">
                          <div class="popover_text_list">
                            <div class="pop_curator"><span>{{ $t('en_curator') }}</span></div>
<!--                            <div class="pop_curator second_color"><span>{{ $t('en_special') }}</span></div>-->
                            <div class="popover_nickname_list">
                              <div class="nick_text">{{ $t('en_NickName') }}</div>
                            </div>
                            <div class="popover_nickname2">
                              <img class="pn_img" src="@/assets/image/main/heart1.png">
                              <div>251</div>
                              <div>ITEM</div>
                              <div>123</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="popover_bottom">
                        <div>
                          자기소개 노출<br>
                          영역 이상일 경우 말줄임표 처리로 작업합니다.
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </li>
                <li>
                  <el-popover
                      :open-delay="500"
                      :offset="0"
                      :append-to-body="true"
                      :popper-options="{
                          boundariesElement: 'body',
                          gpuAcceleration: true,
                          positionFixed: true,
                          preventOverflow: true,}"
                      trigger="click"
                      popper-class="bid_popover no_profile_popover">
                    <img slot="reference" src="@/assets/image/main/bid_thumb3.png">
                    <div class="popover_wrap">
                      <div class="popover_top no_profile">


                        <!--                        큐레이터-->
                        <div class="popover_creator no_profile_creator inline">
                          {{ $t('en_curator') }}
                        </div>
                        <div class="popover_nickname no_profile_nickname">
                          <img class="nickname_img no_profile_img" src="@/assets/image/main/no_profile.png">
                          <div class="popover_text_list no_profile_text">
                            <div class="popover_nickname_list">
                              <div class="nick_text no_profile_text">정보없음</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="popover_bottom no_profile_bottom">
                        <div class="pop_pover_div no_profile_div">
                          창작자 정보가 없습니다.
                        </div>
                      </div>
                    </div>
                  </el-popover>
                </li>
              </ul>
              <div class="time_remain">
                <span>23D 11H 34M 12S</span>
              </div>
            </div>
            <div class="header_title">
              <h3 class="title_left">{{ $t('picture') }}</h3>
              <h3 class="title_right">{{ $t('input_subject') }}</h3>
            </div>
          </div>
          <div class="inform_content">
            <div class="content_top">
              <ul>
                <li>
                  <h3 class="sub_title">{{ $t('mini_bid') }}</h3>
                  <span>700,000 Mg</span>
                </li>
                <li>
                  <h3 class="sub_title">{{ $t('max_bid') }}</h3>
                  <span>700,000 Mg</span>
                </li>
                <li>
                  <h3 class="sub_title">{{ $t('i_buy') }}</h3>
                  <span>2,000,000 Mg</span>
                </li>
              </ul>
            </div>

            <div class="content_bottom">
              <div class="bid_amount">
                <h3 class="content_name">{{ $t('a_bid') }}</h3>
                <input type="number" placeholder="100,000">
              </div>
              <ul>
                <li class="commission_btn">
                  <h3 class="content_name" @click="openModal('commission-info-modal')">{{ $t('f_tax') }}</h3>
                  <span>10,000 Mg</span>
                </li>
                <li>
                  <h3 class="content_name">{{ $t('f_bid') }}</h3>
                  <span class="color">110,000 Mg</span>
                </li>
              </ul>
            </div>
            <div class="search_item">
              <el-checkbox v-model="termsCheck" @click="changeCheck">
                <span class="item_text_span bold" @click="openModal('terms-modal', 'product')">{{$t('t_e_item')}}</span>
                <span class="ql-editor" v-html="$t('agree_terms2')"></span>

<!--                <span class="item_text_span ">{{$t('agree_terms2')}}</span>-->
              </el-checkbox>
            </div>
<!--            <div class="search_item">-->
<!--              <label class="item_text">-->
<!--                <div :class="{ active : isActive}" @click="isActive = !isActive" class="check_box_btn"></div>-->
<!--                <span class="item_text_span bold" @click="openModal('terms-modal', 'auctionBuy')">{{-->
<!--                    $t('terms_bid')-->
<!--                  }}</span>-->
<!--                <span class="item_text_span ">{{ $t('agree_terms2') }}</span>-->
<!--              </label>-->
<!--            </div>-->
            <div class="btn_wrap">
              <button class="cancel">{{ $t('cancel') }}</button>
              <button class="gobid">{{ $t('bid') }}</button>
            </div>
          </div>
        </div>
      </div>

    </div>
    <commission-info-modal></commission-info-modal>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import CommissionInfoModal from "@/components/modal/CommissionInfoModal";
import BidLink from "@/components/bid/BidLink";
import bidUtil from "@/components/bid/bidUtil";

export default {
  name: "BidComponent",
  mixins: [bidUtil],
  components: {
    CommissionInfoModal,
    BidLink,
    Swiper,
    SwiperSlide,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {
    bidType: '',
  },
  data() {
    return {
      isActive: false,
      layout: '',
      visible: false,
      swiperOption: {
        slidesPerView: 4,
        spaceBetween: 8,
        grabCursor: true,
        autoWidth: true,
        loop: true,
      },
      swiperOption2: {
        slidesPerView: 4,
        spaceBetween: 8,
        grabCursor: true,
        autoWidth: true,
        loop: true,
      }
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.initConfig();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    initConfig() {
      let bidConfig = this.returnBidConfig(this.bidType);
      this.layout = bidConfig.layout;
    },
    openModal(name, type) {
      this.$modal.show(name, {termsType: type});
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
