<template>
    <component :is="component" :galleryData="galleryData" v-if="component"></component>
</template>

<script>
export default {
    name: "GalleryWriteLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        writeLayout: '',
        galleryData: {default: () => {}},
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@/template/gallery/write/GalleryWriteDefaultLayout')
            })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.writeLayout) {
                return null
            }
            return () => import(`@/template/gallery/write/${this.writeLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
