<template>
    <div class="unsubscribe_main_wrap">
        <div class="main_title">{{ $t('unsubscribe') }}</div>
        <div class="main_sub_title" v-html="$t('used_id', {nick : UserInfo.mb_id}) + '</br>' + $t('unsub_confirm2')">
            <!--      {{$t('used_id', {nick : UserInfo.mb_id})}}<span>({{ UserInfo.mb_id }})</span>{{$t('unsub_confirm1')}}<br>-->
            <!--      {{$t('unsub_confirm2')}}-->
        </div>
        <div class="terms_content">
            <div class="terms_msg">
                <terms-component :termsType="termsType"></terms-component>
            </div>
        </div>
        <div class="search_item">
            <div class="checkbox" :class="{active: termCheck}" @click="changeCheck('termCheck')"
                 id="termbox" :checked="termCheck"></div>
            <label class="check_term" for="termbox" @click="changeCheck('termCheck')">
                {{ $t('agree_terms3') }}
            </label>
        </div>

        <div class="item_title">{{ $t('unsub_reason') }}</div>
        <div class="reason_content">
            <textarea :placeholder="$t('unsub_reason_msg')" v-model="reasongMsg"></textarea>
        </div>
        <div v-if="returnSocialType() === 'email'">
            <div class="item_title">{{ $t('check_pwd') }}</div>
            <div class="password_input" v-if="returnSocialType() === 'email'">
                <el-input type="password" v-model="inputPassword" @input="setData('inputPassword',inputPassword)"/>
            </div>


            <!--    <div class="validation" v-if="!termCheck && !stepCheck">{{ $t('error_msg3') }}</div>-->
            <!--    <div class="validation" v-if="reasongMsg === '' && !stepCheck">{{ $t('error_msg4') }}</div>-->
            <div class="validation" v-if="inputPassword === '' || !termCheck ">{{ errorMsg }}</div>
        </div>
        <div class="unsubscribe_btn" @click="submit()">
            <button>{{ $t('unsubscribe') }}</button>
        </div>
    </div>

</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";
import TermsComponent from "@/components/terms/TermsComponent";
import {logout} from "@/mixins/Auth";
import {naverDisconnect, naverService} from "@/plugins/naverService";
import {kakaoService} from "@/plugins/kakaoService";
import axios from "axios";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Vue from "vue";
import {setAuthInHeader} from "@/api/request";

export default {
    name: "UnsubscribeComponent",
    mixins: [alertMixins, memberValidator, AuthMixins],
    components: {
        TermsComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            termCheck: false,
            inputPassword: '',
            reasongMsg: '',
            errorMsg: '',
            termsType: 'leave',
            memberType: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.memberType = this.returnSocialType();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
        },
        setData(key, value) {
            this[key] = value;
        },
        movePage(url) {
            this.$router.push(url);
        },
        submit() {
            this.errorMsg = '';
            if (!this.termCheck) {
                this.errorMsg = this.$t('error_msg3');
            } else if (this.inputPassword === '' && this.memberType === 'email') {
                this.errorMsg = this.$t('write_pwd');
            } else if (this.reasongMsg === '') {
                this.errorMsg = this.$t('error_msg4');
            }
            else {
                this.quitConfirm()
            }
        },
        quitConfirm() {
            this.createConfirm({
                content: this.$t('quit_confirm'),
                confirm: () => {
                    this.runQuit()
                }
            })
        },
        memberQuit() {
            let params = {
                mo_reason: this.reasongMsg,
            }
            if (this.memberType === 'email') {
                params.mb_password = this.inputPassword;
            }

            try {
                this.$api.$member.deleteMember(params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('apiLoading', false);
                    let result = this.quitResultType(res.Info.type)
                    if (result === true) {
                        Vue.$localStorage.clear();
                        setAuthInHeader('');
                        this.$store.dispatch('userInfo/initUserInfo', {});
                        this.movePage('/auth/unsubscribe/result');
                    } else {
                        logout();
                        this.errorAlert(result)
                    }
                })
            } catch (e) {

            }
        },
        runQuit() {
            this.memberType = this.returnSocialType();
            console.log(this.memberType)
            if (this.memberType === 'naver') {
                this.naverQuit();
            } else if (this.memberType === 'kakao') {
                kakaoService().disconnect(this.kakaoQuit());
            } else if (this.memberType === 'facebook') {
                this.facebookQuit();
            } else if(this.memberType === 'google') {
                this.googleDisconnect();
            } else if (this.memberType === 'apple') {
                this.appleQuit();
            } else {
                this.memberQuit();
            }
        },
        appleQuit() {
            try {
                let params = {
                    token: this.UserInfo.Social.apple_aos_refresh_token
                }
                this.$api.$auth.deleteApple(params).then(res => res.Data.Result).then(res => {
                    this.memberQuit()
                })
            } catch (e) {
                console.log(e)
            }
        },
        naverQuit() {
            let accessToken = this.UserInfo.Social.access_token;
            let refreshToken = this.UserInfo.Social.refresh_token;
            if (util.isEmpty(accessToken) ) {
                this.memberQuit()
                return false;
            }
            EventBus.$emit('apiLoading', true);
            naverDisconnect(accessToken).then(res => {
                this.memberQuit()
            });
        },
        facebookQuit() {
            EventBus.$emit('apiLoading', true);
            let userId = this.UserInfo.mb_id;
            userId = userId.replace('facebook_', "");

            let accessToken = this.UserInfo.Social.access_token
            try {
                axios.post(`https://graph.facebook.com/${userId}/permissions?method=delete&access_token=${accessToken}`, {}, {headers: {}}).then(res => {
                    if (res.data.success) {
                        this.memberQuit();
                    } else {
                        // this.reFacebookLogin();
                        this.memberQuit();
                    }
                }).catch(e => {
                    console.log(e);
                    // this.reFacebookLogin();
                    this.memberQuit();
                })
            } catch (e) {
                console.log(e);
                // this.reFacebookLogin();
                this.memberQuit();
            }
        },
        kakaoQuit(result) {
            console.log(result)
            this.memberQuit();
        },
        reFacebookLogin() {
            let $this = this;
            getFacebookToken().then(accessToken => {
                let token = $this.$localStorage.get('token');
                $this.$store.dispatch('userInfo/setUserInfo', {token: token});

            })
        },
        googleDisconnect() {
            this.$gAuth.GoogleAuth.disconnect()
            this.memberQuit();
        },
        quitResultType(type) {
            switch (type) {
                case 1 : {
                    return true
                }
                case -281 : {
                    return this.$t('quit_err_bidding')
                }
                case -282 : {
                    return this.$t('quit_err_proceeding')
                }
                case -283 : {
                    return this.$t('quit_err_real_item')
                }
                case -284 : {
                    return this.$t('quit_err_curating')
                }
                case -285 : {
                    return this.$t('quit_err_withdrawing')
                }
                case -286 : {
                    return this.$t('quit_err_hosting_item')
                }
                case -287 : {
                    return this.$t('quit_err_applying_item')
                }
                case -288 : {
                    return this.$t('quit_err_requesting_item')
                }
                case -100 : {
                    return this.$t('no_value')
                }
                case -200 : {
                    return this.$t('quit_err_empty_member')
                }
                case -213 : {
                    return this.$t('pwdErrorMsg1')
                }
                default : {
                    return this.$t('quit_err_default')
                }
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
