let kakaoScript = null;
let kakaoKey = "cbbfa0f3d6504b3b1b8746516da6d5f0";
const scriptUrlKakao = "https://developers.kakao.com/sdk/js/kakao.min.js";

export const kakaoService = () => {
    const initService = () => {
        kakaoScript = document.createElement('script');
        kakaoScript.src = scriptUrlKakao;
        kakaoScript.onload = () => {
            Kakao.init(kakaoKey);
        };
        document.body.appendChild(kakaoScript);
    };
    const setKaKaoBtn = (afterFunc = () => {
    }) => {
        if (kakaoScript === null || !Kakao) {
            kakaoScript = document.createElement('script');
            kakaoScript.src = scriptUrlKakao;
            kakaoScript.onload = () => {
                Kakao.init(kakaoKey);
                Kakao.Auth.createLoginButton({
                    container: '#kakao-login-btn',
                    success: function (authObj) {
                        let accessToken = authObj.access_token;
                        let params = {
                            access_token: accessToken
                        };
                        afterFunc(authObj)
                    },
                    fail: function (err) {
                        console.log(err);
                        afterFunc(err)
                    }
                });
            };
            document.body.appendChild(kakaoScript);
        } else {
            Kakao.Auth.createLoginButton({
                container: '#kakao-login-btn',
                success: function (authObj) {
                    let accessToken = authObj.access_token;
                    afterFunc(authObj)
                },
                fail: function (err) {
                    console.log(err);
                    afterFunc(err)
                }
            });
        }
    }
    const disconnect = (afterFunc = () => {
    }) => {
        if (kakaoScript === null || !Kakao) {
            kakaoScript = document.createElement('script');
            kakaoScript.src = scriptUrlKakao;
            kakaoScript.onload = () => {
                Kakao.init(kakaoKey);
                Kakao.API.request({
                    url: '/v1/user/unlink',
                    success: function (response) {
                        console.log(response);
                        afterFunc(response)
                    },
                    fail: function (error) {
                        console.log(error);
                        afterFunc(error)
                    },
                });
            };
            document.body.appendChild(kakaoScript);
        } else {
            Kakao.API.request({
                url: '/v1/user/unlink',
                success: function (response) {
                    afterFunc(response)
                },
                fail: function (error) {
                    afterFunc(error)
                },
            });
        }
    }
    return {
        initService,
        setKaKaoBtn,
        disconnect,
    }
}
