<template>
    <modal name="share-modal" class="share-modal"
           :width="`650px`" :height="`270px`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="share_wrap">
            <div class="back_btn" @click="modalClose"></div>
            <div class="share_text">
                {{ $t('share') }}
            </div>
            <div class="share_btns">
                <share-link-copy-component :shareData="shareData" :runAfter="modalClose"></share-link-copy-component>

                <share-email-component :shareData="shareData" :runAfter="modalClose"></share-email-component>

                <share-facebook-component :shareData="shareData" :runAfter="modalClose"></share-facebook-component>

                <share-twitter-component :shareData="shareData" :runAfter="modalClose"></share-twitter-component>

                <share-whats-app-component :shareData="shareData" :runAfter="modalClose"></share-whats-app-component>

                <share-kaKao-component :shareData="shareData" :runAfter="modalClose"></share-kaKao-component>

            </div>
        </div>
    </modal>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import ShareLinkCopyComponent from "@/components/share/ShareLinkCopyComponent";
import ShareEmailComponent from "@/components/share/ShareEmailComponent";
import ShareKaKaoComponent from "@/components/share/ShareKaKaoComponent";
import ShareFacebookComponent from "@/components/share/ShareFacebookComponent";
import ShareTwitterComponent from "@/components/share/ShareTwitterComponent";
import ShareWhatsAppComponent from "@/components/share/ShareWhatsAppComponent";

let facebookTitle = '';
export default {
    name: "ShareModal",
    mixins: [alertMixins, imageOption,],
    components: {
        ShareLinkCopyComponent,
        ShareEmailComponent,
        ShareKaKaoComponent,
        ShareFacebookComponent,
        ShareTwitterComponent,
        ShareWhatsAppComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemData: {},
            competitionData: {},
            memberData: {},
            collectData: {},
            shareData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (event.params.hasOwnProperty('itemData')) {
                this.itemData = event.params.itemData;
                this.shareData = {
                    path: this.$route.path,
                    img: this.itemData.ItemFile.Item[0].org_url,
                    title: `${this.itemData.i_name} | 밍글민트(Minglemint NFT Marketplace)`,
                    description: `밍글민트(Minglemint NFT Marketplace)`,
                }
            } else if (event.params.hasOwnProperty('competitionData')) {
                this.competitionData = event.params.competitionData;
                this.shareData = {
                    path: this.$route.path,
                    img: this.competitionData.Poster[0].org_url,
                    title: `${this.competitionData.c_title} | 밍글민트(Minglemint NFT Marketplace)`,
                    description: `밍글민트(Minglemint NFT Marketplace)`,
                }
            } else if (event.params.hasOwnProperty('memberData')) {
                this.memberData = event.params.memberData;
                this.shareData = {
                    path: this.$route.path,
                    img: this.memberData.Files.Profile[0].org_url,
                    title: `${this.memberData.mb_nick} | 밍글민트(Minglemint NFT Marketplace)`,
                    description: `밍글민트(Minglemint NFT Marketplace)`,
                }
            } else if (event.params.hasOwnProperty('collectData')) {
                this.collectData = event.params.collectData;
                this.shareData = {
                    path: this.$route.path,
                    img: this.collectData.CollectionFiles.BgFile[0].org_url,
                    title: `${this.collectData.c_title} | 밍글민트(Minglemint NFT Marketplace)`,
                    description: `밍글민트(Minglemint NFT Marketplace)`,
                }
            } else {
                this.shareData = {
                    path: this.$route.path,
                    img: 'https://image.minglemint.com/profileBack/no_image.jpg',
                    title: `밍글민트(Minglemint NFT Marketplace)`,
                    description: `밍글민트(Minglemint NFT Marketplace)`,
                }
            }

        },
        handlerOpened() {

        }
        ,
        handlerBeforeClose() {
        }
        ,
        modalClose() {
            this.$modal.hide('share-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
