const boardConfig = {
    qna: {
        filterLayout: 'BoardFilterQnaLayout',
        listLayout: 'BoardListQnaLayout',
        emptyLayout: 'BoardEmptyQnaLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getQna',
        listApiParamSet: {
            type: 'post',
            cmt: 1,
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'created_at',
            obs: 'desc',
            la: 1,
        },
        pageRow: 10,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },
    qnaUpdate: {
        writeLayouts: ['BoardWriteQnaLayout'],
        getFunc: 'getQna',
        detailApiParamsSet: {
            type: 'post',
            cmt: 1,
            cmtd: 1,
            wr: 1,
            f: 1,
        },
        keyName: 'wr_id',
        getKeyName: 'id',
        parsingData: {
            title: 'wr_subject',
            content: 'wr_content',
        },
        writeApiParamSet: {
            type: 'post',
            wr_type: 'post',
        },
        writeSuccessType: 1,
        writeFailTypes: {},
        writeFunc: 'updateQna',
        directType: 'back',
        directUrl: '',
        fileInfo: [
            {
                fileName: 'files',
                fileType: 'qna',
            },
        ],
    },
    qnaCreate: {
        writeLayouts: ['BoardWriteQnaLayout'],
        writeFunc: 'createQna',
        writeApiParamSet: {
            type: 'post',
            wr_type: 'post',
        },
        parsingData: {
            title: 'wr_subject',
            content: 'wr_content',
        },
        directUrl: '/qna',
        directType: '',
        writeSuccessType: 1,
        writeFailTypes: {},
        fileInfo: [
            {
                fileName: 'files',
                fileType: 'qna',
            },
        ],
    },
    qnaDetail: {
        detailLayout: 'BoardDetailQnaLayout',
        getFunc: 'getQna',
        detailApiParamsSet: {
            type: 'post',
            cmt: 1,
            cmtd: 1,
            wr: 1,
            f: 1,
        },
        getKeyName: 'id',
        keyName: 'wr_id',

        deleteFunc: 'deleteQna',
        deleteApiParamsSet: {
            type: 'post'
        },
        deleteSuccessType: 1,
        deleteFailTypes: {},
    },
    faq: {
        filterLayout: 'BoardFilterFaqLayout',
        listLayout: 'BoardListFaqLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getFaq',
        listApiParamSet: {
            type: 'post',
            c: 1,
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'wr_seq',
            obs: 'asc',
            la: 1,
        },
        pageRow: 10,
        isLanguage: true,
    },
    faqCategory: {
        getFunc: 'getCategory',
        categoryApiParamSet: {}
    },
    faqDetail: {
        detailLayout: 'BoardDetailFaqLayout',
        getFunc: 'getFaq',
        detailApiParamsSet: {
            type: 'post',
            c: 1,
        },
        getKeyName: 'id',
        keyName: 'wr_id',

        getCategoryFunc: 'getCategory',
        categoryApiParamsSet: {
            na: '', // 카테고리명
            // ip:0,
        },
        isLanguage: true,
    },
    notice: {
        filterLayout: 'BoardFilterDefaultLayout',
        listLayout: 'BoardListNoticeLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getNotice',
        listApiParamSet: {
            type: 'post',
            // ca: 2,
            la: 1,
            ip: 1,
        },
        pageRow: 10,
        isLanguage: true,
    },
    noticeDetail: {
        detailLayout: 'BoardDetailNoticeLayout',
        getFunc: 'getNotice',
        detailApiParamsSet: {
            type: 'post',
        },
        getKeyName: 'id',
        keyName: 'wr_id',
        isLanguage: true,
    }

}
export default {
    methods: {
        returnBoardConfig(type) {
            return boardConfig[type];
        }
    },
    filterConfig: {
        ca: { // faq
            type: 'match',
            classname: 'searchInputOption'
        },
        isa: { //  qna
            type: 'match',
            classname: 'tab'
        },
        ni: {
            type: 'match',
            classname: 'searchInput'
        },
        su: {
            type: 'match',
            classname: 'searchInput'
        },

    }
}
