<template>
  <div class="curator_apply_wrap">
    <div class="curator_apply_title">
      {{ $t('curator_apply1') }}
    </div>
    <curation-application-component></curation-application-component>
<!--    <terms-modal></terms-modal>-->
  </div>
</template>

<script>
import CurationApplicationComponent from "@/components/curation/CurationApplicationComponent";
import TermsModal from "@/components/modal/TermsModal";

export default {
  name: "CurationApply",
  mixins: [],
  components: {
    CurationApplicationComponent,
    TermsModal
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
