<template>
  <component :is="component" v-if="component"/>
</template>

<script>
  export default {
    name: "PointEmptyLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
      return{

      }
    },
    props: {
      emptyLayout: '',
    },
    data() {
      return{
        component: null,
      }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
      this.loader()
              .then(() => {
                this.component = () => this.loader()
              })
              .catch(() => {
                this.component = () => import('@/template/point/empty/PointEmptyDefaultLayout')
              })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
      loader() {
        if (!this.emptyLayout) {
          return null
        }
        return () => import(`@/template/point/empty/${this.emptyLayout}`)
      },
    },
    methods:{

    },
    watch: {

    },
  }
</script>

<style scoped>

</style>