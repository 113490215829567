<template>

    <div class="curator_apply_textarea">
        <el-input class="mb32" type="textarea" id="content" v-model="content" :placeholder="$t('board_write_con')" maxlength="1000" @input="resetErrMsg" :readonly="hasBeforeCurator()"></el-input>
        <div class="curator_apply_upload_wrap" v-if="!hasBeforeCurator()">
            <div class="curator_apply_upload_text">
                <span>{{ $t('apply_curator_text1') }}</span>
            </div>
            <div class="in_block" @click="resetErrMsg">
                <el-upload
                    class="in_block"
                    action=""
                    :limit="5"
                    :file-list="files"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="handleEveryFileChange"
                    :on-remove="fileRemove"
                    :disabled="files.length >= 5"
                >
                    <button class="curator_apply_upload_btn" v-if="files.length < 5">
                        <span class="upload_text">{{ $t('en_upload') }}</span>
                        <span>+</span>
                    </button>
                    <!--                <img v-else src="@/assets/image/main/change_item.png" alt=""
                                         style="width: 93px; height: 74px;">-->
                </el-upload>
            </div>
        </div>
        <div class="curator_apply_file_wrap">
            <div class="curator_apply_file mb10" v-for="(file, index) in files" :key="`file${index}`">
                <span :class="{'no_remove' : hasBeforeCurator()}">{{ file.org_name }}</span>
                <button @click="removeFile(file, index)" v-if="!hasBeforeCurator()"></button>
            </div>
        </div>
        <div class="curator_apply_terms_wrap" v-if="!hasBeforeCurator()">
            <!--      <input type="checkbox" class="terms_check">-->
            <div class="terms_check" :class="{ active : isActive}" @click="changeActive()"></div>
            <span class="terms_text" v-html="$t('apply_curator_text2')">
<!--        <span class="terms_text2" @click="openModal('terms-modal')">큐레이터 신청 규정</span>을 확인하였으며 동의합니다.-->
            </span>
        </div>
        <div class="curator_apply_btn_wrap" v-if="hasBeforeCurator()">
            <button class="curator_btn cancel" @click="openCancelConfirm()">{{ $t('apply_cancel') }}</button>
        </div>
        <div class="curator_apply_btn_wrap" v-else>
            <button class="curator_btn cancel" @click="$router.back()">{{ $t('cancel') }}</button>
            <button class="curator_btn apply" @click="checkValidator">{{ $t('apply1') }}</button>
        </div>
        <div class="check_validator center" v-if="errorMsg != ''">
            <span>{{errorMsg}}</span>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import alertMixins from "@/mixins/alertMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationApplicationComponent",
    mixins: [elFileMixins, alertMixins, awsS3FileUpload],
    components: {

    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {

    },
    data() {
        return{
            content:'',
            isActive: false,

            myCurator: {},
            fileInfo: [
                {
                    fileName: 'files',
                    fileType: 'curator',
                },
            ],
            errorMsg: "",

            isRunning: false,
        }
    },
    beforeRouterEnter() {},
    async created() {
        openTerms = this.openTerms;

        this.getMyCurator();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initData() {
            this.content = "";
            this.files = [];
            this.deleteFiles = [];
            this.isActive = false;
            this.errorMsg = "";
        },
        changeActive() {
            this.isActive = !this.isActive;
            this.resetErrMsg();
        },
        getMyCurator() {
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$curation.getMyCurator().then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if(!util.isEmpty(res.List)) {
                        this.myCurator = res.List[0];
                        this.content = res.List[0].c_context;
                        if(!util.isEmpty(res.List[0].Files)) {
                            let files = res.List[0].Files;
                            this.files = files.filter(file => file.a_idx != null);
                        }
                    }else{
                        this.myCurator = {};
                    }
                })
            }catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
        hasBeforeCurator() {
            return !util.isEmpty(this.myCurator) && !util.isEmpty(this.myCurator.c_idx);
        },
        removeFile(file, index) {
            if(file.hasOwnProperty('a_idx') && !util.isEmpty(file.a_idx)) {
                this.deleteFiles.push(file);
            }
            this.files.splice(index, 1);
        },
        resetErrMsg() {
            this.errorMsg = "";
        },
        checkValidator() {
            if(this.isRunning) {
                return false;
            }
            this.isRunning = false;
            if(!this.isActive) {
                this.errorMsg = this.$t('caption_check');
                this.isRunning = false;
            }else if(util.isEmpty(this.content) || util.isEmpty(this.files)) {
                this.errorMsg = this.$t('caption_check2')
                this.isRunning = false;
            }else{
                this.createCurator();
            }
        },
        createCurator() {
            EventBus.$emit('changeLoading', true);
            let params = {
                c_context: this.content,
            };

            try {
                this.$api.$curation.applicationCurator(params).then(res => res.Data.Result).then(res => {
                    let type = this.returnCreateCurator(res.Info.type);
                    if(type === true) {
                        let key = res.List.c_idx;
                        this.setMultiFileUpload(key, this.fileInfo)
                    }else{
                        EventBus.$emit('changeLoading', false);
                        this.errorAlert(type);
                        this.isRunning = false;
                    }
                })
            }catch (e) {
                console.log(e);
                this.isRunning = false;
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                }
            })
        },
        returnCreateCurator(type) {
            switch (type) {
                case 1:
                    return true;
                case -211:
                    return this.$t('curator_application_err1');
                case -212:
                    return this.$t('curator_application_err2');
                case -251:
                    return this.$t('curator_application_err5');
                case -252:
                    return this.$t('curator_application_err6');
                case -253:
                    return this.$t('curator_application_err7');
                default:
                    return this.$t('curator_application_err3');
            }
        },

        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            this.initData();
            this.createConfirm({
                content: `${this.$t('curator_application_success1')}<br>${this.$t('curator_application_success2')}`,
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.push('/myprofile')
                },
                cancel: () => {
                    this.getMyCurator();
                },
            })
        },

        openCancelConfirm() {
            this.createConfirm({
                content: this.$t('curator_cancel_confirm'),
                confirm: () => {
                    this.runCuratorCancel();
                },
            })
        },
        runCuratorCancel() {
            this.deleteFiles = this.files.slice();

            let params = {
                c_idx: this.myCurator.c_idx,
            };
            try {
                this.$api.$curation.cancelCurator(params).then(res => res.Data.Result).then(res => {
                    let type = this.returnCancelCurator(res.Info.type);
                    if(type === true) {
                        if(util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDelete(this.deleteFiles).then(res => {
                                this.cancelAfter();
                            })
                        }else{
                            this.cancelAfter();
                        }
                    }else{
                        this.errorAlert(type);
                    }
                })
            }catch (e) {
                console.log(e);
            }
        },
        returnCancelCurator(type) {
            switch (type) {
                case 1:
                    return true;
                case -213:
                    return this.$t('curator_cancel_err1');
                case -212:
                    return this.$t('curator_application_err2');
                case -202:
                    return this.$t('curator_cancel_err2');
                default:
                    return this.$t('curator_cancel_err3');
            }
        },
        cancelAfter() {
            this.initData();
            this.getMyCurator();
            this.createAlert({
                content: this.$t('curator_cancel_success'),
            })
        },
        openTerms(name) {
            this.$modal.show(name, {termsType: 'curatorForm'});
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
