<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list">
                <div class="mypage_item_tab" :class="{ active : tab === 'auctionBid'}"
                     @click="changeTab('auctionBid')">
                    {{ $t('bid') }}
                </div>
                <div class="mypage_item_tab" :class="{ active : tab === 'auctionExhibit'}"
                     @click="changeTab('auctionExhibit')">
                    {{ $t('exhibit') }}
                </div>
                <item-list-component :item-type="'myAuction'" v-if="tab === 'auctionBid'"></item-list-component>
                <item-list-component :item-type="'myAuctionExhibit'"
                                     v-else-if="tab === 'auctionExhibit'"></item-list-component>
            </div>
        </div>
        <auction-history-modal></auction-history-modal>
    </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";

export default {
    name: "MyPageAuction",
    mixins: [AuthMixins],
    components: {
        AuctionHistoryModal,
        ItemListComponent,
        MyPageHeaderComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'auctionBid',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if(this.getMemberType() === 'curator') {
            this.$router.replace('/mypage/curation');
            return false;
        }
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('auctionBid');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
