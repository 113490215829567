<template>
    <component :is="component" v-if="component" :sortData="sortData" :filterData="filterData" :filterSet="filterSet"/>
</template>

<script>
export default {
    name: "BoardFilterLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
      filterLayout: {},
      filterData: {
        default: {}
      },
      sortData: {
        default: () => {}
      },
      filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/board/filter/BoardFilterDefaultLayout')
                })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.filterLayout) {
                return null
            }
            return () => import(`@/template/board/filter/${this.filterLayout}`)
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>