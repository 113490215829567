<template>
    <span>
        <button className="google_login_wrap" @click="googleSignIn()">
<!--        <button class="google_login_wrap" @click="movePage('/auth/sns')">-->
            <img src="@/assets/image/main/google.png">
        </button>
      <!--        <button @click="googleDisconnect">구글 탈퇴</button>-->
    </span>
</template>

<script>
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "GoogleLoginComponent",
  mixins: [AuthMixins, alertMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {

  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      //'/snsAuth'
      this.$router.push(url);
    },
    async googleSignIn() {
      console.log(111)
      let googleUser = await this.$gAuth.signIn()
      console.log(222222)
      let isSignIn = this.$gAuth.isAuthorized

      console.log(isSignIn);
      if (isSignIn) {
        this.snsLogin(googleUser);
      }
    },
    snsLogin(data) {
      console.log(data);
      let params = {
        userId: data.Ba
      };
      if (data.hasOwnProperty('wc')) {
        params.code = data.wc.access_token;
      }
      if (data.hasOwnProperty('vc')) {
        params.code = data.vc.access_token;
      }
      if (data.hasOwnProperty('Sc')) {
        params.code = data.Sc.access_token;
      }
      if (data.hasOwnProperty('Oc')) {
        params.code = data.Oc.access_token;
      }
      if (data.hasOwnProperty('Fa')) {
        params.userId = data.Fa;
      }
      if (data.hasOwnProperty('Ga')) {
        params.userId = data.Ga;
      }
      EventBus.$emit('apiLoading', true);
      this.$api.$auth.googleLogin(params).then(res => res.Data.Result).then((res) => {
        if (res.Info.type === 1) {
          this.loginSns(res.List, 'google');
        } else {
          EventBus.$emit('apiLoading', false);
          this.createAlert({
            title: "",
            content: this.$t('error_msg2'),
            btnText: this.$t('yes'),
            confirmBtn: true,
            closeBtn: true,
            hide: () => {
              window.close();
            }
          })
        }
      })
    },

    googleDisconnect() {
      console.log('test')
      this.$gAuth.GoogleAuth.disconnect();
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
