<template>
    <modal name="mypage-nick-modal" class="mypage-modal nick"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <member-write-component :member-type="'updateNick'"></member-write-component>

    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "MypageNickModal",
    mixins: [],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.modalClose();
        },
        modalClose() {
            this.$modal.hide('mypage-nick-modal');
        },
        returnUrl() {
            return this.url
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>