<template>
    <div class="dh_wrap">
        <div class="dh_text">{{ $t('detectionHistory') }}</div>

        <div class="dh_title_text">{{ $t('d_img_title') }}</div>
        <div class="dh_title">{{ itemData.i_name }}</div>
        <div class="dh_result_count_box" v-html="$t('d_result_text', {count: returnNumFormat()})">
            <!--        <span>검출결과 총 <span class="count">N</span>건</span>-->
        </div>
        <detection-list-component :detection-type="'detectionHistory'" :detectionKey="detectionKey"></detection-list-component>
    </div>
</template>

<script>
import DetectionListComponent from "../../components/detection/DetectionListComponent";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "DetectionHistory",
    mixins: [alertMixins],
    components: {DetectionListComponent},
    inject: [],
    provide() {
        return {
            setDetectItemData: this.setDetectItemData,
        }
    },
    props: {},
    data() {
        return {
            detectionKey: Number(this.$route.params.d_idx),
            isShow: false,
            itemData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectItemData(itemData) {
            this.itemData = itemData;
        },
        returnNumFormat() {
            if(util.isEmpty(this.itemData.cnt) || isNaN(this.itemData.cnt)) {
                return 0;
            }
            return util.Number.numFormat(this.itemData.cnt);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
