<template>
    <div class="bid_wrap">
        <item-detail-component :item-type="'itemBid'" :item-key="itemKey"></item-detail-component>
<!--        <terms-modal :termsType="`auctionBuy`"></terms-modal>-->
        <commission-info-modal></commission-info-modal>
    </div>
</template>

<script>
import BidComponent from "@/components/bid/BidComponent";
import TermsModal from "../../components/modal/TermsModal";
import CommissionInfoModal from "@/components/modal/CommissionInfoModal";
import ItemDetailComponent from "@/components/item/ItemDetailComponent";

export default {
    name: "Bid",
    mixins: [],
    components: {
        ItemDetailComponent,
        CommissionInfoModal,
        TermsModal,
        BidComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemKey: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey = this.$route.params.idx;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>