<template>
    <component :is="component" v-if="component"></component>
</template>

<script>
    export default {
        name: "PointWithdrawLink",
        mixins: [],
        components: {
        },
        inject: [],
        provide() {
            return{

            }
        },
        props: {
            writeLayout: '',
        },
        data() {
            return{
                component: null,
            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/point/write/PointWriteDefaultLayout')
                })
        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            loader() {
                if (!this.writeLayout) {
                    return null
                }
                return () => import(`@/template/point/write/${this.writeLayout}`)
            },
        },
        methods:{

        },
        watch: {

        },
    }
</script>

<style scoped>

</style>