let isDev = false;

let config = {
    dev: {
        apiDomain: "https://testapi.minglemint.com",
        launchDomain: "http://115.68.199.206/test",
        bucketOption: {
            BucketName: 'minglemint-dev-real',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        nftBucketOption: {
            BucketName: 'minglemint-dev-real-nft',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        searchElaUrl: '',
    },
    prod: {
        apiDomain: "https://api.minglemint.com",
        launchDomain: "http://115.68.199.206",
        bucketOption: {
            BucketName: 'minglemint-dev',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        nftBucketOption: {
            BucketName: 'minglemint-dev-nft',
            BucketRegion: 'ap-northeast-2',
            BucketIdentityPoolId: 'ap-northeast-2:76a65f97-bc86-46d0-a35a-16574af89e2a',
        },
        searchElaUrl: '',
    },
}


function getConfig(key = 'apiDomain') {
    return isDev ? config.dev[key] : config.prod[key];
}

function setConfig(key, value) {
    if (isDev) {
        config.dev[key] = value;
    } else {
        config.prod[key] = value;
    }
}

let apiDomain = getConfig('apiDomain')
let apiURL = `${getConfig('apiDomain')}/api/`;
let bucketOption = getConfig('bucketOption');
let nftBucketOption = getConfig('nftBucketOption');
let searchElaUrl = getConfig('searchElaUrl')
let launchDomain = getConfig('launchDomain')
let launchUrl = `${getConfig('launchDomain')}/api/`;

function setterApiDomain(domain) {
    setConfig('apiDomain', domain);
    apiDomain = getConfig('apiDomain');
    apiURL = `${getConfig('apiDomain')}/api/`;
    launchDomain = getConfig('launchDomain')
    launchUrl = `${getConfig('launchDomain')}/api/`;
}


export {
    apiURL,
    searchElaUrl,
    apiDomain,
    bucketOption,
    nftBucketOption,
    isDev,
    setterApiDomain,
    launchDomain,
    launchUrl
};
