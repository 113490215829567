<template>
    <div class="creator_detail_wrap">
        <collect-detail-component :collect-type="'collectDetail'" :collect-key="collectKey"></collect-detail-component>
        <div class="wrap-tab-common">
            <div class="tab-common" :class="{'active' : tab === 1}" @click="switchTab(1)">Item</div>
            <div class="tab-common" :class="{'active' : tab === 2}" @click="switchTab(2)">Utility</div>
        </div>
        <item-list-component v-if="tab === 1" :item-type="'collectItem'" :member-key="collectKey"></item-list-component>
        <utility-list-component v-if="tab === 2" :collectKey="collectKey" :utility-type="'utility'"></utility-list-component>
        <share-modal></share-modal>
    </div>
</template>

<script>


import CollectDetailComponent from "@/components/collect/CollectDetailComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import ShareModal from "@/components/modal/ShareModal";
import UtilityListComponent from "@/components/utility/UtilityListComponent";

export default {
    name: "CollectDetail",
    mixins: [],
    components: {UtilityListComponent, ShareModal, ItemListComponent, CollectDetailComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            collectKey: this.$route.params.idx,
            tab: 1,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        switchTab(data) {
            this.tab = data
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

