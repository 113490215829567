<template>
    <div>
        <history-list-component :history-type="'list'" :historyKey="idx" :default-filter="defaultFilter"></history-list-component>
<!--        <history-detail-component historyType="list" :historyKey="idx"></history-detail-component>-->
    </div>
</template>

<script>
import HistoryListComponent from "../../components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
export default {
    name: "HistoryDetail",
    mixins: [],
    components: {
        HistoryListComponent,
        HistoryDetailComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            idx: this.$route.params.idx,
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {sku: this.idx};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
