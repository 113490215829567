<template>
    <div class="pm_list_banner" v-if="checkBanner()" :style="`background-image : url(${returnThumbnail()})`"></div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "PrimiumMarketBannerComponent",
    mixins: [alertMixins, imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            bannerData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getBanner();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getBanner() {
            let params = {
                cid: 1,
                cty: 'premium_market'
            }
            this.$api.$file.getFile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.bannerData = res.List
                } else {
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                }

            })
        },
        checkBanner() {
            return !util.isEmpty(this.bannerData)
        },
        returnThumbnail() {
            if (!util.isEmpty(this.bannerData)) {
                return `${this.bannerData[0].org_url}?${this.setImageOptions(1200, 214, this.returnExtension(this.bannerData[0].org_url))}`
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>