<template>
    <div class="pmu_wrap">
        <div class="pmu_add_title">{{ $t('myitem_enroll') }}</div>
<!--        <div class="pmu_add_sub">{{ $t('s_item_collection') }}{{ $t('my_item') }}<span class="emphasis_font" @click="openModal('curation-terms-modal')">{{ $t('terms_curation') }}</span>{{ $t('follow_this') }}</div>-->
        <div class="pmu_add_sub" v-html="$t('my_item')"></div>
        <div class="pmu_add_sub_msg">{{ $t('c_s_msg') }}</div>
        <item-list-component :item-type="`premiumMarketUpload`" :member-key="UserInfo.mb_no"></item-list-component>
        <curation-terms-modal></curation-terms-modal>
<!--        <terms-modal></terms-modal>-->
        <premium-market-add-modal></premium-market-add-modal>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import CurationTermsModal from "@/components/modal/CurationTermsModal";
import {mapState} from "vuex";
import PremiumMarketAddModal from "@/components/modal/PremiumMarketAddModal";
import TermsModal from "@/components/modal/TermsModal";
export default {
    name: "PremiumMarketUpload",
    mixins: [],
    components: {
        PremiumMarketAddModal,
        CurationTermsModal,
        TermsModal,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    async created() {
      openTerms = this.openTerms;

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
      openTerms(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>