<template>
    <div class="enroll_wrap">
        <div class="enroll_title_wrap">
            <h1 class="title ">{{ $t('item_comp') }}</h1>
        </div>
        <div class="enroll_btn_wrap">
            <div class="enroll_btn item_btn" @click="movePage('/itemcreate')">
                <div class="btn_title ">
                    {{ $t('item') }}
                </div>
                <div class="img_wrap item_img_wrap">
                    <img src="@/assets/image/main/item_img.png" alt="">
                </div>
                <div class="e_btm_text ">
                    <span v-html="$t('e_item_msg1')"></span>
                    <br>
                    <span v-html="$t('e_item_msg2')"></span>
                </div>
            </div>
            <div class="enroll_btn comp_btn" @click="movePage('/competitioncreate')">
                <div class="btn_title ">
                    {{ $t('competition') }}
                </div>
                <div class="img_wrap comp_img_wrap">
                    <img src="@/assets/image/main/comp_img.png" alt="">
                </div>
                <div class="e_btm_text ">
                    <span v-html="$t('e_item_msg3')"></span>
                    <br>
                    <span v-html="$t('e_item_msg4')"></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "Enroll",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>