<template>
    <div class="my_gallery_list_wrap">
        <div class="my_gallery_setting">
            <button class="setting_btn" @click="movePage('/gallery')">{{ $t('gallery_setting') }}</button><br>
        </div>
        <gallery-list-component :gallery-type="'profileGallery'" :defaultFilter="{no: UserInfo.mb_no}"></gallery-list-component>
    </div>

</template>

<script>
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";

export default {
    name: "MyProfileCollection",
    mixins: [AuthMixins],
    components: {
        GalleryListComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            tab: 'galleryMy',
        }
    },
    beforeRouterEnter() {},
    created() {
        // -0--
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        movePage(url){
            this.$router.push(url);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
