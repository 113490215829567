const memberConfig = {
    creator: {
        filterLayout: 'MemberFilterCreatorLayout',
        // filterLayout: 'MemberFilterNoneLayout',
        // listLayout: 'MemberListSearchDetailLayout',
        listLayout: 'MemberListCreatorLayout',
        emptyLayout: 'MemberEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getMember',
        listApiParamSet:{
            obt: 'mb_item_update',
            obs: 'desc',
            ip: 1,
            page: 1
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow:20
    },
    searchCreatorDetail: {
        filterLayout: 'MemberFilterNoneLayout',
        listLayout: 'MemberListSearchDetailLayout',
        emptyLayout: 'MemberEmptyMyChildLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getMember',
        listApiParamSet:{
            obt: 'mb_nick',
            obs: 'desc',
            ip: 1,
            sp: 20,
            page: 1
        },
        pageRow:20
    },
    userProfile: {
        detailLayout: 'MemberDetailUserLayout',
        getFunc: 'getProfile',
        detailApiParamSet: {

        },
        getKeyName: 'uid',
    },
    myProfile: {
        detailLayout: 'MemberDetailMyLayout',
        getFunc: 'getProfile',
        detailApiParamSet: {

        },
    },
    judge: {
        filterLayout: 'MemberFilterJudgeLayout',
        listLayout: 'MemberListJudgeLayout',
        emptyLayout: 'MemberEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getMember',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'mb_item_update',
            obs: 'desc',
            // gid: [], //1 큐레이션 / 2 = 일반
            // pr: [], // 0 일반 // 1 = 스페셜
            en: 1,
        },
        pageRow: 8,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },

    memberUpdate: {
        writeLayouts: ['MemberWriteDefaultLayout'],
        writeFunc: '',
        getFunc:'getInfo',
        writeApiParamSet: {},
        parsingData: {
        },
        fileInfo: [],
        directUrl: '',
        directType: '',
        keyName: '',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    updateNick: {
        writeLayouts: ['MemberWriteNickLayout'],
        writeFunc: 'updateNick',
        writeApiParamSet: {},
        parsingData: {
            nick:'mb_nick'
        },
        fileInfo: [],
        directUrl: 'mypage-nick-modal',
        directType: 'modal',
        keyName: '',
        updateType:'memberInfo',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    updatePwd: {
        writeLayouts: ['MemberWritePwdLayout'],
        writeFunc: 'updatePwd',
        writeApiParamSet: {},
        parsingData: {
            oldPwd:'ori_password',
            pwd:'new_password',
            pwdChk:'new_password_confirmation'
        },
        fileInfo: [],
        directUrl: 'mypage-pw-modal',
        directType: 'modal',
        updateType:'memberInfo',
        keyName: '',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    updateReferrer: {
        writeLayouts: ['MemberWriteReferrerLayout'],
        writeFunc: 'updateRecommend',
        writeApiParamSet: {},
        parsingData: {
            referrerNick:'recommender'
        },
        fileInfo: [],
        directUrl: 'mypage-referrer-modal',
        directType: 'modal',
        keyName: '',
        updateType:'memberInfo',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    topCurator: {
        listLayout: 'MemberListTopCuratorLayout',
    },
    hotCreator: {
        listLayout: 'MemberListHotCreatorLayout',
    },
    topCollector: {
        listLayout: 'MemberListTopCollectorLayout',
    },
    searchCreator: {
        // filterLayout: 'MemberFilterNoneLayout',
        listLayout: 'MemberListSearchCreatorLayout',
        // emptyLayout: 'MemberEmptyDefaultLayout',
        // paginationLayout: 'PaginationDefaultLayout',
    },
    profileSetting: {
        writeLayouts: ['MemberWriteProfileSettingLayout'],
        getFunc: 'getProfile',
        detailApiParamSet: {},
        writeFunc: 'updateProfile',
        writeApiParamSet: {},
        parsingData: {
            profileFile: "",
            thumbnailFile: "",
            backgroundFile: "",
            sns1: 'mb_sns_1',
            sns2: 'mb_sns_2',
            sns3: 'mb_sns_3',
            introduce: 'mb_introduce',
        },
        fileInfo: [
            {
                fileName: 'profileFile',
                fileType: 'profile',
            },
            {
                fileName: 'thumbnailFile',
                fileType: 'profileThumb',
            },
            {
                fileName: 'backgroundFile',
                fileType: 'profileBack',
            },
        ],
        directUrl: 'profile-setting-modal',
        directType: 'modal',
        updateType:'getMemberDetail',
        keyName: 'mb_no',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    curationRequest: {
        detailLayout: 'MemberDetailOnlyNickLayout',
        getFunc: 'getProfile',
        detailApiParamSet: {

        },
        getKeyName: 'uid',
    },
    memberPortfolio: {
        detailLayout: 'MemberDetailPortfolioLayout',
        getFunc: 'getProfile',
        detailApiParamSet: {},
        getKeyName: 'uid',
    },
    portfolioEdit: {
        writeLayouts: ['MemberWritePortfolioLayout'],
        getFunc: 'getProfile',
        detailApiParamSet: {},
        writeFunc: 'updateProfile',
        writeApiParamSet: {},
        parsingData: {
            portfolio: 'mb_portfolio'
        },
        fileInfo: [],
        directUrl: '',
        directType: '',
        updateType:'',
        keyName: 'mb_no',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    interestCreator: {
        filterLayout: 'MemberFilterInterestCreatorLayout',
        // listLayout: 'MemberListInterestCreatorLayout',
        listLayout: 'MemberListLikeLayout',
        emptyLayout: 'MemberEmptyNoHeightLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCreatorLike',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 10,
            ty:'me',

            gid: [], //2 큐레이션 / 1 = 일반
            pr: [], // 0 일반 // 1 = 스페셜
        },
        pageRow: 10,

    },
    addAccount: {
        writeLayouts: ['MemberWriteBankLayout'],
        writeFunc: 'createAccount',
        writeApiParamSet: {
            ma_type: 1,
        },
        parsingData: {
            bankName: 'ma_bank',
            bankAccount: 'ma_account',
            accountName: 'ma_name',
        },
        fileInfo: [],
        directUrl: 'mypage-add-bank-modal',
        directType: 'modal',
        updateType:'getUserInfo',
        keyName: '',
        writeSuccessType: 1,
        writeFailTypes: {},
    },
    tempPassword: {



    },
    gift: {
        filterLayout: 'MemberFilterCreatorLayout',
        listLayout: 'MemberListGiftLayout',
        emptyLayout: 'MemberEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getMember',
        listApiParamSet:{
            obt: 'mb_item_update',
            obs: 'desc',
            ip: 1,
            page: 1
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow:20
    },
}
export default {
    methods: {
        returnMemberConfig(type) {
            return memberConfig[type];
        }
    },
    filterConfig: {
        tab: {
            type: 'match',
            classname: 'tab'
        },
        ni: {
            type: 'match',
            classname: 'searchInput'
        },
        gid: { //큐레이터
            type: 'terms',
            classname: 'filterCurator',
        },
        pr: { //타입
            type: 'terms',
            classname: 'filterType',
            option: {
                aution: [1,2],
                test2: [3],
            },
        },
        lic: { //좋아요
            type: 'between',
            classname: 'filterLike',
            option: {
                start: 'licgt',
                end: 'licle'
            }
        },
        itc: {
            type: 'between',
            classname: 'filterItem',
            option: {
                start: 'itcgt',
                end: 'itcle'
            }
        }
    }
}
