<template>
  <div class="qna_create_wrap">
    <div class="qna_create_title">
     {{ $t('write_qna') }}
    </div>
    <board-write-component :boardType="boardType"></board-write-component>
    <div class="qna_create_btn_wrap">
      <button class="qna_create_btn list" @click="backList()">{{ $t('list') }}</button>
      <button class="qna_create_btn confirm" @click="save">{{ $t('save') }}</button>
    </div>
  </div>
</template>

<script>
import BoardWriteComponent from "@/components/board/BoardWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
  name: "QnaCreate",
  mixins: [],
  components: {
    BoardWriteComponent
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      boardType: 'qnaCreate',
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    backList() {this.$router.back()},
    save() {
      EventBus.$emit('writeBoard');
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>