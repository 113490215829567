import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";

export default {
    mixins: [alertMixins],
    data() {
        return {
            maxPrice: 1000000000,
            maxPriceErrorMsg: ''
        }
    },
    methods: {
        //this.errorAlert(this.$t('withdraw_exp'))
        returnNumber(price) {
            if (!util.isEmpty(price)) {
                return util.Number.returnNumber(price);
            } else {
                return 0;
            }
        },
        returnCommas(price) {
            if (!util.isEmpty(price)) {
                return util.Number.numFormat(price);
            } else {
                return 0;
            }
        },
        returnMaxPrice(price) {
            if (!util.isEmpty(price)) {
                if (util.Number.returnNumber(price) > this.maxPrice) {
                    price = this.maxPrice;
                    this.maxPriceErrorMsg = this.$t('maxPriceErrorMsg', {price: util.Number.numFormat(this.maxPrice)});
                } else {
                    this.maxPriceErrorMsg = '';
                }
                return util.Number.returnNumber(price);
            } else {
                return 0;
            }
        },

        setFees(data, price) {
            if (data.i_status === 'auction' || data.i_status === 'pricetag') {
                if (data.m_first_order === 1) {
                    if (data.im_status === 2 || data.im_status === 5) {
                        return price * 0.1
                    } else {
                        return 0
                    }
                } else {
                    if (data.i_type === 'digital') {
                        return 0;
                    } else {
                        if (data.im_status === 2 || data.im_status === 5) {
                            return price * 0.1;
                        } else {
                            return 0
                        }
                    }
                }
            } else {
                return 0;
            }
        },
        returnMingle(data) {
            if (util.isEmpty(data)) {
                return `￦0`
            }
            let mingle = this.returnCommas((util.Number.returnNumber(data) * 10));
            return `￦${mingle}`
        },
        returnPriceString(data) {
            let price = Number(data);
            if (0 <= price && price < 1000) {
                return price
            } else if (1000 <= price && price < 1000000) {
                price = parseInt(price / 1000)
                return `${price}K`
            } else if (1000000 <= price && price < 1000000000) {
                price = parseInt(price / 1000000)
                return `${price}M`
            } else if (1000000000 <= price && price < 1000000000000) {
                price = parseInt(price / 1000000000)
                return `${price}B`
            } else {
                price = parseInt(price / 1000000000000)
                return `${price}T`
            }
        },
        returnPriceStringMingle(data) {
            let price = Number(data *10);
            if (0 <= price && price < 1000) {
                return `￦${price}`
            } else if (1000 <= price && price < 1000000) {
                price = parseInt(price / 1000)
                return `￦${price}K`
            } else if (1000000 <= price && price < 1000000000) {
                price = parseInt(price / 1000000)
                return `￦${price}M`
            } else if (1000000000 <= price && price < 1000000000000) {
                price = parseInt(price / 1000000000)
                return `￦${price}B`
            } else {
                price = parseInt(price / 1000000000000)
                return `￦${price}T`
            }
        }

    }
}
