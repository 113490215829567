<template>
    <item-list-component :item-type="'creationUser'" :memberKey="memberKey" ></item-list-component>
</template>
<script>
import ItemListComponent from "@/components/item/ItemListComponent";
export default {
    name: "CreatorDetailCreation",
    mixins: [],
    components: {
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
    },
    watch: {},
}
</script>

<style scoped>

</style>
