<template>
    <div class="register_main_wrap">
      <div class="reg_top">
        <span v-html="$t('share_msg')"></span>
<!--        {{$t('loginText1')}}
        <br>{{$t('loginText2')}}-->
        <div><img src="@/assets/image/main/logo2.png" alt=""></div>
      </div>
        <register-component></register-component>
    </div>
</template>

<script>
import RegisterComponent from "@/components/register/RegisterComponent";
import TermsComponent from "@/components/terms/TermsComponent";
import TermsModal from "../../components/modal/TermsModal";

export default {
    name: "Register",
    mixins: [],
    components: {
        TermsModal,
        RegisterComponent,
        TermsComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>