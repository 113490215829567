<template>
    <div class="register_main_wrap">
        <div class="reg_top">
            {{ $t('after_register') }}<br>
            {{ $t('loginText1') }}
            <br>{{ $t('loginText2') }}
            <div><img src="@/assets/image/main/logo2.png" alt=""></div>
        </div>
        <div class="reg_bottom_wrap mt50">
            <div class="reg_bottom">
                <button class="submit_btn mb9" @click="movePage('/auth')">
                    {{ $t('yes') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterComponent from "@/components/register/RegisterComponent";
import TermsComponent from "@/components/terms/TermsComponent";
import TermsModal from "../../components/modal/TermsModal";
import GoogleLoginComponent from "@/components/login/GoogleLoginComponent";
import NaverLoginComponent from "@/components/login/NaverLoginComponent";
import KakaoLoginComponent from "@/components/login/KakaoLoginComponent";
import FacebookLoginComponent from "@/components/login/FacebookLoginComponent";

export default {
    name: "RegisterAfter",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>