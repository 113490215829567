<template>
    <div>
        <div class="creator_detail_wrap">
            <member-detail-component :member-type="'userProfile'" :memberKey="memberKey"></member-detail-component>
            <creator-detail-tabs :nowTab="'collection'" :memberKey="memberKey"></creator-detail-tabs>
            <router-view></router-view>
            <!--            <gallery-list-component :gallery-type="'creatorGallery'" :defaultFilter="defaultFilter"></gallery-list-component>-->
        </div>
        <filter-modal></filter-modal>
        <curation-history-modal :memberKey="memberKey"></curation-history-modal>
        <share-modal></share-modal>
    </div>
</template>

<script>
import CreatorDetailTabs from "@/components/tabs/CreatorDetailTabs";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import FilterModal from "@/components/modal/FilterModal";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import ShareModal from "@/components/modal/ShareModal";

export default {
    name: "CreatorDetail",
    mixins: [],
    components: {
        ShareModal,
        CreatorDetailTabs,
        MemberDetailComponent,
        FilterModal,
        CurationHistoryModal,
        GalleryListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
            tab: 'premiumUser',
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {no: this.memberKey};
        // this.memberKey = this.$route.params.idx;
        // this.memberKey = this.$route.params.idx;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
