<template>
    <div>
        <license-write-component :license-type="'licensePay'" :license-key="licenseKey"></license-write-component>

    </div>
</template>

<script>
import LicenseWriteComponent from "@/components/license/LicenseWriteComponent";

export default {
    name: "License",
    mixins: [],
    components: {LicenseWriteComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            licenseKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>