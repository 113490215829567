<template>
    <div>
        <item-detail-component :item-type="'openMarketDetail'" :item-key="itemKey"></item-detail-component>
        <auction-history-modal></auction-history-modal>
        <commission-info-modal></commission-info-modal>
        <share-modal></share-modal>
        <gift-item-modal></gift-item-modal>
    </div>
</template>

<script>
import ItemDetailComponent from "@/components/item/ItemDetailComponent";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";
import CommissionInfoModal from "@/components/modal/CommissionInfoModal";
import ShareModal from "@/components/modal/ShareModal";
import GiftItemModal from "@/components/modal/GiftItemModal";

export default {
    name: "OpenMarketDetail",
    mixins: [],
    components: {
        GiftItemModal,
        ShareModal,
        CommissionInfoModal,
        AuctionHistoryModal,
        ItemDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemKey: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey = this.$route.params.idx
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab) || this.tab === 'undefined') {
            EventBus.$emit(`changeTab`, 'item');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
