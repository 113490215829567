<template>
    <div>
        <div class="search_item_wrap terms_content_btn">
            <div class="search_item">
                <el-checkbox v-model="termsCheck" @change="changeCheck">
                    <span class="item_text_span bold" @click="openModal('terms-modal', 'product')">{{$t('t_e_item')}}</span>
<!--                    <span class="item_text_span ">{{$t('agree_terms2')}}</span>-->
                  <span v-html="$t('agree_terms2')"></span>

                </el-checkbox>
            </div>
            <div class="search_item pt5" v-if="status === 1 || status === 2">
                <el-checkbox v-model="auctionTermsCheck" @change="changeCheck">
                    <span class="item_text_span bold" @click="openModal('terms-modal','auctionSell')">{{ $t('bid_terms') }}</span>
                    <span v-html="$t('agree_terms2')"></span>

                </el-checkbox>
            </div>
            <div class="search_item pt5" v-if="status === 3">
                <el-checkbox v-model="licenseTermsCheck" @change="changeCheck">
                    <span class="item_text_span bold" @click="openModal('terms-modal','licenseSell')">{{ $t('license_terms') }}</span>
                    <span v-html="$t('agree_terms2')"></span>
                </el-checkbox>
            </div>
            <div class="search_item pt5" v-if="status === 4 || status === 5">
                <el-checkbox v-model="limitSaleTermsCheck" @change="changeCheck">
                    <span class="item_text_span bold" @click="openModal('terms-modal','productSell')">{{ $t('product_sell_terms') }}</span>
                    <span v-html="$t('agree_terms2')"></span>
                </el-checkbox>
            </div>
        </div>
        <div class="submit_wrap item_create_btn">
            <div class="submit_btn">
                <button class="enroll_submit_btn" v-if="isItemData()" @click="openDeleteConfirm">{{$t('confirm_del')}}</button>
                <button class="enroll_save_btn" @click="checkTerms('temporary')">{{$t('temporary_save')}}</button>
                <button class="enroll_submit_btn" @click="checkTerms('create')">{{$t('do_enroll')}}</button>
            </div>
        </div>
        <div class="caption_check mt10" v-show="showTermsMsg"><p>{{$t('caption_check')}}</p></div>
<!--        <div class="caption_check mt10" v-else-if="errorMsg"><p><img src="@/assets/image/main/caution_img.png" alt="">{{$t('item_create_required')}}</p></div>-->
    </div>
</template>

<script>
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemCreateSaveLayout",
    mixins: [elFileMixins],
    components: {
    },
    props: {
        setComponentData: {default: () => {}, type: Function},
        checkValidator: {default: () => {}, type: Function},
        itemData: {default: () => {}, type: Object},
        status: {default: 9,},
        itemClass: {default: null, }
    },
    data() {
        return {
            termsCheck: false,
            auctionTermsCheck: false,
            licenseTermsCheck: false,
            limitSaleTermsCheck: false,
            showTermsMsg: false,
        }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        changeCheck() {
            if(this.showTermsMsg) {
                this.showTermsMsg = false;
            }
        },
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        checkTerms(itemType) {
            if (!this.termsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            if((this.status === 1 || this.status === 2) && !this.auctionTermsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            if((this.status === 3) && !this.licenseTermsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            if((this.status === 4 || this.status === 5) && !this.limitSaleTermsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            this.showTermsMsg = false;
            this.save(itemType)
        },
        save(itemType) {
            this.setComponentData('itemType', itemType);
            this.checkValidator();
        },
        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('caution_del_item')}<br>${this.$t('caution_del')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.deleteItem();
                },
                cancel: () => {
                }
            })
        },
        async deleteItem() {
            EventBus.$emit('apiLoading', true);
            let result = await this.itemClass.deleteItem(this.itemData.i_idx);
            if(result) {
                this.deleteItemFinish();
            }else{
                this.errorAlert(this.$t('deleteErrorMsg'));
            }
            EventBus.$emit('apiLoading', false);
        },
        deleteItemFinish() {
            this.createAlert({
                title: '',
                content: this.$t('deleteSuccessMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    this.$router.back();
                },
            })
        },
    },
    watch: {
        'status': {
            deep: true,
            handler: function (val, oldVal) {
                this.auctionTermsCheck = false;
                this.licenseTermsCheck = false;
                this.limitSaleTermsCheck = false;
            }
        },
    },
}
</script>

<style scoped>

</style>
