<template>
    <div>
        <detection-filter-link :filter-layout="filterLayout" :filterData="filterSet.filter"></detection-filter-link>
        <detection-empty-link :empty-layout="emptyLayout" :finishGetList="finishGetList" v-if="List.length == 0"></detection-empty-link>
        <detection-list-link :list-layout="listLayout" :detectionData="List" :paginationData="filterSet.page" :itemData="itemData" v-else></detection-list-link>
        <pagination-link :pagination-layout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import detectionUtil from "./detectionUtil";
import DetectionFilterLink from "./DetectionFilterLink";
import DetectionEmptyLink from "./DetectionEmptyLink";
import DetectionListLink from "./DetectionListLink";
import PaginationLink from "./PaginationLink";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import DeleteClass from "@/mixins/deleteClass";

const {filterConfig} = detectionUtil;

export default {
    name: "DetectionListComponent",
    mixins: [detectionUtil, filterMixins, listMixins],
    components: {PaginationLink, DetectionListLink, DetectionEmptyLink, DetectionFilterLink},
    inject: ['setDetectItemData'],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setDetectionListData: this.setData,
        }
    },
    props: {
        detectionType: '',
        detectionKey: {default: 0},
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: 10,
            sortOptions: {},
            initListType: 'api',
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            keyName: '',

            itemData: {},
            deleteClass: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('detectListChangeStatus', this.runChangeDetectStatus);
        EventBus.$on('detectListIgnoreDomain', this.runIgnoreDomain);
        EventBus.$on('getDetectionList', this.getList);
        EventBus.$on('getDetectionDelete', this.runDelete);
    },
    beforeDestroy() {
        EventBus.$off('detectListChangeStatus')
        EventBus.$off('detectListIgnoreDomain')
        EventBus.$off('getDetectionList')
        EventBus.$off('getDetectionDelete')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnDetectionConfig(this.detectionType);
            console.log(config)
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.initListType = config.initListType;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.keyName = config.keyName;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = util.isEmpty(this.listApiParamSet) ? {} : JSON.parse(JSON.stringify(this.listApiParamSet));

            if(params.hasOwnProperty('d_idx') && this.detectionKey != 0) {
                params.d_idx = this.detectionKey;
            }

            if(util.isEmpty(this.initListType)) {
                this.initListType = 'api';
            }

            let listOptions = {
                url: this.$api.$detection[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList(this.initListType, listOptions);
            this.initDeleteClass();
        },
        initDeleteClass() {
            let options = {
                func: this.$api.$detection[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.keyName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        getList(isFirst=false) {
            this.finishGetList = false;
            // EventBus.$emit('apiLoading', true);
            try {
                let filterSet = JSON.parse(JSON.stringify(this.getFilterSet()));
                if(isFirst && this.paginationType === 'lazyload' && this.parserType === 'url') {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                    this.List = [];
                    if(!util.isEmpty(this.$route.query.page) && this.$route.query.page != 1) {
                        let url = this.$route.fullPath;
                        url = url.replace(`page=${this.$route.query.page}`, 'page=1');
                        this.$router.replace(url);
                    }
                    this.setPage('page', 1)
                }else if(isFirst && this.paginationType != 'lazyload') {
                    let parsingDataPage = this.getPasingDataPage();
                    filterSet.page.page = parsingDataPage;
                    this.List = [];
                }else if(this.isInitPage === true) {
                    filterSet.page.page = 1;
                    this.filterSet.page.page = 1;
                }
                if(this.listClass === null) {
                    return false;
                }
                if(this.isInitPage === true && !this.isGetFirst && util.isEmpty(filterSet.filter)) {
                    this.List = [];
                    return false;
                }
                this.listClass.getList(filterSet, this.isInitPage).then(res => {
                    console.log(res)
                    let list = res.list.slice();
                    if(this.paginationType === 'lazyload' && this.List.length > 0 && this.hasPre) {
                        this.List = list.concat(this.List);
                    }else if(this.paginationType === 'lazyload' && filterSet.page.page > 1) {
                        // this.List.splice(filterSet.page.page -1 * filterSet.page.size, filterSet.page.size);
                        this.List = this.List.concat(list);
                    }else{
                        this.List = list;
                    }
                    if(res.hasOwnProperty('item')) {
                        this.itemData = res.item;
                        this.setDetectItemData(this.itemData)
                    }
                    if(res.hasOwnProperty('total')) {
                        this.setPage('total', res.total);
                    }
                    if(isFirst && this.paginationType === 'lazyload') {

                    }else if(isFirst) {
                        this.setParsingDataPageFilterSet();
                    }
                    if(this.isInitPage) {
                        this.setPage('page', 1);
                        this.isInitPage = false;
                    }
                    this.finishGetList = true;
                    if(!this.isGet) {
                        this.isGet = true;
                    }
                    EventBus.$emit('apiLoading', false);
                })
            }catch (e) {
                console.log(e);
            }
        },
        runChangeDetectStatus(detect) {
            EventBus.$emit('changeLoading', true);
            let params = {
                dr_idx: detect.dr_idx,
                dr_status: detect.dr_status_select,
            }
            try {
                this.$api.$detection.changeStatus(params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    let type = this.returnDetectStatusType(res.Info.type);
                    if(type === true) {
                        detect.dr_status = detect.dr_status_select;
                        let index = this.List.findIndex(data => data.dr_idx === detect.dr_idx);
                        if(index > -1) {
                            this.List[index] = detect
                        }
                    }else{
                        this.errorAlert(type);
                    }
                })
            }catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        returnDetectStatusType(type) {
            switch (type) {
                case 1:
                    return true;
                case -103:
                    return this.$t('d_change_status_err2');
                case -601:
                    return this.$t('d_change_status_err3');
                case -610:
                    return this.$t('d_change_status_err4');
                default:
                    return this.$t('d_change_status_err1')
            }
        },
        runIgnoreDomain(detect) {
            EventBus.$emit('changeLoading', true);
            let params = {
                dr_idx: detect.dr_idx,
            }
            try {
                this.$api.$detection.updateDetectDomainIgnore(params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    let type = this.returnIgnoreDomain(res.Info.type);
                    if(type === true) {
                        detect.dr_except = 1;
                        let index = this.List.findIndex(data => data.dr_idx === detect.dr_idx);
                        if(index > -1) {
                            this.List[index] = detect
                        }
                    }else{
                        this.errorAlert(type);
                    }
                })
            }catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        returnIgnoreDomain(type) {
            switch (type) {
                case 1:
                    return true;
                case -103:
                    return this.$t('d_change_status_err2');
                case -601:
                    return this.$t('d_change_status_err3');
                case -611:
                    return this.$t('d_ignore_domain_err1');
                default:
                    return this.$t('d_change_status_err1')

            }
        },
        deleteAfterFunc() {
            this.getList();
        },
        runDelete(key) {
            this.deleteClass.runDelete(key);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
