import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import API from '@/api/index'

export default {
    components: {
        quillEditor
    },
    data() {
        return{
            editorOption: {
                placeholder: '',
                modules: {
                    toolbar: {
                        container: [
                            [
                                'bold', 'italic', 'underline', 'strike', 'blockquote',
                                { 'list': 'ordered' }, { 'list': 'bullet' },
                                { 'script': 'sub' }, { 'script': 'super' },
                                { 'indent': '-1' }, { 'indent': '+1' },
                                { 'size': ['small', false, 'large', 'huge'] },
                                { 'header': [1, 2, 3, 4, 5, 6, false] },
                                { 'color': [] }, { 'background': [] },
                                { 'align': [] },
                                'link', 'image', 'video'
                            ],
                        ],
                        handlers: {
                            'image': function (value) {
                                let file = document.createElement('input');
                                file.setAttribute('type', 'file');
                                file.setAttribute('accept', 'image/*')
                                let self = this;
                                file.addEventListener('change', function (e) {
                                    console.log(e)
                                    let fileData = e.target.files[0];
                                    let formData = new FormData();
                                    formData.append('files', fileData);
                                    API.$file.editorUpload(formData).then(res => res.Data.Result).then(res => {
                                        if (res.Info.type == 1) {
                                            let range = self.quill.getSelection();
                                            self.quill.insertEmbed(range.index, 'image', res.List)
                                            document.body.removeChild(file)
                                        }
                                    })

                                })
                                document.body.appendChild(file);
                                file.click();
                            }
                        },
                    },
                }
            }
        }
    },
    methods: {
        onEditorBlur(quill) {
        },
        onEditorFocus(quill) {
        },
        onEditorReady(quill) {
        },
        onEditorChange({ quill, html, text }) {

        }
    }
}
