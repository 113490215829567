<template>
    <modal name="gift-item-modal" class="gift-modal"
           :width="`1280px`" :height="`70%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="gift_wrap">
            <div class="back_btn" @click="modalClose"></div>
            <div class="gift_text">
                {{ $t('gift') }}
            </div>
            <div class="mt30">
                {{ $t('gift_mingle_text') }}
            </div>
            <div class="">
                <member-list-component :member-type="'gift'"></member-list-component>
            </div>
        </div>
    </modal>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import MemberListComponent from "@/components/member/MemberListComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "GiftItemModal",
    mixins: [imageOption, alertMixins],
    components: {MemberListComponent},
    inject: [],
    provide() {
        return {
            runGiftConfirm: this.runGiftConfirm
        }
    },
    props: {},
    data() {
        return {
            itemData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            $("body").addClass("modal-open")
            if (!util.isEmpty(event.params) && !util.isEmpty(event.params.itemData)) {
                this.itemData = event.params.itemData;
            }
        },
        handlerOpened() {

        },
        handlerBeforeClose() {
            $("body").removeClass("modal-open")
            this.itemData = {};
        },
        modalClose() {
            $("body").removeClass("modal-open")
            this.$modal.hide('gift-item-modal');
        },
        runGiftConfirm(key) {
            this.createConfirm({
                content: this.$t('gift_alert'),
                confirm: () => {
                    this.giftItem(key);
                }
            })
        },
        giftItem(key) {
            let params = {
                mb_no: key,
                i_idx: this.itemData.i_idx
            }

            this.$api.$item.giftItem(params).then(res => res.Data.Result).then(res => {
                let type = this.returnGiftError(res.Info.type)
                if (type === true) {
                    this.createAlert({
                        content: this.$t('gift_success'),
                        hide() {
                            EventBus.$emit('getDetail');
                        }
                    })
                    this.modalClose();
                } else {
                    this.errorAlert(type);
                }
            })
        },
        returnGiftError(type) {
            switch (type) {
                case 1 :
                    return true
                case -801:
                    return this.$t('n_e_mingle')
                default :
                    return this.$t('gift_error')
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
