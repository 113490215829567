<template>
  <div>
    <portfolio-filter-link :filterLayout="filterLayout"></portfolio-filter-link>
    <portfolio-list-link :listLayout="listLayout" v-if="true"></portfolio-list-link>
    <portfolio-empty-link :emptyLayout="emptyLayout" v-else></portfolio-empty-link>
    <pagination-link :paginationLayout="paginationLayout"></pagination-link>
  </div>
</template>

<script>
import PortfolioFilterLink from "@/components/portfolio/PortfolioFilterLink";
import PortfolioEmptyLink from "@/components/portfolio/PortfolioEmptyLink";
import PortfolioListLink from "@/components/portfolio/PortfolioListLink";
import PaginationLink from "@/components/portfolio/PaginationLink";
import portfolioUtil from "@/components/portfolio/portfolioUtil";

export default {
  name: "PortfolioListComponent",
  mixins: [portfolioUtil],
  components: {
    PortfolioFilterLink,
    PortfolioEmptyLink,
    PortfolioListLink,
    PaginationLink
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    portfolioType: '',
  },
  data() {
    return{
      listLayout: '',
      filterLayout: '',
      emptyLayout: '',
      paginationLayout: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.initConfig();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    initConfig(){
      let portfolioConfig = this.returnPortfolioConfig(this.portfolioType);
      this.listLayout = portfolioConfig.listLayout;
      this.filterLayout = portfolioConfig.filterLayout;
      this.emptyLayout = portfolioConfig.emptyLayout;
      this.paginationLayout = portfolioConfig.paginationLayout;
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>