<template>
    <div>
        <detection-detail-component :detection-type="'detectionDetail'" :detectionKey="detectionKey"></detection-detail-component>
    </div>
</template>

<script>


import DetectionDetailComponent from "../../components/detection/DetectionDetailComponent";

export default {
    name: "DetectionDetail",
    mixins: [],
    components: {DetectionDetailComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            detectionKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
