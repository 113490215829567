import axios from "axios";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

const firebaseApiKey= 'AIzaSyAE2C0wsTXSG_FZN3Zp9TM7s4gOVhq8z38';
const firebaseUrl = 'https://minglemint.page.link';
const webUrl = 'https://www.minglemint.com';

const configShare = {
};



export default {
    methods: {
        returnShareConfig(type) {
            return configShare[type];
        },
    },
    getShareLink(path) {
        return webUrl + path;
    },
    makeFirebaseShortLink(shareData={path: '', img: '', title: '', description: ''}) {
        //let link = getShareLink(shareData.path);
        let link = webUrl + shareData.path;
        let imgUrl = encodeURIComponent(shareData.img);
        let linkTitle = encodeURIComponent(shareData.title);
        let linkDescription = encodeURIComponent(shareData.description);

        //`${firebaseUrl}?link=${link}&st=Zozimus (꿈의 재료)| 밍글민트(Minglemint NFT Marketplace)&sd=밍글민트(Minglemint NFT Marketplace)&si=${img}`;
        let longDynamicLink = `${firebaseUrl}?link=${link}`;
        if(!util.isEmpty(shareData.img)) {
            longDynamicLink += `&si=${imgUrl}`;
        }
        if(!util.isEmpty(shareData.title)) {
            longDynamicLink += `&st=${linkTitle}`;
        }
        if(!util.isEmpty(shareData.description)) {
            longDynamicLink += `&sd=${linkDescription}`;
        }
        console.log(longDynamicLink)
        let params = {
            "longDynamicLink": longDynamicLink,
            "suffix": {
                "option": "SHORT"
            }
        };
        return axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`, params, {headers: {Authorization: ''}}).then(async (res) =>  {
            if(util.isEmpty(res.data.shortLink)) {
                EventBus.$emit('apiLoading', false);
                return;
            }
            return res.data.shortLink;
        }).catch(error => {
            console.log(error);
        })
    },
}
