<template>
    <modal name="mypage-deposit-modal" class="mypage-modal pw mypage-deposit-modal"
           :width="`100%`" :height="`100%`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="mypage-modal-wrap">
            <div class="top_wrap">
                <div class="modal_title">{{ $t('amount_deposit') }}</div>
                <div class="close_btn_wrap">
                    <button class="close_modal" @click="modalClose()"></button>
                </div>
            </div>
            <point-deposit-component :afterMethod="modalClose"></point-deposit-component>
        </div>
<!--        <member-write-component :member-type="'updatePwd'"></member-write-component>-->
    </modal>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import PointDepositComponent from "@/components/point/PointDepositComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "MypageDepositModal",
    mixins: [memberValidator],
    components: {
        PointDepositComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pwd: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('mypage-deposit-modal');
        },
        returnUrl(){
            return this.url
        },
        setData(key, value) {
            this[key] = value
        },
        checkSave() {

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
