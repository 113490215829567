import {request} from "@/api/request";
import Vue from "vue"

const state = {
    filterSet: {
        page: {page: 1, size: 10, total: 0},
        filter: {},
        sort: {},
    },
}

const getters = {
    getFilterSet: state => state.filterSet,
}

const mutations = {
    updateFilterSet(state, obj) {
        state.filterSet = obj.data;
    },
}
const actions = {
    setFilterSet({commit, rootState}, {data: filterSet}) {
        commit('updateFilterSet', {data: filterSet})
    },
}
export default {namespaced: true, state, getters, mutations, actions}
