<template>
    <div>
        <div class="point_default_wrap">
            <table class="my_point_table" width="100%" border="0" cellspacing="0" cellpadding="0"
                   style="table-layout: auto; width: 1200px; table-layout: fixed;">
                <colgroup>
                    <col width="300px"/>
                    <col width="300px"/>
                    <col width="300px"/>
                    <col width="300px"/>
                </colgroup>
                <tr>
                    <td rowspan="4" class="t_subject">{{ $t('usa_withdraw_mingle') }}</td>
                    <td rowspan="4"><span class="point_text">{{ returnCommas(pointRemain) }} Mg</span></td>
                    <td class="t_subject">{{ $t('real_used_trade') }}</td>
                    <td class="t_content">{{ returnCommas(pointReal) }} Mg</td>
                </tr>
                <tr>
                    <td class="t_subject">{{ $t('auction_bid_mingle') }}</td>
                    <td class="t_content">{{ returnCommas(pointAuction) }} Mg</td>
                </tr>
                <tr>
                    <td class="t_subject">{{ $t('competition_open_mingle') }}</td>
                    <td class="t_content">{{ returnCommas(pointContest) }} Mg</td>
                </tr>
                <tr>
                    <td class="t_subject">{{ $t('withdraw_mingle') }}</td>
                    <td class="t_content">{{ returnCommas(pointWithdraw) }} Mg</td>
                </tr>
                <tr>
                    <td class="t_subject">{{ $t('all_mingle') }}</td>
                    <td class="t_content" colspan="3"><span class="total_mg">{{ returnAllPoint() }} Mg</span></td>
                </tr>
            </table>
            <div class="confirm_btn_wrap">
                <button class="btn charge" @click="movePage('/mypage/point/charge')"
                        >{{ $t('charge_mingle') }}
                </button>
                <button class="btn save" @click="movePage('/mypage/point/withdraw')">{{ $t('request_withdraw') }}
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "PointStatusComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pointData: {},
            pointRemain: 0,
            pointReal: 0,
            pointAuction: 0,
            pointContest: 0,
            pointWithdraw: 0
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getList();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
    },
    methods: {
        getList() {
            EventBus.$emit('changeLoading', true)
            try {
                this.$api.$point.getPointDetail().then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.pointData = res.List;
                        this.pointRemain = this.pointData.remain[0].point;
                        this.pointReal = this.pointData.real[0].point;
                        this.pointAuction = this.pointData.auction[0].point;
                        this.pointContest = this.pointData.contest[0].point;
                        this.pointWithdraw = this.pointData.withdraw[0].point;
                    }
                    EventBus.$emit('changeLoading', false)
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false)
            }

        },
        returnAllPoint() {
            let tatalPoint = Number(this.pointRemain) + Number(this.pointReal * -1) + Number(this.pointAuction * -1) + Number(this.pointContest * -1) + Number(this.pointWithdraw * -1);
            if (tatalPoint < 0) {
                tatalPoint = 0;
            }
            return this.returnCommas(tatalPoint);
        },

        returnCommas(x = 0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
