<template>
    <div>
<!--        <div class="my_collect_list_wrap">-->
<!--            <div class="my_collect_setting">-->
<!--                <button class="setting_btn" @click="movePage('/data')">{{ $t('collect_setting') }}</button>-->
<!--                <br>-->
<!--            </div>-->
<!--            <collect-list-default-layout></collect-list-default-layout>-->
<!--        </div>-->
    </div>
</template>

<script>

import CollectListDefaultLayout from "@/template/collect/list/CollectListDefaultLayout";
export default {
    name: "CollectEdit",
    mixins: [],
    components: {CollectListDefaultLayout},
    inject: [],
    provide() {
        return {
            setViewData: this.setData
        }
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name, {galleryType: 'galleryAdd'});
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
