<template>
    <div style="display: none">
        <video ref="videoPlayer" id="video" class="video-js vjs-default-skin post-detail-video"
               crossorigin="anonymous"
               playsinline controls></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
    name: "videoPlayer",
    props: {
        type: '',
        url: '',
    },
    data() {
        return {
            player: null
        }
    },
    inject: ['videoSetData'],
    mounted() {
        this.setVideo();
        let myVideo = document.getElementById("video");
        myVideo.onloadedmetadata = function () {
            console.log(this.duration);//this refers to myVideo
        };
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    },
    methods: {
        setVideo() {
            let $this = this
            this.player = videojs('video',
                    {
                        sources: [
                            {
                                src: $this.url,
                                type: $this.type,
                            }
                        ]
                    },
                    function onPlayerReady() {
                        $this.videoSetData(`player`, $this.player)
                    })
        }
    },
}
</script>
