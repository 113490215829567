<template>
  <modal name="commission-info-modal" class="commission-info-modal"
         :width="`650px`" :height="`670px`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
      <div class="terms_modal_wrap">
        <h1>{{$t('fees_info')}}</h1>
        <button @click="modalClose()" class="back_btn"></button>
        <terms-component :termsType="`fees`"></terms-component>
      </div>
  </modal>
</template>

<script>
import TermsComponent from "@/components/terms/TermsComponent";

export default {
  name: "CommissionInfoModal",
  mixins: [],
  components: {
    TermsComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      url: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
    },
    handlerOpened() {
    },
    handlerBeforeClose() {

    },
    modalClose() {
      this.$modal.hide('commission-info-modal');
    },
    returnUrl() {
      return this.url
    },

  },
  watch: {},
}
</script>

<style scoped>

</style>