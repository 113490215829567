<template>
  <collect-detail-link :detailLayout="detailLayout" :collect-data="collectData"
                       :launchUrl="launchUrl"></collect-detail-link>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import CollectDetailLink from "@/components/collect/CollectDetailLink";
import collectUtil from "@/components/collect/collectUtil";
import EventBus from "@/utils/event-bus";

export default {
  name: "CollectDetailComponent",
  mixins: [alertMixins, collectUtil],
  components: {
    CollectDetailLink,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {
    collectType: '',
    collectKey: {default: 0},
  },
  data() {
    return {
      detailLayout: '',
      getFunc: 'getCollection',
      detailApiParamSet: {},
      getKeyName: 'id',
      isGetWriter: false,
      launchUrl: '',
      collectData: {},
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.initConfig();
    this.getLaunchUrl();
  },
  mounted() {
    EventBus.$on('getCollect', this.getCollect)
  },
  beforeDestroy() {
    EventBus.$off('getCollect')
  },
  destroyed() {
  },
  computed: {},
  methods: {
    initConfig() {
      let config = this.returnCollectConfig(this.collectType);
      this.detailLayout = config.detailLayout;
      this.getFunc = config.getFunc;
      this.detailApiParamSet = config.detailApiParamSet;
      this.getKeyName = config.getKeyName;
      this.getCollect();
    },
    getCollect() {
      EventBus.$emit('apiLoading', true)
      let params = Object.assign({}, this.detailApiParamSet);
      params[this.getKeyName] = this.collectKey;
      try {
        this.$api.$collect[this.getFunc](params).then(res => res.Data.Result).then(res => {
          EventBus.$emit('apiLoading', false)
          if (res.Info.type != 1 || util.isEmpty(res.List)) {
            this.createAlert({
              content: this.$t('detect_detail_empty'),
              hide: () => {
                this.$router.back();
              }
            })
            return false;
          }

          this.collectData = res.List[0];
        })
      } catch (e) {
        EventBus.$emit('apiLoading', false)
        console.log(e);
      }
    },
    getLaunchUrl() {
      let token = this.$localStorage.get('token');
      let params = {
        "com.dgmonglab.blockchain_weptoken": token,
        c_idx: this.collectKey
      }
      this.$api.$nticket.getLaunchUrl(params).then(res => res.resultStats).then(res => {
        if (res.resultCode == 'success' && res.resultList.length > 0) {
          let token = this.$localStorage.get('token');
          let url = `${res.resultList[0].LINK}&com.dgmonglab.blockchain_weptoken=${token}`
          this.launchUrl = url;

        }
      })
    }
  },
  watch: {},
}
</script>

<style scoped>

</style>
