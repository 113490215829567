<template>
    <div class="register_main_wrap">
        <div class="reg_top">
            <span v-html="$t('share_msg')"></span>
            <div><img src="@/assets/image/main/logo2.png" alt=""></div>
        </div>
        <div class="reg_bottom_wrap mt50">
            <div class="reg_bottom">
                <button class="submit_btn mb9" @click="movePage('/auth/register/email')">{{
                        $t('email_register')
                    }}
                </button>
            </div>
        </div>
        <div class="reg_sns_start mt60">
            <div class="reg_sns_wrap ">
                {{ $t('start_sns') }}
            </div>
            <div class="reg_sns">
                <div class="reg_sns_wrap">
                    <google-login-component></google-login-component>
                    <naver-login-component></naver-login-component>
                    <kakao-login-component></kakao-login-component>
                    <facebook-login-component></facebook-login-component>
                    <apple-login-component></apple-login-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterComponent from "@/components/register/RegisterComponent";
import TermsComponent from "@/components/terms/TermsComponent";
import TermsModal from "../../components/modal/TermsModal";
import GoogleLoginComponent from "@/components/login/GoogleLoginComponent";
import NaverLoginComponent from "@/components/login/NaverLoginComponent";
import KakaoLoginComponent from "@/components/login/KakaoLoginComponent";
import FacebookLoginComponent from "@/components/login/FacebookLoginComponent";
import AppleLoginComponent from "@/components/login/AppleLoginComponent";

export default {
    name: "Register",
    metaInfo: {
      title: `밍글민트(Minglemint NFT Marketplace)`,
    },
    mixins: [],
    components: {
        AppleLoginComponent,
        FacebookLoginComponent,
        KakaoLoginComponent,
        NaverLoginComponent,
        GoogleLoginComponent,
        TermsModal,
        RegisterComponent,
        TermsComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
