<template>
  <div class="collection_wrap" style="min-height: calc(100vh - 400px);">
      <div class="collection_list_wrap">
          <div class="cf_default_wrap">
              <div class="collection_title">{{$t('gallery')}}</div>
          </div>
          <div class="c_add_btn_relative_wrap">
              <div class="collection_add_btn_wrap">
                  <button @click="openModal('gallery-setting-modal')">
                      {{$t('add_gallery')}}
                  </button>
              </div>
          </div>
          <gallery-list-component :gallery-type="'gallery'" :defaultFilter="defaultFilter"></gallery-list-component>
      </div>
    <gallery-setting-modal></gallery-setting-modal>
  </div>
</template>

<script>
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import GallerySettingModal from "@/components/modal/GallerySettingModal";
import {mapState} from "vuex";

export default {
  name: "Gallery",
  mixins: [],
  components: {
    GalleryListComponent,
    GallerySettingModal,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
        defaultFilter: {},
    }
  },
  beforeRouterEnter() {},
  created() {
      this.defaultFilter = {no: this.UserInfo.mb_no}
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
      ...mapState({
          UserInfo: state => state.userInfo.UserInfo,
      })
  },
  methods:{
      openModal() {
          this.$modal.show('gallery-setting-modal')
      },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
