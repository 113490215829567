import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            title: '',
            mainFile: [],
            backgroundFile: [],
            itemKey: 0,
            owner: 1,
            info:''
        }
    },
    validators: {
        title: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_name_error'))
        },
        info: function (value) {
            return Validator.value(value)
        },
        owner: function (value) {
            return Validator.value(value)
        },
        thumbnailFile: function (value) {
            return Validator.value(value)

        },
        backgroundFile: function (value) {
            return Validator.value(value)

        },
        itemKey: function (value) {
            return Validator.value(value)
        }
    },
}
