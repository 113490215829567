<template>
    <div class="">
        <collect-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                             :sortData="filterSet.sort"></collect-filter-link>
        <collect-list-link :listLayout="listLayout" :collect-data="List" :paginationData="filterSet.page"
                           v-if="List.length !== 0"></collect-list-link>
        <collect-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" v-else></collect-empty-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList" v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import CollectListLink from "@/components/collect/CollectListLink";
import CollectEmptyLink from "@/components/collect/CollectEmptyLink";
import PaginationLink from "@/components/collect/PaginationLink";
import collectUtil from "@/components/collect/collectUtil";
import CollectFilterLink from "@/components/collect/CollectFilterLink";

const {filterConfig} = collectUtil;
export default {
    name: "CollectListComponent",
    mixins: [filterMixins, listMixins, alertMixins, collectUtil],
    components: {
        CollectFilterLink,
        PaginationLink,
        CollectEmptyLink,
        CollectListLink

    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setDetectionListData: this.setData,
        }
    },
    props: {
        collectType: '',
        defaultFilter: {
            default: () => {
            }
        },
    },
    data() {
        return {
            listLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: 10,
            sortOptions: {},
            keyName: '',
            filterLayout: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('getCollectList', this.getList)
    },
    beforeDestroy() {
        EventBus.$off('getCollectList')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setData(type, value) {
            this[type] = value;
        },
        initConfig() {
            let config = this.returnCollectConfig(this.collectType);
            this.filterLayout = config.filterLayout
            this.listLayout = config.listLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.keyName = config.keyName;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = util.isEmpty(this.listApiParamSet) ? {} : JSON.parse(JSON.stringify(this.listApiParamSet));

            if (!util.isEmpty(this.defaultFilter)) {
                params = Object.assign({}, params, this.defaultFilter);
            }

            let listOptions = {
                url: this.$api.$collect[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
