<template>
    <div class="portfolio_list_wrap">
        <div class="my_portfolio_setting">
            <button class="setting_btn" @click="movePage('/portfolio/edit')">{{ $t('introduce_setting') }}</button><br>
        </div>

        <member-detail-component :memberType="'memberPortfolio'" :memberKey="UserInfo.mb_uuid"></member-detail-component>
    </div>
<!--    <portfolio-list-component :portfolio-type="'portfolioMy'"></portfolio-list-component>-->
</template>

<script>
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import PortfolioListComponent from "@/components/portfolio/PortfolioListComponent";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";

export default {
    name: "MyProfilePortfolio",
    mixins: [AuthMixins, ],
    components: {
        PortfolioListComponent,
        MemberDetailComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            tab: 'galleryMy',
        }
    },
    beforeRouterEnter() {},
    created() {
        // -0--
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        movePage(url){
            this.$router.push(url);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
