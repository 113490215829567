<template>
    <component :is="component" :filterData="filterData" :sortData="sortData" v-if="component"/>
</template>

<script>
export default {
    name: "CurationFilterLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        filterLayout: {},
        filterData: {default: () => {}},
        sortData: {default: () => {}},
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@/template/curation/filter/CurationFilterDefaultLayout')
            })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.filterLayout) {
                return null
            }
            return () => import(`@/template/curation/filter/${this.filterLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
