<template>
    <div>
        <gallery-write-link v-for="writeLayout in writeLayouts" :key="`gallery${writeLayout}`" :galleryData="galleryData"
                            :write-layout="writeLayout"></gallery-write-link>
    </div>
</template>

<script>
import GalleryWriteLink from "@/components/gallery/GalleryWriteLink";
import galleryUtil from "@/components/gallery/galleryUtil";
import galleryValidator from "@/mixins/validators/galleryValidator";
import elFileMixins from "@/mixins/elFileMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import util from "@/mixins/util";
import DeleteClass from "@/mixins/deleteClass";
import EventBus from "@/utils/event-bus";


export default {
    name: "GalleryWriteComponent",
    mixins: [galleryUtil, galleryValidator, elFileMixins, awsS3FileUpload],
    components: {
        GalleryWriteLink,
    },
    inject: [],
    provide() {
        return{
            galleryWriteSetData: this.setData,
        }
    },
    props: {
        galleryType: '',
        galleryKey: {default: 0},
    },
    data() {
        return{
            writeLayouts: '',
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            writeSuccessType: 1,
            writeFailTypes: {},
            writeFailDefault: '',
            writeSuccessAlert: '',
            getFunc: '',
            detailApiParamsSet: {},
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            keyName: '',

            galleryData: {},
            deleteClass: null,
            isRunning: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.initDeleteClass();
        if(this.galleryKey === 0) {
            this.initTemplate();
        }else{
            this.getGallery();
        }
    },
    mounted() {
        EventBus.$on('writeGallery', this.checkValidator);
        EventBus.$on('galleryWriteRunDelete', this.runDelete)
    },
    beforeDestroy() {
        EventBus.$off('writeGallery')
        EventBus.$off('galleryWriteRunDelete')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setData(type, value) {
            this[type] = value;
        },
        initTemplate() {
            let config = this.returnGalleryConfig(this.galleryType);
            this.writeLayouts = config.writeLayouts;

        },
        initConfig(){
            let config = this.returnGalleryConfig(this.galleryType);
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.writeSuccessType = config.writeSuccessType;
            this.writeFailTypes = config.writeFailTypes;
            this.writeFailDefault = config.writeFailDefault;
            this.writeSuccessAlert = config.writeSuccessAlert;
            this.getFunc = config.getFunc;
            this.detailApiParamsSet = config.detailApiParamsSet;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.keyName = config.keyName;
        },
        getGallery() {
            let params = Object.assign({}, this.detailApiParamsSet);
            params.id = this.galleryKey;
            try {
                this.$api.$gallery[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                if(this.directType === 'modal') {
                                    this.$modal.hide(this.directUrl)
                                }else{
                                    this.$router.back();
                                }
                            }
                        })
                        return false;
                    }
                    this.galleryData = res.List[0];
                    this.initTemplate();
                })
            }catch (e) {
                console.log(e);
            }
        },
        initDeleteClass() {
            if(util.isEmpty(this.deleteFunc)) {
                return false;
            }
            let options = {
                func: this.$api.$gallery[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.keyName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        deleteAfterFunc() {
            if(this.directType === 'modal') {
                EventBus.$emit('getGalleryList');

            }
            this.createAlert({
                content: this.$t('deleteSuccessMsg'),
                hide: () => {
                    if(this.directType === 'modal') {
                        this.$modal.hide(this.directUrl)
                        EventBus.$emit('getGalleryList');
                        return false;
                    }else{
                        this.$router.back();
                    }
                }
            })
        },
        checkValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = Object.keys(this.parsingData);

            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeGallery();
                } else {
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`, validatorArr);
                    });
                }
            })
        },
        writeGallery() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                if(this.parsingData[dataKey] != '') {
                    params[this.parsingData[dataKey]] = this[dataKey];
                }
            })
            if(this.galleryKey != 0) {
                params[this.keyName] = this.galleryKey;
            }
            try {
                EventBus.$emit('changeLoading', true)
                this.$api.$gallery[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnWriteType(res.Info.type);
                    if(type === true) {
                        let key = ''
                        if(res.List.hasOwnProperty(this.keyName)) {
                            key = res.List[this.keyName];
                        }else{
                            key = this.galleryKey;
                        }
                        if (!util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDelete(this.deleteFiles)
                        }
                        if (!util.isEmpty(this.fileInfo)) {
                            this.setMultiFileUpload(key, this.fileInfo)
                        }else{
                            this.writeAfterFunc()
                        }
                    }else if(type.openAlert) {
                        this.errorAlert(this.$t(type.text));
                    }else{
                        this.setValidatorErr(this.$t(type.text))
                    }
                    this.isRunning = false;
                    EventBus.$emit('changeLoading', false)
                })
            }catch (e) {
                console.log(e);
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                }
            })
        },
        returnWriteType(type) {
            if(this.writeSuccessType == type) {
                return true;
            }
            return this.writeFailTypes.hasOwnProperty(type) ? this.writeFailTypes[type] : {openAlert: true, text: this.writeFailDefault};
        },
        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            let detailKey = key;

            this.createAlert({
                content: this.$t(this.writeSuccessAlert),
                hide: () => {
                    if (this.directType === 'back') {
                        this.$router.back();
                        return false;
                    }
                    if (this.directUrl === '') {
                        this.writeLayouts = [];
                        this.getGallery();
                        return false;
                    }
                    if(this.directType === 'modal') {
                        this.$modal.hide(this.directUrl)
                        EventBus.$emit('getGalleryList');
                        return false;
                    }
                    let url = this.directUrl;
                    if (this.directType === 'detail') {
                        url = url + detailKey;
                    }
                    this.$router.push(url);
                },
            })
        },
        runDelete() {
            this.deleteClass.runDelete(this.galleryKey);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
