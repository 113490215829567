import {request, setAuthInHeader} from "@/api/request";

const state = {
    Country: 'ko',
};

const getters = {
    getCountry: state => state.Country,
};

const mutations = {
    updateCountry(state, obj) {
        state.Country = obj.value;
    },
    resetCountry(state) {
        state.Country = {};
    },
};

const actions = {
    changeCountry({commit, rootState}, {data}) {
        commit('updateCountry', {'value': data});
    },
    initCountry({commit, rootState}, {data}) {
        commit('resetCountry');
    },
};

export default {namespaced: true, state, getters, mutations, actions}
