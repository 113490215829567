<template>
    <div>
        <item-write-component :item-type="'myItemCreate'"></item-write-component>
    </div>
</template>

<script>
import ItemWriteComponent from "@/components/item/ItemWriteComponent";

export default {
    name: "MyItemCreate",
    mixins: [],
    components: {ItemWriteComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>