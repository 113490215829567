import util from "@/mixins//util";
import alertMixins from "@/mixins/alertMixins";
import {fileuploadS3} from "@/plugins/awsS3Plugin";

let resultCnt = 0;
let hasSuccess = true;
export default {
    mixins: [alertMixins],
    // inject: {parentChange: {default: () => {}}},
    data() {
        return {
            fileType: '',
            imageMaxSize: 20 * 1024 * 1024,
            videoMaxSize: 200 * 1024 * 1024,
            fileMaxSize: 200 * 1024 * 1024,
            fileSizeError: '512MB',
            imageSizeError: '20MB',
            videoSizeError: '300MB',
            fileExceedText: '파일은 한개만 가능합니다.',
            files: [],
            deleteFiles: [],
            afterFileType: '',
        }
    },
    methods: {
        changeType(fileName) {
            this.fileType = fileName;
        },
        regExp(str) {
            let reg = /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\" ]/gi
            if (reg.test(str)) {
                return str.replace(reg, "_");
            } else {
                return str
            }
        },
        makeFileObj(file) {
            file.name = this.regExp(file.name)
            file.org_url = file.url;
            file.org_name = file.name;
            return file;
        },
        handleFileChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');
            file.name = this.regExp(file.name)
            file.org_url = file.url;
            if (file.size > maxSize) {
                this.errorAlert('1회 업로드 가능' + this.imageSizeError + '를 초과했습니다!');
                fileList.pop();
            } else if (!(type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'gif')) {
                this.errorAlert('이미지 파일만 업로드 가능합니다.')
                fileList.pop();
            } else {
                if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                    this.files = fileList;
                    if (this.parentChange) {
                        this.parentChange('files', this.files);
                    }
                    this.$forceUpdate();
                } else {
                    this[this.fileType] = fileList;
                    this.parentChange(this.fileType, fileList);
                    this.$forceUpdate();
                }
            }
        },
        onlyOnePhotoChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');

            file.name = this.regExp(file.name)
            if (file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 ' + this.imageSizeError + '를 초과했습니다!');
                fileList.pop();
            } else if (!(type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'gif')) {
                this.errorAlert('이미지 파일만 업로드 가능합니다.')
                fileList.pop();
            } else {
                file.org_url = file.url;
                // let idx  =file.name.lastIndexOf(".")
                // console.log(file.name.substring(idx+1))
                if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                    if (this.files.length > 0 && this.files[0].hasOwnProperty('a_idx') && this.files[0].a_idx != null) {
                        file.a_idx = this.files[0].a_idx;
                    }
                    this.files[0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange('files', this.files);
                    }
                } else {
                    if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                        file.a_idx = this[this.fileType][0].a_idx;
                    }
                    this[this.fileType][0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange(this.fileType, this[this.fileType]);
                    }
                }
            }
        },
        onlyOneDocsUpload(file, fileList) {
            if (fileList.length > 1) {
                this.errorAlert('하나의 파일만 첨부가능합니다.');
                fileList.pop();
                return false
            }
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');
            let ty = type[1].split('.').pop();
            file.name = this.regExp(file.name)
            if (file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 ' + this.maxSize + '를 초과했습니다!');
                fileList.pop();
            } else if ((ty.indexOf('doc') === -1)) {
                this.errorAlert('doc과 docx 파일만 업로드 가능합니다.')
                fileList.pop();
            } else {
                file.org_url = file.url;
                if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                    if (this.files.length > 0 && this.files[0].hasOwnProperty('a_idx') && this.files[0].a_idx != null) {
                        file.a_idx = this.files[0].a_idx;
                    }
                    this.files[0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange('files', this.files);
                    }
                } else {
                    if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                        file.a_idx = this[this.fileType][0].a_idx;
                    }
                    this[this.fileType][0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange(this.fileType, this[this.fileType]);
                    }
                }
            }

        },
        onlyOneFileUpload(file, fileList) {
            let maxSize = this.videoMaxSize;
            let type = file.raw.type.split('/');

            file.name = this.regExp(file.name)
            if (file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 ' + this.videoSizeError + '를 초과했습니다!');
                fileList.pop();
            } else {
                // file.type = type
                file.org_url = file.url;
                if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                    if (this.files.length > 0 && this.files[0].hasOwnProperty('a_idx') && this.files[0].a_idx != null) {
                        file.a_idx = this.files[0].a_idx;
                    }
                    this.files[0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange('files', this.files);
                    }
                } else {
                    if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                        file.a_idx = this[this.fileType][0].a_idx;
                    }
                    this[this.fileType][0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange(this.fileType, this[this.fileType]);
                    }
                }
            }
        },
        handleFileRemove(file, fileList) {
            if (file.a_idx) {
                this.deleteFiles.push(file);
                if (this.parentChange) {
                    this.parentChange('deleteFiles', this.deleteFiles);
                }
            }
            if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                this.files = util.isEmpty(fileList) ? [] : fileList; //fileList가 undefined일 때의 오류 방지를 위한 코드 추가
                if (this.parentChange) {
                    this.parentChange('files', this.files);
                }
                this.$forceUpdate();
            } else {
                this[this.fileType] = fileList;
                if (this.parentChange) {
                    this.parentChange(this.fileType, fileList);
                }
                this.$forceUpdate();
            }
        },
        handleFileExceed() {
            this.errorAlert(this.fileExceedText);
        },
        uploadFiles(files, type, id) {
            return new Promise((resolve, reject) => {

                if (files.length === 0) {
                    resolve(true)
                }
                return this.uploadApiFile(files, type, id, resolve, 0);
            })
        },
        uploadApiFile(files, type, id, resolve, fileIndex = 0) {
            if (files[fileIndex].hasOwnProperty('raw')) {
                let file = files[fileIndex]
                let formData = new FormData();
                formData.append('files', file.raw);
                formData.append('a_container_type', type);
                formData.append('a_container_id', id);
                if (file.hasOwnProperty('a_idx')) {
                    formData.append('a_idx', file.a_idx);
                }
                try {
                    this.$api.$file.updateFile(formData).then(res => res.Data.Result).then(res => {
                        if (res.Info.type != 1) {
                            hasSuccess = false;
                        }
                        resultCnt = resultCnt + 1;
                        if (resultCnt >= files.length || hasSuccess === false) {
                            resolve(hasSuccess);
                            this.initFileData();
                        } else {
                            this.uploadApiFile(files, type, id, resolve, fileIndex + 1);
                        }

                    })
                } catch (e) {
                    console.log(e)
                }
            } else {
                resultCnt = resultCnt + 1;
                if (resultCnt === files.length || hasSuccess === false) {
                    this.initFileData();
                    resolve(hasSuccess);
                } else {
                    this.uploadApiFile(files, type, id, resolve, fileIndex + 1);
                }
            }
        },
        removeFiles(files) {
            return util.Array.each(files, (file, index, arr) => {
                if (file.hasOwnProperty('a_idx') && file.a_idx != null) {
                    let params = {
                        a_idx: file.a_idx
                    }
                    this.$api.$file.deleteFile(params).then(res => {
                        // console.log(res);
                    })
                }
            })
        },
        initFileData() {
            resultCnt = 0;
            hasSuccess = true;
        },
        overWriteFileUpload(file, fileList, fileName, fileFormat = [], errMsg = '저장 할 수 없는 파일입니다.', isOverwrite = false) {
            if (isOverwrite) {
                fileList = [] //파일업로드시 리스트가 쌓이는것이 아닌 교체가 되기 위해 파일 리스트를 초기화
            }
            let maxSize = fileName === 'video' ? this.videoMaxSize : this.imageMaxSize;
            let sizeError = fileName === 'video' ? this.videoSizeError : this.imageSizeError;
            let type = file.raw.type.split('/');
            let checkFormat = this.returnCheckFileFormat(type[1], fileFormat)
            file.name = this.regExp(file.name)
            if (file.size > maxSize) { //선택한 파일의 용량이 설정값보다 클 경우
                this.errorAlert('1회 업로드 가능 ' + sizeError + '를 초과했습니다!');
                fileList.pop();
            } else if (!checkFormat) { //선택 한 파일의 포맷이 설정한 값에 포함되지 않은 파일이라면
                this.errorAlert(errMsg)
                fileList.pop();
            } else {
                file.org_url = file.url;
                file.name = file.name.replace(/ /g, "")
                if (this[fileName].length > 0 && this[fileName][0].hasOwnProperty('a_idx') && this[fileName][0].a_idx != null) {
                    file.a_idx = this[fileName][0].a_idx;
                }
                //비디오일 경우 스트림을 추가로 제거 하기 위해 스트림의 a_idx 추가합니다.
                if (fileName === 'video' && this['stream'].length > 0 && this['stream'][0].hasOwnProperty('a_idx') && this['stream'][0].a_idx != null) {
                    file.stream_idx = this['stream'][0].a_idx;
                }
                this[fileName][0] = file;
                this.$forceUpdate();
                if (this.parentChange) {
                    this.parentChange(fileName, this[fileName]);
                }
            }
        },
        overWriteFileListUpload(file, fileList, fileName, fileFormat = [], errMsg = '저장 할 수 없는 파일입니다.',) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');
            let checkFormat = this.returnCheckFileFormat(type[1], fileFormat)
            file.name = this.regExp(file.name)
            if (file.size > maxSize) { //선택한 파일의 용량이 설정값보다 클 경우
                this.errorAlert('1회 업로드 가능 ' + this.imageSizeError + '를 초과했습니다!');
                fileList.pop();
            } else if (!checkFormat) { //선택 한 파일의 포맷이 설정한 값에 포함되지 않은 파일이라면
                this.errorAlert(errMsg)
                fileList.pop();
            } else {
                file.org_url = file.url;
                if (this[fileName].length > 0 && this[fileName][0].hasOwnProperty('a_idx') && this[fileName][0].a_idx != null) {
                    file.a_idx = this[fileName][0].a_idx;
                }
                this[fileName] = fileList;
                this.$forceUpdate();
                if (this.parentChange) {
                    this.parentChange(fileName, this[fileName]);
                }
            }
        },
        returnCheckFileFormat(rawType, fileFormat) {
            let check = false
            fileFormat.forEach(format => {
                format === rawType ? check = true : ''
            })
            return check
        },
        fileRemove(file, fileList, fileName) {
            if (file.a_idx) {
                this.deleteFiles.push(file);
                if (this.parentChange) {
                    this.parentChange('deleteFiles', this.deleteFiles);
                }
            }
            this[fileName] = fileList;
            if (this.parentChange) {
                this.parentChange(this[fileName], fileList);
            }
            this.$forceUpdate();
        },


        handleEveryOneFileChange(file, fileList) {
            let type = file.raw.type.split('/');
            file.name = this.regExp(file.name)
            this.afterFileType = type[0];
            file.org_url = file.url;
            file.org_name = file.name;
            let maxSize = this.fileMaxSize;
            console.log(file.size);
            if (file.size > maxSize) {
                this.errorAlert(this.$t('uploadFile_error'));
                return;
            }
            if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                this.files[0] = fileList;
                if (this.parentChange) {
                    this.parentChange('files', this.files);
                }
                this.$forceUpdate();
                return
            } else {
                if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                    file.a_idx = this[this.fileType][0].a_idx;
                }
                this[this.fileType][0] = file;
                this.parentChange(this.fileType, this[this.fileType]);
                this.$forceUpdate();
                return
            }
        },
        handleEveryFileChange(file, fileList) {
            let type = file.raw.type.split('/');
            file.name = this.regExp(file.name)
            file.org_url = file.url;
            file.org_name = file.name;
            let maxSize = this.fileMaxSize;
            console.log(file.size);
            if (file.size > maxSize) {
                this.errorAlert(this.$t('uploadFile_error'));
                return;
            }
            if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                this.files = fileList;
                if (this.parentChange) {
                    this.parentChange('files', this.files);
                }
                this.$forceUpdate();
            } else {
                if (this.fileType === 'original') {
                    this.afterFileType = type[0];
                }
                if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                    file.a_idx = this[this.fileType][0].a_idx;
                }
                this[this.fileType] = fileList;
                this.parentChange(this.fileType, fileList);
                this.$forceUpdate();
            }
        },

    }
}
