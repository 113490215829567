<template>
    <div class="share_btn" @click="runShare()">
        <button class="link"></button>
        <div>{{ $t('link_copy') }}</div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import shareUtil from "@/components/share/shareUtil";
import EventBus from "../../utils/event-bus";
import imageOption from "@/mixins/imageOption";

const {getShareLink, makeFirebaseShortLink} = shareUtil;
export default {
    name: "ShareLinkCopyComponent",
    mixins: [alertMixins,imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        shareType: {default: '',},
        shareData: {
            default: {
                path: '/',
                img: 'https://testimage.minglemint.com/profileBack/no_image.jpg',
                title: `밍글민트(Minglemint NFT Marketplace)`,
                description: `밍글민트(Minglemint NFT Marketplace)`
            }
        },
        runAfter: {
            type: Function, default: () => {
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        runShare() {
            EventBus.$emit('apiLoading', true);
            let $this = this;
            let format = this.returnExtension(this.shareData.img);
            let imgUrl = this.shareData.img + '?' + this.setImageOptions(300, 200, format);
            let img = new Image()
            img.src = imgUrl;
            img.onload = function () {
                setTimeout(() => {
                    $this.copyUrl(imgUrl);
                    document.body.removeChild(img);
                }, 500)
            }
            document.body.appendChild(img);
        },

        async copyUrl(imgUrl) {
            let shareData = {
                img: imgUrl,
                path: this.shareData.path,
                title: this.shareData.title,
                description: this.shareData.description,
            }
            let dynamicLink = await makeFirebaseShortLink(shareData);
            EventBus.$emit('apiLoading', false);
            if (!dynamicLink) {
                this.errorAlert(this.$t('share_fail'));
                return false;
            }

            let tmpTextarea = document.createElement('textarea');

            tmpTextarea.value = dynamicLink;
            tmpTextarea.setAttribute('readonly', '');
            tmpTextarea.style.position = 'absolute';
            tmpTextarea.style.left = '-9999px';
            document.body.appendChild(tmpTextarea);

            tmpTextarea.select();
            tmpTextarea.setSelectionRange(0, 9999);
            let successChk = document.execCommand('copy');
            document.body.removeChild(tmpTextarea);

            if (!successChk) {
                this.errorAlert(this.$t('copy_fail_msg'));
            } else {
                this.createAlert({
                    content: this.$t('copy_msg'),
                    hide: () => {
                        this.runAfter();
                    }
                })
            }

        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
