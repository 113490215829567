import {searchElaUrl} from "@/api/config";
let es = require('elasticsearch');

let AWS = require('aws-sdk');

let AccessKeyId = 'QUtJQTJDQVROSlkyQ1Q0MlFUT00=';
let SecretAccessKey = "a0tRTjBDN05sWHJwY29zOE5VZVhmMzRVQ1M4UGY3YTZNUUxvSUo5ZA==";

let key = atob(AccessKeyId);
let secret = atob(SecretAccessKey)
AWS.config.update({
    credentials: new AWS.Credentials(key, secret),
    region: 'ap-northeast-2'
});
const limitCount = 10000;
let client = new es.Client({
    host: searchElaUrl,
    connectionClass: require('http-aws-es'),
    httpOptions: {}
});

/*var client = new es.Client({
    // host: 'https://search-eot-dev-vtq2lrmbupk227felfdohbogqq.ap-northeast-2.es.amazonaws.com',
    // host: 'https://search-eot-pub-kckftkvfhiytst7hsmwwvzqxv4.ap-northeast-2.es.amazonaws.com',
    host: 'http://search-justreet-dev-7sub6fesdbgbvimnjh2zxmn6hu.ap-northeast-2.es.amazonaws.com',
});*/

let index = '';
let type = '';

function init(myIndex, myType) {
    index = myIndex;
    type = myType;
}

function getTotal(body={}) {
    return client.count({
        index: index,
        body: body
    }).then(resp => {
        return resp;
    }, err => {
        return {count: 0,};
    })
}

function search(body = {}) {
    return client.search({
        index: index,
        type: type,
        body: body
    }).then(function (resp) {
        return resp;
    }, function (err) {
        console.trace(err.message);
        return [];
    });
};

function newScroll(body, size) {
    let scrollParams = {
        index: index,
        type: type,
        scroll: '10s',
        size: size,
        body: body,
    }

    return client.search(scrollParams).then(function (resp) {
        return resp;
    }, function (err) {
        console.trace(err.message);
        return [];
    });
}

function scroll(body, size) {
    let scrollParams = {
        index: index,
        type: type,
        scroll: '10s',
        size: size,
        body: body,
    }
    let getFunc = 'search';
    if(body._scroll_id) {
        scrollParams = {
            scrollId: body._scroll_id,
            scroll: "10s",
        };
        getFunc = 'scroll'
    }
    /*return client.search(scrollParams).then(function (resp) {
        return resp;
    }, function (err) {
        console.trace(err.message);
        return [];
    });*/
    return client[getFunc](scrollParams).then(function (resp) {
        return resp;
    }, function (err) {
        console.trace(err.message);
        return [];
    });
}

function clearScrollId(scrollId) {
    let body = {
        scroll_id: scrollId,
    }
    client.clearScroll({body: body}).then(function (resp) {
        return resp;
    }, function (err) {
        console.log(err);
        return false;
    })
}

export {search, getTotal, init, scroll, clearScrollId, newScroll, limitCount}
