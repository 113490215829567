<template>
    <div class="">
        <div class="reg_middle_wrap">
            <div class="reg_middle">
                <input class="register_nickname" v-model="nick" :placeholder="$t('nickPlaceholder')" maxlength="20" @blur="checkNickDuplicate()" />
                <div class="validation" v-if="validation.hasError('nick') && nick.length > 0">{{ validation.firstError('nick') }}</div>
                <div class="validation" v-else-if="validation.hasError('nickCheckDuplicate')">
                    {{ validation.firstError('nickCheckDuplicate') }}
                </div>
                <div class="possible" v-else-if="!validation.hasError('nickCheckDuplicate') && nick !== ''">
                    {{$t('usableNick')}}
                </div>
            </div>
            <div class="reg_middle">
                <input type="email" class="register_email" :placeholder="$t('email')" v-model="email" @blur="checkEmailDuplicate()" />
                <button class="verify_btn" @click="sendSms()">{{$t('certification')}}</button>
                <div class="validation" v-if="validation.hasError('email') && email.length > 0">
                    {{ validation.firstError('email') }}
                </div>
                <div class="validation" v-else-if="validation.hasError('emailCheckDuplicate') && email.length > 0">
                    {{ validation.firstError('emailCheckDuplicate') }}
                </div>
                <div class="possible" v-else-if="!validation.hasError('emailCheckDuplicate') && email !== ''">
                    {{$t('usableEmail')}}
                </div>
            </div>
            <div class="reg_middle">
                <input class="verify_number" :placeholder="$t('certificationNumber')" v-model="emailChk"
                       @input="checkNum()" @blur="emailCheckFunc()" :disabled="checkGetMail"/> <!--  -->
                <div class="validation" v-if="validation.hasError('emailChk')">
                    {{ validation.firstError('emailChk') }}
                </div>
                <div class="validation" v-if="errorMsg && emailChk.length > 0" >{{$t('c_number_no')}}</div>
                <div class="possible" v-else-if="successMsg" >{{$t('c_number_confirm')}}</div>
            </div>
            <div class="reg_middle">
                <input class="register_password" type="password" :placeholder="$t('pwd')"
                       v-model="pwd"/>
                <div class="validation" v-if="validation.hasError('pwd') && pwd.length > 0">
                    {{ validation.firstError('pwd') }}
                </div>
            </div>
            <div class="reg_middle">
                <input type="password" class="register_password" :placeholder="$t('confirm_pwd')" v-model="pwdChk"/>
                <div class="validation" v-if="validation.hasError('pwdChk') && pwdChk.length > 0">
                    {{ validation.firstError('pwdChk') }}
                </div>
            </div>
            <div class="reg_middle">
                <input class="user_id" type="text" :placeholder="`${$t('r_nick')} (${$t('options')})`" v-model="referrerNick" @blur="referrerNickCheck()"/>
                <div class="validation" v-if="validation.hasError('referrerNick') && referrerNick.length > 0">
                    {{ validation.firstError('referrerNick') }}
                </div>
                <div class="validation" v-else-if="validation.hasError('referrerNickChk')">
                    {{ validation.firstError('referrerNickChk') }}
                </div>
            </div>
        </div>
        <!--      register_middle_wrap-->
        <div class="reg_bottom_wrap">
            <div class="reg_bottom">
                <button class="submit_btn" @click="checkValidator()">{{$t('register')}}</button>
            </div>
            <div class="reg_bottom check_wrap">
                <div class="search_item">
                    <div class="checkbox" :class="{active: checkTerms}" @click="changeCheck('checkTerms')"
                         id="agreeTerm" :checked="checkTerms"></div>
                    <span class="emphasis_font ef1" @click="openModal('terms-modal','service')">{{$t('terms')}}</span>{{$t('and')}}
                    <span class="emphasis_font" @click="openModal('terms-modal','privacy')">{{$t('personal_terms')}}</span>{{$t('agree_terms')}}
                </div>
            </div>
            <div class="reg_bottom check_wrap">
                <div class="search_item">
                    <div class="checkbox" :class="{active: checkAge}" @click="changeCheck('checkAge')"
                         id="agreeAge" :checked="checkAge"></div>
                    <span class="ml24">{{ $t('age14_over') }}</span>
                </div>
            </div>
            <div class="reg_bottom check_wrap">
                <div class="validation  last_val" v-if="btnCheck">{{$t('reject_register')}}</div>
                <div class="validation  last_val" v-else-if="btnCheck2">{{$t('no_age14_over_err')}}</div>
            </div>

        </div>
    </div>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import alertMixins from "@/mixins/alertMixins";
import {loginTokenSet} from "@/mixins/Auth";

export default {
    name: "RegisterComponent",
    mixins: [memberValidator, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            errorMsg: false,
            successMsg: false,
            checkTerms: false,
            checkAge: false,
            checkGetMail: false,
            checkSendMail: true, //
            btnCheck: false,
            btnCheck2: false,
            params: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type})
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if(this[dataName] ) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if(this[dataName]) {
                this.btnCheck = false;
                this.btnCheck2 = false;
            }
        },
        sendSms() {
            this.checkGetMail = false;

            this.$validate(['email']).then(success => {
                if (!success) {
                    return false;
                }
                let lang = this.$localStorage.get('lang');
                let params = {
                    mb_email: this.email
                };
                if (lang === 'en') {
                    params.lan = 'en'
                }
                try {
                    this.$api.$member.sendMail(params).then(res => res.Data.Result).then(res => {
                        if(res.Info.type === -220 ) {
                            return false;
                        }
                        if (res.Info.type === 1 ) {
                            this.createAlert({
                                title: '',
                                content: this.$t('send_num'),
                                btnText: this.$t('yes'),
                                hide: () => {
                                    this.checkSendMail = false
                                }
                            });
                        } else {
                            this.errorAlert(this.$t('r_send_num'));
                        }
                    })
                } catch (e) {
                    console.log(e);
                }
            })
        },
        emailCheckFunc() {
            if (this.checkSendMail) {
                return false;
            }
            if(this.checkGetMail) {
                return false;
            }
            let validatorArr = ['email', 'emailChk'];
            this.$validate(validatorArr).then(success => {
                if (!success) {
                    return false;
                }
                let params = {
                    num: this.emailChk,
                    mb_id: this.email
                }
                try {
                    this.$api.$member.getMail(params).then(res => res.Data.Result).then(res => {
                        if (res.type === 1 ) {
                            // 성공시
                            this.checkGetMail = true;
                            this.successMsg = true;
                            this.errorMsg = false;

                        } else {
                            // 실패시
                            this.errorMsg = true;
                            this.successMsg = false;
                            this.checkGetMail = false;
                        }
                    })
                }catch (e) {
                    console.log(e);
                }
            })
        },
        checkNum() {
            this.emailChk = this.emailChk.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        },
        checkReferrer() {
            let params = {

            }
        },
        checkValidator() {
            if (!this.checkTerms) {
                this.btnCheck = true;
                return false;
            }
            if (!this.checkAge) {
                this.btnCheck2 = true;
                return false;
            }
            if(!this.checkGetMail){
                this.errorAlert(this.$t('c_number_no'))
                return false;
            }
            let validatorArr = ['nick','email', 'emailChk', 'pwd', 'pwdChk', 'nickCheckDuplicate', 'emailCheckDuplicate', 'referrerNickChk'];
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeMember();
                } else {
                    this.errorAlert(this.$t('r_register'));
                }
            })
        },
        writeMember() {
            try {
                if (this.referrerNick === '') {
                    let params = {
                        mb_nick: this.nick,
                        mb_id: this.email,
                        mb_password: this.pwd,
                        mb_password_confirmation: this.pwdChk,
                    }
                    this.params = params;
                } else {
                    let params = {
                        mb_nick: this.nick,
                        mb_id: this.email,
                        mb_password: this.pwd,
                        mb_password_confirmation: this.pwdChk,
                        recommender: this.referrerNick,
                    };
                    this.params = params;
                }
                this.$api.$member.createMember(this.params).then(res => res.Data.Result).then(res => {
                    console.log(res.Info.type);
                    if (res.Info.type === 1 ) {
                        this.createAlert({
                            title: '',
                            content: this.$t('c_register'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                this.$router.push('/auth/register/after')
                            }
                        });
                    } else if (res.Info.type === -241 ) {
                        this.referrerNickChk = false;
                    } else {
                        this.errorAlert(this.$t('r_register'));
                    }
                });
            } catch (e) {
                console.log(e);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
