<template>
    <div>
        <!--        <detection-filter-link :filterLayout="filterLayout"></detection-filter-link>-->
        <detection-write-link v-for="writeLayout in writeLayouts" :key="`detection${writeLayout}`"
                              :write-layout="writeLayout"></detection-write-link>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import DetectionWriteLink from "./DetectionWriteLink";
import detectionUtil from "./detectionUtil";
import detectionValidator from "@/mixins/validators/detectionValidator";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import elFileMixins from "@/mixins/elFileMixins";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "DetectionWriteComponent",
    mixins: [detectionUtil, detectionValidator, alertMixins, elFileMixins, awsS3FileUpload, AuthMixins],
    components: {
        DetectionWriteLink,
    },
    inject: ['setValidatorErr'],
    provide() {
        return {
            detectionWriteSetData: this.setData,
        }
    },
    props: {
        detectionType: '',
        detectionKey: {default: 0},
    },
    data() {
        return {
            filterLayout: '',
            writeLayouts: '',
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            writeSuccessType: 1,
            writeFailTypes: {},
            writeFailDefault: '',
            writeSuccessAlert: '',
            keyName: '',
            updatePoint: false,

            isRunning: false,
            detectPoint: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        if(this.detectionKey === 0) {
            this.initTemplate();
        }else{
            this.getDetection();
        }
    },
    mounted() {
        EventBus.$on('writeDetection', this.checkValidator);

    },
    beforeDestroy() {
        EventBus.$off('writeDetection')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setData(type, value) {
            this[type] = value;
            if(!util.isEmpty(this.setValidatorErr) && util.isFunction(this.setValidatorErr)) {
                this.setValidatorErr("")
            }
        },
        initTemplate() {
            let config = this.returnDetectionConfig(this.detectionType);
            this.writeLayouts = config.writeLayouts;
        },
        initConfig() {
            let config = this.returnDetectionConfig(this.detectionType);
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.writeSuccessType = config.writeSuccessType;
            this.writeFailTypes = config.writeFailTypes;
            this.writeFailDefault = config.writeFailDefault;
            this.writeSuccessAlert = config.writeSuccessAlert;
            this.keyName = config.keyName;
            this.updatePoint = config.updatePoint;
        },
        getDetection() {
            this.initTemplate();
        },
        checkValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = Object.keys(this.parsingData);

            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeDetection();
                } else {
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                        if(!util.isEmpty(this.setValidatorErr) && util.isFunction(this.setValidatorErr)) {
                            this.setValidatorErr(this.$t('require_validator'))
                        }
                    });
                }
            })
        },
        writeDetection() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                if(this.parsingData[dataKey] != '') {
                    params[this.parsingData[dataKey]] = this[dataKey];
                }
            })
            try {
                EventBus.$emit('changeLoading', true)
                this.$api.$detection[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnWriteType(res.Info.type);
                    console.log(type);
                    if(type === true) {
                        let key = ''
                        if(res.hasOwnProperty('List') && res.List.hasOwnProperty(this.keyName)) {
                            key = res.List[this.keyName];
                        }else{
                            key = this.detectionKey;
                        }
                        if (!util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDelete(this.deleteFiles)
                        }
                        if (!util.isEmpty(this.fileInfo)) {
                            this.setMultiFileUpload(key, this.fileInfo)
                        }else{
                            this.updatePointDetect()
                        }
                    }else if(type.openAlert) {
                        this.errorAlert(this.$t(type.text));
                    }else{
                        this.setValidatorErr(this.$t(type.text))
                    }
                    this.isRunning = false;
                    EventBus.$emit('changeLoading', false)
                })
            }catch (e) {
                console.log(e);
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.updatePointDetect(key);
                }
            })
        },
        returnWriteType(type) {
            if(this.writeSuccessType == type) {
                return true;
            }
            return this.writeFailTypes.hasOwnProperty(type) ? this.writeFailTypes[type] : {openAlert: true, text: this.writeFailDefault};
        },
        updatePointDetect(key) {
            if(this.updatePoint && this.detectPoint > 0) {
                let myPoint = this.getMyPoint();
                myPoint = myPoint - this.detectPoint;
                let changeUserInfo = this.UserInfo;
                changeUserInfo.point = myPoint;
                this.$store.dispatch('userInfo/changeUserInfo', {data: changeUserInfo});
            }
            this.writeAfterFunc(key);

            /*let params = {
                pd_code:'DETECT',
                pd_point: this.detectPoint,
            }
            try {
                this.$api.$point.updatePointDetect(params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1) {
                        this.writeAfterFunc(key);
                    }else{
                        this.errorAlert(this.writeFailDefault);
                        this.isRunning = false;
                        EventBus.$emit('changeLoading', false)
                    }
                })
            }catch (e) {
                console.log(e);
                this.isRunning = false;
                EventBus.$emit('changeLoading', false)
            }*/
        },
        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            let detailKey = key;

            this.createAlert({
                content: this.$t(this.writeSuccessAlert),
                hide: () => {
                    if (this.directType === 'back') {
                        this.$router.back();
                        return false;
                    }
                    if (this.directType === 'getItemList') {
                        EventBus.$emit('getItemList');
                        return false;
                    }
                    if (this.directType === 'getDetectionList') {
                        EventBus.$emit('getDetectionList');
                        return false;
                    }
                    if (this.directUrl === '') {
                        this.writeLayouts = [];
                        return false;
                    }
                    if(this.directType === 'modal') {
                        this.$modal.hide(this.directUrl)
                        if(this.detectionType === 'urlWrite') {
                            EventBus.$emit('getDetectionList');
                        }
                        return false;
                    }
                    let url = this.directUrl;
                    if (this.directType === 'detail') {
                        url = url + detailKey;
                    }
                    this.$router.push(url);
                },
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
