<template>
    <div class="submit_main_wrap">
        <div class="search_item_wrap terms_content_btn">
            <div class="search_item">
                <el-checkbox v-model="termsCheck" @change="changeCheck">
                    <span class="item_text_span bold" @click="openModal('terms-modal','licenseSell')">{{ $t('license_terms') }}</span>
<!--                    <span class="item_text_span">{{ $t('agree_terms2') }}</span>-->
                  <span v-html="$t('agree_terms2')"></span>

                </el-checkbox>
            </div>
        </div>
        <div class="submit_wrap item_create_btn">
            <div class="submit_btn">
                <button class="enroll_submit_btn" @click="checkTerms('license')" >{{ $t('save') }}</button>
            </div>
        </div>
        <div class="caption_check">
            <div class="mt10" v-if="showTermsMsg"><p>{{ $t('caption_check') }}</p></div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemLicenseSaveLayout",
    mixins: [],
    components: {
    },
    props: {
        setComponentData: {default: () => {}, type: Function},
        checkValidator: {default: () => {}, type: Function},
        itemData: {default: () => {}, type: Object},
        itemClass: {default: null, }
    },
    data() {
        return {
            termsCheck: false,
            showTermsMsg: false,
        }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        changeCheck() {
            if(this.showTermsMsg) {
                this.showTermsMsg = false;
            }
        },
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        checkTerms(itemType) {
            if (!this.termsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            this.showTermsMsg = false;
            this.save(itemType)
        },
        save(itemType) {
            this.setComponentData('itemType', itemType);
            this.checkValidator();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
