<template>
  <div>
    <award-write-component :award-type="'awardWrite'" :competitionKey="competitionKey"></award-write-component>
    <award-selection-rule-modal></award-selection-rule-modal>
    <entry-search-modal></entry-search-modal>
<!--    <terms-modal></terms-modal>-->
  </div>
</template>

<script>
import AwardWriteComponent from "@/components/award/AwardWriteComponent";
import AwardSelectionRuleModal from "@/components/modal/AwardSelectionRuleModal";
import EntrySearchModal from "@/components/modal/EntrySearchModal";
import TermsModal from "@/components/modal/TermsModal";

export default {
  name: "AwardWrite",
  mixins: [],
  components: {
    TermsModal,
    AwardWriteComponent,
    AwardSelectionRuleModal,
    EntrySearchModal,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
        competitionKey : this.$route.params.c_idx
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
