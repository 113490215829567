<template>
    <div>
<!--        <creator-detail-tabs :nowTab="'collection'" :memberKey="memberKey"></creator-detail-tabs>-->
        <gallery-list-component :memberKey ="memberKey" :gallery-type="'creatorGallery'" :defaultFilter="defaultFilter"></gallery-list-component>
    </div>

</template>

<script>
import CreatorDetailTabs from "@/components/tabs/CreatorDetailTabs";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";

export default {
    name: "CreatorDetailCollection",
    mixins: [],
    components: {
        CreatorDetailTabs,
        GalleryListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {uid: this.memberKey};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
    },
    watch: {},
}
</script>

<style scoped>

</style>
