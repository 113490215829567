<template>
    <modal name="curation-history-modal" class="curation-history-modal"
           :width="`1200px`" :height="`739px`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="ch_modal_wrap">
            <div class="ch_modal_title">
                <span>{{ $t('curation_history1') }}</span>
                <button class="cancel_btn" @click="modalClose"></button>
            </div>
            <curation-vuex-list-component v-if="showHistory" :curation-type="'curationHistory'" :defaultFilter="defaultFilter"></curation-vuex-list-component>
        </div>
    </modal>
</template>

<script>
import CurationVuexListComponent from "@/components/curation/CurationVuexListComponent";
import util from "@/mixins/util";

export default {
    name: "CurationHistoryModal",
    mixins: [],
    components: {
        CurationVuexListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberKey: {default: 0},
    },
    data() {
        return {
            url: '',
            showHistory: false,
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
            if(!util.isEmpty(event.params) && !util.isEmpty(event.params.memberKey)) {
                this.defaultFilter = {no: event.params.memberKey}
            }else{
                this.defaultFilter = {no: this.memberKey}
            }
            this.showHistory = true;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.showHistory = false;
        },
        modalClose() {
            this.$modal.hide('curation-history-modal');
        },
        returnUrl(){
            return this.url
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
