import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            title: '',
            poster: [],
            category: 2,
            hashtag: [],
            organize: '',
            target: '',
            content: '',
            license: '',
            evaluate: [],
            intro: [],
            is_judge: 0,
            judge: [],
            prize: 100,
            startApplyDate: '',
            startStatus: 0,
            endApplyDate: '',
            releaseDate: '',
            chance: 1,
            u_judge: {
                create: [],
                update: [],
                delete: []
            },
            c_idx: '',
        }
    },
    computed: {},
    validators: {
        title: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_c_title'))
        },
        poster: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_poster'))
        },
        category: function (value) {
            return Validator.value(value)
                .required(this.$t('err_select_category'))
        },
        hashtag: function (value) {
            return Validator.value(value)
                .required(this.$t('itemErrorMsg8'))
        },
        organize: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_organize'))
        },
        target: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_target'))
        },
        content: function (value) {
            return Validator.value(value)
                .required(this.$t('board_write_con'))
        },
        license: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_license'))
        },
        evaluate: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_evaluate'))
        },
        intro: function (value) {
            return Validator.value(value)
                .custom(() => {
                        if (value.length === 0) {
                            return this.$t('err_input_intro')
                        }
                    }
                )
        },
        is_judge: function (value) {
            return Validator.value(value)
        },
        judge: function (value) {
            return Validator.value(value)
                .required(this.$t('err_select_judge'))
        },
        prize: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_prize'))
                .custom(() => {
                    if (value === '' || Number(value) === 0) {
                        return this.$t('empty_price')
                    }

                    let str = String(value);
                    let last = String(str.substring(str.length - 2, str.length))
                    if (last !== '00') {
                        return this.$t('Mg_msg')
                    }
                })
        },
        startApplyDate: function (value) {
            return Validator.value(value)
                .custom(() => {
                        if (this.startStatus === 1 && value === '') {
                            return this.$t('err_input_start_time');
                        }
                    }
                )

        },
        endApplyDate: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_end_time'))
                .custom(() => {
                        if (value <= this.startApplyDate) {
                            return this.$t('award_error_msg2');
                        }
                        if (this.startStatus === 0) {
                            if (value < this.$moment().format('YYYY-MM-DD HH:mm')) {
                                return this.$t('award_error_msg2');
                            }
                        }
                    }
                )
        },
        releaseDate: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_release'))
                .custom(() => {
                        if (value <= this.endApplyDate) {
                            return this.$t('award_error_msg');
                        }
                    }
                )
        },
        chance: function (value) {
            return Validator.value(value)
                .required(this.$t('err_input_count'))
        },
        u_judge: function (value) {
            return Validator.value(value)
                .custom(() => {
                        if (value.create.length === 0 && value.update.length === 0) {
                            return this.$t('err_select_judge')
                        }
                    }
                )
        },

    },
    methods: {}
}
