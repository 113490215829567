<template>
  <div>
    <find-component :member-type="`tempPassword`"></find-component>
  </div>
</template>

<script>
import FindComponent from "@/components/login/FindComponent";

export default {
  name: "FindPassWord",
  mixins: [],
  components: {
    FindComponent
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>