<template>
    <div>
        <member-write-link v-for="writeLayout in writeLayouts" :key="`member${writeLayout}`"
                           :writeLayout="writeLayout" :memberData="memberData"></member-write-link>
    </div>
</template>

<script>
import MemberWriteLink from "@/components/member/MemberWriteLink";
import memberUtil from "@/components/member/memberUtil";
import memberValidator from "@/mixins/validators/memberValidator";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";

export default {
    name: "MemberWriteComponent",
    mixins: [memberUtil, memberValidator, alertMixins, awsS3FileUpload],
    components: {
        MemberWriteLink
    },
    inject: [],
    provide() {
        return {
            memberSetData: this.setData
        }
    },
    props: {
        memberType: '',
        memberKey: {default: 0},
    },
    data() {
        return {
            writeLayouts: [],
            writeFunc: '',
            getFunc: '',
            detailApiParamSet: {},
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            keyName: '',
            writeSuccessType: '',
            writeFailTypes: {},
            updateType: '',
            memberData: {},
            deleteFiles: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        if (util.isEmpty(this.getFunc) || this.memberKey === 0) {
            this.initTemplateConfig();
        } else {
            this.getMember();
        }
    },
    mounted() {
        EventBus.$on('updateMember', this.checkValidator)
    },
    beforeDestroy() {
        EventBus.$off('updateMember')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initTemplateConfig() {
            let config = this.returnMemberConfig(this.memberType);
            this.writeLayouts = config.writeLayouts;
        },
        initConfig() {
            let config = this.returnMemberConfig(this.memberType);
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.fileInfo = config.fileInfo;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.updateType = config.updateType;
            this.keyName = config.keyName;
            this.writeSuccessType = config.writeSuccessType;
            this.writeFailTypes = config.writeFailTypes;
        },
        getMember() {
            EventBus.$emit('changeLoading', true);
            let params = Object.assign({}, this.detailApiParamSet);
            if (this.memberKey != 0) {
                params.no = this.memberKey;
            }
            try {
                this.$api.$member[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if (res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    this.memberData = res.List[0];
                    this.initTemplateConfig();
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
        checkValidator() {
            EventBus.$emit('apiLoading', true);
            let validatorArr = Object.keys(this.parsingData);
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.updateMember();
                } else {
                    EventBus.$emit('apiLoading', false);
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                    });
                }
            })
        },
        setData(key, value) {
            this[key] = value
        },
        updateMember() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                params[this.parsingData[dataKey]] = this[dataKey];
            })
            this.$api.$member[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== this.writeSuccessType) {
                    EventBus.$emit('apiLoading', false);
                    this.updateReturnType(res.Info.type)
                    return false;
                }
                if (!util.isEmpty(this.fileInfo)) {
                    this.runS3ApiDeleteFiles(this.deleteFiles).then(res => {
                        this.setMultiFileUpload(this.memberKey, this.fileInfo)
                    });
                } else if (!util.isEmpty(this.deleteFiles)) {
                    this.runS3ApiDeleteFiles(this.deleteFiles)
                } else {
                    this.writeAfterFunc(res.List);
                }


            });

        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                }
            })
        },
        writeAfterFunc(key) {
            EventBus.$emit('apiLoading', false);
            this.createAlert({
                content: this.$t('updateMsg'),
                hide: () => {
                    if (this.directType === 'back') {
                        this.$router.back();
                        return false;
                    }
                    if (this.directType === 'modal') {
                        if (this.updateType === 'memberInfo') {
                            EventBus.$emit('checkData', 1);
                            this.setUserInfo();
                        }else if(this.updateType == 'getUserInfo') {
                            this.getUserInfo();
                        }
                        if (this.updateType === 'getMemberDetail') {
                            EventBus.$emit('memberDetailGet', true)
                        }
                        this.$modal.hide(this.directUrl)
                        this.$forceUpdate();
                        return
                    }
                    let url = this.directUrl;
                    if (this.directType === 'detail') {
                        url = url + key;
                    }
                    this.$router.push(url);
                }
            })
        },
        setUserInfo() {
            let updateUserInfo = this.UserInfo;
            if (this.memberType !== 'updateReferrer') {
                util.Object.each(this.parsingData, (key, value, obj) => {
                    updateUserInfo[value] = this[key];
                });
            } else {
                updateUserInfo.Recommend = [{mb_nick: this.referrerNick}]
                // updateUserInfo.Recommend[0].mb_id = this.referrerNick;
            }
            this.$store.dispatch('userInfo/changeUserInfo', {data: updateUserInfo});

            util.Array.each(this.writeLayouts, (layout, index, arr) => {
                EventBus.$emit(`${layout}ForceUpdate`);
            });
        },
        updateReturnType(type) {
            if (type === -221) {
                EventBus.$emit('checkData');
            } else if (type === -213) {
                EventBus.$emit('checkData', true);
            } else if (type === -241) {
                EventBus.$emit('checkData', true);
            } else if (type === -242) {
                EventBus.$emit('checkData', true);
            } else {
                this.errorAlert(`${this.$t('d_change_status_err1')}`);
            }
        },
        getUserInfo() {
            let token = this.$localStorage.get('token');
            this.$store.dispatch('userInfo/setUserInfo',{token: token});
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
