<template>
    <div class="popup_wrap" v-if="popupStatus">
        <button @click="closePopup()" class="back_btn"></button>
        <div class="popup_title">< 공지사항 ></div>
        <div>* 작업일자 : 2022-12-02(금) 오후 3시30분 ~ 5시</div>
        <div>* 설명문구 : 블록체인 업그레이드로 진행할 예정이므로, 밍글민트 서비스에 접속제한이 될 수도 있습니다.</div>
    </div>
</template>

<script>

import dateMixins from "@/mixins/dateMixins";

export default {
    name: "PopupComponent",
    mixins: [dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            popupStatus: true
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.checkPopup();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        closePopup() {
            this.popupStatus = false;
        },
        checkPopup() {
            let currentTime = this.$moment();
            let endTime = this.$moment('2022-12-02 17:01:00');
            let diff = endTime.diff(currentTime, 'second')
            if (diff < 1) {
                this.popupStatus = false;
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
