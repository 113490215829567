<template>
  <div class="login_main_wrap">

    <div class="login_middle_wrap">
      <div class="login_middle">
        <input type="email" class="register_email" :placeholder="$t('email')" v-model="id" @keypress.enter="checkValidator()"/>
      </div>
      <div class="login_middle">
        <input class="register_password" type="password" v-model="password" @keypress.enter="checkValidator()"
               :placeholder="$t('pwd')"/>
      </div>
    </div>
    <div class="login_btn_wrap">
      <div class="login_bottom">
        <button class="login_btn mb9" @click="checkValidator()">{{$t('login')}}</button>

<!--          <span v-if="errorMsg != ''">{{ errorMsg }}</span>-->

      </div>
    </div>
    <div class="search_login">
      <div class="search_login_wrap">
        <div class="search_login_middle">
          <button class="id_login" @click="movePage('/find/password')">{{$t('find_pwd')}}</button>
          <button class="password_login" @click="movePage('/auth/register')">{{$t('register')}}</button>
          <div class="validation" v-if="errorMsg != ''">{{ errorMsg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import memberValidator from "@/mixins/validators/memberValidator";
import {loginTokenSet, getTokenObj} from "@/mixins/Auth";
import util from "@/mixins/util";
import checkLevel from "@/mixins/checkLevelMixins";

export default {
  name: "LoginComponent",
  mixins: [memberValidator ,alertMixins, checkLevel],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      redirect: '/',
      errorMsg: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    if (!util.isEmpty(this.$route.query.redirect)) {
      this.redirect = this.$route.query.redirect;
    }
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    checkValidator() {
      this.errorMsg = '';
      this.$validate('id').then(success => {
        if(success) {
          this.$validate('password').then(success => {
            if (success) {
              this.login();
            } else {
              this.errorMsg = this.$t('write_pwd');
            }
          })
        } else {
          this.errorMsg = this.$t('write_id_msg');
        }
      })
    },
    login() {
      let params = {
        mb_id: this.id,
        mb_password: this.password,
      }
      try {
        this.$api.$auth.login(params).then(res => res.Data.Result).then(res => {
          console.log("1111111111",res)
          let type = this.loginReturnType(res.Info.type);
          console.log("1111111111",type,type === true)
          if (type === true) {
            console.log("22222222")
            this.errorMsg = '';
            let token = res.List;
            loginTokenSet(token);
            this.checkLevel();
          } else if (type !== false) {
            this.errorMsg = this.$t('login_error_Msg');
            this.errorAlert(type);
          }
        })
      } catch (e) {
        console.log(e);
      }
    },
    loginReturnType(type) {
      switch (type) {
        case 1:
          return true;
        case -3:
          return this.$t('login_error_Msg');
        default:
          return this.$t('error_msg2');
      }
    },
    movePage(url) {
      this.$router.push(url);
    },
    // autoTrim() {
    //   this.id = this.id.replace(' ', '');
    // },
    // setData(key, value) {
    //   this[key] = value
    //   if (this.errorMsg != '') {
    //     this.errorMsg = '';
    //   }
    // },

  },
  watch: {},

}
</script>

<style scoped>

</style>
