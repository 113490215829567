<template>
    <div>
        <div class="mypage_main">
            <my-page-header-component></my-page-header-component>
            <div class="mypage_list"  style="border-top: none;">
                <member-write-component :member-type="tab"></member-write-component>

                <div class="m_b_wrap">
                    <a href="/auth/unsubscribe" class="page_link">{{ $t('unsubscribe') }}</a>
                </div>
            </div>
        </div>
        <mypage-nick-modal></mypage-nick-modal>
        <mypage-pw-modal></mypage-pw-modal>
        <mypage-phone-modal></mypage-phone-modal>
        <mypage-authentication-modal></mypage-authentication-modal>
        <mypage-bank-modal></mypage-bank-modal>
        <mypage-referrer-modal></mypage-referrer-modal>
    </div>
</template>

<script>
import MemberWriteComponent from "@/components/member/MemberWriteComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import MypageNickModal from "@/components/modal/MypageNickModal";
import MypagePwModal from "@/components/modal/MypagePwModal";
import MypagePhoneModal from "@/components/modal/MypagePhoneModal";
import MypageReferrerModal from "@/components/modal/MypageReferrerModal";
import MypageBankModal from "@/components/modal/MypageBankModal";
import MypageAuthenticationModal from "@/components/modal/MypageAuthenticationModal";

export default {
    name: "MyPageUpdate",
    mixins: [],
    components: {
        MyPageHeaderComponent,
        MemberWriteComponent,
        MypageAuthenticationModal,
        MypageBankModal,
        MypageReferrerModal,
        MypagePhoneModal,
        MypagePwModal,
        MypageNickModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'memberUpdate',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
          this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>