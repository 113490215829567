<template>
    <div>
        <div class="creator_detail_wrap">

            <member-detail-component :member-type="'myProfile'"></member-detail-component>
            <my-profile-tabs></my-profile-tabs>
            <router-view></router-view>
            <!--        <gallery-list-component :gallery-type="'profileGallery'" :defaultFilter="{no: UserInfo.mb_no}"></gallery-list-component>-->

        </div>
        <curation-history-modal :memberKey="UserInfo.mb_no"></curation-history-modal>
        <share-modal></share-modal>
    </div>
</template>

<script>
import MyProfileTabs from "@/components/mypage/MyProfileTabs";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import {logout} from "@/mixins/Auth";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import ShareModal from "@/components/modal/ShareModal";

export default {

  metaInfo: {
    meta:[
      {
        hid: 'og:image',
        property: 'og:image',
        content: 'http://testimage.minglemint.com/banner/12/16391234774371639122313186.jpg'
      },
    ]
  },

    name: "MyProfile",
    mixins: [AuthMixins, alertMixins],
    components: {
        ShareModal,
        MyProfileTabs,
        MemberDetailComponent,
        GalleryListComponent,
        CurationHistoryModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'galleryMy',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        // -0--
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
        },
        logout() {
            const param = {
                content: this.$t('logout_confirm'),
                confirm: () => {
                    logout();
                }
            };
            this.createConfirm(param);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
