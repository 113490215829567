<template>
    <component :is="component" :filterData="filterData" v-if="component"/>
</template>

<script>
export default {
    name: "DetectionFilterLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        filterLayout: {},
        filterData: {default: () => {}},
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/detection/filter/DetectionFilterDefaultLayout')
                })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.filterLayout) {
                return null
            }
            return () => import(`@/template/detection/filter/${this.filterLayout}`)
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
