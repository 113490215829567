import Vue from 'vue'
import Vuex from 'vuex'

import userInfo from "./user/UserInfo"
import itemCategory from "./item/itemCategory";
import competitionCategory from "@/store/competition/competitionCategory";
import country from "./country/Country"
import common from "@/store/common/common";
import collection from "@/store/collection/Collection";
import utilityCategory from "@/store/utility/utilityCategory";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        common,
        userInfo,
        itemCategory,
        competitionCategory,
        country,
        collection,
        utilityCategory,

    }
})
