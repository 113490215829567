import {authPageCheck, storeSet} from "@/mixins/Auth.js"
import VueRouter from 'vue-router'

function setViewResolver(component) {
    return require('../views/' + component).default;
}

const layout = {
    render(h) {
        return h('router-view')
    }
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload()
    })
};


export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name : 'Home',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: 'Home', title: '',  storeRequired:true, transitionName: 'none',},
    },
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/auth',
        component: setViewResolver('auth/Login'),
        name: 'Login',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "Login", title: "로그인", transitionName: 'none'},
    },
    {
        path: '/auth/sns',
        component: setViewResolver('auth/RegisterSocial'),
        name: 'SnsLogin',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "SnsLogin", title: "소셜로그인", transitionName: 'none'},
    },{
        path: '/auth/naver/callback',
        component: setViewResolver('auth/NaverCallback'),
        name: 'SnsLogin',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "SnsLogin", title: "소셜로그인", transitionName: 'none'},
    },
    {
        path: '/auth/certification/result',
        component: setViewResolver('auth/AuthenticationResult'),
        name: 'AuthenticationResult',
        meta: {name: "AuthenticationResult", title: "실명인증 결과", transitionName: 'none'},
    },
    {
        path: '/auth/register',
        component: setViewResolver('auth/Register'),
        name: 'Register',
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/email',
        component: setViewResolver('auth/RegisterEmail'),
        name: 'SNSRegister',
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/sns',
        component: setViewResolver('auth/RegisterSocial'),
        name: 'Register',
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/after',
        component: setViewResolver('auth/RegisterAfter'),
        name: 'RegisterAfter',
        meta: {name: "RegisterAfter", title: "회원가입완료", transitionName: 'none'},
    },
    {
        path: '/auth/unsubscribe',
        component: setViewResolver('auth/Unsubscribe'),
        name: 'Unsubscribe',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Unsubscribe", title: "회원탈퇴", storeRequired:true, transitionName: 'none'},
    },

    {
        path: '/auth/unsubscribe/result',
        component: setViewResolver('auth/UnsubscribeResult'),
        name: 'UnsubscribeResult',
        meta: {name: "UnsubscribeResult", title: "회원탈퇴 완료", transitionName: 'none'},
    },
    {
        path: '/find/password',
        component: setViewResolver('auth/FindPassWord'),
        name: 'FindPassWord',
        meta: {name: "FindPassWord", title: "비밀번호 찾기", transitionName: 'none'},
    },
    {
        path: '/search',
        component: setViewResolver('search/Search'),
        name: 'Search',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Search", title: "검색",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/search/:type',
        component: setViewResolver('search/SearchDetail'),
        name: 'SearchDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "SearchDetail", title: "검색",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/enroll',
        component: setViewResolver('enroll/Enroll'),
        name: 'Enroll',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Enroll", title: "등록",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/alarm',
        component: setViewResolver('alarm/Alarm'),
        name: 'Alarm',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Alarm", title: "알림",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/item',
        component: setViewResolver('item/Item'),
        name: 'Item',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Item", title: "아이템",storeRequired:true, transitionName: 'none'},
    },

    {
        path: '/competitioncreate',
        component: setViewResolver('competition/CompetitionCreate'),
        name: 'CompetitionCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionCreate", title: "공모전 등록",  authRequired: true, storeRequired: true, storeConfig: {userInfo: true},transitionName: 'none'},
    },
    {
        path: '/competition',
        component: setViewResolver('competition/Competition'),
        name: 'Competition',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Competition", title: "공모전",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/competition/:idx',
        component: setViewResolver("competition/CompetitionDetail"),
        name: "CompetitionDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionDetail", title: "공모전 상세",storeRequired:true,}
    },
    {
        path: '/competition/:idx/update',
        component: setViewResolver("competition/CompetitionUpdate"),
        name: "CompetitionUpdate",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionUpdate", title: "공모전 상태 변경",storeRequired:true,authRequired: true}
    },
    {
        path: '/itemcreate',
        component: setViewResolver('item/ItemCreate'),
        name: 'ItemCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemCreate", title: "아이템 등록", transitionName: 'none',storeRequired:true, authRequired: true, storeConfig: {userInfo: true}},
    },
    {
        path: '/item/update/:idx',
        component: setViewResolver('item/ItemUpdate'),
        name: 'ItemUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemUpdate", title: "아이템 수정", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true}},
    },
    {
        path: '/gallery/:idx/itemadd',
        component: setViewResolver('item/ItemAdd'),
        name: 'ItemAdd',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemAdd", title: "아이템 추가",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/creator',
        component: setViewResolver('member/Creator'),
        name: 'Creator',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Creator", title: "크리에이터",storeRequired:true, transitionName: 'none'},

    },
    {
        path: '/creator/:idx',
        component: setViewResolver("member/CreatorDetail"),
        name: "CreatorDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,},
        children: [
            {
                path: '',
                component: setViewResolver("member/CreatorDetailCollection"),
                name: "CreatorDetailCollection",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'premium',
                component: setViewResolver("member/CreatorDetailPremium"),
                name: "CreatorDetailPremium",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'market',
                component: setViewResolver("member/CreatorDetailMarket"),
                name: "CreatorDetailMarket",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'collect',
                component: setViewResolver("member/CreatorDetailCollect"),
                name: "CreatorDetailCollect",
                meta: {name: "CreatorDetailCollect", title: "유저 프로필",storeRequired: true,}
            },
            {
                path: 'competition',
                component: setViewResolver("member/CreatorDetailCompetition"),
                name: "CreatorDetailCompetition",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'creation',
                component: setViewResolver("member/CreatorDetailCreation"),
                name: "CreatorDetailCreation",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'portfolio',
                component: setViewResolver("member/CreatorDetailPortfolio"),
                name: "CreatorDetailPortfolio",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
        ],
    },
    {
        path: '/gallery',
        component: setViewResolver('gallery/Gallery'),
        name: 'Gallery',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Gallery", title: "갤러리",storeRequired:true, transitionName: 'none', storeConfig: {userInfo: true}},

    },
    {
        path: '/gallery/:idx/edit',
        component: setViewResolver('gallery/GalleryEdit'),
        name: 'GalleryEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "GalleryEdit", title: "갤러리 편집", authRequired: true, storeRequired: true, storeConfig: {userInfo: true} , transitionName: 'none'},

    },
    {
        path: '/gallery/:idx',
        component: setViewResolver("gallery/GalleryDetail"),
        name: "GalleryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "GalleryDetail", title: "갤러리 상세",storeRequired:true,},
    },
    {
        path: '/collect',
        component: setViewResolver('collect/Collect'),
        name: 'Collect',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Collect", title: "컬렉션",storeRequired:true, transitionName: 'none', storeConfig: {userInfo: true}},

    },
    {
        path: '/collect/add',
        component: setViewResolver('collect/CollectEdit'),
        name: 'CollectEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CollectEdit", title: "컬렉션 편집", authRequired: true, storeRequired: true, storeConfig: {userInfo: true} , transitionName: 'none'},

    },
    {
        path: '/collect/:idx',
        component: setViewResolver("collect/CollectDetail"),
        name: "CollectDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CollectDetail", title: "컬렉션 상세",storeRequired:true,},
    },
    {
        path: '/curation/:mb_no/request',
        component: setViewResolver('curation/CurationRequest'),
        name: 'CurationRequest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationRequest", title: "큐레이션 의뢰", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}},

    },
    {
        path: '/curation/:mb_no/request/:i_idx',
        component: setViewResolver("curation/CurationRequestDetail"),
        name: "CurationRequestDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationRequestDetail", title: "큐레이션 의뢰 상세",storeRequired:true,}

    },
    {
        path: '/curationapply',
        component: setViewResolver('curation/CurationApply'),
        name: 'CurationApply',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationApply", title: "큐레이션 신청",storeRequired:true,authRequired: true, transitionName: 'none'},
    },
    {
        path: '/portfolio',
        component: setViewResolver('portfolio/Portfolio'),
        name: 'portfolio',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationApply", title: "포트폴리오",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/portfolio/edit',
        component: setViewResolver('portfolio/PortfolioEdit'),
        name: 'PortfolioEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PortfolioEdit", title: "포트폴리오 편집",authRequired: true, storeRequired: true, storeConfig: {userInfo: true}},

    },
    {
        path: '/myprofile',
        component: setViewResolver('mypage/MyProfile'),
        name: 'MyProfile',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyProfile", title: "내 프로필", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
        children: [
            {
                path: '',
                component: setViewResolver("mypage/MyProfileCollection"),
                name: "MyProfileCollection",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'premium',
                component: setViewResolver("mypage/MyProfilePremium"),
                name: "MyProfilePremium",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'market',
                component: setViewResolver("mypage/MyProfileMarket"),
                name: "MyProfileMarket",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'collect',
                component: setViewResolver("mypage/MyProfileCollect"),
                name: "MyProfileCollect",
                meta: {name: "MyProfileCollect", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'competition',
                component: setViewResolver("mypage/MyProfileCompetition"),
                name: "MyProfileCompetition",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'creation',
                component: setViewResolver("mypage/MyProfileCreationItem"),
                name: "MyProfileCreation",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'portfolio',
                component: setViewResolver("mypage/MyProfilePortfolio"),
                name: "MyProfilePortfolio",
                meta: {name: "MyProfile", title: "내 프로필",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
        ],
    },
    {
        path: '/mypage',
        component: setViewResolver('mypage/MyPageItem'),
        name: 'MyPage',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPage", title: "마이페이지",
            authRequired: true, storeRequired: true, storeConfig: {userInfo: true},
            transitionName: 'none'},
    },
    {
        path: '/mypage/item',
        component: setViewResolver('mypage/MyPageItem'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageItem',
        meta: {name: "MyPageItem", title: "마이페이지 아이템",
            authRequired: true, storeRequired: true, storeConfig: {userInfo: true},
            transitionName: 'none'},
    },
    {
        path: '/mypage/item/:idx/history',
        component: setViewResolver('history/ItemHistory'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'ItemHistory',
        meta: {name: "ItemHistory", title: "거래내역서", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },

    {
        path: '/mypage/update',
        component: setViewResolver('mypage/MyPageUpdate'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageUpdate',
        meta: {name: "MyPageUpdate", title: "마이페이지 정보수정", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},

    },
    {
        path: '/mypage/point',
        component: setViewResolver('mypage/MyPagePoint'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPagePoint',
        meta: {name: "MyPagePoint", title: "마이페이지 포인트", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/deposit',
        component: setViewResolver('mypage/MyPageDeposit'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPagePoint',
        meta: {name: "MyPagePoint", title: "마이페이지 입금하기", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
/*    {
        path: '/mypage/point/status',
        component: setViewResolver('mypage/MyPagePoint'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPagePoint',
        meta: {name: "MyPagePoint", title: "마이페이지 포인트", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },*/
    {
        path: '/mypage/point/history',
        component: setViewResolver('mypage/MyPagePointHistory'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPagePointHistory',
        meta: {name: "MyPagePointHistory", title: "마이페이지 포인트 사용내역", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point/charge',
        component: setViewResolver('mypage/PointCharge'),
        name: 'PointCharge',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PointCharge", title: "충전", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point/withdraw',
        component: setViewResolver('mypage/PointWithdraw'),
        name: 'PointWithdraw',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PointWithdraw", title: "출금", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/auction',
        component: setViewResolver('mypage/MyPageAuction'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageAuction',
        meta: {name: "MyPageAuction", title: "마이페이지 경매", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/curation',
        component: setViewResolver('mypage/MyPageCuration'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageCuration',
        meta: {name: "MyPageCuration", title: "마이페이지 큐레이션", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/real',
        component: setViewResolver('mypage/MyPageReal'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageReal',
        meta: {name: "MyPageReal", title: "마이페이지 실물", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/competition',
        component: setViewResolver('mypage/MyPageCompetition'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageCompetition',
        meta: {name: "MyPageCompetition", title: "마이페이지 공모전", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/interest',
        component: setViewResolver('mypage/MyPageInterest'),
        name: 'MyPageInterest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageInterest", title: "마이페이지 관심", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/detection',
        component: setViewResolver('mypage/MyPageDetection'),
        name: 'MyPageDetection',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageDetection", title: "마이페이지 검출", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},

    },
    {
        path: '/mypage/detection/:idx',
        component: setViewResolver('detection/DetectionDetail'),
        name: 'DetectionDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionDetail", title: "ITEM 검출", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/detection/:d_idx/history',
        component: setViewResolver('detection/DetectionHistory'),
        name: 'DetectionHistory',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionHistory", title: "ITEM 검출", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/detection/:idx/request',
        component: setViewResolver('detection/DetectionRequest'),
        name: 'DetectionRequest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionRequest", title: "의뢰하기", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/detection/request/:idx',
        component: setViewResolver('detection/DetectionRequestDetail'),
        name: 'DetectionRequestDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionRequestDetail", title: "의뢰상세", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/detection/:d_idx/url',
        component: setViewResolver('detection/DetectionUrl'),
        name: 'DetectionUrl',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionUrl", title: "무시 도메인 설정", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/openmarket/bid',
        component: setViewResolver('market/Bid'),
        name: 'Bid',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Bid", title: "입찰",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/openmarket',
        component: setViewResolver('market/OpenMarket'),
        name: 'OpenMarket',
        /*beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },*/
        meta: {name: "OpenMarket", title: "오픈마켓",/*authRequired: true, storeRequired:true, storeConfig: {userInfo: true, nftUser:true},*/ transitionName: 'none'},
    },
    {
        path: '/openmarket/:idx',
        component: setViewResolver("market/OpenMarketDetail"),
        name: "OpenMarketDetail",
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "OpenMarketDetail", title: "오픈 마켓 아이템 상세", transitionName: 'none', storeRequired: true, },
        meta: {name: "OpenMarketDetail", title: "오픈 마켓 아이템 상세",},
    },
    {
        path: '/openmarket/:idx/history',
        component: setViewResolver("history/HistoryDetail"),
        name: "openHistoryDetail",
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "openHistoryDetail", title: "거래 내역",storeRequired:true,},
        meta: {name: "openHistoryDetail", title: "거래 내역",},
    },
    {
        path: '/openmarket/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        name: 'openItemUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "openItemUpdate", title: "아이템 상태변경", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        // meta: {name: "openItemUpdate", title: "아이템 상태변경", },
    },
    {
        path: '/openmarket/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'openBid',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "openBid", title: "입찰", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "openBid", title: "입찰", transitionName: 'none'},
    },
    {
        path: '/premiummarket/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'premiumBid',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "premiumBid", title: "입찰", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "premiumBid", title: "입찰",},
    },
    {
        path: '/item/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'itemBid',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "itemBid", title: "입찰", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "itemBid", title: "입찰", },
    },
    {
        path: '/openmarket/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'marketPrice',
        meta: {name: "marketPrice", title: "지정가구매", },
    },
    {
        path: '/openmarket/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'marketPrice',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "marketPrice", title: "지정가구매", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "marketPrice", title: "지정가구매", },
    },
    {
        path: '/premiummarket/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'premiumPrice',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "premiumPrice", title: "지정가구매", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "premiumPrice", title: "지정가구매", },
    },
    {
        path: '/item/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'itemPrice',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "itemPrice", title: "지정가구매", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "itemPrice", title: "지정가구매", },
    },
    {
        path: '/openmarket/:idx/license/:type',
        component: setViewResolver('market/License'),
        name: 'openLicense',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "openLicense", title: "라이선스구매", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "openLicense", title: "라이선스구매",},
    },
    {
        path: '/item/:idx/license/:type',
        component: setViewResolver('market/License'),
        name: 'itemLicense',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "itemLicense", title: "라이선스구매", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "itemLicense", title: "라이선스구매", },
    },
    {
        path: '/premiummarket/upload',
        component: setViewResolver('market/PremiumMarketUpload'),
        name: 'PremiumMarketUpload',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "PremiumMarketUpload", title: "내 아이템 프리미엄 마켓 등록", transitionName: 'none' ,authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
        meta: {name: "PremiumMarketUpload", title: "내 아이템 프리미엄 마켓 등록", },
    },
    {
        path: '/premiummarket',
        component: setViewResolver('market/PremiumMarket'),
        name: 'PremiumMarket',
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "PremiumMarket", title: "프리미엄마켓", transitionName: 'none'},
        meta: {name: "PremiumMarket", title: "프리미엄마켓",},
    },
    {
        path: '/premiummarket/:idx',
        component: setViewResolver("market/PremiumMarketDetail"),
        name: "PremiumMarketDetail",
        // beforeEnter: (to, from, next) => {
        //     storeSet(to, from, next);
        // },
        // meta: {name: "PremiumMarketDetail", title: "프리미엄 마켓 아이템 상세",storeRequired:true,transitionName: 'none'},
        meta: {name: "PremiumMarketDetail", title: "프리미엄 마켓 아이템 상세"},
    },
    {
        path: '/premiummarket/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        name: 'premiumItemStatusUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "premiumItemStatusUpdate", title: "아이템 상태변경", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
    },
    {
        path: '/premiummarket/:idx/history',
        component: setViewResolver("history/HistoryDetail"),
        name: "historyDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "historyDetail", title: "거래 내역",storeRequired:true,},
    },

    {
        path: '/entry/:cid/list/:mb_no',
        component: setViewResolver('entry/EntryList'),
        name: 'EntryList',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryList", title: "응모작보기",storeRequired:true, transitionName: 'none'},

    },
    {
        path: '/entry/:cid/write',
        component: setViewResolver('entry/EntryWrite'),
        name: 'EntryWrite',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryWrite", title: "응모하기",authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,}, transitionName: 'none'},
    },
    {
        path: '/entry/:cid/list/:mb_no/:idx',
        component: setViewResolver("entry/EntryDetail"),
        name: "EntryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryDetail", title: "응모작 상세",storeRequired:true,}
    },
    {
        path: '/award/:c_idx/write',
        component: setViewResolver('award/AwardWrite'),
        name: 'AwardWrite',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "AwardWrite", title: "수상작 선정", authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
    },
    {
        path: '/award/:c_idx/list',
        component: setViewResolver('award/AwardList'),
        name: 'AwardList',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "AwardList", title: "수상작 페이지",storeRequired:true},
    },
    {
        path: '/qna/create',
        component: setViewResolver('qna/QnaCreate'),
        name: 'QnaCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaCreate", title: "문의 등록",authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/qna',
        component: setViewResolver('qna/Question'),
        name: 'Question',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Question", title: "문의",authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},

    },
    {
        path: '/qna/:idx',
        component: setViewResolver("qna/QnaDetail"),
        name: "QnaDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaDetail", title: "문의 상세",storeRequired:true,}
    },
    {
        path: '/qna/:idx/update',
        component: setViewResolver("qna/QnaUpdate"),
        name: "QnaUpdate",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaUpdate", title: "문의 수정",storeRequired:true,}
    },
    {
        path: '/faq',
        component: setViewResolver('faq/Faq'),
        name: 'FAQ',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "FAQ", title: "FAQ",storeRequired:true, transitionName: 'none'},

    },
    {
        path: '/faq/:idx',
        component: setViewResolver("faq/FaqDetail"),
        name: "FaqDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "FaqDetail", title: "FAQ 상세",storeRequired:true,}
    },
    {
        path: '/notice',
        component: setViewResolver('notice/Notice'),
        name: 'Notice',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Notice", title: "공지사항",storeRequired:true, transitionName: 'none'},

    },
    {
        path: '/notice/:idx',
        component: setViewResolver("notice/NoticeDetail"),
        name: "NoticeDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "NoticeDetail", title: "공지사항 상세",storeRequired:true,}
    },
    {
        path: '/item/:idx',
        component: setViewResolver("item/ItemDetail"),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: "ItemDetail",
        meta: {name: "ItemDetail", title: "아이템 상세", transitionName: 'none', storeRequired: true, },
    },
    {
        path: '/item/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'ItemStatusUpdate',
        meta: {name: "ItemStatusUpdate", title: "아이템 상태변경", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true},},
    },
    {
        path: '/item/:idx/history',
        component: setViewResolver("history/HistoryDetail"),
        name: "ItemHistoryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "openHistoryDetail", title: "거래 내역",storeRequired:true,},
    },
    {
        path: '/point/success',
        component: setViewResolver("payment/PaymentResult"),
        name: "PaymentResult",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PaymentResult", title: "결제성공",storeRequired:true,},
    },
    {
        path: '/point/chrome',
        component: setViewResolver("payment/PaymentChromeResult"),
        name: "PaymentChromeResult",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PaymentChromeResult", title: "결제성공",storeRequired:true,},
    },
    {
        path: '/utility/:idx',
        component: setViewResolver("utility/UtilityDetail"),
        name: "UtilityDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "UtilityDetail", title: "유틸리티",storeRequired:true,},
    },
    {
        path: '/utility/:idx/market/:uu_idx',
        component: setViewResolver("utility/UtilityUserDetail"),
        name: "UtilityUserDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "UtilityUserDetail", title: "유틸리티",storeRequired:true,},
    },
]
