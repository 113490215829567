import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            i_idx: '',
            curatorKey: '',
        }
    },
    validators: {
        i_idx: function (value) {
            return Validator.value(value)
        },
        curatorKey: function (value) {
            return Validator.value(value)
        },
    },
}
