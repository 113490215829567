<template>
    <div>
        <div class="title">{{ $t('mypage') }}</div>
        <div class="mypage_header">
            <div class="user_profile_img" @click="movePage('/myprofile')" :style="`background-image: url(${returnThumbnail()});`"></div>
            <div class="info_wrap" :class="{no_tag: !tagCheck}">
                <div class="tag_wrap" v-if="tagCheck">
                    <div class="tag_curator" v-if="UserInfo.g_idx === 2"><span>Curator</span></div>
                    <div class="tag_special" v-if="UserInfo.mb_premium === 1"><span>Special</span></div>
                </div>
                <div class="user_nick_wrap" :class="{no_tag_nick: !tagCheck}">
                    <div class="user_nick">{{UserInfo.mb_nick}}&nbsp;</div>
                    <router-link to="/mypage/update" class="a_link">{{ $t('infoModify') }}</router-link>
                </div>
                <div class="move_page_btn_wrap">
                    <button @click="movePage('/mypage/point')">{{returnCommas(UserInfo.point)}}Mg</button>
                    <button v-if="showDeposit()" class="ml10" @click="checkAuthCertification()" >{{ $t('deposit_btn')}}</button>
                </div>
            </div>
            <div class="mypage_btn_wrap">
                <button class="item_btn item" :class="{active: activeCheck === 'item'}" v-if="getMemberType() !== 'curator' && UserInfo.g_idx !== 8 && UserInfo.g_idx !== 7" @click="movePage('/mypage/item')">{{ $t('item') }}
                   <!-- <div :class="`new`" v-show="newType">N</div>-->
                </button>
                <button class="item_btn auction":class="{active: activeCheck === 'auction'}" v-if="getMemberType() !== 'curator' && UserInfo.g_idx !== 8 && UserInfo.g_idx !== 7" @click="movePage('/mypage/auction')">{{ $t('auction') }}
                   <!-- <div :class="`new`" v-show="!newType">N</div>-->
                </button>
                <button class="item_btn curation" v-if="UserInfo.g_idx != 8 && UserInfo.g_idx !== 7" :class="{active: activeCheck === 'curation'}" @click="movePage('/mypage/curation')">{{ $t('curation') }}
                    <div :class="`new`" v-show="!newType">N</div>
                </button>
                <button class="item_btn real" v-if="UserInfo.g_idx != 8 && UserInfo.g_idx !== 7" :class="{active: activeCheck === 'real'}" @click="movePage('/mypage/real')">{{ $t('real') }}
                    <div :class="`new`" v-show="!newType">N</div>
                </button>
                <button class="item_btn competition" v-if="UserInfo.g_idx != 8 && UserInfo.g_idx !== 7" :class="{active: activeCheck === 'competition'}" @click="movePage('/mypage/competition')">{{ $t('competition') }}
                    <div :class="`new`" v-show="!newType">N</div>
                </button>
                <button class="item_btn interest" :class="{active: activeCheck === 'interest'}" @click="movePage('/mypage/interest')">{{ $t('interest') }}
                    <div :class="`new`" v-show="!newType">N</div>
                </button>
                <button class="item_btn detection" v-if="getMemberType() === 'normal'  && UserInfo.g_idx !== 8 && UserInfo.g_idx !== 7" :class="{active: activeCheck === 'detection'}" @click="movePage('/mypage/detection')">{{ $t('detection') }}
                    <div :class="`new`" v-show="!newType">N</div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import AuthMixins from "@/mixins/AuthMixins";
import {mapState, mapGetters} from "vuex";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "MyPageHeaderComponent",
    mixins: [imageOption,AuthMixins,imageResizeMixins, alertMixins],
    components: {

    },
    inject: [],
    provide() {
        return {}
    },
    props: {
    },
    data() {
        return {
            newType: true,
            activeCheck: 'item',
            tagCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.checkActiveButton(this.$route.path)
        this.setMetaTitle();
    },
    mounted() {
        if(this.UserInfo.g_idx === 2 || this.UserInfo.mb_premium === 1 ) {
            this.tagCheck = true;
        } else {
            this.tagCheck = false;
        }
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        setMetaTitle(){
          if (!util.isEmpty(this.UserInfo) && this.UserInfo.hasOwnProperty('mb_nick')) {
            EventBus.$emit('setMetaTitle', this.UserInfo.mb_nick);
          }
        },
        movePage(url) {
            this.$router.push(url);
        },
        checkActiveButton(path) {
            if(path === '/mypage/item') {
                this.activeCheck = 'item';
            } else if (path === '/mypage/auction') {
                this.activeCheck = 'auction';
            } else if (path === '/mypage/curation') {
                this.activeCheck ='curation';
            } else if (path === '/mypage/real') {
                this.activeCheck ='real';
            } else if (path === '/mypage/competition') {
                this.activeCheck ='competition';
            } else if (path === '/mypage/interest') {
                this.activeCheck ='interest';
            } else if (path === '/mypage/detection') {
                this.activeCheck ='detection';
            } else {
                this.activeCheck = '';
            }
        },
        returnThumbnail(){
            if (!util.isEmpty(this.UserInfo.Files.Profile) && !util.isEmpty(this.UserInfo.Files.Profile[0])) {
                return `${this.UserInfo.Files.Profile[0].org_url}?${this.setImageOptions(97, 98.5, this.returnExtension(this.UserInfo.Files.Profile[0].org_url))}`;
            }
        },
        returnCommas(x=0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        showDeposit() {
            return this.$route.path.indexOf('/mypage/point') > -1 || this.$route.path == '/mypage/deposit';
        },
        checkAuthCertification() {
            if(this.isAuthenticated()) {
                let movePath = '/mypage/deposit';
                if(this.$route.path != movePath) {
                    this.$router.push('/mypage/deposit');
                }
            }else{
                this.createConfirm({
                    content: `${this.$t('check_deposit_auth1')}<br>${this.$t('check_deposit_auth2')}`,
                    confirmText: this.$t('check_now'),
                    confirm : () => {
                        this.$router.push('/mypage/update')
                    },
                })
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
