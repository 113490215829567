<template>
  <component :is="component" :paginationData="paginationData"  :listLength="listLength" v-if="component"/>
</template>

<script>
export default {
  name: "PaginationLink",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
      paginationLayout: {},
      paginationData: {
          type: Object,
          default: {
              total: 0,
              page: 1,
              size: 10,
          }
      },
      listLength: {
          type: Number,
          default: 0
      }  },
  data() {
    return{
      component: null,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/competition/pagination/PaginationDefaultLayout')
        })
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    loader() {
      if (!this.paginationLayout) {
        return null
      }
      return () => import(`@/template/competition/pagination/${this.paginationLayout}`)
    },
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
