<template>
    <div>
        <board-write-link v-for="writeLayout in writeLayouts" :key="`board${writeLayout}`"
                          :boardLayout="writeLayout" :boardData="boardData"></board-write-link>
    </div>
</template>

<script>
import BoardWriteLink from "@/components/board/BoardWriteLink";
import boardUtil from "@/components/board/boardUtil";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import boardValidator from "@/mixins/validators/boardValidator";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import elFileMixins from "@/mixins/elFileMixins";

export default {
    name: "BoardWriteComponent",
    mixins: [alertMixins ,boardUtil, boardValidator, awsS3FileUpload, elFileMixins],
    components: {
        BoardWriteLink
    },
    inject: [],
    provide() {
        return {
            boardWriteSetData: this.setData,
        }
    },
    props: {
        boardType: '',
        boardKey: '',
    },
    data() {
        return {
            writeLayouts: '',
            boardData: {},
            writeApiParamSet: {},
            parsingData: {},
            directUrl: '',
            directType: '',
            writeSuccessType: 0,
            writeSuccessText: this.$t('write_qna_result'),
            writeFailTypes: {},
            fileInfo: [],
            isRunning: false,

            getFunc: '',
            detailApiParamSet: '',
            keyName: '',
            getKeyName: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig()
        if (!util.isEmpty(this.boardKey)) {
            this.getBoard();
        } else {
            this.initConfig();
        }
    },
    mounted() {
        EventBus.$on('writeBoard', this.checkValidator);
    },
    beforeDestroy() {
        EventBus.$off('writeBoard');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let boardConfig = this.returnBoardConfig(this.boardType);
            this.writeLayouts = boardConfig.writeLayouts;
            this.writeFunc = boardConfig.writeFunc;
            this.writeApiParamSet = boardConfig.writeApiParamSet;
            this.parsingData = boardConfig.parsingData;
            this.directUrl = boardConfig.directUrl;
            this.directType = boardConfig.directType;
            this.writeSuccessType = boardConfig.writeSuccessType;
            this.writeSuccessText = boardConfig.writeSuccessText;
            this.writeFailTypes = boardConfig.writeFailTypes;
            this.fileInfo = boardConfig.fileInfo;

            this.getFunc = boardConfig.getFunc;
            this.detailApiParamsSet = boardConfig.detailApiParamsSet;
            this.getKeyName = boardConfig.getKeyName;
            this.keyName = boardConfig.keyName;
        },
        getBoard() {
            EventBus.$emit('apiLoading', true);
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet));

            params[this.getKeyName] = this.boardKey;

            this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || res.List.length === 0) {
                    EventBus.$emit('apiLoading', false);
                    this.createAlert({
                        title: '',
                        content: this.$t('no_data_loading'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    });
                }
                EventBus.$emit('apiLoading', false);
                this.boardData = res.List[0];
            })
        },
        setData(key, value) {
            this[key] = value;
        },
        checkValidator() {
            let dataArr = Object.keys(this.parsingData);
            this.$validate(dataArr).then(success => {
                if (!success) {
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`, dataArr);
                    });
                    this.errorAlert(this.$t('require_validator'));
                } else {
                    this.writeBoard();
                }
            })
        },
        writeBoard() {
            EventBus.$emit('apiLoading', true);
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
            util.Object.each(this.parsingData, (key, value, obj) => {
                if(this[key] != this.boardData[value]) {
                    params[value] = this[key];
                }
            })
            if (!util.isEmpty(this.boardKey)) {
                params.wr_id = this.boardKey;
                params[this.keyName] = this.boardKey;
            }
            try {
                this.$api.$board[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    //let type = res.Info.type;
                    let type = this.returnWriteTypes(res.Info.type);
                    if (type != true) {
                        this.errorAlert(type);
                        EventBus.$emit('apiLoading', false);
                        return false;
                    } else {
                        if(!util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDelete(this.deleteFiles)
                        }
                        if(!util.isEmpty(this.fileInfo)) {
                            let key = this.boardKey;
                            if(res.hasOwnProperty('List') && res.List.hasOwnProperty('wr_id')) {
                                key = res.List.wr_id;
                            }
                            this.setFileUpload(this.fileInfo, key);
                        }else{
                            this.afterFunc();
                        }
                    }
                });
            } catch (e) {
                EventBus.$emit('apiLoading', false);
            }
        },
        returnWriteTypes(type) {
            if (type == 1) {
                return true;
            } else {
                return util.isEmpty(this.writeFailTypes[type]) ? this.$t('b_save_fail') : this.writeFailTypes[type]
            }
        },
        setFileUpload(fileInfo, key) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                this.afterFunc();
            })
        },
        afterFunc() {
            EventBus.$emit('apiLoading', false);
            this.createAlert({
                title: '',
                content: this.$t('write_qna_result'),
                btnText: this.$t('yes'),
                hide: () => {
                    if (this.directType === 'back') {
                        this.$router.back();
                        return false;
                    }
                    if (this.directUrl === '') {
                        this.title = '';
                        this.content = '';
                        return false;
                    }
                    let url = this.directUrl;
                    if (this.directType === 'detail') {
                        url = url + detailKey;
                    }
                    this.$router.push(url);
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
