import Vue from 'vue'
import store from '@/store/'
import util from "@/mixins/util";
import {request, setAuthInHeader} from "@/api/request";
import API from '@/api/index'
import EventBus from "@/utils/event-bus";
import MobileDetect from "mobile-detect";
import {apiURL} from "@/api/config";

var OrderClass = (function () {
    function OrderClass(PayUser) {
        var User = PayUser || null,
            UserKey = PayUser[this.getOrderClassConstants().UserKey] || null

        this.User = User;
        this.UserKey = UserKey;
        this.addr = null;
        this.point = null;
        this.coupon = null;

        if (this.UserKey == null) {
            throw new Error(this.getOrderClassConstants().PARAMETERERROR);
        }
    };

    OrderClass.prototype.getOrderClassConstants = function getOrderClassConstants() {

        let OrderClassConstants = {
            PayStatus: {
                pay: {
                    class: '',
                    desc: '일반구매',
                    isModalOpen: true,
                    name: 'chat'
                },
                subscribtion: {
                    class: 'chat_report_talk',
                    desc: '정기구독',
                    isModalOpen: false,
                    name: 'report'
                }, name: 'live'
            },
            address: {
                pb_deposit_name: '나는갓',
                b_idx: 3,
                pb_bank_name: '디지몽은행',
                pb_bank_account: '3123123-321312-312312321'
            },
            DefaultPay: {
                o_payment: 1,
                o_type: "basic",   //basic
                o_pg: 'kcp',        //kcp,dgmong,bank
                o_id: null,         //
                coupon: null,
                pw: 'dgmong',       //강제 결제를 위한 암호.
            },
            UserKey: 'mb_no',
            UserName: 'mb_nick',
            memberId: 'mb_id',
            RoomUsers: {},
            DataTime: 'created_at',
            DgmongPw: 'dgmong',
            PARAMETERERROR: 'The parameters: UserKey, userAuth data is not available plz check your data store',
        };
        return OrderClassConstants;
    };

    OrderClass.prototype._getBankFormat = function _getBankFormat(obj) {

        let address = this.getOrderClassConstants().address;


        obj.o_payment = 1;
        obj.o_pg = 'bank';
        obj.pb_deposit_name = address.pb_deposit_name;
        obj.b_idx = address.b_idx;
        obj.pb_bank_name = address.pb_bank_name;
        obj.pb_bank_account = address.pb_bank_account;

        return obj;
    };

    OrderClass.prototype._getKcpFormat = function _getKcpFormat(obj) {
        obj.o_payment = 10;
        obj.o_pg = 'kcp';
        return obj;
    };

    OrderClass.prototype._getDgmFormat = function _getDgmFormat(obj) {
        obj.o_payment = 10;
        obj.o_pg = 'dgm';
        obj.pw = this.getOrderClassConstants().DgmongPw;
        return obj;
    };
    OrderClass.prototype._getDgmFormat = function _getCardFormat(obj) {
        obj.o_payment = 10;
        obj.o_pg = 'dgm';
        // obj.success_url =

        return obj;
    };

    OrderClass.prototype._getNaverFormat = function _getNaverFormat(obj) {
        obj.o_payment = 10;
        obj.o_pg = 'naver';
        return obj;
    };
    OrderClass.prototype._getCardFormat = function _getCardFormat(obj) {
        obj.pe_is_mobile = this._checkType();
        obj.isSafari = this._checkPlatform();
        if (obj.isSafari === 'safari') {
            obj.fail_url = `${window.location.origin}/mypage/point/charge`
            obj.success_url = `${apiURL}orderpoint/payment`
        } else {
            obj.fail_url = `${apiURL}orderpoint/fail`
            obj.success_url = `${apiURL}orderpoint/success`
        }

        return obj;
    };


    OrderClass.prototype._getDefaultPgForamt = function _getDefaultPgForamt(pgType) {
        // let obj = this.getOrderClassConstants().DefaultPay;
        let obj = {};
        if (pgType == 'bank') {
            obj = this._getBankFormat(obj);
        } else if (pgType == 'dgm') {
            obj = this._getDgmFormat(obj);
        } else if (pgType == 'card') {
            obj = this._getCardFormat(obj);
        } else {
            obj = this._getBankFormat(obj);
        }
        return obj;
    };

    OrderClass.prototype._checkType = function _checkType() {
        let md = new MobileDetect(window.navigator.userAgent);
        // 모바일일때 1,아닐때 0
        if (md.mobile() !== null) {
            return 1;
        }
        return 0;
    };
    OrderClass.prototype._checkPlatform = function _checkPlatform() {
        var agt = navigator.userAgent.toLowerCase();
        if (agt.indexOf('chrome') != -1) return 'notSafari';
        if (agt.indexOf("safari") != -1) return 'safari';
        return 'notSafari'
    };
    OrderClass.prototype.OrderPay = async function OrderStart(orderId, pg) {

        let params = this._getDefaultPgForamt(pg);
        params.o_id = orderId;

        return API.$pay.createOrderPoint(params).then(res => res.Data.Result).then(res => {
            let status = this._chkStatus(res.List)
            console.log('OrderPay', status);
            if (!status) {
                return res.List.redirect_page_url;
            }
            return '';
        })

    };


    OrderClass.prototype._chkStatus = function _chkStatus(List) {
        if (List.hasOwnProperty('Data') && !util.isEmpty(List.Data) &&
            List.Data.hasOwnProperty('Status') && !util.isEmpty(List.Data.Status)) {
            return List.Data.Status
        }

        return false;

    };
    OrderClass.prototype.checkOrder = function checkOrder(orderId) {
        let params = {}
        params.o_id = orderId;

        return API.$pay.checkOrder(params).then(res => res.Data.Result).then(res => {
            if (res.Info.type == 1) {
                return true;
            }
            return false;
        })
    };
    OrderClass.prototype.writePoint = function writePoint(orderId) {
        let params = {}
        params.o_id = orderId;
        params.o_payment = 10;
        params.o_type = 'basic';
        params.o_pg = 'e2u';
        params.o_status = 20;

        return API.$pay.writePoint(params).then(res => res.Data.Result).then(res => {
            if (res.Info.type == 1) {
                return true;
            }
            return false;
        })
    };


    return OrderClass;
})();

export default {
    OrderClass: OrderClass,
    data() {
        return {}
    },
    methods: {}
}

