<template>
    <div class="create_item_wrap create_competition_wrap">
        <div class="write_header_wrap">
            <h1 class="title ">{{$t('e_update')}}</h1>
        </div>
        <competition-write-component :competition-type="'competitionUpdate'"
                                     :competition-key="competitionKey"></competition-write-component>
        <div>
            <div class="submit_wrap item_create_btn">
                <div class="submit_btn">
                    <button class="enroll_submit_btn" @click="submit">{{$t('modify')}}</button>
                </div>
                <div class="caption_check mt10" v-if="hasAllError"><p>{{$t('caption_check2')}}</p></div>
            </div>
        </div>

        <judge-search-modal></judge-search-modal>
    </div>
</template>

<script>
import CompetitionWriteComponent from "@/components/competition/CompetitionWriteComponent";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import JudgeSearchModal from "@/components/modal/JudgeSearchModal";

export default {
    name: "CompetitionUpdate",
    mixins: [],
    components: {
        CompetitionWriteComponent,JudgeSearchModal
    },
    inject: [],
    provide() {
        return {
            competitionVueSetData : this.setData
        }
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.idx,
            hasAllError:false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name){
            this.$modal.show(name);
        },
        submit(){
            EventBus.$emit('writeCompetition')
        },
        setData(key,value){
            this[key] = value
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
