<template>
    <div>
        <portfolio-write-link v-for="writeLayout in writeLayouts" :key="`portfolio${writeLayout}`"
                              :write-layout="writeLayout"></portfolio-write-link>
    </div>
</template>

<script>

import PortfolioWriteLink from "./PortfolioWriteLink";
import portfolioUtil from "./portfolioUtil";

export default {
    name: "PortfolioWriteComponent",
    mixins: [portfolioUtil],
    components: {PortfolioWriteLink},
    inject: [],
    provide() {
        return {}
    },
    props: {
        portfolioType: '',
    },
    data() {
        return {
            writeLayouts: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnPortfolioConfig(this.portfolioType);
            this.writeLayouts = config.writeLayouts
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>