<template>
  <component :is="component" v-if="component" :itemData="itemData" :utilityList="utilityList"/>
</template>

<script>
export default {
  name: "ItemDetailLink",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    detailLayout: '',
      itemData:{},
      utilityList: [],
  },
  data() {
    return{
      component: null,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/template/item/detail/ItemDetailDefaultLayout')
        })
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    loader() {
      if (!this.detailLayout) {
        return null
      }
      return () => import(`@/template/item/detail/${this.detailLayout}`)
    },
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
