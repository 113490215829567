<template>
    <div class="detection_request_wrap">
      <div class="detection_request_title">{{ $t('request1') }}</div>
      <detection-write-component :detection-type="'requestWrite'"></detection-write-component>
    </div>
</template>

<script>


import DetectionWriteComponent from "@/components/detection/DetectionWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionRequest",
    mixins: [],
    components: {DetectionWriteComponent},
    inject: [],
    provide() {
        return {
            setValidatorErr: this.setValidatorErr,
        }
    },
    props: {},
    data() {
        return {
            validatorErr: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setValidatorErr(value) {
            this.validatorErr = value;
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
