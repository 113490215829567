<template>
  <modal name="judge-write-modal" class="judge-write-modal"
         :width="`650px`" :height="`631px`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div class="judge_write_wrap">
      <button @click="modalClose()" class="back_btn"></button>
      <button @click="Confirm()" class="save_btn">{{$t('save')}}</button>
      <judge-write-component :judge-type="'judgeWrite'" :judge-key="idx"></judge-write-component>
    </div>
  </modal>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import JudgeWriteComponent from "@/components/judge/JudgeWriteComponent";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
  name: "JudgeWriteModal",
  mixins: [alertMixins],
  components: {
    JudgeWriteComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
        idx : ''
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
  },
  methods: {
    handlerBeforeOpen(event) {
        if (util.isEmpty(event.params) || util.isEmpty(event.params.idx)) {
            event.stop();
        }else{
            this.idx = event.params.idx;
        }
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
    },
    modalClose() {
      this.$modal.hide('judge-write-modal');
    },

    Confirm() {
      this.createConfirm({
        content : this.$t('judge_w_msg1') +'<br/>'+ this.$t('judge_w_msg2') ,
        confirmText : this.$t('save'),
        cancelText : this.$t('cancel'),
        confirm: () => {
            EventBus.$emit('writeJudge')
        },
        cancel: () => {

        }
      });
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
