<template>
    <div class="collection_wrap" style="min-height: calc(100vh - 400px);">
        <div class="collection_list_wrap">
            <div class="cf_default_wrap">
                <div class="collection_title">{{ $t('collection') }}</div>
            </div>
            <div class="c_add_btn_relative_wrap">
                <div class="collection_add_btn_wrap">
                    <button @click="openModal('collection-setting-modal')">
                        {{ $t('add_collect') }}
                    </button>
                </div>
            </div>
            <div style="margin-top: 55px">
                <collect-list-component :collect-type="'collect'"
                                        :defaultFilter="defaultFilter"></collect-list-component>
            </div>
        </div>
        <collection-setting-modal></collection-setting-modal>
    </div>
</template>

<script>

import CollectListUpdateLayout from "@/template/collect/list/CollectListUpdateLayout";
import CollectionSettingModal from "@/components/modal/CollectionSettingModal";
import CollectListComponent from "@/components/collect/CollectListComponent";
import {mapState} from "vuex";

export default {
    name: "Collect",
    mixins: [],
    components: {CollectListComponent, CollectionSettingModal, CollectListUpdateLayout},
    inject: [],
    provide() {
        return {
            setViewData: this.setData
        }
    },
    props: {},
    data() {
        return {
            defaultFilter: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {no: this.UserInfo.mb_no}
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name) {
            this.$modal.show(name, {collectType: 'collectAdd'});
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
