<template>
<!--    <span v-if="this.resultType">{{ this.resultType }}</span>
    <span v-else>{{ isDay(competitionData) ? returnDate() : returnTime }}</span>-->
  <div>
    <div class="time_limit_wrap">
      <span class="time_limit_txt">{{ returnDiffTime(competitionData) }}</span>
    </div>
  </div>

</template>

<script>
import EventBus from "@/utils/event-bus";

let timeInterval = null;

export default {
    name: "CompetitionDateTime",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            timer: null,
            diffDay: 0,
            diffTime: 0,
            resultType: false,
            currentTime:'',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
        // clearInterval(this.timer)
    },
    mounted() {
        // this.setResultType()
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    computed: {
        /*returnTime() {
            let diff = this.diffTime
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min} : ${sec}`
        },*/
    },
    methods: {

        returnDiffTime(competition) {
            if (competition.c_status === 3) {
                return this.$t('p_completed')
            } else if (competition.c_status === 2) {
                return this.$t('under_review')
            } else if (competition.c_status === 0) {
                //return //'진행 예정'
                return this.$t('scheduled_to_proceed')
            } else {
                return this.reduceTime(competition)
            }
        },
        getCurrentTime() {
            this.currentTime = this.$moment()
        },
        reduceTime(competition){
            let current = this.currentTime
            let end = this.$moment(competition.c_end_time)
            let diff = current.diff(end, 'day')
            if (diff > -1) {
                let s_diff = current.diff(end, 'second')
                diff =  s_diff > 0 ? 0 : Math.abs(s_diff)
            } else {
                return `D-${Math.abs(diff)}`
            }
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            if(hour == '00' && min == '00' && sec == '00') {
                EventBus.$emit('getCompetitionDetail');
            }
            return `${hour} : ${min} : ${sec}`
        },

        /*setResultType() {
            let competition = this.competitionData
            if (competition.c_status === 3) {
                this.resultType = '발표완료'
            } else if (competition.c_status === 2) {
                this.resultType = '심사중'
            } else if (competition.c_status === 0) {
                this.resultType = '진행 예정'
            } else {
                this.resultType = false
                // return this.reduceTime(competition)
            }
        },
        isDay(competition) {
            let current = this.$moment()
            let end = this.$moment(competition.c_end_time)
            let diff = current.diff(end, 'day')
            this.diffDay = diff
            if (diff > -1) {
                let s_diff = current.diff(end, 'second')
                s_diff = s_diff > 0 ? 0 : Math.abs(s_diff)
                this.diffTime = s_diff
                // this.reduceTime()
            }
            console.log(diff > 0)
            return diff > 0
        },
        returnDate() {
            return `D-${Math.abs(this.diffDay)}`

        },
        reduceTime(){
            timer = setInterval(() => {
                this.diffTime = this.diffTime -1
            }, 1000)
            if (this.diffTime < -1) {
                clearInterval(timer)
            }
        },*/

    },
    watch: {},
}
</script>

<style scoped>

</style>
