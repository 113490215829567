<template>
    <div>
        <!--    <award-filter-link :filterLayout="filterLayout"></award-filter-link>-->
        <award-list-link :listLayout="listLayout" :awardList = "awardList" v-if="awardList.length > 0">
            <!--      <button @click="openModal('judge-result-modal')">심사 결과</button>-->
        </award-list-link>
        <award-empty-link :emptyLayout="emptyLayout" v-else></award-empty-link>
        <pagination-link :paginationLayout="paginationLayout"></pagination-link>
        <judge-result-modal></judge-result-modal>
    </div>
</template>

<script>
import awardUtil from "@/components/award/awardUtil";
import AwardListLink from "@/components/award/AwardListLink";
import AwardEmptyLink from "@/components/award/AwardEmptyLink";
import AwardFilterLink from "@/components/award/AwardFilterLink";
import PaginationLink from "@/components/award/PaginationLink";
import JudgeResultModal from "@/components/modal/JudgeResultModal";
import util from "@/mixins/util";

export default {
    name: "AwardListComponent",
    mixins: [awardUtil],
    components: {
        AwardFilterLink,
        AwardListLink,
        AwardEmptyLink,
        PaginationLink,
        JudgeResultModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        awardType: '',
        awardKey : '',
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            getKeyName: 'cid',
            awardList:[],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let awardConfig = this.returnAwardConfig(this.awardType);
            this.listLayout = awardConfig.listLayout;
            this.filterLayout = awardConfig.filterLayout;
            this.emptyLayout = awardConfig.emptyLayout;
            this.paginationLayout = awardConfig.paginationLayout;
            this.getFunc = awardConfig.getFunc
            this.listApiParamSet = awardConfig.listApiParamSet;
            this.getKeyName = awardConfig.getKeyName;
            this.getAward()
        },
        openModal(data) {
            this.$modal.show(data);
        },
        getAward(){
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if(!util.isEmpty(this.getKeyName)) {
                params[this.getKeyName] = this.awardKey;
            }else{
                params.cid = this.awardKey
            }
            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type === 1){
                        this.awardList = res.List
                    }
                    else {

                    }
                })
            }catch (e) {

            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
