<template>
    <div class="share_btn" @click="runShare('twitter')">
        <button class="twitter"></button>
        <div>Twitter</div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import shareUtil from "@/components/share/shareUtil";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";

const {getShareLink, makeFirebaseShortLink} = shareUtil;
export default {
    name: "ShareTwitterComponent",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        shareType: {default: '', },
        shareData: {
            default: {
                path: '/',
                img: 'https://testimage.minglemint.com/profileBack/no_image.jpg',
                title: `밍글민트(Minglemint NFT Marketplace)`,
                description: `밍글민트(Minglemint NFT Marketplace)`
            }
        },
        runAfter:{type: Function, default:  () => {}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        runShare() {
            EventBus.$emit('apiLoading', true);
            let $this = this;
            let format = this.returnExtension(this.shareData.img);
            let imgUrl = this.shareData.img + '?' + this.setImageOptions(300, 200, format);
            let img = new Image()
            img.src = imgUrl;
            img.onload = function () {
                setTimeout(() => {
                    $this.openTwitter(imgUrl);
                    document.body.removeChild(img);
                }, 500)
            }
            document.body.appendChild(img);
        },
        async openTwitter(imgUrl) {
            let shareData = {
                img: imgUrl,
                path: this.shareData.path,
                title: this.shareData.title,
                description: this.shareData.description,
            }
            let dynamicLink = await makeFirebaseShortLink(shareData);
            EventBus.$emit('apiLoading', false);
            if(!dynamicLink) {
                this.errorAlert(this.$t('share_fail'));
                return false;
            }
            let twitterUrl = "https://twitter.com/intent/tweet?url=" + dynamicLink
            window.open(twitterUrl, 'Share on Twitter', 'scrollbars=no, width=500, height=500');
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
