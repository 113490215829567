<template>
    <div class="portfolio_edit_wrap">
        <div class="portfolio_edit_title">
            {{ $t('portfolio') }}
        </div>
        <member-write-component :memberType="'portfolioEdit'" :memberKey="UserInfo.mb_no"></member-write-component>
<!--        <portfolio-write-component :portfolio-type="`portfolioUpdate`"></portfolio-write-component>-->
    </div>
</template>

<script>
// import PortfolioWriteComponent from "@/components/portfolio/PortfolioWriteComponent";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";
import AuthMixins from "@/mixins/AuthMixins";
export default {
    name: "PortfolioEdit",
    mixins: [AuthMixins],
    components: {
        // PortfolioWriteComponent
        MemberWriteComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{

        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
