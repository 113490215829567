<template>
    <component :is="component" :finishGetList="finishGetList" v-if="component"/>
</template>

<script>
export default {
    name: "DetectionEmptyLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        emptyLayout: '',
        finishGetList: {default: true},
    },
    data() {
        return {
            component:null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/detection/empty/DetectionEmptyDefaultLayout')
                })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.emptyLayout) {
                return null
            }
            return () => import(`@/template/detection/empty/${this.emptyLayout}`)
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
