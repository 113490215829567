<template>
    <div>
        <license-write-link v-for="writeLayout in writeLayouts" :key="`license${writeLayout}`"
                            :write-layout="writeLayout"></license-write-link>
    </div>
</template>

<script>
import licenseUtil from "./licenseUtil";
import LicenseWriteLink from "./LicenseWriteLink";

export default {
    name: "LicenseWriteComponent",
    mixins: [licenseUtil],
    components: {LicenseWriteLink},
    inject: [],
    provide() {
        return {}
    },
    props: {
        licenseType: '',
        licenseKey: '',
    },
    data() {
        return {
            writeLayouts: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnLicenseConfig(this.licenseType);
            this.writeLayouts = config.writeLayouts
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>