<template>
  <div class="qna_main_wrap">
    <div class="notice_text">
      <span>{{ $t('service_center') }}</span>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div class="mypage_item_3_tab" v-bind:class="{active: tab === 'notice'}"
           @click="changeTab('notice')">
        {{$t('notice')}}
      </div>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div  class="mypage_item_3_tab" :class="{ active: tab === 'faq'}"
            @click="changeTab('faq')">
        FAQ
      </div>
    </div>
    <div class="mypage_list" @click="tab = boardType">
      <div  class="mypage_item_3_tab" :class="{ active: tab === 'qna'}"
            >
        {{ $t('qna') }}
      </div>
    </div>
    <board-List-component :boardType="boardType"></board-List-component>
    <transition :name="`detail-${$root.$children[0].transitionName}`">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";

export default {
  name: "Question",
  mixins: [],
  components: {
    BoardListComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      boardType: 'qna',

      tab: 'qna',
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.backUrl = this.$route.query.backUrl;
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url){
      this.$router.push(url);
    },
    changeTab(tab) {
      this.tab = '';
      this.$nextTick(() => {
        this.tab = tab
      })
      this.backUrl = this.boardType;
      // this.$router.replace(`${this.$route.path}?tab=${tab}`);
      //this.movePage(`/${tab}?backUrl=${this.backUrl}`);
      this.movePage(`/${tab}`);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>