import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    mixins: [imageOption],
    data() {
        return {
            smallSize: [0, 0],
            mediumSize: [0, 0],
            largeSize: [0, 0],
            originalSize: [0, 0],
            smallType: false,
            mediumType: false,
            largeType: false,
            mainImg:'',
            thumnailImgs : [],
            thumnailStatus : false,
            orgItemImg : ''
        }
    },
    methods: {
        imageResize(width, height) {
            let imageSize = width * height;
            let resizePercent = 0.5;
            this.originalSize[0] = width;
            this.originalSize[1] = height;
            if (imageSize < 2500000) {

            } else if (imageSize < 5000000) {
                this.smallType = true;
                this.smallSize[0] = (width * resizePercent).toFixed(2);
                this.smallSize[1] = (height * resizePercent).toFixed(2);
            } else if (imageSize < 10000000) {
                this.smallType = true;
                this.mediumType = true;
                this.mediumSize[0] = (width * resizePercent).toFixed(2);
                this.mediumSize[1] = (height * resizePercent).toFixed(2);
                this.smallSize[0] = (this.mediumSize[0] * resizePercent).toFixed(2);
                this.smallSize[1] = (this.mediumSize[1] * resizePercent).toFixed(2);
            } else if (imageSize > 10000000) {
                this.smallType = true;
                this.mediumType = true;
                this.largeType = true;
                this.largeSize[0] = (width * resizePercent).toFixed(2);
                this.largeSize[1] = (height * resizePercent).toFixed(2);
                this.mediumSize[0] = (this.largeSize[0] * resizePercent).toFixed(2);
                this.mediumSize[1] = (this.largeSize[1] * resizePercent).toFixed(2);
                this.smallSize[0] = (this.mediumSize[0] * resizePercent).toFixed(2);
                this.smallSize[1] = (this.mediumSize[1] * resizePercent).toFixed(2);
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data)) {
                // return `${data}?${this.setImageOptions(730, null, 'png')}`;
                return `${data}`;
            }else{
                return '';
            }
        },
        returnExtension(data){
            if (!util.isEmpty(data)) {
                let idx = data.lastIndexOf('.')
                let extension  = data.substring(idx+1);
                return extension;
            }else{
                return '';
            }
        },

        returnSmallThumbnail(data) {
            if (!util.isEmpty(data)) {
                return `${data}?${this.setImageOptions(176, 113, this.returnExtension(data))}`;
            }else{
                return '';
            }
        },
        changeThumnail(data) {
            if (!util.isEmpty(data)) {
                this.mainImg = data;
            }
        },
        setFitWidthImg(data, w=null) {
            if(util.isEmpty(data)) {
                return '';
            }
            if(util.isArray(data)) {
                return `${data[0].org_url}?${this.setImageOptions(w, null, this.returnExtension(data[0].org_url))}`;
            }
            return `${data.org_url}?${this.setImageOptions(w, null, this.returnExtension(data.org_url))}`;
        }
    }
}
