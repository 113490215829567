<template>
    <div class="content_wrap">
        <div class="img_wrap">
            <div class="b_img">
                <img :src="returnItemImg()"/>
            </div>
        </div>
        <div class="inform_wrap">
            <div class="inform_header">
                <div class="i_header_top">
                    <div class="trash_btn" @click="openDeleteConfirm"
                         v-if="itemData.isCanDelete"></div>
                </div>
            </div>
            <div class="inform_content">
                <div class="i_content_wrap">
                    <ul>
                        <li>
                            <div>{{ $t('type') }}</div>
                            <div><span>{{ returnItemType(itemData.type) }}</span></div>
                        </li>
                        <li>
                            <div>{{ $t('category') }}</div>
                            <div><span>{{ returnCategoryName(itemData.category) }}</span></div>
                        </li>
                        <li>
                            <div>{{ $t('i_name') }}</div>
                            <div><span>{{ itemData.title }}</span></div>
                        </li>
                        <li class="edition_wrap" v-if="itemData.isEdition === 1">
                            <div>{{ $t('edition') }}</div>
                            <div v-if="itemData.type === 'real'">
                                <span>{{ itemData.editionCount }} of {{ itemData.editionTotal }}</span></div>
                            <div v-else><span> {{ itemData.editionCount }} of {{ itemData.editionTotal }} </span></div>
                        </li>
                        <li class="file_wrap">
                            <div style="vertical-align: top">{{ $t('certi') }}</div>
                            <div style="height: 36px;">
                                <span v-for="(data,index) in guaranteeFile"
                                      :key="`guranty${index}`"
                                      @click="awsS3Download('guaranty', data, itemData.i_idx)">
                                            {{ data.org_name }}
                                </span>
                            </div>
                        </li>
                        <li class="file_wrap" v-if="originalFile.length > 0">
                            <div style="vertical-align: top">{{ $t('f_real') }}</div>
                            <div style="height: 36px;">
                                <span
                                    @click="awsS3Download('original', originalFile[0],itemData.i_idx,true)">
                                    {{originalFile[0].a_filename }}
                                </span>
                            </div>

                        </li>
                        <li class="creator_wrap" v-if="checkOriginal()">
                            <div>{{ $t('k_creator') }}</div>
                            <div>
                                <img :src="returnProfile(originatorProfile)"/>
                                <span>{{ itemData.originatorNick }}</span></div>
                        </li>
                        <li class="detail_info_box">
                            <div class="detail_title">{{ $t('detail_info') }}</div>
                            <div class="detail_wrap" v-if="exifType==='photo'">
                                <div>
                                    <span>{{ $t('t_video2') }}</span>
                                    <span v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_country2') }}</span>
                                    <span v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_si2') }}</span>
                                    <span v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_city2') }}</span>
                                    <span v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('size2') }}</span>
                                    <span v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('copyright_info2') }}</span>
                                    <span v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</span>
                                </div>
                            </div>
                            <div class="detail_wrap" v-else-if="exifType==='video'">
                                <div>
                                    <span>{{ $t('t_video2') }}</span>
                                    <span v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_country2') }}</span>
                                    <span v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_si2') }}</span>
                                    <span v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_city2') }}</span>
                                    <span v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_long') }} : </span>
                                    <span v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('copyright_info2') }}</span>
                                    <span v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</span>
                                </div>
                            </div>
                            <div class="detail_wrap" v-else-if="exifType==='audio'">
                                <div>
                                    <span>{{ $t('t_record') }} : </span>
                                    <span v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_country') }} : </span>
                                    <span v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_si') }} : </span>
                                    <span v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_city') }} : </span>
                                    <span v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('v_long') }} : </span>
                                    <span v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('copyright_info2') }}</span>
                                    <span v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</span>
                                </div>
                            </div>
                            <div class="detail_wrap" v-else-if="exifType==='etc'">
                                <div>
                                    <span>{{ $t('t_video2') }}</span>
                                    <span v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_country') }} : </span>
                                    <span v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_si') }} : </span>
                                    <span v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('c_city') }} : </span>
                                    <span v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</span>
                                </div>
                                <div>
                                    <span>{{ $t('copyright_info2') }}</span>
                                    <span v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {mapState} from "vuex";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemInfoViewLayout",
    mixins: [imageOption, awsS3FileDownload, imageResizeMixins],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setCategoryData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            validatorArr: [],
            guaranteeFile: [],
            thumbnailFile: [],
            originalFile: [],
            originatorProfile: [],
            category: '',
            exif: {},
            exifType: '',

            categoryObj: {},
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.guaranteeFile = this.$_.cloneDeep(this.itemData.guaranteeFile);
            this.thumbnailFile = this.$_.cloneDeep(this.itemData.thumbnailFile)
            this.originalFile = this.$_.cloneDeep(this.itemData.ntfFile)
            this.originatorProfile = this.$_.cloneDeep(this.itemData.originatorProfile)
            this.category = this.itemData.category;
            this.exifType = this.itemData.exifType;
            this.exif = this.$_.cloneDeep(this.itemData.exif)
            this.isEdition = this.$_.cloneDeep(this.itemData.isEdition);
            this.setCategory(this.category)
        },
        setCategory(value) {
            this.parentChange('category', value);
            let index = this.itemCategoryList.findIndex(cate => cate.PKEY == value);
            if (index > -1) {
                let categoryData = this.itemCategoryList[index];
                this.categoryObj = categoryData;
                this.setCategoryData(categoryData)
            }
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        returnItemImg() {
            if (!util.isEmpty(this.thumbnailFile)) {
                return this.setFitWidthImg(this.thumbnailFile, 730)
            }
        },
        checkIsDelete() {
            if (util.isEmpty(this.itemClass)) {
                return false;
            }
            return this.itemClass.isDeleteItem(this.itemData);
        },
        returnItemType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        returnCategoryName() {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return this.categoryObj.NAME
            }
            return this.categoryObj.NAME_ENG
        },
        checkOriginal() {
            return !util.isEmpty(this.itemData.originatorNick)
        },
        returnProfile(data) {
            if (!util.isEmpty(this.originatorProfile)) {
                return `${this.originatorProfile[0].org_url}?${this.setImageOptions(25, 25, 'png')}`
            }
        },
        checkExifEmpty(type) {
            return !this.itemData.exif.hasOwnProperty(type) && util.isEmpty(this.itemData.exif[type].value);
        },

        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('caution_del_item')}<br>${this.$t('caution_del')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.deleteItem();
                },
                cancel: () => {
                }
            })
        },
        async deleteItem() {
            EventBus.$emit('apiLoading', true);
            let result = await this.itemClass.deleteItem(this.itemData.i_idx);
            if (result) {
                this.deleteItemFinish();
            } else {
                this.errorAlert(this.$t('deleteErrorMsg'));
            }
            EventBus.$emit('apiLoading', false);
        },
        deleteItemFinish() {
            this.createAlert({
                title: '',
                content: this.$t('deleteSuccessMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    // this.$router.back();
                    this.$router.go(-2);
                },
            })
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
