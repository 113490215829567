<template>
    <ul class="list_item_wrap">
        <li class="list_item" v-for="(data,index) in memberData" :key="`member${index}`"
        >
            <div class="thumbnail" :style="` background-image: url(${returnBackground(data)});`"
                 @click="movePage(`creator/${data.mb_uuid}`)">
                <div class="curator" v-if="data.g_idx === 2"></div>
            </div>
            <div class="user_detail">
                <div class="user_wrap" @click="movePage(`creator/${data.mb_uuid}`)">
                    <div class="profile" :style="` background-image: url(${returnThumbnail(data)});`"></div>
                    <div class="badge" v-if="data.mb_premium === 1"></div>
                    <div class="user_nickname">{{ data.mb_nick }}</div>
                </div>
                <div class="sub_wrap  pl8 pr8">
                    <div class="like_img" :class="{'active': isMyLike(data)}" @click="likeMember(data.mb_no)"></div>
                    <div class="like_cnt" @click="likeMember(data.mb_no)">{{ data.likeCnt }}</div>
                    <span class="sub_point" @click="movePage(`creator/${data.mb_uuid}`)">·</span>
                    <div class="item_subject" @click="movePage(`creator/${data.mb_uuid}`)">Item <span>{{
                            data.itemCnt
                        }}</span></div>
                </div>
            </div>
        </li>
    </ul>

</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberListSearchCreatorLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: [],
        paginationData: ''
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnLike(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.Like)) {
                return data.Like.cnt
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
                return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, 'png')}`;
            }
        },
        returnBackground(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.ProfileBack)) {
                return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 218, 'jpg')}`;
            }
        },
        isMyLike(data) {
            return data.likeStatus
        },
        likeMember(data) {
            EventBus.$emit('like', data, 'member');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
