import EventBus from "@/utils/event-bus";

const awardConfig = {
    award: {
        listLayout: 'AwardListDefaultLayout',
        emptyLayout: 'AwardEmptyDefaultLayout',
        filterLayout: 'AwardFilterDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
        getFunc : 'getAward',
        listApiParamSet:{},
        getKeyName: 'cuid',
    },
    awardDetail: {
        detailLayout: 'AwardDetailDefaultLayout',
    },
    awardWrite: {
        writeLayout: 'AwardWriteDefaultLayout',
        writeFunc: 'createCompetition',
        writeApiParamSet: {

        },
        getFunc: 'getCompetitionDetail',
        detailApiParamsSet: {
            ip: 0,

        },
        parsingData: {
        },
        keyName : 'id',
        getKeyName: 'uid',
    }
}
export default {
    methods: {
        returnAwardConfig(type) {
            return awardConfig[type];
        },
    }
}
