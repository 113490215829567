<template>
    <div class="like_icon_group">
        <div class="like_wrap">
            <div class="like_img mr10" :class="{'active': isMyLike()}"
                 @click="like()"></div>
            <div class="like_count" @click="like()">{{ returnLike() }}</div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import collectionMixin from "@/mixins/collectionMixin";
import util from "@/mixins/util";

export default {
    name: "LikeCollectionComponent",
    mixins: [collectionMixin],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeType: '',
        likeData: {}
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('like', this.like);
    },
    beforeDestroy() {
        EventBus.$off('like');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        like() {
            EventBus.$emit('apiLoading', true);
            let params = {c_idx: this.likeData.c_idx}
            this.$api.$collect.collectLike(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.likeAfterFunc('add');
                } else if (res.Info.type === 2) {
                    this.likeAfterFunc('sub');
                }
                EventBus.$emit('apiLoading', false);
            })
        },

        likeAfterFunc(key) {
            let likeCnt = Number(this.likeData.Likes.cnt)
            if (key === 'add') {
                this.likeData.Likes.cnt = likeCnt + 1;
            } else if (key === 'sub') {
                this.likeData.Likes.cnt = likeCnt - 1;
            }
            this.likeData.Likes.status = !this.likeData.Likes.status;

            this.updateCollectionList(this.likeData);
            EventBus.$emit('getCollectList')
            EventBus.$emit('getCollect')
            this.$forceUpdate();
        },
        returnLike() {
            if (!util.isEmpty(this.likeData.Likes)) {
                return this.likeData.Likes.cnt
            }
            return 0
        },
        isMyLike() {
            if (!util.isEmpty(this.likeData.Likes)) {
                return this.likeData.Likes.status
            }
            return false
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>