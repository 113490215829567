import { render, staticRenderFns } from "./RegistSocialComponent.vue?vue&type=template&id=0b1dc006&scoped=true"
import script from "./RegistSocialComponent.vue?vue&type=script&lang=js"
export * from "./RegistSocialComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b1dc006",
  null
  
)

export default component.exports