<template>
    <div class="cp_list_wrap">
        <div class="cp_text">
            <span>{{ $t('competition') }}</span>
            <span class="competition_text ml10">{{ $t('competition_introduction_msg') }}</span>
            <button @click="openUrl()">{{$t('competition_introduction')}}</button>
            <button @click="movePage('/competitioncreate')">{{$t('enroll_comp')}}</button>
        </div>

        <div class="cp_tap_wrap">
            <button class="cp_tap" :class="{ active : tab === 'competitionAll'}" @click="changeTab('competitionAll')">
                {{ $t('all') }}
                <div class="active_b_line" v-show="tab === 'competitionAll'"></div>
            </button>
            <button class="cp_tap" :class="{ active : tab === 'competitionArt'}" @click="changeTab('competitionArt')">
                {{ $t('competition_art') }}
                <div class="active_b_line" v-show="tab === 'competitionArt'"></div>
            </button>
            <button class="cp_tap" :class="{ active : tab === 'competitionCollect'}"
                    @click="changeTab('competitionCollect')">
                {{ $t('competition_idea') }}
                <div class="active_b_line" v-show="tab === 'competitionCollect'"></div>
            </button>
            <button class="cp_tap" :class="{ active : tab === 'competitionPhoto'}"
                    @click="changeTab('competitionPhoto')">
                {{ $t('picture') }}
                <div class="active_b_line" v-show="tab === 'competitionPhoto'"></div>
            </button>
            <button class="cp_tap" :class="{ active : tab === 'competitionMusic'}"
                    @click="changeTab('competitionMusic')">
                {{ $t('competition_broad') }}
                <div class="active_b_line" v-show="tab === 'competitionMusic'"></div>
            </button>
            <button class="cp_tap" :class="{ active : tab === 'competitionVideo'}"
                    @click="changeTab('competitionVideo')">
                {{ $t('etc') }}
                <div class="active_b_line" v-show="tab === 'competitionVideo'"></div>
            </button>
        </div>
        <div v-if="tab !== ''">
            <competition-list-component :competition-type="tab"></competition-list-component>
        </div>
        <competition-filter-modal></competition-filter-modal>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import CompetitionFilterModal from "@/components/modal/CompetitionFilterModal";

export default {
    name: "competition",
    metaInfo: {
        title: `공모전(Contest)`,
        titleTemplate: '%s | 밍글민트(Minglemint NFT Marketplace)',
    },
    mixins: [],
    components: {
        CompetitionListComponent,
        CompetitionFilterModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'competitionAll',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.$router.replace(this.$route.path)
                this.tab = tab
            })
        },
        movePage(url) {
            this.$router.push(url);
        },
        openUrl() {
            window.open('https://www.youtube.com/watch?v=pvivoJwJ2uA', '_blank');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
