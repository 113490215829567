import { render, staticRenderFns } from "./CompetitionHomeListComponent.vue?vue&type=template&id=3cdbf466&scoped=true"
import script from "./CompetitionHomeListComponent.vue?vue&type=script&lang=js"
export * from "./CompetitionHomeListComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cdbf466",
  null
  
)

export default component.exports