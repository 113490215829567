import util from "@/mixins/util";
import Vue from 'vue'

function encodeBase64(data) {
    let encodedString = btoa(data);
    return encodedString;
}

function decodeBase64(codeString) {
    let decodedString = atob(codeString);
    return decodedString;
}


class LevelClass {
    levelObj = {}
    checkHasLevelObj() {
        return util.isEmpty(this.levelObj);
    }
    setterLevelObj(data) {
        this.levelObj = data;
        let objString = JSON.stringify(data);
        let encodedData = encodeBase64(objString);
        Vue.$localStorage.set('level', encodedData);
    }
    getterLevelObj() {
        let codeString = Vue.$localStorage.get('level');
        if(!util.isEmpty(codeString)) {
            let levelObjString = decodeBase64(codeString);
            this.levelObj = JSON.parse(levelObjString);
        }
        return this.levelObj;
    }
}

export {LevelClass};
