<template>
    <div class="search_wrap">
        <!--        <search-detail-component></search-detail-component>-->
        <member-list-component :member-type="'searchCreatorDetail'"
                               v-if="searchType==='member'"></member-list-component>
        <item-list-component :item-type="'searchItemDetail'" v-else-if="searchType==='item'"></item-list-component>
        <competition-list-component :competition-type="'searchCompetition'"
                                    v-else-if="searchType==='competition'"></competition-list-component>
        <collect-list-component :collect-type="'searchCollect'"
                                v-else-if="searchType==='collect'"></collect-list-component>
    </div>
</template>

<script>
import SearchDetailComponent from "@/components/search/SearchDetailComponent";
import MemberListComponent from "@/components/member/MemberListComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import CollectListComponent from "@/components/collect/CollectListComponent";

export default {
    name: "SearchDetail",
    metaInfo: {
        title: `밍글민트(Minglemint NFT Marketplace)`,
    },
    mixins: [],
    components: {
        CollectListComponent,
        CompetitionListComponent,
        ItemListComponent,
        MemberListComponent,
        SearchDetailComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {

            searchType: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.searchType = this.$route.params.type;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>