<template>
    <div class="register_main_wrap">
        <div class="reg_top">
            {{ $t('loginText1') }}
            <br>{{ $t('loginText2') }}
            <div><img src="@/assets/image/main/logo2.png" alt=""></div>
        </div>
        <register-social-component></register-social-component>
    </div>
</template>

<script>
import RegisterSocialComponent from "@/components/register/RegistSocialComponent";
import {request, setAuthInHeader} from "@/api/request";
import Vue from 'vue'
import EventBus from "@/utils/event-bus";

export default {
    name: "Register",
    mixins: [],
    components: {
        RegisterSocialComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    beforeRouteLeave(to, from, next) {
        if (to.name !== 'RegisterAfter') {
            Vue.$localStorage.clear();
            setAuthInHeader('');

        }
        location.href = to.path;
        // return next();
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>