<template>
    <div class="search_wrap">
        <search-component></search-component>
    </div>
</template>

<script>

import SearchComponent from "@/components/search/SearchComponent";

export default {
    name: "Search",
    metaInfo: {
      title: `밍글민트(Minglemint NFT Marketplace)`,
    },
    mixins: [],
    components: {
        SearchComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>