<template>
    <div class="my_collect_list_wrap">
        <div class="my_collect_setting">
            <button class="setting_btn" @click="movePage('/collect')">{{ $t('collect_setting') }}</button>
            <br>
        </div>
        <collect-list-component :collect-type="'profileCollect'"
                                :defaultFilter="defaultFilter"></collect-list-component>
    </div>

</template>

<script>
import util from "@/mixins/util";
import CollectListComponent from "@/components/collect/CollectListComponent";
import {mapState} from "vuex";


export default {
    name: "MyProfileCollect",
    mixins: [],
    components: {CollectListComponent,},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            defaultFilter: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {no: this.UserInfo.mb_no}
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
