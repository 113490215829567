<template>
    <div class="submit_main_wrap">
        <div class="submit_wrap item_create_btn">
            <div class="submit_btn">
                <button class="enroll_submit_btn" @click="save('nosale')" >{{ $t('save') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "ItemNoSaleSaveLayout",
    mixins: [],
    components: {
    },
    props: {
        setComponentData: {default: () => {}, type: Function},
        checkValidator: {default: () => {}, type: Function},
        itemData: {default: () => {}, type: Object},
        itemClass: {default: null, }
    },
    data() {
        return {
            termsCheck: false,
            showTermsMsg: false,
        }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        changeCheck() {
            if(this.showTermsMsg) {
                this.showTermsMsg = false;
            }
        },
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        checkTerms(itemType) {
            if (!this.termsCheck) {
                this.showTermsMsg = true;
                return false;
            }
            this.showTermsMsg = false;
            this.save(itemType)
        },
        save(itemType) {
            this.setComponentData('itemType', itemType);
            this.checkValidator();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
