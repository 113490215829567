<template>
    <modal name="entry-search-modal" class="entry-search-modal"
           :width="`1200px`" :height="`768px`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="en_modal_wrap">
            <div class="en_modal_title">
                <span>{{ $t('search_entry') }}</span>
                <button class="cancel_btn" @click="modalClose"></button>
            </div>
            <!--            <entry-list-component :entry-type="'entrySearch'" :entryKey="key"></entry-list-component>-->
            <!--            <item-list-component :item-type="`entrySearch`"></item-list-component>-->
            <competition-list-component :competition-key="key"
                                        competition-type="entrySearch"
                                        :selectArray="selectArray"></competition-list-component>
        </div>
    </modal>
</template>

<script>
import EntryListComponent from "@/components/entry/EntryListComponent";
import util from "@/mixins/util";
import ItemListComponent from "@/components/item/ItemListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "EntrySearchModal",
    mixins: [],
    components: {
        CompetitionListComponent,
        ItemListComponent,
        EntryListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            key: 0,
            idx: 0,
            selectArray:[],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('selectEntryModalItem', this.selectEntryModalItem)
    },
    beforeDestroy() {
        EventBus.$off('selectEntryModalItem')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.idx) || util.isEmpty(event.params.competitionKey)) {
                event.stop()
            } else {
                this.idx = event.params.idx
                this.key = event.params.competitionKey
                this.selectArray = event.params.selectArray
            }
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('entry-search-modal');
        },
        returnUrl() {
            return this.url
        },
        selectEntryModalItem(idx,name) {
            EventBus.$emit('awardSetData',this.idx,idx,name)
            this.modalClose()
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
